import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {SubscriptionsBag} from "./subscriptions-bag";
import {distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {BehaviorSubject, combineLatest} from "rxjs";

export class MetaTagsData {
  public title: string;
  public imageUrl: string;
  public desc: string;
  public url: string;
}

class MetaTagsDataIntetnal extends MetaTagsData {
  public componentInstance;
}

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService implements OnDestroy {

  private sb: SubscriptionsBag = new SubscriptionsBag();

  private defaultTags: MetaTagsData = null;
  private tagsByComponentType = {};

  private tagsByComponentType$ = new BehaviorSubject({});

  public constructor(
    private router: Router,
    private titleService: Title,
    private meta: Meta,
  ) {
  }

  public init(defaultTags: MetaTagsData): void {

    this.defaultTags = defaultTags;

    combineLatest([
      this.router.events.pipe(filter(e => e instanceof NavigationEnd)),
      this.tagsByComponentType$
    ]).pipe(
      map(([e, tagsByComponentType]) =>
        this.searchComponentTypeName(this.router.routerState.root, tagsByComponentType)
      ),
      tap((componentTypeName) => {
        const tags = this.tagsByComponentType[componentTypeName] || this.defaultTags;
        this.apply(tags);
      })
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.sb.unsubscribeAll();
  }

  public set(componentInstance: any, data: MetaTagsData): void {
    const key = componentInstance?.constructor?.name;

    if (!key) {
      return;
    }

    this.tagsByComponentType[key] = data;
    this.tagsByComponentType$.next(this.tagsByComponentType);
  }

  private searchComponentTypeName(activatedRoute: ActivatedRoute, tagsByComponentType: any): string {
    let stack: ActivatedRoute[] = [];
    stack.push(activatedRoute);

    let componentTypeName: string = null;

    while (stack.length) {
      let ar = stack.pop();
      stack.push(...ar.children);

      const key = (ar?.component as any)?.name;

      if (key && tagsByComponentType[key]) {
        componentTypeName = key;
        break;
      }
    }

    return componentTypeName;
  }

  private apply(data: MetaTagsData): void {

    if (!data) {
      return;
    }

    if (data.title) {

      this.titleService.setTitle(data.title);

      this.meta.updateTag({name: 'og:title', content: data.title}, "name='og:title'");
      this.meta.updateTag({
        name: 'og:image',
        content: data.imageUrl,
      }, "name='og:image'");
    }

    if (data.desc) {
      this.meta.updateTag({
        name: 'og:description',
        content: data.desc
      }, "name='og:description'");
    }

    if (data.url) {
      this.meta.updateTag({
        name: 'og:url',
        content: data.url,
      }, "name='og:url'");
    }
  }
}

