import {Injectable} from '@angular/core';
import {ToastController} from "@ionic/angular";

@Injectable()
export class ToastService {

  private duration: number = 4000;
  private buttons = [
    {
      icon: 'close-circle-outline',
      role: 'cancel'
    }
  ];

  constructor(
    public toastController: ToastController
  ) {
  }

  async success(message: string, toastSettings = null) {
    const toast = await this.toastController.create({
      ...{
        color: "success",
        duration: this.duration,
        message: message,
        buttons: [...this.buttons],
      },
      ...toastSettings
    });
    await toast.present();
  }

  async info(message: string, toastSettings = null) {
    const toast = await this.toastController.create({
      ...{
        color: "primary",
        duration: this.duration,
        message: message,
        buttons: [...this.buttons],
      },
      ...toastSettings
    });
    await toast.present();
  }

  async warning(message: string, toastSettings = null) {
    const toast = await this.toastController.create({
      ...{
        color: "warning",
        duration: this.duration,
        message: message,
        buttons: [...this.buttons],
      },
      ...toastSettings
    });
    await toast.present();
  }

  async error(message: string, toastSettings = null) {
    const toast = await this.toastController.create({
      ...{
        color: "danger",
        duration: this.duration,
        message: message,
        buttons: [...this.buttons],
      },
      ...toastSettings
    });
    await toast.present();
  }

}
