<ion-app>

  <ion-router-outlet></ion-router-outlet>

  <ion-tab-bar *ngIf="isMobile && activatedUrl" slot="bottom">
    <ion-tab-button routerLink="/home" [ngClass]="{'tab-selected': activatedUrl == '/home'}">
      <ion-icon name="home"></ion-icon>
      Главная
    </ion-tab-button>
    <ion-tab-button routerLink="/catalog" [ngClass]="{'tab-selected': activatedUrl == '/catalog'}">
      <ion-icon name="people"></ion-icon>
      Мастера
    </ion-tab-button>
    <ion-tab-button routerLink="/bookings" [ngClass]="{'tab-selected': activatedUrl == '/bookings'}">
      <ion-icon name="book"></ion-icon>
      Записи
    </ion-tab-button>
    <ion-tab-button routerLink="/profile" [ngClass]="{'tab-selected': activatedUrl == '/profile'}">
      <ion-icon name="person-circle"></ion-icon>
      Профиль
    </ion-tab-button>
    <ion-tab-button routerLink="/help" [ngClass]="{'tab-selected': activatedUrl == '/help'}">
      <ion-icon name="help-buoy"></ion-icon>
      Помощь
    </ion-tab-button>
  </ion-tab-bar>

  <app-menu *ngIf="!isMobile"></app-menu>

</ion-app>

<lib-network-status></lib-network-status>
