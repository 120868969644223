<input #input style="display: none" type="file" multiple (change)="onFilesSelected($event)"
       accept="image/png, image/jpeg, image/webp">

<div class="images">
  <div *ngFor="let img of this.imagesList"
       class="image-container default"
       [ngClass]="{'default': this.size == 'default', 'small': this.size == 'small'}">

    <img [src]="img | fileUrl | thumbnailUrl"
         (click)="onImageClick(img, $event)"/>

    <ion-button *ngIf="allowDeleting == true" class="delete-icon" fill="outline" size="small"
                (click)="onDeleteClick(img)">
      <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
    </ion-button>
  </div>

  <div class="image-container default" *ngIf="allowAdding">
    <ion-buttons class="add-button"
                 style="display: flex; flex-direction: column; align-items: center; justify-content: center"
                 (click)="input.click()">
      <ion-button>
        <ion-icon name="add-circle-outline" size="large" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>

</div>




