import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {FormControl} from "@angular/forms";
import {startWith} from "rxjs/operators";
import {PriceListVm, PriceListItem} from "../price-list-vm";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'app-choose-price-list-item',
  templateUrl: './choose-price-list-item.page.html',
  styleUrls: ['./choose-price-list-item.page.scss'],
})
export class ChoosePriceListItemPage extends ModalComponentBase implements OnInit {
  @Input()
  public priceList: PriceListVm = new PriceListVm();

  @Input()
  public showSpecialities: boolean = false;

  constructor(
    modalController: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
    public applicationIdService: ApplicationIdService,
  ) {
    super(modalController, router, activeRoute, location);
  }

  ngOnInit() {
    super.init().then();
  }

  public async onCloseClick(): Promise<void> {
    await super.dismiss();
  }

  public async onItemClicked(item: PriceListItem): Promise<void> {
    await super.dismiss(item);
  }
}
