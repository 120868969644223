import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SignInController} from "../signInController";
import {ActivatedRouteFixService} from "../../utils/activated-route-fix.service";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'lib-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
  host: {'class': 'ion-page'},
})
export class CodeComponent implements OnInit {
  public formGroupCode: FormGroup;

  public get processing(): boolean {
    return this._signInController.processing;
  }

  constructor(
    private _signInController: SignInController,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _activatedRouteFix: ActivatedRouteFixService,
  ) {
  }

  ngOnInit() {
    if (this._signInController.phone == null) {
      this._router.navigate(['../'],
        {
          relativeTo: this._activatedRouteFix.getActivatedRoute(this._activatedRoute),
          replaceUrl: true
        }
      ).then();

      return;
    }

    this.formGroupCode = new FormGroup({
      codeControl: new FormControl(null, [
        Validators.required,
        Validators.minLength(6)
      ]),
    });
  }

  public async onSubmitCodeClick(): Promise<void> {
    if (this.processing || this.formGroupCode.invalid) {
      return;
    }

    const codeControl = this.formGroupCode.get('codeControl');
    const code = codeControl.value;
    await this._signInController.step2(code);
  }
}
