import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit} from '@angular/core';
import {AuthService, FeedbackClient, RefreshService, SubscriptionsBag} from "bc-common";
import {Router} from "@angular/router";
import {NgEventBus} from "ng-event-bus";
import {startWith} from "rxjs/operators";

@Component({
  selector: 'app-your-bookings',
  templateUrl: './your-bookings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YourBookingsComponent implements OnInit {
  @Input()
  public masterId: string = null;
  @Input()
  public saloonId: string = null;

  @Input()
  public hideIfEmpty: boolean = true;

  public processing: boolean = true;

  @HostBinding('hidden')
  public get hidden() {
    return this.hideIfEmpty && this.empty;
  }

  public get empty(): boolean {
    if (this.feedbacks === null) {
      return null;
    }

    return this.feedbacks.length === 0;
  }

  public feedbacks: FeedbackClient.BookingWithFeedback[] = null;
  public now = new Date();
  public mode: "home-client-bookings" | "profile-bookings";

  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _authService: AuthService,
    private _feedbackClient: FeedbackClient.FeedbackClient,
    private _eventBus: NgEventBus,
    private _refreshService: RefreshService,
  ) {
  }

  public ngOnInit(): void {
    if (this.masterId || this.saloonId) {
      this.mode = "profile-bookings";
    } else {
      this.mode = "home-client-bookings";
    }

    this.sb.sub = this._refreshService.refreshAndResume$.subscribe(() => {
      this.ngOnDestroy();
      this.ngOnInit();
    })

    this.sb.sub = this._eventBus.on('booking:*')
      .pipe(startWith(null))
      .subscribe(async () => {
        await this.loadData();
      });
  }

  ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

  public isWeekPassed(endTime): boolean {
    let weekAgo = new Date(endTime);
    weekAgo.setDate(weekAgo.getDate() + 7);
    return new Date() > weekAgo;
  }

  public async loadData(): Promise<void> {
    this.processing = true;
    const clientId = this._authService.parsedToken?.userId;

    if (!clientId) {
      return;
    }

    await this._feedbackClient.getBookingsWithFeedbacks(
      this.masterId ?? null,
      this._authService.parsedToken?.userId,
      this.saloonId ?? null,
      [],
      false,
      false,
      null,
      null,
      null,
      null,
      null,
      0,
      0
    ).toPromise()
      .then(data => {
        let resultFeedbacks = [];

        const sortedFeedbacks = data.bookingsWithFeedbacks.filter(bwf => this.isWeekPassed(bwf.endTime) == false)
          .sort((a, b) => new Date(a.endTime).getTime() - new Date(b.endTime).getTime());

        let pastFeedbacks = sortedFeedbacks.filter(f => this.now > f.endTimeOffset);
        if (pastFeedbacks.length) {
          let item = pastFeedbacks.pop();
          if (!item.clientText && !item.clientStars) {
            resultFeedbacks.push(item);
          }
        }

        let futureFeedbacks = sortedFeedbacks.filter(f => this.now <= f.endTime)

        resultFeedbacks.push(...futureFeedbacks);

        return resultFeedbacks;
      })
      .then(resultFeedbacks => this.feedbacks = resultFeedbacks)
      .finally(() => {
        this.processing = false;
        this._cdr.markForCheck();
      });
  }

  public trackById(index, item: any): any {
    return item.id;
  }
}
