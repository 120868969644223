import {NgModule} from '@angular/core';
import {BcCommonModule} from "../bc-common.module";

import {AvatarComponent} from "./avatar.component";
import {InitialsPipe} from "./initials.pipe";
import {BackgroundPipe} from "./background.pipe";
import {ImagesModule} from "../images/images.module";


@NgModule({
  imports: [
    BcCommonModule,
    ImagesModule,
  ],
  declarations: [AvatarComponent, InitialsPipe, BackgroundPipe],
  exports: [AvatarComponent, InitialsPipe, BackgroundPipe]
})
export class AvatarModule {
}
