import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {
  MasterProfileComplete,
  CreatePriceListItemReq
} from "../../api-clients/master-list/clients";
import {BookingRes, UpdateScheduleSettingsReq} from "../../api-clients/booking/clients";
import {AddressSetupData} from "../../address/addressController";

@Injectable()
export class MasterFirstSetupWizardSettings {
  public masterId: string;
  public addressId: string;
  public scheduleUrl: string;
}

@Injectable()
export class MasterFirstSetupWizardController {

  private _initialized: Promise<void> = Promise.resolve();

  private _profileDataSubj = new BehaviorSubject<ProfileData>(null);
  private _addressDataSubj = new BehaviorSubject<AddressSetupData>(new AddressSetupData());
  private _priceListItemsDataSubj = new BehaviorSubject<void>(null);
  private _specsDataSubj = new BehaviorSubject<void>(null);
  private _botsDataSubj = new BehaviorSubject<void>(null);
  private _scheduleSettingsDataSubj = new BehaviorSubject<ScheduleSettingsData>(null);
  private _masterCheckResultDataSubj = new BehaviorSubject<CheckResultData>(null);

  public refreshSubj = new Subject<void>();

  private _loader = new BehaviorSubject<boolean>(false);

  public get initialized(): Promise<void> {
    return this._initialized;
  }

  public get profileData(): ProfileData {
    return this._profileDataSubj.value;
  }

  public get addressData(): AddressSetupData {
    return this._addressDataSubj.value;
  }

  public get loader(): boolean {
    return this._loader.value;
  }

  public get scheduleSettingsData(): ScheduleSettingsData {
    return this._scheduleSettingsDataSubj.value;
  }

  public get masterCheckResultData(): CheckResultData {
    return this._masterCheckResultDataSubj.value;
  }

  public get profileData$(): Observable<ProfileData> {
    return this._profileDataSubj.asObservable();
  }

  public get priceListItemsData$(): Observable<void> {
    return this._priceListItemsDataSubj.asObservable();
  }

  public get botsData$(): Observable<void> {
    return this._botsDataSubj.asObservable();
  }

  public get specsData$(): Observable<void> {
    return this._specsDataSubj.asObservable();
  }

  public get scheduleSettingsData$(): Observable<ScheduleSettingsData> {
    return this._scheduleSettingsDataSubj.asObservable();
  }

  public get masterCheckResultData$(): Observable<CheckResultData> {
    return this._masterCheckResultDataSubj.asObservable();
  }

  public constructor(private _masterFirstSetupWizardSettings: MasterFirstSetupWizardSettings) {
  }

  public setProfileData(profileData: ProfileData): void {
    this._profileDataSubj.next(profileData);
  }

  public setPriceListItemsData(): void {
    this._priceListItemsDataSubj.next();
  }

  public setSpecsData(): void {
    this._specsDataSubj.next();
  }

  public setBotsData(): void {
    this._botsDataSubj.next();
  }

  public setScheduleSettingsData(scheduleSettingsData: ScheduleSettingsData): void {
    this._scheduleSettingsDataSubj.next(scheduleSettingsData);
  }

  public setMasterCheckResultData(masterCheckResult: CheckResultData): void {
    this._masterCheckResultDataSubj.next(masterCheckResult);
  }

  public loaderShow() {
    this._loader.next(true);
  }

  public loaderHide() {
    this._loader.next(false);
  }
}

export class ProfileData {
  firstName: string;
  lastName: string;
  aboutMe: string;
  avatarFileName: string;
  avatarData: File;
}

export class PriceListItemsData {
  priceListItemsReq: CreatePriceListItemReq[];
}

export class SpecsData {
  specialityIds: string[];
}

export class ScheduleSettingsData {
  settings: UpdateScheduleSettingsReq;
}

export class CheckResultData {
  checkResult: MasterProfileComplete;
  bookings: BookingRes[];
  days: Date[];
}
