<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>
      Инфо
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <lib-refresher slot="fixed"></lib-refresher>

  <div class="content-section cards-list safe-area-space">

    <lib-card-empty
      *ngIf="ifNullOrEmptyOrWhiteSpace(vm.master?.aboutMeText?.trim()) &&
      vm.master?.aboutMeImagesNames?.length == 0 &&
      authService.isAuthenticated &&
      (!vm.contacts) &&
      (!vm.saloonsContacts || vm.saloonsContacts?.length == 0)"></lib-card-empty>

    <div class="card padding sing-in" *ngIf="!authService.isAuthenticated">
      <span class="ion-text-center">
        Чтобы увидеть контакты:
      </span>
      <div>
        <ion-button (click)="this.onContactsSignIn()">Войти</ion-button>
      </div>
    </div>

    <div *ngIf="!ifNullOrEmptyOrWhiteSpace(vm.master?.aboutMeText?.trim())"
         class="card padding text">
      <div [innerText]="vm.master?.aboutMeText?.trim()"></div>
    </div>

    <div class="card padding" *ngIf="vm.contacts && (vm.saloonsContacts.length == 0 || vm.showMasterContacts == true)">
      <h2>{{vm?.master?.fullName}}</h2>
      <lib-contacts-info [contacts]="vm.contacts"></lib-contacts-info>
    </div>

    <div class="card padding" *ngFor="let contacts of vm.saloonsContacts">
      <h2>{{contacts.saloonAddress.addressSaloonType}} {{contacts.saloonAddress.addressSaloonName}}</h2>
      <lib-contacts-info [contacts]="contacts.contacts"></lib-contacts-info>
    </div>

    <div class="card padding" *ngIf="vm.master?.aboutMeImagesNames.length > 0">
      <app-images-set [imagesInit]="vm.master?.aboutMeImagesNames" title="О себе"></app-images-set>
    </div>

  </div>

</ion-content>
