import {inject} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {TokenStoreService} from "./token-store.service";

export function TokenlessGuard(): CanActivateFn {
  return () => {
    const token = inject(TokenStoreService).getToken();

    if (!token) {
      return true;
    } else {
      return inject(Router).navigate(['/'], { replaceUrl: true })
        .then(() => false);
    }
  }
}
