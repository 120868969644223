import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {map, tap} from "rxjs/operators";
import {
  BlockClientReq,
  DeleteMasterClientReq,
  MasterListClient, UnblockClientReq,
  UpdateMasterClientReq, UpdateNoteBlockingReq
} from "../../../api-clients/master-list/clients";
import {ToastService} from "../../../toast/toast.service";
import {ApplicationIdService} from "../../../application-id/application-id.service";
import {TextInputPopupComponent} from "../../../editors/text-input-popup/text-input-popup.component";
import {TextAreaPopupComponent} from "../../../editors/text-area-popup/text-area-popup.component";
import {InstagramChangePopupComponent} from "../../../editors/instagram-change-popup/instagram-change-popup.component";
import {ClientVm} from "../../../client/clients-vm";
import {ActivatedRouteFixService} from "../../../utils/activated-route-fix.service";
import {AuthService} from "../../../security/auth.service";
import {SaloonsClient} from "../../../api-clients/saloon/clients";
import {ClientsControllerProvider, MasterClientsController} from "../masterClientsController";
import {DeleteClientModalComponent} from "../../../editors/delete-client-modal/delete-client-modal.component";

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  providers: [
    {
      provide: MasterClientsController,
      useFactory: (provider: ClientsControllerProvider) => provider.provide(),
      deps: [ClientsControllerProvider]
    }
  ]
})
export class ClientComponent implements OnInit {

  public clientId: string = null;
  public masterId: string = null;

  public client: ClientVm = null;
  public mode: "client" | "master" | "saloon";

  public clientNameUpdated: boolean = false;
  public processing: boolean = false;

  public relatedSaloonId: string = null;
  public relatedSaloonName: string = null;

  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private masterListClient: MasterListClient,
    private saloonClient: SaloonsClient,
    private toastService: ToastService,
    private alertController: AlertController,
    private applicationIdService: ApplicationIdService,
    private masterClientController: MasterClientsController,
  ) {
  }

  public ngOnInit(): void {
    this.processing = true;
    this.mode = this.applicationIdService.applicationId;
    this.clientId = this.activatedRoute.snapshot.paramMap.get('id');
    this.masterId = this.authService.parsedToken.userId;

    this.masterListClient.getClients(this.masterId, null, [this.clientId], 0, 1, true)
      .pipe(
        map(res => res.clients[0]),
        tap(async client => {
          if (client.saloonId) {
            return this.saloonClient.getSaloonById(client.saloonId)
              .toPromise()
              .then((data) => {
                this.relatedSaloonId = data.saloon.id;
                this.relatedSaloonName = data.saloon.name;
              });
          }
        }),
        map(client => ClientVm.ParseFromMaster(client))
      )
      .toPromise()
      .then(clientVm => {
        this.client = clientVm;
      })
      .finally(() => this.processing = false);
  }

  public ngOnDestroy() {
  }

  public async onCloseClick() {
  }

  async onClientNameClick(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: TextInputPopupComponent,
      componentProps: {
        title: 'Имя',
        val: this.client.definedName
      },
    });

    modal.onDidDismiss().then(async e => {

      const req = this.createReq();

      if (e.data == null) {
        return;
      }

      if (!this.ifNullOrEmptyOrWhiteSpace(e.data)) {
        req.masterDefinedName = e.data;
      } else {
        return;
      }

      await this.masterListClient.updateMasterClient(req).toPromise()
        .then(() => {
          this.toastService.success("Имя изменено");
          this.client.definedName = req.masterDefinedName?.trim();
          this.clientNameUpdated = true;
          this.masterClientController.notifyClientsUpdated();
        })
        .catch(err => {
          this.toastService.error(err.result.messages[0]);
        });

    });


    await modal.present();
  }

  async onClientNoteClick(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: TextAreaPopupComponent,
      componentProps: {
        title: 'Примечание',
        val: this.client.note
      },
    });

    modal.onDidDismiss().then(async e => {

      const req = this.createReq();

      if (e.data == null) {
        return;
      }

      if (!this.ifNullOrEmptyOrWhiteSpace(e?.data)) {
        req.note = e.data?.toString()?.trim();
      } else {
        req.note = null;
      }

      await this.masterListClient.updateMasterClient(req).toPromise()
        .then(() => {
          this.toastService.success("Примечание изменено");
          this.client.note = req.note;
        })
        .catch(err => {
          this.toastService.error(err.result.messages[0]);
        });
    });


    await modal.present();
  }

  async onClientNoteBlockingClick(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: TextAreaPopupComponent,
      componentProps: {
        title: 'Примечание о блокировке',
        val: this.client.noteBlocking
      },
    });

    modal.onDidDismiss().then(async e => {
      if (e.data == null) {
        return;
      }
      let note;

      if (!this.ifNullOrEmptyOrWhiteSpace(e?.data)) {
        note = e.data?.toString()?.trim();
      } else {
        note = null;
      }

      await this.masterListClient.updateNoteBlocking({
        clientId: this.clientId,
        masterId: this.masterId,
        note: note
      } as UpdateNoteBlockingReq).toPromise()
        .then(() => {
          this.client.noteBlocking = note;
          this.toastService.success("Примечание о блокировке изменено");
        })
        .catch(err => {
          this.toastService.error(err.result.messages[0]);
        });
    });

    await modal.present();
  }

  public async onInstagramClick(): Promise<void> {
    if (this.client.phone == null) {
      return;
    }

    if (this.client.originalInstagramProfile) {
      navigator.clipboard.writeText(this.client.originalInstagramProfile).then();
      this.toastService.info("Cкопировано").then();
      return;
    }

    if (this.client.originalInstagramProfile == null) {
      const modal = await this.modalCtrl.create({
        component: InstagramChangePopupComponent,
        componentProps: {
          isAcceptLink: true,
        },
      });

      modal.onDidDismiss().then(async e => {

        const req = this.createReq();

        if (e.data == null) {
          return;
        }

        if (!this.ifNullOrEmptyOrWhiteSpace(e?.data)) {
          req.masterDefinedInstagramProfile = e.data;
        } else {
          req.masterDefinedInstagramProfile = null;
        }

        await this.masterListClient.updateMasterClient(req).toPromise()
          .then(() => {
            this.toastService.success("Instagram изменен");
            this.client.definedInstagramProfile = req.masterDefinedInstagramProfile;
          })
          .catch(err => {
            this.toastService.error(err.result.messages[0]);
          });

      });

      await modal.present();
    }
  }

  private async onInstagramLinkClick($event) {
    $event?.preventDefault();
    $event?.stopPropagation();

    const link = this.client.originalInstagramProfile ?? this.client.definedInstagramProfile;
    let url: string = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'https://';
    }
    url += link;

    window.open(url, "_blank");
  }

  public async onPhoneClick() {
    if (this.client.phone == null) {
      return;
    }

    const phone = this.client.phone;
    navigator.clipboard.writeText(phone).then();
    this.toastService.info("Cкопировано").then();
  }

  public onPhoneCallClick($event): void {
    $event?.preventDefault();
    $event?.stopPropagation();

    const phone = this.client.phone;
    var fallbackLink = 'tel:' + phone;
    window.open(fallbackLink);
  }

  public async unblockClient(): Promise<void> {
    await this.masterListClient.unblockClient({
      clientId: this.clientId,
      masterId: this.masterId
    } as UnblockClientReq).toPromise()
      .then(() => {
        this.client.isBlockedByMaster = false;
        this.client.deleted = false;
        this.client.noteBlocking = null;
        this.toastService.success("Клиент восстановлен из чёрного списка");
      })
      .catch(err => {
        this.toastService.error(err.result.messages[0]);
      });
  }

  public async restoreClient(): Promise<void> {
    await this.masterListClient.restoreClient(this.clientId, this.masterId).toPromise()
      .then((data) => {
        this.ngOnInit();
        this.toastService.info('Клиент восстановлен');
      })
      .catch(async (error) => {
        switch (error.result.code) {
          case 'MASTER_CANT_RESTORE_SALOON_CLIENT':
            await this.toastService.error('Клиент салона. Невозможно восстановить');
            break;
          default:
            await this.toastService.error('Ошибка');
        }
      });
  }

  public async deleteClient(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: DeleteClientModalComponent,
    });

    modal.onDidDismiss().then(async e => {

      if (e.data == null || e.data.role == "dismiss") {
        return;
      }

      if (e.data.role == "block") {
        await this.masterListClient.blockClient({
          clientId: this.clientId,
          masterId: this.masterId,
          note: e.data.note
        } as BlockClientReq).toPromise()
          .then(() => {
            this.client.isBlockedByMaster = true;
            this.client.deleted = true;
            this.client.noteBlocking = e.data.note;
            this.toastService.success("Клиент добавлен в черный список");
          })
          .catch(err => {
            this.toastService.error(err.result.messages[0]);
          });
      }

      if (e.data.role == "delete") {
        await this.masterListClient.deleteMasterClient({
          masterId: this.masterId,
          clientId: this.clientId,
        } as DeleteMasterClientReq).toPromise()
          .then(async (data) => {
            await this.masterClientController.notifyClientsUpdated();
            this.ngOnInit();
          })
          .catch((err) => {
            this.toastService.error('Ошибка. Клиент не удален.')
          });
      }
    });

    await modal.present();
  }

  public ifNullOrEmptyOrWhiteSpace(str: string): boolean {
    return str == null || str.length == 0 || str.trim().length == 0;
  }

  private createReq(): UpdateMasterClientReq {
    const req = new UpdateMasterClientReq;
    req.masterId = this.masterId;
    req.clientId = this.clientId;
    req.masterDefinedName = this.client.definedName?.toString()?.trim();
    req.masterDefinedInstagramProfile = this.client.definedInstagramProfile?.toString()?.trim();
    req.note = this.client.note?.toString()?.trim();

    return req;
  }

  public async onClientBookingsClick(): Promise<void> {
    this.router.navigate(['bookings'], {relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)}).then();
  }
}
