import {DayAddress} from "../api-clients/booking/clients";
import {MasterRes} from "../api-clients/master-list/clients";

export class DaysVm{
  days: DayVm[];
}

export const convertDays = (days: any): DaysVm => ({
  days: days.map(d => convertDay(d))
});

export class DayVm{
  startTime?: Date;
  endTime?: Date;
  address?: DayAddress;
  timeSlots?: TimeSlotVm[];
  master?: MasterRes;
  durationInMinutes?: number;
  restInMinutes?: number;
  priceMin?: number;
  priceMax?: number;
}

export const convertDay = (day: any): DayVm => ({
  startTime: day.startTime,
  endTime: day.endTime,
  address: day.address,
  timeSlots: day.timeSlots.map(t => convertTimeSlot(t)),
  master: day.master,
  durationInMinutes: day.durationInMinutes,
  restInMinutes: day.restInMinutes,
});

export class TimeSlotVm{
  startTime?: Date;
  endTime?: Date;
  preferable?: boolean;
  problems?: number[];
  fatalProblems?: number[];
  hotWindow?: boolean;
  priceMin?: number;
  priceMax?: number;
}

export const convertTimeSlot = (timeSlot: any): TimeSlotVm => ({
  startTime: timeSlot.startTime,
  endTime: timeSlot.endTime,
  preferable: timeSlot.preferable,
  problems: timeSlot.problems,
  fatalProblems: timeSlot.fatalProblems,
  hotWindow: timeSlot.hotWindow,
  priceMin: timeSlot.priceMin,
  priceMax: timeSlot.priceMax,
});


