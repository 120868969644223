import {Component, OnInit} from '@angular/core';
import {MasterFirstSetupWizardSettings} from "../../master-first-setup-wizard.controller";

@Component({
  selector: 'lib-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {

  constructor(
    public _wizardSettings: MasterFirstSetupWizardSettings
  ) {
  }

  ngOnInit() {
  }

}
