import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bcCurrency'
})
export class CurrencyPipe implements PipeTransform {

  public transform(currency: number): string {
    switch (currency){
      case 1:
        return "BYN";
      case 2:
        return "₽"
      default:
        throw new Error("Unknown currency");
    }
  }
}
