import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'app-choose-sorting',
  templateUrl: './choose-sorting.page.html',
  styleUrls: ['./choose-sorting.page.scss'],
})
export class ChooseSortingPage extends ModalComponentBase implements OnInit {

  public sortingArray = [1, 3, 4];

  @Output()
  public citySelected: EventEmitter<string>;

  constructor(
    modalCtrl: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
    public applicationIdService: ApplicationIdService,
  ) {
    super(modalCtrl, router, activeRoute, location);
  }

  ngOnInit() {
    super.init().then();
  }

  public async onCloseClick(): Promise<void> {
    await this.dismiss();
  }

  public async onSortingClick(sorting: number): Promise<void> {
    await this.dismiss(sorting);
  }
}
