import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonTextarea, ModalController} from "@ionic/angular";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {Location} from "@angular/common";

@Component({
  selector: 'reason-for-cancellation-popup',
  templateUrl: './reason-for-cancellation-popup.component.html',
  styleUrls: ['./reason-for-cancellation-popup.component.scss'],
})
export class ReasonForCancellationPopupComponent extends ModalComponentBase implements OnInit {

  @Input()
  public val: string;

  @Input()
  public info: string;

  @Input()
  public title: string = "Введите что-нибудь";

  @ViewChild("inp", {static: true})
  public inp: IonTextarea;

  public formGroupReason: FormGroup;
  public mode: "client" | "master" | "saloon";

  public defaultReasons: ReasonVm [] = [];

  public defaultReasonsForMaster: ReasonVm [] = [
    new ReasonVm('Болею', 'Болею, выберите другое время'),
    new ReasonVm('Срочное дело', 'Срочное дело, выберите другое время'),
    new ReasonVm('Неудобное время', 'Неудобное время, выберите другое'),
  ];

  public defaultReasonsForClient: ReasonVm [] = [
    new ReasonVm('Болею', 'Болею'),
    new ReasonVm('Срочное дело', 'Срочное дело'),
  ];

  constructor(
    modalController: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
    public applicationIdService: ApplicationIdService
  ) {
    super(modalController, router, activeRoute, location);
  }

  public ngOnInit(): void {
    super.init().then();

    this.mode = this.applicationIdService.applicationId;

    if (this.mode === 'client') {
      this.defaultReasons = this.defaultReasonsForClient;
    } else {
      this.defaultReasons = this.defaultReasonsForMaster;
    }

    this.formGroupReason = new FormGroup({
      textControl: new FormControl(this.val)
    });
  }

  public async onCloseClick(): Promise<void> {
    await this.dismiss({cancelBooking: false});
  }

  public async onSaveClick(): Promise<void> {
    if (this.formGroupReason.invalid){
      return ;
    }
    await this.dismiss(this.formGroupReason.controls.textControl.value?.trim());
  }

  public async onReasonClick(reason: ReasonVm): Promise<void> {
    this.formGroupReason.controls.textControl.setValue(reason.text);
  }
}

class ReasonVm {
  text: string;
  name: string;

  constructor(name: string, text: string) {
    this.text = text;
    this.name = name;
  }
}
