import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {SubscriptionsBag} from "../utils/subscriptions-bag";
import {distinctUntilChanged, map} from "rxjs/operators";
import {Location} from "@angular/common";

export class ModalComponentBase {

  private dataToDismiss = null;
  private dismissed: boolean = false;
  private sb: SubscriptionsBag = new SubscriptionsBag();

  private static modalLevel: number = 0;
  private myModalLevel: number;

  constructor(
    protected modalCtrl: ModalController,
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected location: Location,
  ) {

  }

  public onInit(): void {
  }

  public async init(): Promise<void> {
    if (this.myModalLevel > 0) {
      return;
    }

    this.myModalLevel = ++ModalComponentBase.modalLevel;

    return this.router.navigate([], {queryParams: {modalLevel: this.myModalLevel}, queryParamsHandling: "merge"})
      .then(() => {
        this.sb.sub = this.activeRoute.queryParamMap
          .pipe(
            map(qp => parseInt(qp.get('modalLevel')) || 0),
            distinctUntilChanged()
          )
          .subscribe(modalLevel => {
            ModalComponentBase.modalLevel = modalLevel;

            if ((!modalLevel || modalLevel < this.myModalLevel) && !this.dismissed) {
              this.dismissed = true;

              this.modalCtrl.dismiss(this.dataToDismiss)
                .then()
                .catch(err => console.log("Error during modal dismissing: " + err));

              this.sb.unsubscribeAll();
            }
          });
      });
  }

  public ionViewWillEnter(): void {
    this.init().then();
  }

  public onDestroy(): void {
    this.sb.unsubscribeAll();
  }

  public async dismiss(data: any = undefined): Promise<void> {
    this.dataToDismiss = data;

    if (this.location) {
      this.location.back();
    } else {
      await this.router.navigate([], {
        queryParams: {modalLevel: this.myModalLevel - 1 || undefined},
        queryParamsHandling: "merge",
        replaceUrl: true,
      });
    }

  }
}
