import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {IonicModule} from "@ionic/angular";

import {NgEventBus} from "ng-event-bus";

import {UiComponentsModule} from "./ui-components/ui-components.module";
import {UtilsModule} from "./utils/utils.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    UiComponentsModule,
    UtilsModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    UiComponentsModule,
    UtilsModule,
  ]
})
export class BcCommonModule {
  static forRoot(): ModuleWithProviders<BcCommonModule> {
    return {
      ngModule: BcCommonModule,
      providers: [NgEventBus],
    };
  }
}
