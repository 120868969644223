import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {AlertController, IonicSafeString} from "@ionic/angular";
import {filter} from "rxjs";
import {map} from "rxjs/operators";

export const checkForUpdates = (swUpdate: SwUpdate, alertController: AlertController): (() => Promise<any>) => {
  return (): Promise<void> => {
    if (swUpdate.isEnabled == false) {
      return Promise.resolve();
    }

    const updatesAvailable = swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })));

    updatesAvailable.subscribe(async () => {
      const message = '<div>Бьюти круг обновился!</div> <div>Требуется перезагрузка</div>';
      const buttonReloadText = 'Перезагрузить';
      const buttonCancelText = 'Позже';


      let alert = await alertController.create({
        message: new IonicSafeString(message),
        backdropDismiss: false,
        cssClass: 'center',
        buttons: [
          {
            text: buttonReloadText,
            handler: () => {
              swUpdate.activateUpdate().then(() => document.location.reload());
            }
          },
          {
            text: buttonCancelText,
            role: "cancel"
          }
        ]
      });
      await alert.present();
    });

    return Promise.resolve();
  }
};
