import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ActionSheetController, ModalController,
  Platform
} from '@ionic/angular';
import {
  MasterListClient,
  SaloonClient,
  PriceListVm,
  AuthService,
  FileUrlPipe,
  ThumbnailUrlPipe,
  TrackingService,
  SpecialitiesDirectoryService,
  RefreshService, SubscriptionsBag, AddressInfoComponent, ClientsClient, ActivatedRouteFixService
} from "bc-common";
import {Meta, Title} from "@angular/platform-browser";
import {combineLatest} from "rxjs";
import {map, startWith, take} from "rxjs/operators";

class Vm {
  public saloon: SaloonClient.Saloon;
  public contacts: SaloonClient.SaloonContactsRes;
  public priceList: PriceListVm.PriceListVm;
  public masters: MasterListClient.MasterRes[] = [];
}

@Component({
  selector: 'app-saloon-profile',
  templateUrl: './saloon-profile.component.html',
  styleUrls: ['./saloon-profile.component.scss']
})
export class SaloonProfileComponent implements OnInit, OnDestroy {
  public vm: Vm = new Vm();

  private metaTags: HTMLMetaElement[] = [];

  private mastersIds: string[] = [];
  public saloonIsFavorites: boolean = false;

  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private masterListClient: MasterListClient.MasterListClient,
    private specialitiesDirectoryService: SpecialitiesDirectoryService,
    public authService: AuthService,
    private fileUrlPipe: FileUrlPipe,
    private thumbnailPipe: ThumbnailUrlPipe,
    private meta: Meta,
    private saloonClient: SaloonClient.SaloonsClient,
    private titleService: Title,
    private actionSheetController: ActionSheetController,
    private trackingService: TrackingService,
    private platform: Platform,
    private refreshService: RefreshService,
    private modalCtrl: ModalController,
    private clientsClient: ClientsClient.ClientsClient,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  public ngOnInit(): void {

    // this.sb.sub = this.refreshService.refreshAndResume$.subscribe(() => {
    //   this.ngOnDestroy();
    //   this.ngOnInit();
    // });

    this.sb.sub =
      combineLatest([
        this.authService.parsedToken$,
        this.refreshService.refreshAndResume$.pipe(startWith(null))
      ]).pipe(
        map(([token]) => token)
      ).subscribe(() => {

        const saloonId = this.activatedRoute.snapshot.paramMap.get('id');
        const refCode = parseInt(this.activatedRoute.snapshot.paramMap.get('refCode'));

        if (refCode) {
          this.trackingService.setSaloonRefCode(refCode).then();
        }

        let dataPromise = null;

        if (saloonId) {
          dataPromise = Promise.all([
            this.saloonClient.getSaloonById(saloonId).toPromise().then(res => res),
            this.authService.isAuthenticated
              ? this.saloonClient.getSaloonContacts(saloonId, null).toPromise()
              : Promise.resolve(null),
            this.authService.isAuthenticated
              ? this.clientsClient.getClient(this.authService.parsedToken?.userId, null).toPromise()
              : Promise.resolve(null)
          ]);
        } else if (refCode) {
          dataPromise = this.saloonClient.getSaloonByReferalCode(refCode)
            .toPromise()
            .then(saloonRes => Promise.all([
                Promise.resolve(saloonRes),
                this.authService.isAuthenticated
                  ? this.saloonClient.getSaloonContacts(saloonRes.saloon.id, null).toPromise()
                  : Promise.resolve(null),
                this.authService.isAuthenticated
                  ? this.clientsClient.getClient(this.authService.parsedToken?.userId, null).toPromise()
                  : Promise.resolve(null)
              ])
            );
        }

        dataPromise
          .then(async ([saloonRes, contacts, clientRes]) => {
            const saloon = saloonRes.saloon;
            this.trackingService.track(null, saloon.id).then();

            this.saloonIsFavorites = clientRes?.favoriteSaloons?.some(f => f.masterId == saloonRes.id) ?? false;

            const vm = new Vm();

            vm.saloon = saloon;
            vm.contacts = contacts;

            this.mastersIds = saloon.connections.filter(c => c.masterId).map(c => c.masterId);

            if (this.mastersIds.length != 0){
              let masters = await this.masterListClient.getMasters(this.mastersIds, null).toPromise();

              vm.masters.push(...masters.filter(m => m.isProfileSet == true));
            }

            let calcPromos = this.authService.isAuthenticated ? await Promise.all([...this.mastersIds.map(id => this.masterListClient.calcPromos(id, this.authService.parsedToken.userId, null).toPromise())]) : [];
            let calcPromosAll: MasterListClient.CalcPromosRes[] = []
            for (let calc of calcPromos) {
              calcPromosAll = [...calcPromosAll, ...calc];
            }

            if(vm.masters.length > 0){
              let specialities = await this.specialitiesDirectoryService.getSpecialities(this.mastersIds).pipe(take(1)).toPromise().then(res => res);
              vm.priceList = PriceListVm.PriceListVm.ParseFromMasters(vm.masters, specialities, calcPromosAll);
            }

            this.vm = vm;

            this.titleService.setTitle(saloon.name);

            this.metaTags.push(this.meta.getTag("property='og:title'"));
            this.metaTags.push(this.meta.getTag("property='og:image'"));
            this.metaTags.push(this.meta.getTag("property='og:description'"));

            this.meta.updateTag({name: 'og:title', content: this.vm.saloon.name}, "property='og:title'");
            this.meta.updateTag({
              name: 'og:image',
              content: this.thumbnailPipe.transform(this.fileUrlPipe.transform(this.vm.saloon.avatarFileName))
            }, "property='og:image'");
            this.meta.updateTag({
              name: 'og:url',
              content: `https://bk.style/saloons/${saloonId}`
            }, "property='og:url'");
          });

      });
  }

  public ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

  ionViewWillEnter() {
    if (this.vm.saloon?.name) {
      this.titleService.setTitle(this.vm.saloon.name);
    }
  }

  ionViewWillLeave() {
    this.titleService.setTitle('Бьюти Круг');
  }

  public async onBookClick(priceListItemVm: PriceListVm.PriceListItem = null): Promise<void> {
    await this.router.navigate(["bookings", "new"], {
      queryParams: {
        saloonId: this.vm.saloon.id,
        serviceTypeIds: [priceListItemVm?.serviceTypeId]
      }
    });
  }

  public async onAddressClick(address) {
    let options = {
      component: AddressInfoComponent,
      initialBreakpoint: undefined,
      breakpoints: undefined,
      componentProps: {
        title: this.vm.saloon.name,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        addressSaloonId: address.addressSaloonId,
        addressSaloonType: address.addressSaloonType,
        addressSaloonName: address.addressSaloonName,
        addressLongitude: address.addressLongitude,
        addressLatitude: address.addressLatitude,
        addressHowToGetText: address.howToGetText,
        addressHowToGetImagesNames: address.howToGetImagesNames,
      }
    };

    const addressInfoModal = await this.modalCtrl.create(options);

    await addressInfoModal.present();
  }

  public trackById(index, item: any): any {
    return item.id;
  }

  public async onInfoClick(): Promise<void> {
    await this.router.navigate(['/saloons', this.vm.saloon?.id, 'info'], {
      relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
    });
  }

  public async onHistoryClick(): Promise<void> {
    await this.router.navigate(['/saloons', this.vm?.saloon?.id, 'history'], {
      relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
    });
  }

  public async onAddToBkClick(): Promise<void> {
    if (this.authService.isAuthenticated) {
      let req = {
        saloonId: this.vm.saloon.id,
        clientId: this.authService.parsedToken?.userId
      } as ClientsClient.AddFavoriteSaloonReq;

      await this.clientsClient.addFavoriteSaloon(req).toPromise().then(() => {
        this.saloonIsFavorites = true;
        this.cdr.markForCheck();
      });
    } else {
      let url = this.router.url;
      await this.router.navigate(['sign-in'], {queryParams: {backUrl: url}}).then();
    }
  }

  public async onDeleteBkClick(): Promise<void> {
    let req = {
      saloonId: this.vm.saloon.id,
      clientId: this.authService.parsedToken.userId,
    } as ClientsClient.DeleteFavoriteSaloonReq;

    await this.clientsClient.deleteFavoriteSaloon(req).toPromise().then(() => {
      this.saloonIsFavorites = false;
      this.cdr.markForCheck();
    });
  }
}







