<ion-button size="big"
            color="warning"
            style="position: absolute; bottom: 10px; right: 10px;"
            [ngStyle]="{'display': retryInterceptor.smallNetworkProblem && !retryInterceptor.bigNetworkProblem ? 'block': 'none'}">
  <span style="color: white; margin-right: 8px">Плохая связь</span>
  <ion-spinner style="width: 18px; height: 18px" slot="end"></ion-spinner>
</ion-button>

<ion-button size="big"
            color="danger"
            style="position: absolute; bottom: 10px; right: 10px;"
            [ngStyle]="{'display': retryInterceptor.bigNetworkProblem ? 'block': 'none'}"
            (click)="refreshService.refresh()">
  Не удалось загрузить
  <ion-icon slot="end" name="refresh-outline"></ion-icon>
</ion-button>
