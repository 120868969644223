import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import {Observable, of} from "rxjs";

import {tap} from "rxjs/operators";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";

@Injectable()
export class SsrHelper {

  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
  }

  public getCachedData<T>(key: string, getValueFunc: () => Observable<T>): Observable<T> {
    const stateKey = makeStateKey(key);

    let obs;

    if (this.transferState.hasKey(stateKey)) {
      obs = of(this.transferState.get(stateKey, null));

      if (isPlatformBrowser(this.platformId)) {
        this.transferState.remove(stateKey);
      }

    } else {
      obs = getValueFunc().pipe(
        tap(value => {

          if (isPlatformServer(this.platformId)) {
            this.transferState.set(makeStateKey<T>(stateKey), value);
          }

        })
      );
    }

    return obs;
  }

}
