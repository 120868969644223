<div class="banner b4">
  <img src="assets/banners/banner4.png"/>
  <h3>
    Получите доступ к скидкам, и горящим предложениям!
  </h3>
  <p>
    Подключитесь к телеграм-боту
  </p>
  <ion-button fill="clear" routerLink="/profile">Посмотреть</ion-button>
</div>
