import {Inject, Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponseBase
} from "@angular/common/http";
import {from, Observable, of} from "rxjs";
import {TokenStoreService} from "./token-store.service";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {API_BASE_URL} from "../api-clients/master-list/clients";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private router: Router,
    private tokenStore: TokenStoreService,
    private authService: AuthService
  ) {
  }

  private patchReqByTokenIfPossible(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.tokenStore.getToken();

    if (token) {
      return req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    return req;
  }

  private async goToSignIn(): Promise<void> {
    const url = this.router.url;

    return await this.authService.signOut().then(() =>
      this.router.navigate(['/sign-in'], {queryParams: {backUrl: url}}).then()
    );
  }

  private async interceptInternal(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    if (req.url.includes(this.apiBaseUrl) == false || req.url.includes('refresh-token')) {
      return next.handle(req).toPromise();
    }

    const now = new Date();
    const tokenExpired = this.authService.parsedToken && this.authService.parsedToken.expirationDate.valueOf() <= now.valueOf();

    // Предварительный рефреш
    if (tokenExpired) {
      try {
        await this.authService.refreshToken();
      } catch (e) {
        if (e?.status === 400) {
          await this.authService.signOut();
          await this.goToSignIn();
        }
        throw e;
      }
    }

    // Запрос
    try {
      return await next.handle(this.patchReqByTokenIfPossible(req)).toPromise();
    } catch (e) {
      if (e?.status !== 401) {
        throw e;
      }
    }

    // Рефреш
    try {
      await this.authService.refreshToken();
    } catch (e) {
      if (e?.status === 400) {
        await this.authService.signOut();
        await this.goToSignIn();
      }
      throw e;
    }

    // Повторный запрос
    try {
      return await next.handle(this.patchReqByTokenIfPossible(req)).toPromise();
    } catch (e){
      if (e?.status === 401) {
        await this.authService.signOut();
        await this.goToSignIn();
      }
      throw e;
    }
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.interceptInternal(req, next));
  }
}
