import {Pipe, PipeTransform} from '@angular/core';

import * as tmp from 'moment';

const moment = tmp['default'];

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: Date, format: string): string {
    if (!value) {
      return null;
    }

    return moment(value).locale('ru').format(format);
  }
}

