import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AuthService, ClientsClient, ParsedToken, SubscriptionsBag} from "bc-common";
import {Router} from "@angular/router";
import {NgEventBus} from "ng-event-bus";
import {map, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit, OnDestroy {

  public client: ClientsClient.GetClientRes;

  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    private clientsClient: ClientsClient.ClientsClient,
    private _eventBus: NgEventBus,
  ) {
  }

  public get isSignedIn(): boolean {
    return this.authService.isAuthenticated;
  }

  public ngOnInit(): void {
    this.sb.sub = this.authService.parsedToken$.pipe(
      map((x) => x),
      map((t: ParsedToken) => t?.userId),
      switchMap(userId => userId ? this.clientsClient.getClient(userId, null) : of(null))
    ).subscribe(clientRes => {
      this.client = clientRes;
      this.cdr.markForCheck();
    });

    // Возможно, надо пинать инит на аутх сервисе из места изменения этого имени, а не отсюда
    this.sb.sub = this._eventBus.on("user:name-changed").subscribe(async () => this.authService.init());
  }

  public ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

  public flip(link: string): void {
    if (window) {
      window.location.assign(link);
    }
  }

}
