import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {CreateMasterAddressReq, MasterAddressRes} from "../api-clients/master-list/clients";
import {ScheduleDayRes} from "../api-clients/booking/clients";

@Injectable()
export class AddressController {
  public constructor() {
  }

  public getAddresses: () => Promise<MasterAddressRes[]>;
  public addAddress: (addressData: CreateMasterAddressReq, addressImages: string[]) => Promise<void>;
  public editAddress: (addressId: string, addressData: AddressEditData) => Promise<void>;
  public deleteAddress: (addressId: string) => Promise<void>;

  public readOnly: boolean = false;
  public masterId: string;
  public listType: 'saloon' | 'master';
  public scheduleDays: ScheduleDayRes[] = [];

  private _addressData: AddressSetupData = new AddressSetupData();

  public get addressData(): AddressSetupData {
    return this._addressData;
  }

  public clear(): void {
    this._addressData = new AddressSetupData();
  }
}

export class AddressSetupData {
  private _addressLinesSubj = new BehaviorSubject<AddressLineData>(null);
  private _addressLocationSubj = new BehaviorSubject<AddressLocationData>(null);
  private _addressHowToGetSubj = new BehaviorSubject<AddressHowToGetData>(null);
  private _addressUpdated = new BehaviorSubject<string>(null);

  public get addressLineData(): AddressLineData {
    return this._addressLinesSubj.value;
  }

  public get addressLocationData(): AddressLocationData {
    return this._addressLocationSubj.value;
  }

  public get addressHowToGetData(): AddressHowToGetData {
    return this._addressHowToGetSubj.value;
  }

  public get addressLineData$(): Observable<AddressLineData> {
    return this._addressLinesSubj.asObservable();
  }

  public get addressLocationData$(): Observable<AddressLocationData> {
    return this._addressLocationSubj.asObservable();
  }

  public get addressHowToGetData$(): Observable<AddressHowToGetData> {
    return this._addressHowToGetSubj.asObservable();
  }

  public get addressUpdated$(): Observable<string> {
    return this._addressUpdated.asObservable();
  }

  public setAddressLineData(addressLineData: AddressLineData): void {
    this._addressLinesSubj.next(addressLineData);
  }

  public setAddressLocationData(addressLocationData: AddressLocationData): void {
    this._addressLocationSubj.next(addressLocationData);
  }

  public setAddressHowToGetData(addressHowToGetData: AddressHowToGetData): void {
    this._addressHowToGetSubj.next(addressHowToGetData);
  }

  public setAddressUpdated(id: string): void {
    this._addressUpdated.next(id);
  }
}

export class AddressLineData {
  addressLine1: string;
  addressLine2: string;
  addressLocation: google.maps.LatLng;
}

export class AddressLocationData {
  addressLocation: google.maps.LatLng;
}

export class AddressHowToGetData {
  howToGet: string;
  imagesAdded: string[] = [];
  imagesDeleted: string[] = [];
}

export class AddressEditData {
  addressLine2: string;
  howToGet: string;
  imagesAdded: string[] = [];
  imagesDeleted: string[] = [];
}
