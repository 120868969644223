<ion-header translucent="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button defaultHref="/"></lib-back-button>
    </ion-buttons>
    <ion-title>Установка</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div class="content-section">

    <div class="cards-list safe-area-space">
      <div class="card padding">

        <ion-list *ngIf="platform.is('mobile')">
          <ion-item (click)="activeApp = 'client'" [ngClass]="{'active': activeApp == 'client'}">
            <ion-avatar slot="start">
              <img
                src="https://landing.bk.style/images/popup-card1.png"
                alt="Бьюти Круг">
            </ion-avatar>
            <ion-label>
              <h4>Бьюти Круг</h4>
              <p>Окружите себя лучшими мастерами!</p>
            </ion-label>
          </ion-item>
          <ion-item (click)="activeApp = 'master'" [ngClass]="{'active': activeApp == 'master'}">
            <ion-avatar slot="start">
              <img
                src="https://landing.bk.style/images/popup-card2.png"
                alt="БК: Мастер">
            </ion-avatar>
            <ion-label>
              <h4>БК: мастер</h4>
              <p>Личный кабинет мастера</p>
            </ion-label>
          </ion-item>
          <ion-item (click)="activeApp = 'saloon'" [ngClass]="{'active': activeApp == 'saloon'}">
            <ion-avatar slot="start">
              <img
                src="https://landing.bk.style/images/popup-card3.png"
                alt="БК: Салон">
            </ion-avatar>
            <ion-label>
              <h4>БК: салон</h4>
              <p>Личный кабинет владельца или админа</p>
            </ion-label>
          </ion-item>
        </ion-list>

        <ng-container *ngIf="platform.is('desktop')">
          <p class="ion-text-center">
            На компьютер не надо ничего устанавливать, просто сделайте закладку на главную страницу
          </p>
        </ng-container>

      </div>


      <div class="instructions card transparent padding-mobile" *ngIf="platform.is('mobile')">

        <p class="ion-text-center">
          Бьюти Круг, это не классическое приложение, а сайт, адаптированный под приложение
        </p>

        <p *ngIf="platform.is('android')" class="ion-text-center">
          Для установки на телефон, <b>скопируйте эту ссылку и вставьте в&nbsp;Google&nbsp;Chrome</b>
        </p>
        <p *ngIf="platform.is('ios')" class="ion-text-center">
          Для установки на телефон, <b>скопируйте эту ссылку и вставьте в Safari</b>
        </p>

        <p class="ion-text-center" *ngIf="platform.is('android') || platform.is('ios')">
          <a *ngIf="activeApp == 'client'"
             (click)="$event.preventDefault(); $event.stopPropagation(); onLinkClick('https://bk.style/install/step2');"
          >
            https://bk.style/install/step2
            <ion-icon name="copy-outline" style="transform: translateY(3px);"></ion-icon>
          </a>
          <a *ngIf="activeApp == 'master'"
             (click)="$event.preventDefault(); $event.stopPropagation(); onLinkClick('https://master.bk.style/install/step2');"
          >
            https://master.bk.style/install/step2
            <ion-icon name="copy-outline" style="transform: translateY(3px);"></ion-icon>
          </a>
          <a *ngIf="activeApp == 'saloon'"
             (click)="$event.preventDefault(); $event.stopPropagation(); onLinkClick('https://saloon.bk.style/install/step2');"
          >
            https://saloon.bk.style/install/step2
            <ion-icon name="copy-outline" style="transform: translateY(3px);"></ion-icon>
          </a>
        </p>
      </div>
    </div>

    <div class="card padding-mobile transparent" *ngIf="platform.is('desktop') == true">
      <ion-button expand="block" [routerLink]="['/']">На главную</ion-button>
    </div>

  </div>

</ion-content>
