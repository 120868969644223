import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {
  ChooseCityPage,
  ChooseRegionPage,
  ChooseSortingPage,
  ChooseSpecialityPage,
  CitiesDirectoryService,
  FiltersModel,
  NavigatorService,
  RegionsDirectoryService,
  SpecialitiesDirectoryService,
  ToastService,
  PriceListVm,
  ChoosePriceListItemPage, ModalComponentBase, ActivatedRouteFixService, CityStore, GeoClient
} from "bc-common";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-filters',
  templateUrl: './filters.page.html',
  styleUrls: ['./filters.page.scss'],
})

export class FiltersPage implements OnInit {
  @Input()
  public filterModel: FiltersModel;
  public regions: GeoClient.RegionRes[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private location: Location,
    private modalCtrl: ModalController,
    private citiesService: CitiesDirectoryService,
    private regionsService: RegionsDirectoryService,
    private specialitiesService: SpecialitiesDirectoryService,
    private toastService: ToastService,
    private navigatorService: NavigatorService,
  ) {
  }

  ngOnInit() {
    if (this.filterModel?.city) {
      this.regionsService.getRegions(this.filterModel.city.id).subscribe(data => this.regions = data);
    }
  }

  public async onSetCityClick(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ChooseCityPage
    });

    modal.onDidDismiss().then(async e => {
      if (e.data) {
        await this.filterModel.setCity(e.data);
        this.regionsService.getRegions(e.data).subscribe(data => this.regions = data);
        await this.navigate();
      }
    });

    await modal.present();
  }

  public async onUnsetCityClick(cityId: string): Promise<void> {
    await this.filterModel.unsetCity(cityId);
    await this.navigate();
  }

  public async onSetRegionClick(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ChooseRegionPage,
      componentProps: {
        cityId: this.filterModel.city.id
      }
    });

    modal.onDidDismiss().then(async e => {
      if (e.data) {
        await this.filterModel.setRegion(this.filterModel.city.id, e.data);
        await this.navigate();
      }
    });

    await modal.present();
  }

  public async onUnsetRegionClick(cityId: string): Promise<void> {
    await this.filterModel.unsetRegion(cityId);
    await this.navigate();
  }

  public async onSetSpecialityClick(specialityToReplaceId: string = null): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ChooseSpecialityPage
    });

    modal.onDidDismiss().then(async e => {
      if (e.data) {
        await this.filterModel.setSpeciality(e.data.id, specialityToReplaceId);
        await this.navigate();
      }
    });

    await modal.present();
  }

  public async onUnsetSpecialityClick(specialityId: string): Promise<void> {
    await this.filterModel.unsetSpeciality(specialityId);
    await this.navigate();
  }

  public async onSetServiceTypeClick(specialityId: string, serviceTypeToReplaceId: string = null): Promise<void> {
    let specs = await firstValueFrom(this.specialitiesService.getSpecialities());

    if (specialityId) {
      specs = specs.filter(s => s.id == specialityId);
    }

    const priceListVm = PriceListVm.PriceListVm.ParseFromMasters([], specs, null, true);

    const modal = await this.modalCtrl.create({
      component: ChoosePriceListItemPage,
      componentProps: {
        priceList: priceListVm,
        viewMode: 'slim',
        showSpecialities: true,
      }
    });

    modal.onDidDismiss().then(async e => {
      if (e.data) {
        await this.filterModel.setServiceType(e.data.serviceTypeId, serviceTypeToReplaceId);
        await this.navigate();
      }
    });

    await modal.present();
  }

  public async onUnsetServiceTypeClick(serviceTypeId: string): Promise<void> {
    await this.filterModel.unsetServiceType(serviceTypeId);
    await this.navigate();
  }

  public async onSetSortingClick(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ChooseSortingPage
    });

    modal.onDidDismiss().then(async e => {
      if (!e.data) {
        return;
      }

      try {
        await this.filterModel.setSorting(e.data);
        await this.navigate();
      } catch (ex) {
        await this.toastService.warning(ex.message, {duration: 5000});
      }
    });

    await modal.present();
  }

  public async onUnsetSortingClick(): Promise<void> {
    await this.filterModel.unsetSorting();
    await this.navigate();
  }

  public async navigate(): Promise<void> {
    // await this.router.navigate([], {
    //   queryParams: {
    //     ...this.filterModel.toQueryParams(),
    //     rnd: Math.random()
    //   },
    //   relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
    // });
  }
}
