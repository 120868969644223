<ion-list class="ion-no-padding show-groups">
  <ng-container *ngFor="let spec of priceList?.specsAllowedByFilter; trackBy: trackById">

    <ng-container *ngFor="let group of spec.groupsAllowedByFilter; trackBy: trackById">
      <ion-list-header>
        <h3>
          <span>{{ group.name }}</span>
          <a (click)="this.onCreateCustomServiceType(group.id)">+ своя услуга</a>
        </h3>
      </ion-list-header>
      <ion-reorder-group (ionItemReorder)="$event.stopImmediatePropagation(); onItemReorder($event,group)"
                         [disabled]="reorderEnabled === false">
        <ion-item *ngFor="let item of group.itemsAllowedByFilter; trackBy: trackById" class="pli" [button]="true"
                  [detail]="false" (click)="onItemClick($event, item)">
          <ion-label>
            <div>
              <span class="service-owner-marker master" [ngClass]="{'empty': item.isEmpty}" *ngIf="item.ownerMasterId">[М+]</span>
              <span class="service-owner-marker saloon" [ngClass]="{'empty': item.isEmpty}" *ngIf="item.ownerSaloonId">[C+]</span>
              {{ item.serviceTypeName }}

            </div>
            <p>
              <ion-note *ngIf="item.isEmpty">Не выбрано</ion-note>
              <ion-note *ngIf="!item.isEmpty">
                <ion-icon name="checkmark"></ion-icon>
                <ng-container *ngIf="(item.priceMinWithDiscount != null && item.priceMaxWithDiscount != null) &&
                  (item.priceMin != item.priceMinWithDiscount || item.priceMax != item.priceMaxWithDiscount); else notDiscount">
                    <span class="price new">
                      {{ (item.priceMinWithDiscount | priceRange: item.priceMaxWithDiscount:priceList?.currency) }}
                    </span>
                  <span class="price old">
                      {{ (item.priceMin | priceRange: item.priceMax:priceList?.currency) }}
                    </span>
                </ng-container>
                <ng-template #notDiscount>
                    <span class="price">
                      {{ (item.priceMin | priceRange: item.priceMax:priceList?.currency) }}
                    </span>
                </ng-template>
                <span class="time">
<!--                    Так выводим для клиентов без учёта отдыха, а для мастеров и студий - с учётом-->
                  {{ (applicationIdService.applicationId == 'client' ? item.durationInMinutesMin : item.durationWithRestInMinutesMin) | durationRange :(applicationIdService.applicationId == 'client' ? item.durationInMinutesMax : item.durationWithRestInMinutesMax) }}
                  </span>
                <span class="icons">
                    <ion-icon
                      *ngIf="applicationIdService.applicationId == 'saloon' && item.saloonWorkplaceTypes.length > 0"
                      name="golf"
                      style="top: 1px;"></ion-icon>
                    <ion-icon *ngIf="item.connectedBookingsOnly" name="leaf"
                              style="top: 1px; margin: 0 4px"></ion-icon>
                    <ion-icon *ngIf="item.hiddenFromClients" name="eye-off"
                              style="top: 2px;"></ion-icon>
                    <ion-icon *ngIf="item.canBeBoockedByMasterOnly" name="chatbox-ellipses"
                              style="top: 2px;"></ion-icon>
                    <ion-icon *ngIf="item.infoText" name="information-circle"
                              style="top: 2px;"></ion-icon>
                  </span>
              </ion-note>
            </p>
          </ion-label>
          <ion-buttons slot="end">
            <ion-button *ngIf="!item.isEmpty && reorderEnabled == false" size="small"
                        (click)="$event.preventDefault(); $event.stopPropagation(); onTrashClick(item)">
              <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-reorder slot="end" *ngIf="item.id"></ion-reorder>
        </ion-item>
      </ion-reorder-group>

    </ng-container>
  </ng-container>
</ion-list>
