import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'backgroundImage'
})
export class BackgroundPipe implements PipeTransform {

  transform(value: string): unknown {
    let number = Math.abs(this.getHashCode(value) % 11);
    return '../../assets/backgrounds/gradient-' + number + '.png';
  }

  getHashCode(str) {
    let hash = 0, i, chr;
    if (!str || str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash;
  }

}

