<ion-header>
  <ion-toolbar>
    <ion-title>
      <ion-searchbar (ionChange)="this.priceList.applySearch($event?.detail?.value)" mode="ios" placeholder="Услуга"></ion-searchbar>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content force-overscroll="true">

  <div class="content-section">
    <div class="cards-list">
      <div class="card padding" style="padding-top: 20px;">
        <app-price-list [priceList]="priceList"
                        [showGroups]="true"
                        [showCloseButton]="false"
                        [showInfoButton]="true"
                        [coloredSecondary]="false"
                        (itemClicked)="onItemClicked($event)">
        </app-price-list>
      </div>
    </div>
  </div>
</ion-content>
