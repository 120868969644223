import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from "@ionic/angular";
import {BackButtonComponent} from "./back-button/back-button.component";
import {SpinnerComponent} from "./spinner/spinner.component";
import {OutletAnimationControllingDirective} from "./outlet-animation-controlling.directive";
import {CardSpinnerComponent} from "./card-spinner/card-spinner.component";
import {CardEmptyComponent} from "./card-empty/card-empty.component";
import {FormSubmitButtonComponent} from "./form-submit-button/form-submit-button.component";
import {RefresherComponent} from "./refresher/refresher.component";
import {NetworkStatusComponent} from "./network-status/network-status.component";
import {ShortDescriptionComponent} from "./short-description/short-description.component";

@NgModule({

  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [
    BackButtonComponent,
    SpinnerComponent,
    CardSpinnerComponent,
    CardEmptyComponent,
    OutletAnimationControllingDirective,
    FormSubmitButtonComponent,
    RefresherComponent,
    NetworkStatusComponent,
    ShortDescriptionComponent
  ],
  exports: [
    BackButtonComponent,
    SpinnerComponent,
    CardSpinnerComponent,
    CardEmptyComponent,
    OutletAnimationControllingDirective,
    FormSubmitButtonComponent,
    RefresherComponent,
    NetworkStatusComponent,
    ShortDescriptionComponent
  ]
})
export class UiComponentsModule {
}
