<form [formGroup]="howToGetForm" *ngIf="howToGetForm && !submitted">
  <div class="cards-lists">
    <div class="card padding">
      <ion-list lines="none" class="classic">
        <ion-item>
          <ion-label position="stacked">Как добраться (не обязательно)</ion-label>
          <ion-textarea formControlName="howToGet"></ion-textarea>
        </ion-item>
        <div>
          <app-images-set [imagesInit]="this.addressHowToGetImages"
                          title="Как добраться"
                          [allowAdding]="true"
                          [allowDeleting]="true"
                          (deleteClicked)="onImageDeleteClicked($event)"
                          (addClicked)="onImageAddClicked($event)">
          </app-images-set>
        </div>
      </ion-list>
    </div>
  </div>
  <div class="hide-mobile">
    <lib-submit-button (click)="this.onNextClick()">
      {{buttonTitle}}
    </lib-submit-button>
  </div>
</form>
<lib-card-spinner *ngIf="submitted"></lib-card-spinner>
