import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FileUrlPipe} from "./file-url.pipe";
import {FilesUrlsPipe} from "./files-urls.pipe";
import {AvatarUrlPipe} from "./avatar-url-pipe.service";
import {ThumbnailUrlPipe} from "./thumbnail-url.pipe";
import {MediumUrlPipe} from "./medium-url.pipe";
import {ImagesSetComponent} from "./images-set/images-set.component";
import {ImagesViewerPage} from "./images-viewer/images-viewer.page";
import {UiComponentsModule} from "../ui-components/ui-components.module";
import {SwiperDirective} from "./swiper/swiper.directive";

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    UiComponentsModule,
  ],
  declarations: [
    FileUrlPipe,
    FilesUrlsPipe,
    AvatarUrlPipe,
    ThumbnailUrlPipe,
    MediumUrlPipe,
    ImagesSetComponent,
    ImagesViewerPage,
    SwiperDirective
  ],
  exports: [
    FileUrlPipe,
    FilesUrlsPipe,
    AvatarUrlPipe,
    ThumbnailUrlPipe,
    MediumUrlPipe,
    ImagesSetComponent,
    ImagesViewerPage,
    SwiperDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImagesModule {
}
