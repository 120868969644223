<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button [defaultHref]="backUrl"></lib-back-button>
    </ion-buttons>
    <ion-title>Новая акция</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <lib-card-spinner *ngIf="this.processing == true"></lib-card-spinner>
    <div class="card padding ion-text-center" *ngIf="this.processing == false && this.promoTypes.length == 0">Нет свободных акций</div>
    <ng-container *ngIf="this.processing == false && this.promoTypes.length != 0">
      <form>
        <div class="cards-list">
          <div class="card padding">
            <ion-list lines="none" class="classic">
              <ion-item>
                <ion-label position="stacked">Тип акции</ion-label>
                <ion-select #promoType
                            interface="action-sheet"
                            cancel-text="Отмена"
                            [interfaceOptions]="{header: 'Тип акции'}"
                            (ionChange)="onSelectChange($event)"
                            placeholder="Выберите тип акции">
                  <ion-select-option *ngFor="let type of promoTypes"
                                     [value]="type.type">{{type.name}}</ion-select-option>
                </ion-select>
              </ion-item>
              <lib-promo-form *ngIf="this.formGroup" [formGroup]="formGroup"></lib-promo-form>
            </ion-list>
          </div>
          <div class="card padding" *ngIf="promoType.value">
            <h2>Услуги входящие в акцию</h2>
            <ion-list class="colored-secondary">
              <app-price-list [priceList]="this.priceListVmSelected"
                              [showGroups]="true"
                              [showCloseButton]="true"
                              [showInfoButton]="false"
                              [coloredSecondary]="false"
                              (itemClicked)="onChooseItemClick()"
                              (deleteItemClicked)="onUnchooseItemClick($event)">
              </app-price-list>
              <span class="ion-text-center" *ngIf="this.priceListVm?.getAllItems().length == 0">Пусто</span>
            </ion-list>

            <ion-buttons class="ion-justify-content-center"
                         [ngStyle]="{'padding-top': (this.priceListItemIdsSelected.length > 0) ? '20px' : '0'}"
                         *ngIf="this.priceListVm.getAllItems().length != this.priceListItemIdsSelected.length">
              <ion-button shape="circle" (click)="onChooseItemClick()">
                <ion-icon slot="icon-only" name="add-outline"></ion-icon>
              </ion-button>
            </ion-buttons>
          </div>
          <div class="hide-mobile">
            <ng-container *ngTemplateOutlet="submit_button"></ng-container>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button (click)="onSubmitClick()"
                expand="block"
                size="default"
                [disabled]="this.priceListVm?.getAllItems().length == 0">Сохранить
    </ion-button>
  </div>
</ng-template>
