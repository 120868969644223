import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'typeOperation'
})
export class TypeOperationPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    switch (value) {
      case 'EvaluationGift':
        return 'Подарок';
      case 'BookingPayment':
        return 'Запись';
      case 'LeadPayment':
        return 'Клиент';
      case 'AdBookingPayment':
        return 'Привлечение клинта';
      default:
        return 'Транзакция';
    }
  }

}
