import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {TokenStoreService} from "./token-store.service";

export function TokenGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const token = inject(TokenStoreService).getToken();

    if (token) {
      return true;
    } else {
      const router = inject(Router);
      const urlTree = router.createUrlTree(['/sign-in'], {
        queryParams: {backUrl: state.url}
      });

      return router.navigateByUrl(urlTree, {replaceUrl: true})
        .then(() => false);
    }
  };
}

