import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoadingController, ModalController} from "@ionic/angular";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {ToastService} from "../../toast/toast.service";
import {ImagesViewerPage} from "../images-viewer/images-viewer.page";
import {allowedImageTypes} from "../../utils/allowed-image-types";
import {FileUrlPipe} from "../file-url.pipe";
import {FilesClient} from "../../api-clients/file/clients";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-images-set',
  templateUrl: './images-set.component.html',
  styleUrls: ['./images-set.component.scss'],
})
export class ImagesSetComponent implements OnInit {
  @Input()
  public imagesInit: string[] = [];

  @Input()
  public imagesList: string[] = []

  @Input()
  public title: string;

  @Input()
  public allowDeleting: boolean = false;

  @Input()
  public allowAdding: boolean = false;

  @Input()
  public size: "small" | "default" = "default";

  @Input()
  public wrap: boolean = false;

  @Output()
  public deleteClicked: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public addClicked: EventEmitter<string[]> = new EventEmitter<string[]>();


  public constructor(
    private modalController: ModalController,
    private fileUrlPipe: FileUrlPipe,
    private loadingController: LoadingController,
    private toastService: ToastService,
    public applicationIdService: ApplicationIdService,
    public filesClient: FilesClient
  ) {
  }

  public ngOnInit(): void {
    if (this.imagesInit?.length) {
      this.imagesList = this.imagesInit;
    }
  }

  public async onImageClick(img: string, $event): Promise<void> {
    $event.preventDefault();
    $event.stopPropagation();

    const modal = await this.modalController.create({
      component: ImagesViewerPage,
      componentProps: {
        title: this.title,
        images: this.imagesList,
        initialSlide: this.imagesList.indexOf(img),
        allowDeleting: this.allowDeleting
      }
    });

    await modal.present();

    const res = await modal.onDidDismiss();

    if (res.data) {
      this.deleteClicked.emit(res.data);
    }
  }

  public async onFilesSelected($event): Promise<void> {
    const target: HTMLInputElement = $event.target;

    const loading = await this.loadingController.create({
      message: 'Загрузка...',
    });

    await loading.present();

    let newImages = [];

    for (let i = 0; i < target.files.length; i++) {
      if (!allowedImageTypes.some(t => t == target.files[i]?.type)) {
        await this.toastService.error("Фото номер " + (i + 1) + " - формат изображения не поддерживается");
        await this.loadingController.dismiss();
        continue;
      }

      let file = await lastValueFrom(this.filesClient.postTemporaryFile({data: target.files[i] , fileName: target.files[i].name}));

      this.imagesList.push(file.filePatch);

      newImages.push(file.filePatch);
    }

    target.value = null; // Чистим, чтобы не было бага с одинаковыми картинками

    this.addClicked.emit(newImages);

    await this.loadingController.dismiss();
  }

  public onDeleteClick(img: string) {
    this.imagesList = this.imagesList.filter(i => i != img);

    this.deleteClicked.emit(img);
  }
}

