import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {IonInput} from "@ionic/angular";
import {cleanPhone, validatorPhone} from "../../editors/validation";
import {AuthService} from "../../security/auth.service";
import {ToastService} from "../../toast/toast.service";
import {map} from "rxjs/operators";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SignInRootComponent} from "../sign-in-root/sign-in-root.component";
import {SignInController} from "../signInController";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {SubscriptionsBag} from "../../utils/subscriptions-bag";

@Component({
  selector: 'lib-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  host: {'class': 'ion-page'},
})
export class PhoneComponent implements OnInit {
  private _sb: SubscriptionsBag = new SubscriptionsBag();
  private _role: string;

  public formGroupPhone: FormGroup;

  public get processing(): boolean {
    return this._signInController.processing;
  }

  public constructor(
    private _signInController: SignInController,
    private _activatedRoute: ActivatedRoute,
  ) {
  }

  public ngOnInit() {
    this._sb.sub = this._activatedRoute.queryParamMap.subscribe(qpm => this._role = qpm.get('role') || 'Client');

    this.formGroupPhone = new FormGroup({
      phoneControl: new FormControl(null, [
        Validators.required,
        validatorPhone()
      ]),
    });
  }

  public async onSubmitPhoneClick(): Promise<void> {
    if (this.processing || this.formGroupPhone.invalid) {
      return;
    }

    const phoneControl = this.formGroupPhone.get('phoneControl');
    const phoneNumber = cleanPhone(phoneControl.value);

    await this._signInController.step1(phoneNumber);
  }
}
