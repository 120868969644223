import * as tmp from 'moment';

const moment = tmp['default'];

export function normalizeDate(date: string | number | Date): Date {
  if (date == null) {
    throw new Error('Date is null or undefined. ' + date);
  }

  let normalized: Date = new Date(date);

  if (normalized.toString() === 'Invalid Date') {
    throw new Error("Invalid date. " + date);
  }

  return normalized;
}

export function removeTimeZoneOffset(date: Date): Date {
  date = normalizeDate(date);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date;
}

export function hasSameMonth(x: Date, y: Date): boolean {

  if (!x || !y) {
    return false;
  }

  x = normalizeDate(x);
  y = normalizeDate(y);

  return x?.getFullYear() == y?.getFullYear() && x?.getMonth() == y?.getMonth();
}

export function hasSameDate(x: Date, y: Date): boolean {

  if (!x || !y) {
    return false;
  }

  x = normalizeDate(x);
  y = normalizeDate(y);

  return x?.getFullYear() == y?.getFullYear() && x?.getMonth() == y?.getMonth() && x?.getDate() == y?.getDate();
}

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes*60000);
}

export function addDays(date: Date, days: number): Date {
  date = normalizeDate(date);
  date.setDate(date.getDate() + days);
  return date;
}

export function addMonths(date: Date, months: number): Date {
  date = normalizeDate(date);
  date.setMonth(date.getMonth() + months);
  return date;
}

export function minDate(dates: Date[]): Date {
  if (dates.length <= 0) {
    return null;
  }

  return dates.reduce((prev, curr) => curr.valueOf() < prev.valueOf() ? curr : prev, dates[0]);
}

export function maxDate(dates: Date[]): Date {
  if (dates.length <= 0) {
    return null;
  }

  return dates.reduce((prev, curr) => curr.valueOf() > prev.valueOf() ? curr : prev, dates[0]);
}

export function getRussianDayOfWeek(date: Date): number {
  let res = date.getDay();
  return res || 7;
}

export function calcRussianDayOfWeekDate(dateOfDayInWeek: Date, targetDayOfWeek: number): Date {
  let x = new Date(dateOfDayInWeek.getFullYear(), dateOfDayInWeek.getMonth(), dateOfDayInWeek.getDate());
  const distance = targetDayOfWeek - getRussianDayOfWeek(x);
  x.setDate(x.getDate() + distance);
  return x;
}

export function calcNthDayOfMonthDate(date: Date, targetDayOfMonth: number): Date {
  let x = new Date(date.getFullYear(), date.getMonth(), 1);
  const distance = targetDayOfMonth - x.getDate();
  x.setDate(x.getDate() + distance);
  return x;
}

export function minutesToDate(minutes: number): Date {
  const now = new Date();

  if (minutes === null || minutes === undefined) {
    return null;
  }

  return new Date(now.getFullYear(), now.getMonth(), now.getDate(),
    minutes / 60,
    minutes % 60);
}

export function minutesToDateString(minutes: number): string {
  const date = minutesToDate(minutes);
  const dateString = dateToString(date);
  return dateString;
}

export function dateToMinutes(date: Date): number {
  date = normalizeDate(date);
  return date.getHours() * 60 + date.getMinutes();
}

export function dateStringToMinutes(str: string): number {
  if (!str) {
    return null;
  } else {
    return dateToMinutes(new Date(str));
  }
}

export function calcDistanceInMinutes(from: Date, to: Date) {
  from = normalizeDate(from);
  to = normalizeDate(to);
  return dateToMinutes(to) - dateToMinutes(from);
}

export function calcDistanceInDays(from: Date, to: Date) {
  from = normalizeDate(from);
  to = normalizeDate(to);
  return to.getDate() - from.getDate();
}

export function dateToString(date: Date): string {
  const dateString = moment(date).format();
  return dateString;
}

export function dateToUtc(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
}

