import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../security/auth.service";
import {BookingWithFeedback} from "../../../api-clients/feedback/clients";
import {MasterClientsController, ClientsControllerProvider} from "../masterClientsController";
import {SubscriptionsBag} from "../../../utils/subscriptions-bag";

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
  providers: [
    {
      provide: MasterClientsController,
      useFactory: (provider: ClientsControllerProvider) => provider.provide(),
      deps: [ClientsControllerProvider]
    }
  ]
})
export class BookingsComponent implements OnInit {

  public selectedTab: 'actual' | 'others' = 'actual';

  public masterId: string = null;
  public clientId: string = null;

  private sb: SubscriptionsBag = new SubscriptionsBag();

  public constructor(
    private _clientsController: MasterClientsController,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
    this.sb.unsubscribeAll();
    this.masterId = this._authService.parsedToken.userId;
    this.clientId = this._activatedRoute.snapshot.paramMap.get('id');
  }

  public ionViewWillEnter(): void {
    this.sb.unsubscribeAll();

    this.sb.sub = this._activatedRoute.queryParamMap.subscribe(qpm => {
      this.selectedTab = qpm.get('tab') as any || 'actual';
    });
  }

  public ngOnDestroy(): void {
    this.sb.unsubscribeAll();
  }

  public async onSegmentChanged($event: any): Promise<void> {
    await this._router.navigate([], {queryParams: {tab: $event.detail.value}, queryParamsHandling: "merge", replaceUrl: true});
  }

  public async onCloseClick() {
  }

  public async onDetailsClicked(bookingWithFeedback: BookingWithFeedback): Promise<void> {
    await this._clientsController.navigateToBooking(bookingWithFeedback.id);
  }
}
