<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div class="content-section">
    <form tabindex="0"
          id="frm"
          *ngIf="control"
          class="form-meta">
      <div class="cards-list form">
        <div class="card padding">
      <ion-list lines="none" class="classic">
        <ion-item>
          <ion-label position="stacked">Текст</ion-label>
          <ion-textarea #inp
                        [formControl]="control"
                        [autoGrow]="true"
                        (ionFocus)='setTextCursor()'
                        (keyup.shift.enter)="onSaveClick()"
                        [maxlength]="maxlength"
                        placeholder="Введите текст">
          </ion-textarea>
        </ion-item>
      </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>

  </div>

</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button [disabled]="!control?.valid"
                expand="block"
                form="frm"
                (click)="onSaveClick()">Сохранить
    </ion-button>
  </div>
</ng-template>

