<ion-header class="hide-mobile">
  <ion-toolbar>
    <ion-title>Готово</ion-title>
  </ion-toolbar>
</ion-header>

<ng-container *ngIf="wizardController.timeSlotData">
  <ion-content force-overscroll="true">
    <div class="content-section">


      <ng-container *ngIf="applicationIdService.applicationId === 'client'">
        <div class="cards-list">
          <ng-container *ngIf="!wizardController.baseBooking">


            <div class="card transparent-mobile padding">
              <div class="confirm-banner">
                <img src="assets/check_mark.svg" style="margin-right: 17px;">
              <span>Готово!</span>
              </div>
              <h3>
                Мы отправили вам сообщение со ссылкой на вашу запись
              </h3>
              <p>
                По этой ссылке вы всегда можете:
              </p>
              <ul>
                <li>Посмотреть всю подробную информацию о записи</li>
                <li>Самостоятельно перенести или отменить запись</li>
                <li>Оставить отзыв после визита</li>
                <li>Записаться снова ;)</li>
              </ul>
            </div>
          </ng-container>

          <ng-container *ngIf="wizardController.baseBooking">
            <div class="card transparent-mobile padding">
              <div class="confirm-banner">
                <img src="assets/check_mark.svg" style="margin-right: 17px;">
                <span>Запись успешно перенесена!</span>
              </div>
              <h3>
                Мы отправили вам сообщение со ссылкой на вашу запись
              </h3>
              <p>
                По этой ссылке вы всегда можете:
              </p>
              <ul>
                <li>Посмотреть всю подробную информацию о записи</li>
                <li>Самостоятельно перенести или отменить запись</li>
                <li>Оставить отзыв после визита</li>
                <li>Записаться снова ;)</li>
              </ul>
            </div>
          </ng-container>

          <div class="hide-mobile">
            <ng-container *ngTemplateOutlet="next_button"></ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="applicationIdService.applicationId === 'master' || applicationIdService.applicationId === 'saloon'">
        <div class="cards-list">
          <ng-container *ngIf="!wizardController.baseBooking">

            <div class="card transparent-mobile padding">
              <div class="confirm-banner">
                <img src="assets/check_mark.svg" style="margin-right: 17px;">
                <span>Запись создана!</span>
              </div>
              <h3>
                Ваш клиент получил сообщение со ссылкой на эту запись
              </h3>
              <p>
                Так же, БК сделает следующее:
              </p>
              <ul>
                <li *ngIf="wizardController.reminderTimeInMinutes">Напомнит клиенту о записи
                  (за {{wizardController.reminderTimeInMinutes | duration :'minutes'}})
                </li>
                <li>Запросит отзыв (если клиент его не оставлял ранее)</li>
              </ul>
            </div>
          </ng-container>

          <ng-container *ngIf="wizardController.baseBooking">
            <div class="card transparent-mobile padding">
              <div class="confirm-banner">
                <img src="assets/check_mark.svg" style="margin-right: 17px;">
                <span>Запись успешно перенесена!</span>
              </div>
              <h3>
                Вашему клиенты отправлено уведомление
              </h3>
            </div>
          </ng-container>

          <div class="hide-mobile">
            <ng-container *ngTemplateOutlet="next_button"></ng-container>
          </div>
        </div>
      </ng-container>

    </div>
  </ion-content>

  <ion-footer class="hide-desktop ion-no-border">
    <ng-container *ngTemplateOutlet="next_button"></ng-container>
  </ion-footer>

  <ng-template #next_button>
    <div class="card transparent padding-mobile">
      <ion-button *ngIf="applicationIdService.applicationId == 'client'" expand="block"
                  [routerLink]="['/']"
                  replaceUrl="true">
        Ок
      </ion-button>

      <ion-button *ngIf="applicationIdService.applicationId == 'master'" expand="block"
                  [routerLink]="['/schedule']"
                  [queryParams]="{'date': wizardController.timeSlotData.timeSlot.startTime.toJSON()}"
                  replaceUrl="true">
        Ок
      </ion-button>

      <ion-button *ngIf="applicationIdService.applicationId == 'saloon'" expand="block"
                  [routerLink]="['/'+typeSaloonUser+'/saloons/'+wizardController.timeSlotData.address.addressSaloonId+'/masters/' + wizardController.timeSlotData.master.id]"
                  [queryParams]="{'date': wizardController.timeSlotData.timeSlot.startTime.toJSON()}"
                  replaceUrl="true">
        Ок
      </ion-button>
    </div>
  </ng-template>
</ng-container>
