<div class="banner b3">
  <img src="assets/banners/banner3.png"/>
  <h2>
    Вы мастер?
  </h2>
  <p>
    Присоединяйтесь к нам!
  </p>
  <ion-button fill="clear" href="https://landing.bk.style/">Узнать подробности</ion-button>
</div>
