import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {filter, map} from "rxjs/operators";
import {SignInSettings} from "./signInSettings";
import {AuthService} from "../security/auth.service";
import {ClientsClient, UpdateClientReq} from "../api-clients/clients/clients";
import {MasterListClient, UpdateMasterNameReq} from "../api-clients/master-list/clients";
import {SaloonsClient, UpdateOwnerNameReq, UpdateSaloonAdminNameReq} from "../api-clients/saloon/clients";

@Injectable()
export class SignInController {

  private _processing: boolean = false;

  private _phone: string;
  private _code: string;
  private _firstName: string;
  private _lastName: string;

  private _phoneSetSubject = new Subject<void>();
  private _codeSetSubject = new Subject<void>();
  private _nameSetSubject = new Subject<void>();

  public get processing(): boolean {
    return this._processing;
  }

  public get phone(): string {
    return this._phone;
  }

  public get code(): string {
    return this._code;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public get lastName(): string {
    return this._lastName;
  }

  public get phoneSet$(): Observable<void> {
    return this._phoneSetSubject.asObservable();
  }

  public get codeSet$(): Observable<void> {
    return this._codeSetSubject.asObservable();
  }

  public get nameSet$(): Observable<void> {
    return this._nameSetSubject.asObservable();
  }

  public constructor(
    private _settings: SignInSettings,
    private _authService: AuthService,
    private _clientsClient: ClientsClient,
    private _mastersListClient: MasterListClient,
    private _saloonsClient: SaloonsClient,
  ) {
  }

  public async handleProcessing(fn: () => Promise<void>): Promise<void> {
    try {
      this._processing = true;
      await fn();
    } finally {
      this._processing = false;
    }
  }

  public async step1(phone: string): Promise<void> {
    this._phone = phone;
    this._phoneSetSubject.next();
  }

  public async step2(code: string): Promise<void> {
    this._code = code;
    this._codeSetSubject.next();
  }

  public async setName(firstName: string, lastName: string): Promise<void> {

    this._firstName = firstName?.trim();
    this._lastName = lastName?.trim();

    this._nameSetSubject.next();
  }

}
