import {NgModule} from "@angular/core";
import {BcCommonModule} from "../bc-common.module";
import {ContactsFormComponent} from "./contacts-form/contacts-form.component";
import {ContactsInfoComponent} from "./contacts-info/contacts-info.component";

@NgModule({
  imports: [
    BcCommonModule,
  ],
  declarations: [
    ContactsFormComponent,
    ContactsInfoComponent
  ],
  exports: [
    ContactsFormComponent,
    ContactsInfoComponent
  ]
})
export class ContactsModule{

}
