<lib-card-spinner *ngIf="this.processing == true; else content"></lib-card-spinner>

<ng-template #content>
  <ng-container *ngIf="this.activeConnection === undefined">
    <h2 class="ion-no-margin">Подключитесь к боту</h2>

    <p>И получайте уведомления и напоминания от БК в любимом мессенджере!</p>
    <p *ngIf="_applicationIdService.applicationId == 'client'">Подключение бота - это обязательное условие для доступа к акциям и скидкам мастеров</p>

    <h3>Шаг 1. Нажмите на код, чтобы скопировать</h3>
    <div class="code-textarea" (click)="onCopyClick($event); step2.classList.add('enabled')">
      {{code}}
    </div>

    <div #step2 class="step-2">
      <h3>Шаг 2. Отправьте код боту</h3>
      <div class="bots">
        <ion-button id="tgButton" href="https://t.me/BeautyCircleBot" target="_blank">Telegram</ion-button>
        <ion-button id="viberButton" href="viber://pa?chatURI=beauty-circle" target="_blank">Viber
        </ion-button>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="this.activeConnection?.botConnectionType==1">
    <h2 class="ion-no-margin">Ура! Вы подключены к <span class="highlight-header tg-header">телеграм-боту</span></h2>
    <div class="ion-margin-top">
      <ion-row>
        <ion-col class="ion-no-padding" style="padding-right: 5px">
          <ion-button href="https://t.me/BeautyCircleBot" target="_blank" style="display: block" class="tg-button">
            Перейти в бота
          </ion-button>
        </ion-col>

        <ion-col class="ion-no-padding" style="padding-right: 5px">
          <ion-button style="display: block;" fill="outline" (click)="onDeleteConnectionClick()">
            Отключиться
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </ng-container>

  <ng-container *ngIf="this.activeConnection?.botConnectionType==2">
    <h2 class="ion-no-margin">Ура! Вы подключены к <span class="highlight-header viber-header">вайбер-боту</span></h2>
    <div class="ion-margin-top">
      <ion-row>
        <ion-col class="ion-no-padding" style="padding-right: 5px">
          <ion-button href="viber://pa?chatURI=beauty-circle" target="_blank" style="display: block"
                      class="viber-button">
            Перейти в бота
          </ion-button>
        </ion-col>

        <ion-col class="ion-no-padding" style="padding-right: 5px">
          <ion-button style="display: block;" fill="outline" (click)="onDeleteConnectionClick()">
            Отключиться
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </ng-container>
</ng-template>


