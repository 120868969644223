import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'lib-delete-client-modal',
  templateUrl: './delete-client-modal.component.html',
  styleUrls: ['./delete-client-modal.component.scss'],
})
export class DeleteClientModalComponent extends ModalComponentBase implements OnInit {

  public form: FormGroup;

  constructor(
    modalController: ModalController,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location,
  ) {
    super(modalController, router, activatedRoute, location);
  }

  ngOnInit() {
    this.form = new FormGroup({
      block: new FormControl(null),
      note: new FormControl(null),
    });
  }

  async onSaveClick(){
    if (this.form.controls.block.value == true){
      await this.dismiss({
        role: "block",
        note: this.form.controls.note.value,
      });
    }
    else {
      await this.dismiss({
        role: "delete",
      });
    }
  }

  async onCloseClick(){
    await this.dismiss({role: "dismiss"});
  }
}
