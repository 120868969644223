import {BcCommonModule} from "../bc-common.module";
import {NgModule} from '@angular/core';

import {SignInRoutingModule} from './sign-in-routing.module';

import {PhoneComponent} from "./phone/phone.component";
import {CodeComponent} from "./code/code.component";
import {SignInRootComponent} from "./sign-in-root/sign-in-root.component";
import {InfoComponent} from "./info/info.component";

@NgModule({
  imports: [
    BcCommonModule,
    SignInRoutingModule
  ],
  declarations: [
    SignInRootComponent,
    PhoneComponent,
    CodeComponent,
    InfoComponent
  ],
})
export class SignInModule {
}
