import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {combineLatest, merge, Observable, of} from "rxjs";
import {ModalController} from "@ionic/angular";
import {map} from "rxjs/operators";
import {SpecialityRes} from "../../api-clients/master-list/clients";
import {CitiesDirectoryService} from "./../../directories/cities-directory.service";
import {SpecialitiesDirectoryService} from "./../../directories/specialities-directory.service";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'app-choose-speciality',
  templateUrl: './choose-speciality.page.html',
  styleUrls: ['./choose-speciality.page.scss'],
})
export class ChooseSpecialityPage extends ModalComponentBase implements OnInit {

  public searchControl: FormControl = new FormControl(null);

  public specialities$: Observable<SpecialityRes[]> = null;

  @Output()
  public citySelected: EventEmitter<string>;

  constructor(
    modalController: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
    private citiesService: CitiesDirectoryService,
    private specialitiesService: SpecialitiesDirectoryService,
    public applicationIdService: ApplicationIdService,
  ) {
    super(modalController, router, activeRoute, location);
  }

  ngOnInit() {
    super.init().then();

    this.specialities$ = combineLatest([
      this.specialitiesService.getSpecialities(),
      merge(of(null), this.searchControl.valueChanges.pipe()),
    ]).pipe(map(([specialities, searchValue]) => {
      const search = searchValue ? searchValue.toLowerCase() : searchValue;
      return specialities.filter(s => !search || s.name.toLowerCase().includes(search));
    }));
  }

  public async onCloseClick(): Promise<void> {
    await this.dismiss();
  }

  public async onSpecialityClick(speciality: SpecialityRes): Promise<void> {
    await this.dismiss(speciality);
  }

}
