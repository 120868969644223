<ion-header translucent="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>{{saloon?.name}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <lib-refresher slot="fixed"></lib-refresher>

  <div class="content-section">

    <lib-card-spinner *ngIf="this.loading == true"></lib-card-spinner>
    <lib-card-empty *ngIf="this.loading == false && this.masters.length == 0">
      <div>
        Нет мастеров
      </div>
    </lib-card-empty>

    <div class="cards-list">
      <div class="card master" *ngFor="let master of masters; trackBy: trackById" [routerLink]="['/masters', master.id]">
        <div class="head">
          <ion-avatar>
            <lib-avatar *ngIf="master"
                        class="ion-margin-end"
                        [name]="master.fullName"
                        [avatarFileName]="master.avatarFileName">
            </lib-avatar>
          </ion-avatar>
          <div>
            <h3>
              {{master.fullName}}
            </h3>
            <p class="specs">
              {{master | specialitiesPipe}}
            </p>
            <p
              *ngIf="master?.stars || master?.reviewsCount || master?.returnabilityPercent || master?.cancellationsPercent !== null || master?.movedPercent !== null"
              class="digits">
                  <span *ngIf="master?.stars" class="digit star">
                    <span title="Оценка">
                      <ion-icon name="star"></ion-icon>
                      <ion-text> {{master?.stars | number: '1.1-1'}}
                        <span class="top"> {{master?.reviewsCount}}</span>
                      </ion-text>
                    </span>
                  </span>

              <span *ngIf="master?.returnabilityPercent" class="digit returnability">
                    <span title="Возвращаемость">
                      <ion-icon name="repeat-outline"></ion-icon>
                      <ion-text> {{master?.returnabilityPercent}}
                        <span class="bottom"> %</span>
                      </ion-text>
                    </span>
                  </span>

              <span *ngIf="master?.cancellationsPercent !== null || master?.movedPercent !== null"
                    class="digit reliability">
                    <span title="Надёжность">
                      <ion-icon name="shield-checkmark-outline"></ion-icon>
                      <ion-text> {{100 - (master?.cancellationsPercent + master?.movedPercent)}}<span
                        class="bottom"> %</span></ion-text>
                    </span>
                  </span>

              <ion-ripple-effect></ion-ripple-effect>
            </p>
          </div>
        </div>

        <div *ngIf="master?.addresses?.length" class="address">
          <ion-icon name="location-outline"></ion-icon>
          <div *ngIf="master.addresses[0].addressSaloonId == null">
            <div class="line1">{{master.addresses[0].addressLine1}}</div>
            <div class="line2">{{master.addresses[0].addressLine2}}</div>
          </div>
          <div *ngIf="master.addresses[0].addressSaloonId != null">
            <div
              class="line1">{{master.addresses[0].addressSaloonType}} {{master.addresses[0].addressSaloonName}}</div>
            <div
              class="line2">{{master.addresses[0].addressLine1 | addressPipe :master.addresses[0].addressLine2}} </div>
          </div>
        </div>

        <div *ngIf="master?.slogan" class="text">
          {{master.slogan}}
        </div>

        <ion-list *ngIf="master.promos?.length > 0">
          <ng-container *ngFor="let promo of master.promos">
            <ion-item *ngIf="(promo.startTimeOffset < now && promo.endTimeOffset > now) ||
              hasSameDate(now, promo.startTimeOffset) ||
              hasSameDate(now, promo.endTimeOffset)" class="promo colored-primary" detail="false" button="true">
              <ion-label>
                <div>{{promo.title}}</div>
                <p class="info">
                    <span class="discount">
                      {{promo.isPercentValue == true ? '- ' + promo.value + ' %' : '- ' + promo.value + ' BYN'}}
                    </span>
                  <span class="dates">
                      <span *ngIf="promo.endTimeOffset">
                        до {{promo.endTimeOffset | moment:'D MMM'}}
                      </span>
                    </span>
                </p>
              </ion-label>
            </ion-item>
          </ng-container>
        </ion-list>

        <ion-list>

          <ion-item *ngIf="master.nearestDays?.length > 0" class="nearest-days colored-secondary" detail="false"
                    button="true">
            <ion-label>
              <div>Ближайшее рабочие дни</div>
              <p class="days">
                  <span *ngFor="let day of master.nearestDays" class="day">
                    <span>{{dateToFromNowDaily(day)}} </span>
                  </span>
              </p>
            </ion-label>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>

</ion-content>
