import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'instagramProfileName'
})
@Injectable({providedIn: 'root'})
export class InstagramProfileNamePipe implements PipeTransform {

  transform(nameOrLink: string): string {
    if (nameOrLink == null) {
      return nameOrLink;
    }

    let name = null;

    if (nameOrLink.indexOf('/') >= 0) {
      const split = nameOrLink.split('/');
      const last = split.filter(x => x.length > 0).reverse()[0];
      name = last;
    } else {
      name = nameOrLink;
    }

    if (name[0] == "@") {
      return name;
    } else {
      return '@' + name;
    }

  }

}

