import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({providedIn: 'root'})
@Pipe({
  name: 'thumbnailUrl'
})
export class ThumbnailUrlPipe implements PipeTransform {

  transform(url: string): string {
    if (!url) {
      return null;
    }

    return url + "?size=thumbnail";
  }
}

