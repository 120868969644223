<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div class="content-section">
    <div class="card padding">
      <p [innerText]="text"></p>
    </div>
  </div>

</ion-content>

