import {NgModule} from "@angular/core";
import {AvatarModule, BcCommonModule, BookingWithFeedbackModule, EditorsModule} from "bc-common";
import {YourBookingsComponent} from "./your-bookings/your-bookings.component";
import {YourMastersComponent} from "./your-masters/your-masters.component";

@NgModule({
  declarations: [
    YourBookingsComponent,
    YourMastersComponent
  ],
  exports: [
    YourBookingsComponent,
    YourMastersComponent
  ],
  imports: [
    BcCommonModule,
    AvatarModule,
    BookingWithFeedbackModule,
    EditorsModule,
  ],
})
export class ComponentsModule {

}
