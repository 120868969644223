<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>Работы и отзывы</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true" *ngIf="vm?.master?.id">

  <lib-refresher slot="fixed"></lib-refresher>

  <div class="content-section cards-list safe-area-space" [ngClass]="{'mh': list.feedbacks == null}">

    <div class="card padding">

      <p *ngIf="vm?.master?.stars && vm?.master?.reviewsCount">
          <span class="digit star">
            <span title="Оценка">
              <ion-icon name="star"></ion-icon>
              <ion-text>
                {{vm?.master?.stars | number: '1.1-1'}}
                <span class="top">{{vm?.master?.reviewsCount}}</span>
              </ion-text>
            </span>
          </span>
        - <b>средняя оценка</b> , за последние 20 отзывов, всего отзывов - {{vm?.master?.reviewsCount}}
      </p>

      <p *ngIf="vm?.master?.returnabilityPercent">
          <span class="digit returnability">
            <span title="Возвращаемость">
              <ion-icon name="repeat-outline"></ion-icon>
              <ion-text>
                {{vm?.master?.returnabilityPercent}}
                <span class="bottom">%</span>
              </ion-text>
            </span>
          </span>
        - <b>процент возвращаемости</b>, по последним 20 новым клиентам
      </p>

      <p *ngIf="vm?.master?.cancellationsPercent !== null || vm?.master?.movedPercent !== null">
          <span class="digit reliability">
            <span title="Надёжность">
              <ion-icon name="shield-checkmark-outline"></ion-icon>
              <ion-text>
                {{100 - (vm?.master?.cancellationsPercent + vm?.master?.movedPercent)}}
                <span class="bottom">%</span>
              </ion-text>
            </span>
          </span>
        - <b>процент надёжности</b>, вероятность, что мастер не попросист перенести или отменить вашу запись
      </p>

      <ion-button expand="block" fill="outline" (click)="onDoFeedbackClicked()">Оставить отзыв</ion-button>
    </div>

    <lib-bookings-with-feedback-list #list
                                     mode="master-feedbacks"
                                     [masterId]="vm?.master?.id"
                                     [canceled]="false"
                                     [actual]="false"
                                     [hasAnyFeedback]="true"
                                     (detailsClicked)="onBwfDetailsClicked($event)">
    </lib-bookings-with-feedback-list>

  </div>

</ion-content>
