<ion-list *ngIf="contacts.phone ||
                 contacts.instagramProfile ||
                 contacts.telegram ||
                 contacts.viber ||
                 contacts.vkProfile; else empty">
  <ion-item
    *ngIf="contacts.phone"
    (click)="_contactsClickHandlerService.onPhoneClick(contacts.phone)"
    button="true" detail="false">
    <ion-label>
      <p>Телефон</p>
      <h4>{{contacts.phone}}</h4>
    </ion-label>
    <ion-buttons slot="end">
      <ion-button>
        <ion-icon name="call-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>

  <ion-item
    *ngIf="contacts.instagramProfile"
    (click)="_contactsClickHandlerService.onInstagramClick(contacts.instagramProfile)"
    button="true" detail="false">
    <ion-label>
      <p>Инстаграм</p>
      <h4>{{contacts.instagramProfile | instagramProfileName}}</h4>
    </ion-label>
    <ion-buttons slot="end">
      <ion-button>
        <ion-icon name="open-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>

  <ion-item
    *ngIf="contacts.telegram"
    (click)="_contactsClickHandlerService.onTelegramClick(contacts.telegram)"
    button="true" detail="false">
    <ion-label>
      <p>Телеграм</p>
      <h4>{{contacts.telegram | instagramProfileName}}</h4>
    </ion-label>
    <ion-buttons slot="end">
      <ion-button>
        <ion-icon name="open-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>

  <ion-item
    *ngIf="contacts.viber"
    (click)="_contactsClickHandlerService.onViberClick(contacts.viber)"
    button="true" detail="false">
    <ion-label>
      <p>Вайбер</p>
      <h4>{{contacts.viber}}</h4>
    </ion-label>
    <ion-buttons slot="end">
      <ion-button>
        <ion-icon name="open-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>

  <ion-item
    *ngIf="contacts.vkProfile"
    (click)="_contactsClickHandlerService.onVkClick(contacts.vkProfile)"
    button="true" detail="false">
    <ion-label>
      <p>Вконтакте</p>
      <h4>{{contacts.vkProfile | instagramProfileName}}</h4>
    </ion-label>
    <ion-buttons slot="end">
      <ion-button>
        <ion-icon name="open-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
</ion-list>

<ng-template #empty>
  Контакты не указаны
</ng-template>
