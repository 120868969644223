import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {mergeWith, Observable, Subject} from "rxjs";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class RefreshService {

  private refreshSubj: Subject<void> = new Subject<void>();
  private resumeSubj: Subject<void> = new Subject<void>();

  public get refresh$(): Observable<void> {
    return this.refreshSubj.asObservable();
  }

  public get resume$(): Observable<void> {
    return this.resumeSubj.asObservable();
  }

  public get refreshAndResume$(): Observable<void> {
    return this.refresh$.pipe(mergeWith(this.resume$));
  }

  public constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }


  public init(): void {

    if (isPlatformBrowser(this.platformId)) {

      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == 'visible') {
          this.resumeSubj.next();
        }
      });

    }

  }

  public refresh(): void {
    this.refreshSubj.next();
  }
}
