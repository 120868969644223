import { NgModule } from '@angular/core';

import {BcCommonModule} from "../bc-common.module";
import {ConnectComponent} from "./connect/connect.component";


@NgModule({
  imports: [
    BcCommonModule,
  ],
  declarations: [ConnectComponent],
  exports: [ConnectComponent]
})
export class BotsModule { }
