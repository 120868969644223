import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonTextarea, ModalController} from "@ionic/angular";
import {FormControl} from "@angular/forms";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'text-area-popup',
  templateUrl: './text-area-popup.component.html',
  styleUrls: ['./text-area-popup.component.scss'],
})
export class TextAreaPopupComponent extends ModalComponentBase implements OnInit {

  @Input()
  public val: string;

  @Input()
  public maxlength: number;

  @Input()
  public title: string = "Введите что-нибудь";

  @ViewChild('inp') input: IonTextarea;

  public control: FormControl;

  constructor(
    modalController: ModalController,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location
  ) {
    super(modalController, router, activatedRoute, location);
  }

  public ngOnInit(): void {

    super.init().then(() => {

      setTimeout(async () => {
        this.control = new FormControl(this.val, []);
      }, 200);

    });

  }

  public async onCloseClick(): Promise<void> {
    await super.dismiss();
  }

  public async onSaveClick(): Promise<void> {
    await super.dismiss(this.control.value?.toString()?.trim() ?? '');
  }

  async setTextCursor() {
    if(this.control.value) {
      let inputElement = await this.input.getInputElement();
      inputElement.setSelectionRange(this.control.value.length, this.control.value.length);
    }
  }
}
