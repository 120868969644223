import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class TokenStoreService {
  private KEY: string = 'token';
  private KEY2: string = 'refresh_token';

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
  }

  get(): TokenInfo {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage?.getItem(this.KEY) && localStorage?.getItem(this.KEY2)) {
        return new TokenInfo(localStorage?.getItem(this.KEY), localStorage.getItem(this.KEY2));
      }
    }

    return null;
  }

  getToken(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage?.getItem(this.KEY) || null;
    } else{
      return null;
    }
  }

  getRefreshToken(): string {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage?.getItem(this.KEY2) || null;
    } else{
      return null;
    }
  }

  put(token: string, refreshToken: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage?.setItem(this.KEY, token);
      localStorage?.setItem(this.KEY2, refreshToken);
    }
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage?.removeItem(this.KEY);
      localStorage?.removeItem(this.KEY2);
      localStorage?.removeItem("clientTrackingCode");
    }
  }
}

class TokenInfo {
  public token: string;
  public refreshToken: string;

  constructor(token, refreshToken) {
    this.token = token;
    this.refreshToken = refreshToken;
  }
}
