import {NgModule} from "@angular/core";
import {HelpComponent} from "./help/help.component";
import {IonicModule} from "@ionic/angular";
import {MarkdownModule} from "ngx-markdown";
import {StrapiModalComponent} from "./strapi-modal/strapi-modal.component";

@NgModule({
  imports: [
    IonicModule,
    MarkdownModule.forRoot(),
  ],
  exports: [
    StrapiModalComponent,
  ],
  declarations: [
    HelpComponent,
    StrapiModalComponent,
  ]
})
export class StrapiModule {

}
