import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-install-banner',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss'],
})
export class InstallComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
