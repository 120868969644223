<div class="banner b1">
  <img src="assets/banners/banner1.png"/>
  <h2>
    Только активные мастера
  </h2>
  <p>
    Реальные отзывы и статистика<br/>
    Онлайн запись
  </p>
</div>
