<ion-header>
  <ion-toolbar>
    <ion-title>Начало работы</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <lib-address-form-step2 #formStep2Component [addressLocation]="this._wizardController.addressData.addressLineData.addressLocation" (onFormSubmit)="this.onFormSubmit($event)"></lib-address-form-step2>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <lib-submit-button (click)="formStep2Component.onNextClick()">Продолжить</lib-submit-button>
</ion-footer>
