import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import {catchError, Observable, retry, timer} from "rxjs";
import {AlertController} from "@ionic/angular";
import {RefreshService} from "./refresh.service";
import {tap} from "rxjs/operators";
import {NetworkError} from "./network.error";

@Injectable()
export class RetryInterceptorService implements HttpInterceptor {

  public smallNetworkProblem: boolean = false;
  public bigNetworkProblem: boolean = false;

  public isStatusReplayable(status: number): boolean {
    return status == 0 || status == 504;
  }

  constructor(private alertController: AlertController, private refreshService: RefreshService) {
    this.refreshService.refreshAndResume$.subscribe(() => {
      this.smallNetworkProblem = false;
      this.bigNetworkProblem = false;
    });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        retry({
          count: 20,
          delay: (error, retryCount) => {

            if (error instanceof HttpErrorResponse && this.isStatusReplayable(error.status)) {
              this.smallNetworkProblem = true;
              return timer(1000);
            } else {
              throw error;
            }
          },
          resetOnSuccess: false
        }),
        catchError((error, caught) => {

          if (error instanceof HttpErrorResponse && this.isStatusReplayable(error.status)) {
            this.bigNetworkProblem = true;
            throw new NetworkError(error);
          }

          throw error;
        }),
        tap((x: HttpEvent<any>) => {

          if (x.type == HttpEventType.Response || x.type == HttpEventType.ResponseHeader) {
            this.smallNetworkProblem = false;
            this.bigNetworkProblem = false;
          }

        })
      );
  }
}
