import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {IonicSlides, ModalController} from "@ionic/angular";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {SwiperOptions} from "swiper/types";
import Swiper from "swiper";

@Component({
  selector: 'app-images-viewer',
  templateUrl: './images-viewer.page.html',
  styleUrls: ['./images-viewer.page.scss'],
})
export class ImagesViewerPage extends ModalComponentBase implements OnInit {
  swiperModules = [IonicSlides];

  @Input()
  public title: string;

  @Input()
  public images: string[] = []

  @Input()
  public allowDeleting: boolean = false;

  @Input()
  public allowAdding: boolean = false;

  @Input()
  public initialSlide: number = 0;

  swiper?: Swiper;
  @ViewChild('swiperRef')
  swiperRef: ElementRef | undefined;

  constructor(modalController: ModalController,
              router: Router,
              activeRoute: ActivatedRoute,
              location: Location,
              private ngZone: NgZone,
  ) {
    super(modalController, router, activeRoute, location);
  }

  ngAfterViewInit() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
    this.swiper.slideTo(this.initialSlide);
  }

  ngOnInit() {
    super.init().then();
  }

  public async onCloseClicked(): Promise<void> {
    await super.dismiss();
  }

  public async onDeleteClicked(image: string): Promise<void> {
    await super.dismiss(image);
  }

  public config: SwiperOptions = {
    pagination: true
  }
}
