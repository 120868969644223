<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button [defaultHref]="backUrl"></lib-back-button>
    </ion-buttons>
    <ion-title>{{promo?.title}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <lib-card-spinner *ngIf="this.processing == true"></lib-card-spinner>
    <form id="formGroup" *ngIf="this.processing == false && this.promo != null">
      <div class="cards-list">
        <div class="card padding">
          <lib-promo-form *ngIf="this.formGroup" [formGroup]="formGroup"></lib-promo-form>
        </div>
        <div class="card padding" *ngIf="this.promo != null">
          <h2>Услуги входящие в акцию</h2>
          <ion-list class="colored-secondary">
            <app-price-list [priceList]="this.priceListVmSelected"
                            [showGroups]="true"
                            [showCloseButton]="true"
                            [showInfoButton]="false"
                            [coloredSecondary]="false"
                            (deleteItemClicked)="onUnchooseItemClick($event)"
                            (itemClicked)="onChooseItemClick()">
            </app-price-list>
          </ion-list>
          <ion-buttons class="ion-justify-content-center"
                       [ngStyle]="{'padding-top': (this.priceListItemIdsSelected.length > 0) ? '20px' : '0'}"
                       *ngIf="this.priceListVm.getAllItems().length != this.priceListItemIdsSelected.length">
            <ion-button shape="circle" (click)="onChooseItemClick()">
              <ion-icon slot="icon-only" name="add-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button (click)="onSubmitClick()"
                expand="block"
                size="default"
                form="formGroup">Сохранить
    </ion-button>
  </div>
</ng-template>
