<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button [defaultHref]="backUrl"></lib-back-button>
    </ion-buttons>
    <ion-title>Адреса</ion-title>
    <ion-buttons slot="end">
      <ion-button [routerLink]="['new']">
        <ion-icon name="add-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <lib-card-spinner *ngIf="this.processing == true"></lib-card-spinner>
    <div class="cards-list" *ngIf="this.processing == false">
      <ng-template #empty>
        <lib-card-empty></lib-card-empty>
      </ng-template>
      <div class="card padding" *ngIf="addresses.length != 0; else empty">
        <ion-list>
          <ng-container *ngFor="let address of addresses">
            <ion-item (click)="onAddressClick(address)" button="true" detail="false">
              <ng-container *ngIf="!address.addressSaloonName || _addressController.listType == 'saloon'; else withSaloon">
                <ion-avatar slot="start" style="background: #FFFFFF">
                  <ion-icon name="location-outline"></ion-icon>
                </ion-avatar>
                <ion-label>
                  <h4>{{address.addressLine1}}</h4>
                  <p>{{address.addressLine2}}</p>
                </ion-label>
              </ng-container>
              <ng-template #withSaloon>
                <lib-avatar [name]="address.addressSaloonName"
                            [avatarFileName]="address.addressSaloonAvatar"
                            slot="start">
                </lib-avatar>
                <ion-label>
                  <h4>{{address.addressSaloonName}}, {{address.addressSaloonType}}</h4>
                  <p>{{address.addressLine1 | addressPipe :address.addressLine2}}</p>
                </ion-label>
              </ng-template>
              <ion-buttons *ngIf="this._addressController.readOnly == false" slot="end">
                <ion-button (click)="onDeleteClick(address, $event)">
                  <ion-icon name="close-circle-outline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ng-container>
        </ion-list>
      </div>
    </div>
  </div>
</ion-content>
