import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import GeocoderRequest = google.maps.GeocoderRequest;
import {ToastService} from "../../../toast/toast.service";
import {Loader} from "@googlemaps/js-api-loader";
import {AddressLineData} from "../../addressController";

@Component({
  selector: 'lib-address-form-step1',
  templateUrl: './address-form-step1.component.html',
  styleUrls: ['./address-form-step1.component.scss'],
})
export class AddressFormStep1Component implements OnInit {
  @Output()
  public buttonTitle: string = "Продолжить";
  @Output()
  public onFormSubmit: EventEmitter<AddressLineData> = new EventEmitter<AddressLineData>();

  public addressForm: FormGroup;

  private loader = new Loader({
    apiKey: "AIzaSyA1rGT_3ycjqg0RWD764N1gFJjrA9KOhXo",
    version: "weekly",
    language: "ru",
  });

  constructor(
    private _toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.loader.load().then();

    this.addressForm = new FormGroup({
      addressCity: new FormControl(null, [Validators.required]),
      addressStreet: new FormControl(null, [Validators.required]),
      addressHouse: new FormControl(null, [Validators.required]),
      addressLine2: new FormControl(null, []),
    });
  }

  public async onNextClick() {
    if (this.addressForm.invalid){
      return;
    }

    let addressLocation: google.maps.LatLng;
    let geocoder = new google.maps.Geocoder();

    let addressLine1 = [this.addressForm.controls.addressCity.value,
      this.addressForm.controls.addressStreet.value,
      this.addressForm.controls.addressHouse.value].join(" ");

    await geocoder.geocode({
        address: addressLine1
      } as GeocoderRequest,
      async (geocode, status) => {
        if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
          await this._toastService.error("К сожалению, мы не смогли найти на карте Ваш адрес");
          return;
        }

        addressLocation = geocode[0].geometry.location;
      });

    this.onFormSubmit.emit({
      addressLine1: addressLine1,
      addressLine2: this.addressForm.controls.addressLine2.value,
      addressLocation: addressLocation,
    } as AddressLineData);
  }
}

