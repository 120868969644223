<ion-list class="ion-no-padding"
          [ngClass]="{'colored-secondary': coloredSecondary, 'show-specialities': showSpecialities, 'show-groups': showGroups}">
  <ng-container *ngFor="let spec of priceList?.specsAllowedByFilter; trackBy: trackById">
    <ion-list-header *ngIf="showSpecialities && priceList?.specialities?.length > 1" color="primary">
      <h2 class="ion-margin-vertical" style="margin: 10px 20px">
        {{ spec.name }}
      </h2>
    </ion-list-header>
    <ng-container *ngFor="let group of spec.groupsAllowedByFilter; trackBy: trackById">
      <ion-list-header *ngIf="showGroups">
        <h3>
          <span>{{ group.name }}</span>
        </h3>
      </ion-list-header>
      <ion-reorder-group (ionItemReorder)="$event.stopImmediatePropagation(); onItemReorder($event,group)"
                         [disabled]="reorderEnabled === false">
        <ion-item *ngFor="let item of group.itemsAllowedByFilter; trackBy: trackById" class="pli" [button]="true"
                  [detail]="false" (click)="onItemClick($event, item)">
          <ion-label>
            <div>
              {{ item.serviceTypeName }}
            </div>
            <p>
              <ion-note *ngIf="item.isEmpty">Не выбрано</ion-note>
              <ion-note *ngIf="!item.isEmpty">
                <ng-container *ngIf="(item.priceMinWithDiscount != null && item.priceMaxWithDiscount != null) &&
                  (item.priceMin != item.priceMinWithDiscount || item.priceMax != item.priceMaxWithDiscount); else notDiscount">
                    <span class="price new">
                      {{ (item.priceMinWithDiscount | priceRange: item.priceMaxWithDiscount:priceList?.currency) }}
                    </span>
                  <span class="price old">
                      {{ (item.priceMin | priceRange: item.priceMax:priceList?.currency) }}
                    </span>
                </ng-container>
                <ng-template #notDiscount>
                    <span class="price">
                      {{ (item.priceMin | priceRange: item.priceMax:priceList?.currency) }}
                    </span>
                </ng-template>
                <span class="time">
<!--                    Так выводим для клиентов без учёта отдыха, а для мастеров и студий - с учётом-->
                  {{ (applicationIdService.applicationId == 'client' ? item.durationInMinutesMin : item.durationWithRestInMinutesMin) | durationRange :(applicationIdService.applicationId == 'client' ? item.durationInMinutesMax : item.durationWithRestInMinutesMax) }}
                  </span>
              </ion-note>
            </p>
          </ion-label>
          <ion-buttons slot="end">
            <ng-container *ngIf="showInfoButton == true">
              <ion-button *ngIf="item.infoText" size="small"
                          (click)="$event.preventDefault(); $event.stopPropagation(); onInfoClick(item)">
                <ion-icon slot="icon-only" name="information-circle-outline"></ion-icon>
              </ion-button>
            </ng-container>
            <ion-button *ngIf="!item.isEmpty && showCloseButton === true && reorderEnabled == false" size="small"
                        (click)="$event.preventDefault(); $event.stopPropagation(); onTrashClick(item)">
              <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-reorder-group>
    </ng-container>
  </ng-container>
</ion-list>
