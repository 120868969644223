import {Injectable} from '@angular/core';
import {catchError, Observable, shareReplay, switchMap} from "rxjs";
import {publishReplay, refCount, share, startWith, tap} from "rxjs/operators";
import {MasterListClient, SpecialityRes,} from "./../api-clients/master-list/clients";
import {RefreshService} from "../utils/refresh.service";


@Injectable({
  providedIn: 'root'
})
export class SpecialitiesDirectoryService {

  private cache: any = {};

  public constructor(
    private client: MasterListClient,
    private refreshService: RefreshService,
  ) {
  }


  public getSpecialities(masterIds: string[] = null, force: boolean = false): Observable<SpecialityRes[]> {

    const key = this.generateKey(masterIds);

    if (!this.cache[key] || force) {
      this.cache[key] = this.refreshService.refreshAndResume$.pipe(
        startWith(null),
        switchMap(() => this.client.getSpecialities(masterIds, null)),
        catchError(err => {
          this.cache[key] = null;
          throw err;
        }),
        shareReplay(1),
      );
    }

    return this.cache[key];
  }

  private generateKey(masterIds: string[]): string {
    if (masterIds == null || masterIds?.length == 0) {
      return 'all';
    }

    return masterIds.sort().reduce((sum, x) => sum + x, '');
  }
}
