import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {FormGroup} from "@angular/forms";


export function validatorInstagramLogin(): ValidatorFn {
  return Validators.pattern("^(((@).{3,40})|)$");
}

export function validatorInstagramLink(): ValidatorFn {
  return Validators.pattern("^((((http://)|(https://))(www.)?(instagram.com).{3,40})|)$");
}

export function validatorInstagram(): ValidatorFn {
  return Validators.pattern("^((((((http://)|(https://))(www.)?(instagram.com))|(@)).{3,40})|)$");
}

export function validatorVk(): ValidatorFn {
  return Validators.pattern("^((((http://)|(https://))(www.)?(vk.com).{3,40})|)$");
}

export function validatorTelegram(): ValidatorFn {
  return Validators.pattern("^((((http://)|(https://))(www.)?(t.me).{3,40})|)$");
}

export function validatorSkype(): ValidatorFn {
  return Validators.pattern(".*");
}

export function validatorViber(): ValidatorFn {
  return Validators.pattern("^([+][0-9]{12,15})$");
}

export function validatorPhone(): ValidatorFn {
  const pattern = new RegExp("^([+][0-9]{11,15})$");
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = pattern.test(cleanPhone(control.value));
    return isValid ? null : {forbiddenName: {value: control.value}};
  };
}

export function cleanPhone(value: string): string {
  return value?.replace(/[^0-9+]/g, '');
}

export class AddressValidator {
  static requiredLine1StartDate(addressForm: FormGroup): { [key: string]: boolean } {

    const addressNextLine1 = addressForm.value.addressNextLine1;
    const addressNextStartDate = addressForm.value.addressNextStartDate;

    const error = {required: true};
    if (addressNextLine1 && !addressNextStartDate){
      addressForm.controls.addressNextStartDate.setErrors(error);
      return error;
    }
    if (!addressNextLine1 && addressNextStartDate){
      addressForm.controls.addressNextLine1.setErrors(error);
      return error;
    }
    addressForm.controls.addressNextLine1.setErrors(null);
    addressForm.controls.addressNextStartDate.setErrors(null);
    return null;
  }
}


