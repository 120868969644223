import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BookingPage} from "./booking/booking.page";
import {TokenGuard} from "../security/token.guard";

import {NewComponent} from "./new/new.component";
import {ServicesComponent} from "./new/services/services.component";
import {TimeSlotsComponent} from "./new/time-slots/time-slots.component";
import {SignInComponent} from "./new/sign-in/sign-in.component";
import {SignInModule} from "../sign-in/sign-in.module";
import {ClientComponent} from "./new/client/client.component";
import {CheckComponent} from "./new/check/check.component";
import {ConfirmationComponent} from "./new/confirmation/confirmation.component";

import {MoveComponent} from "./move/move.component";

const routes: Routes = [
  {
    path: 'new',
    component: NewComponent,
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        redirectTo: 'services',
        pathMatch: 'prefix',
      },
      {
        path: 'services',
        component: ServicesComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'time-slots',
        component: TimeSlotsComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'sign-in',
        component: SignInComponent,
        loadChildren: () => SignInModule,
        pathMatch: 'prefix',
      },
      {
        path: 'client',
        component: ClientComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'check',
        component: CheckComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'confirmation',
        component: ConfirmationComponent,
        pathMatch: 'prefix',
      }
    ]
  },
  {
    path: ':id/move',
    component: MoveComponent,
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        redirectTo: 'services',
        pathMatch: 'prefix',
      },
      {
        path: 'services',
        component: ServicesComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'time-slots',
        component: TimeSlotsComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'sign-in',
        component: SignInComponent,
        loadChildren: () => SignInModule,
        pathMatch: 'prefix',
      },
      {
        path: 'client',
        component: ClientComponent,
        loadChildren: () => SignInModule,
        pathMatch: 'prefix',
      },
      {
        path: 'check',
        component: CheckComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'confirmation',
        component: ConfirmationComponent,
        pathMatch: 'prefix',
      }
    ]
  },
  {
    path: ':id',
    canActivate: [TokenGuard()],
    component: BookingPage,
    pathMatch: 'prefix',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BookingsRoutingModule {
}
