import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-add-bookings-banner',
  templateUrl: './add-bookings.component.html',
  styleUrls: ['./add-bookings.component.scss'],
})
export class AddBookingsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
