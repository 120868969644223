<ion-header>
  <ion-toolbar>
    <ion-title>Сортировка</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content forceOverscroll="true">
  <div class="content-section">
    <div class="cards-list">
      <div class="card padding">
        <ion-list lines="full">
          <ion-item *ngFor="let sorting of sortingArray" (click)="onSortingClick(sorting)" [button]="true"
                    details="false">
            {{sorting | sortingName}}
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
</ion-content>
