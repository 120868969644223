<ion-header>
  <ion-toolbar>
    <div>
      <ion-title>{{title}}</ion-title>
    </div>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClicked()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="height: 100%;">
    <swiper-container swiperElement #swiperRef *ngIf="images" style="height: 100%" [config]="config" init="false">
      <swiper-slide *ngFor="let image of images">
        <ion-img [src]="image | fileUrl | mediumUrl"
                 class="ion-margin-bottom"
                 style="height: 100%;--margin-bottom: 0;margin-bottom: 0;"></ion-img>
      </swiper-slide>
    </swiper-container>
  </div>
</ion-content>
