<span *ngIf="this.applicationId != 'client'">
  <ion-icon
    *ngFor="let star of [1,2,3,4,5]"
    [name]="star > value ? 'star-outline' : 'star'"
    [ngStyle]="{'font-size': fontSize + 'px', 'color': activeColor, 'cursor': 'pointer'}"
    (click)="writeValue(star)"></ion-icon>
</span>
<span *ngIf="this.applicationId == 'client'" class="new-design">
  <ion-icon
    *ngFor="let star of [1,2,3,4,5]"
    [name]="star > value ? 'star-outline' : 'star'"
    [ngStyle]="{'color': activeColor, 'cursor': 'pointer'}"
    (click)="writeValue(star)"></ion-icon>
</span>
