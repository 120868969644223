import {Inject, Injectable, Pipe, PipeTransform} from '@angular/core';
import {FILES_BASE_URL} from "./files-base-url";

@Injectable({providedIn: 'root'})
@Pipe({
  name: 'fileUrl'
})
export class FileUrlPipe implements PipeTransform {

  constructor(@Inject(FILES_BASE_URL) private filesBaseUrl: string) {
  }

  public transform(name: string): string {
    if (!name) {
      return null;
    }

    return this.filesBaseUrl + '/' + name;
  }
}

