import { Component, OnInit } from '@angular/core';
import {AddressController, AddressLocationData} from "../../addressController";

@Component({
  selector: 'lib-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements OnInit {

  constructor(
    public _addressController: AddressController,
  ) { }

  ngOnInit() {}

  onFormSubmit(data: AddressLocationData) {
    this._addressController.addressData.setAddressLocationData(data);
  }

}
