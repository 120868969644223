import {Component, OnInit} from '@angular/core';
import {AddressController} from "../addressController";
import {DeleteMasterAddressReq, MasterAddressRes, MasterListClient} from "../../api-clients/master-list/clients";
import {SaloonsClient} from "../../api-clients/saloon/clients";
import {AlertButton, AlertController} from "@ionic/angular";
import {ToastService} from "../../toast/toast.service";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {NgEventBus} from "ng-event-bus";
import {BookingClient, UpdateAddressWorkingDayReq} from "../../api-clients/booking/clients";
import {concat, last} from "rxjs";

@Component({
  selector: 'lib-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  public addresses: MasterAddressRes[] = [];
  public backUrl: string;
  public processing: boolean;

  constructor(
    public _addressController: AddressController,
    private _masterListClient: MasterListClient,
    private _saloonsClient: SaloonsClient,
    private _alertController: AlertController,
    private _toastService: ToastService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _applicationIdService: ApplicationIdService,
    private _eventBus: NgEventBus,
    private _bookingClient: BookingClient,
  ) {
  }

  ngOnInit() {
    this.backUrl = this._router.createUrlTree(['../'], {relativeTo: this._activatedRoute}).toString();
  }

  ionViewWillEnter() {
    this.processing = true;
    this._addressController.getAddresses().then((data) => {
        this.addresses = data;
        this.processing = false;
      }
    );
  }

  public async showAlert(message, buttons): Promise<string> {
    const alert = await this._alertController.create({
      cssClass: 'center',
      message: message,
      buttons: buttons,
    });

    await alert.present();

    return (await alert.onDidDismiss()).role;
  }

  public async onDeleteClick(address: MasterAddressRes, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    if(this._addressController.listType == "saloon" && this.addresses.length == 1){
      this._toastService.error("Удалять последний адрес салона запрещено").then();
      return;
    }

    const scheduleDaysRes = this._addressController.scheduleDays;

    let scheduleDaysInAddress = scheduleDaysRes.filter(s =>
      s.addressLine1 == address.addressLine1 &&
      s.addressLine2 == address.addressLine2 &&
      s.addressLatitude == address.addressLatitude &&
      s.addressLongitude == address.addressLongitude
    );

    let alertRole: string;

    if (scheduleDaysInAddress.length > 0) {
      alertRole = await this.alertUpdateAddressWorkingDay(address.id, address.addressSaloonId != null);
    } else {
      alertRole = await this.alertConfirmDeleteAddress(address.addressSaloonId != null);
    }

    if (alertRole == 'backdrop') {
      return;
    } else if (alertRole == 'delete') {
      await this.deleteAddress(address.id)

      await this._toastService.success("Адрес удален");
    } else {
      let masterAddress = this.addresses.find(a => a.id == alertRole);
      if (masterAddress == null) {
        await this._toastService.success("Адрес не найден");
        return;
      }

      let scheduleDayIds = scheduleDaysInAddress.map(a => a.id);

      let res$ = scheduleDayIds.map(scheduleDayId =>
        this._bookingClient.updateAddressWorkingDay({
          scheduleDayId: scheduleDayId,
          addressLine1: masterAddress.addressLine1,
          addressLine2: masterAddress.addressLine2,
          addressSaloonId: masterAddress.addressSaloonId,
          addressSaloonName: masterAddress.addressSaloonName,
          addressSaloonAvatar: masterAddress.addressSaloonAvatar,
          addressSaloonType: masterAddress.addressSaloonType,
          addressLatitude: masterAddress.addressLatitude,
          addressLongitude: masterAddress.addressLongitude,
          addressCountryId: masterAddress.addressCountryId,
          addressCityId: masterAddress.addressCityId,
          addressRegionId: masterAddress.addressRegionId,
          addressCityTimeZoneShiftInSeconds: masterAddress.addressCityTimeZoneShiftInSeconds,
          addressHowToGetImagesNames: masterAddress.howToGetImagesNames,
          addressHowToGetText: masterAddress.howToGetText,

        } as UpdateAddressWorkingDayReq));

      this.processing = true;
      await concat(...res$).pipe(last()).subscribe(async () => {
        await this.deleteAddress(address.id);
        this.processing = false;
        this._toastService.success("Адрес удален, записи перенесены").then();
      });
    }
  }

  public async alertUpdateAddressWorkingDay(currentAddressId: string, isSaloonAddress: boolean): Promise<string> {
    let message = '';

    if (this._addressController.listType == "master"){
      if (isSaloonAddress){
        message = 'Вы покидаете салон. ' +
          'У вас есть рабочие дни в будущем в этом салоне. ' +
          'Выберете новый адрес для этих дней или сделайте их выходными (записи отменятся).' +
          'Записи салона отменятся.';
      }
      else {
        message = 'У вас есть рабочие дни в будущем на этом адресе. ' +
          'Выберете новый адрес для этих дней или сделайте их выходными (записи отменятся)';
      }
    }
    else if (this._addressController.listType == "saloon"){
      message = 'У вас есть рабочие дни в будущем на этом адресе. ' +
        'Выберете новый адрес для этих дней или сделайте их выходными (записи отменятся)';
    }

    let availableAddresses = this.addresses.filter(a => a.id != currentAddressId);

    let buttons = [];

    if (availableAddresses.length > 0){
      buttons.push(...availableAddresses.map(a =>
        ({text: a.addressLine1, role: a.id, cssClass: "alert-button-custom"} as AlertButton)));
    }
    else {
      buttons.push({
        text: 'Нет доступных адресов',
        cssClass: "alert-button-custom",
        htmlAttributes: {'disabled': true}
      } as AlertButton);
    }
    buttons.push({text: "Отменить записи", role: "delete", cssClass: "alert-button-custom"} as AlertButton);

    return await this.showAlert(message, buttons);
  }

  public async alertConfirmDeleteAddress(isSaloonAddress: boolean): Promise<string> {
    let buttons = [{
      text: 'Удалить',
      role: 'delete'
    }, {
      text: 'Отмена',
      role: 'backdrop'
    }];

    let message;

    if (isSaloonAddress && this._addressController.listType == "master") {
      message = 'Вы уверены что хотите покинуть салон?'
    } else {
      message = 'Вы уверены что хотите удалить адрес?'
    }

    return this.showAlert(message, buttons);
  }

  public async deleteAddress(addressId: string) {
    const req = new DeleteMasterAddressReq();
    req.addressId = addressId;
    await this._addressController.deleteAddress(addressId);
    this.addresses = await this._addressController.getAddresses();
    this._eventBus.cast("profile:addressDeleted");
  }

  public async onAddressClick(address: MasterAddressRes) {
    if (this._addressController.readOnly == true) {
      return;
    }

    if (address.addressSaloonId && this._addressController.listType == "master") {
      let saloon = await this._saloonsClient.getSaloonById(address.addressSaloonId).toPromise();
      let connection = saloon.saloon.connections
        .filter(x => x.saloonId == address.addressSaloonId && x.masterId == this._addressController.masterId)[0];
      let request = await this._saloonsClient.getActualRequestToChangeConnectionSettings(connection.id).toPromise();
      if (request != null) {
        await this._router.navigate(['approve-connection-settings/' + connection.code]);
      } else {
        await this._router.navigate(['saloon-connection-settings/' + connection.code]);
      }
      return;
    }

    await this._router.navigate([address.id], {relativeTo: this._activatedRoute});
  }
}
