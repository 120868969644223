import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {
  AuthenticatebyPhoneStep2Req,
  AuthenticationClient,
  AuthenticationPhoneRequest,
  TokenRequest
} from "./../api-clients/authentication/clients";
import {TokenStoreService} from "./token-store.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private parsedTokenSubj: BehaviorSubject<ParsedToken> = new BehaviorSubject(null);

  public get parsedToken$(): Observable<ParsedToken> {
    return this.parsedTokenSubj;
  }

  public get parsedToken(): ParsedToken {
    return this.parsedTokenSubj.value;
  }

  public get isAuthenticated(): boolean {
    return !!this.parsedToken;
  }

  public constructor(
    private authClient: AuthenticationClient,
    private tokenStore: TokenStoreService
  ) {
  }

  public async init(): Promise<void> {
    const token = this.tokenStore.getToken();

    if (!token) {
      this.parsedTokenSubj.next(null);
    }

    const parsedToken = ParsedToken.fromToken(token);

    this.parsedTokenSubj.next(parsedToken);
  }

  public async signInStep1(phoneNumber: string, role: string): Promise<void> {
    await this.authClient.authenticateByPhoneStep1(AuthenticationPhoneRequest.fromJS({
      phone: phoneNumber,
      role: role
    })).toPromise();
  }

  public async signInStep2(phoneNumber: string, code: string, roles: string[] = undefined): Promise<void> {
    const res = await this.authClient.authenticateByPhoneStep2(AuthenticatebyPhoneStep2Req.fromJS({
      phone: phoneNumber,
      code: code,
      roles: roles || undefined
    })).toPromise();

    if (res.token) {
      this.tokenStore.put(res.token, res.refreshToken);
      this.parsedTokenSubj.next(ParsedToken.fromToken(res.token));
    }
  }

  public async signOut(): Promise<void> {
    const token = this.tokenStore.getToken();

    if (token) {
      this.tokenStore.clear();
      this.parsedTokenSubj.next(null);
    }
  }

  private refreshTokenPromise: Promise<void> = null;

  public async refreshToken(): Promise<void> {

    if (this.refreshTokenPromise) {
      return this.refreshTokenPromise;
    }

    const refreshToken = this.tokenStore.getRefreshToken();

    if (!refreshToken) {
      return Promise.resolve();
    }

    this.refreshTokenPromise = this.authClient.refreshToken({refreshToken: refreshToken} as TokenRequest)
      .toPromise()
      .then((data) => {
        this.tokenStore.put(data.token, data.refreshToken);
        this.parsedTokenSubj.next(ParsedToken.fromToken(data.token));
      })
      .finally(() => {
        this.refreshTokenPromise = null;
      });

    return this.refreshTokenPromise;
  }
}

export class ParsedToken {
  userId: string = null;
  userName: string = null;
  expirationDate: Date = null;

  roles: string[] = [];
  isClient: boolean;
  isMaster: boolean;
  isSaloonOwner: boolean;
  isSaloonAdmin: boolean;

  saloonIdsUserOwnerOf: string[] = [];
  saloonIdsUserAdminIn: string[] = [];

  public static fromToken(token: string): ParsedToken {
    if (!token) {
      return null;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const json = JSON.parse(jsonPayload);

    const parsedToken = new ParsedToken();

    parsedToken.userId = json['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
    parsedToken.expirationDate = new Date(json['exp'] * 1000);

    parsedToken.roles = json['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']?.split(',') || [];
    parsedToken.isClient = parsedToken.roles.some(r => r == 'Client');
    parsedToken.isMaster = parsedToken.roles.some(r => r == 'Master');
    parsedToken.isSaloonOwner = parsedToken.roles.some(r => r == 'SaloonOwner');
    parsedToken.isSaloonAdmin = parsedToken.roles.some(r => r == 'SaloonAdmin');

    parsedToken.saloonIdsUserOwnerOf = json['saloonIdsUserOwnerOf']?.split(',') || [];
    parsedToken.saloonIdsUserAdminIn = json['saloonIdsUserAdminIn']?.split(',') || [];

    return parsedToken;
  }
}
