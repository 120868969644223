<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="content-section">
    <div class="cards-list">
      <div class="card padding">
        <h2>
          {{addressLine1}}
          <ng-container *ngIf="addressLine2">
            <br/>{{addressLine2}}
          </ng-container>
        </h2>

        <p class="saloon" *ngIf="addressSaloonId">
          <a routerLink="/saloons/{{addressSaloonId}}">{{addressSaloonType}} {{addressSaloonName}}</a>
        </p>

        <div class="maps">
          <ion-button href="https://yandex.ru/maps/?whatshere[point]={{addressLongitude}},{{addressLatitude}}&whatshere[zoom]=20" target="_blank">
            <ion-icon name="logo-yahoo" style="color: #e7542e" slot="start"></ion-icon>
            Яндекс
          </ion-button>
          <ion-button href="https://www.google.com/maps/search/?api=1&query={{addressLatitude}},{{addressLongitude}}" target="_blank">
            <ion-icon name="logo-google" style="color: #517fe7" slot="start"></ion-icon>
            Google
          </ion-button>
        </div>

        <p *ngIf="addressHowToGetText">
          {{addressHowToGetText}}
        </p>

        <app-images-set *ngIf="addressHowToGetImagesNames?.length"
                        size="small"
                        [imagesInit]="addressHowToGetImagesNames"></app-images-set>
      </div>
    </div>
  </div>

</ion-content>
