<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
    <div *ngIf="formGroupReason" class="content-section">
      <form [formGroup]="formGroupReason">
        <div class="cards-list form">

          <div class="card padding">
            <ion-list lines="none" class="classic">
              <ion-item>
                <ion-label position="stacked">Причина отмены (необязательно)</ion-label>
                <ion-input #inp
                           formControlName="textControl"
                           [maxlength]="40"
                           placeholder="Введите текст">
                </ion-input>
              </ion-item>
              <ion-item class="note-v2">
                <ion-note>
                  <ion-chip (click)="onReasonClick(reason)"
                            *ngFor="let reason of defaultReasons"
                            color="primary">
                    <span>{{reason.name}}</span>
                  </ion-chip>
                </ion-note>
              </ion-item>
              <ion-item class="info-v2" *ngIf="info != null">
                <span class="ion-text-center padding-mobile">{{info}}</span>
              </ion-item>
            </ion-list>
          </div>

          <div class="hide-mobile">
            <ng-container *ngTemplateOutlet="cancellation_button"></ng-container>
          </div>
        </div>
      </form>
    </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="cancellation_button"></ng-container>
</ion-footer>
<ng-template #cancellation_button>
  <div class="card transparent padding-mobile">
    <ion-button (click)="onSaveClick()" expand="block">Далее</ion-button>
  </div>
</ng-template>


