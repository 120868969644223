import {NgModule} from "@angular/core";
import {StarRatingComponent} from "./star-rating.component";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [
    IonicModule,
    CommonModule
  ],
  exports: [
    StarRatingComponent
  ],
  declarations: [
    StarRatingComponent
  ]
})
export class StarRatingModule {

}
