<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>
      Инфо
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <lib-refresher slot="fixed"></lib-refresher>

  <div class="content-section cards-list safe-area-space">

    <lib-card-empty
      *ngIf="ifNullOrEmptyOrWhiteSpace(saloon?.aboutSaloonText?.trim()) &&
      saloon?.aboutSaloonImagesNames?.length == 0 &&
      authService.isAuthenticated &&
      !saloonsContacts"></lib-card-empty>

    <div class="card padding sing-in" *ngIf="!authService.isAuthenticated">
      <span class="ion-text-center">
        Чтобы увидеть контакты:
      </span>
      <div>
        <ion-button (click)="this.onContactsSignIn()">Войти</ion-button>
      </div>
    </div>

    <div *ngIf="!ifNullOrEmptyOrWhiteSpace(saloon?.aboutSaloonText?.trim())"
         class="card padding text">
      <div [innerText]="saloon?.aboutSaloonText?.trim()"></div>
    </div>

    <div class="card padding" *ngIf="this.saloonsContacts">
      <h2>{{saloon.type}} {{saloon.name}}</h2>
      <lib-contacts-info [contacts]="saloonsContacts"></lib-contacts-info>
    </div>

    <div class="card padding" *ngIf="saloon?.aboutSaloonImagesNames.length > 0">
      <app-images-set [imagesInit]="saloon?.aboutSaloonImagesNames" title="О себе"></app-images-set>
    </div>

  </div>

</ion-content>
