<ng-container *ngIf="stars !== null && this._applicationIdService.applicationId != 'client' && this.mode=='long'">
  <ion-icon
    [name]="stars > 0.75 ? 'star' : stars > 0.25 ? 'star-half-outline' : 'star-outline'"
    style="color: #ffa500"></ion-icon>
  <ion-icon
    [name]="stars > 1.75 ? 'star' : stars > 1.25 ? 'star-half-outline' : 'star-outline'"
    style="color: #ffa500"></ion-icon>
  <ion-icon
    [name]="stars > 2.75 ? 'star' : stars > 2.25 ? 'star-half-outline' : 'star-outline'"
    style="color: #ffa500"></ion-icon>
  <ion-icon
    [name]="stars > 3.75 ? 'star' : stars > 3.25 ? 'star-half-outline' : 'star-outline'"
    style="color: #ffa500"></ion-icon>
  <ion-icon
    [name]="stars > 4.75 ? 'star' : stars > 4.25 ? 'star-half-outline' : 'star-outline'"
    style="color: #ffa500"></ion-icon>
</ng-container>
<ng-container *ngIf="stars !== null && this._applicationIdService.applicationId == 'client' && this.mode=='long'">
  <div style="display: flex;gap: 2px;flex-direction: row;">
    <ion-icon
      [name]="stars > 0.75 ? 'star' : stars > 0.25 ? 'star-half-outline' : 'star-outline'"
      style="color: #ffa500"></ion-icon>
    <ion-icon
      [name]="stars > 1.75 ? 'star' : stars > 1.25 ? 'star-half-outline' : 'star-outline'"
      style="color: #ffa500"></ion-icon>
    <ion-icon
      [name]="stars > 2.75 ? 'star' : stars > 2.25 ? 'star-half-outline' : 'star-outline'"
      style="color: #ffa500"></ion-icon>
    <ion-icon
      [name]="stars > 3.75 ? 'star' : stars > 3.25 ? 'star-half-outline' : 'star-outline'"
      style="color: #ffa500"></ion-icon>
    <ion-icon
      [name]="stars > 4.75 ? 'star' : stars > 4.25 ? 'star-half-outline' : 'star-outline'"
      style="color: #ffa500"></ion-icon>
  </div>
</ng-container>
<ng-container *ngIf="stars !== null && this._applicationIdService.applicationId == 'client' && this.mode=='small'">
  <span title="Оценка" class="small-mode">
    <ion-icon name="star" color="#FFD700"></ion-icon>
    <ion-text>
      {{stars}}
    </ion-text>
  </span>
</ng-container>
