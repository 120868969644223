import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../security/auth.service";
import {ToastService} from "../../../toast/toast.service";
import {MasterListClient, PromoRes} from "../../../api-clients/master-list/clients";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivatedRouteFixService} from "../../../utils/activated-route-fix.service";
import {NavigationService} from "../../../utils/navigation.service";

@Component({
  selector: 'lib-list-promos',
  templateUrl: './list-promos.component.html',
  styleUrls: ['./list-promos.component.scss'],
})
export class ListPromosComponent implements OnInit {

  public promos: PromoRes[] = [];

  public processing: boolean = false;
  public masterId: string = null;
  public backUrl: string;

  constructor(
    private _authService: AuthService,
    private _toastService: ToastService,
    private _masterListClient: MasterListClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _activatedRouteFix: ActivatedRouteFixService,
  ) { }

  ngOnInit() {
    this.masterId = this._authService.parsedToken.userId;

    this.backUrl = this._router.createUrlTree(['../'], {
      relativeTo: this._activatedRouteFix.getActivatedRoute(this._activatedRoute)
    }).toString();
  }

  public ionViewWillEnter() {
    this.loadData();
  }

  public loadData() {
    this.processing = true;

    this._masterListClient.getMasterPromosByMasterId(this.masterId).subscribe(data => {
      this.promos = data;
      this.processing = false;
    });
  }

  public async onDeleteClick(promo, $event) {
    $event.preventDefault();
    $event.stopPropagation();

    await this._masterListClient.deletePromo(promo.id).toPromise()
      .catch(() => {
        this._toastService.error("Произошла ошибка");
      })
      .finally(() => {
        this.loadData();
        this._toastService.success("Акция удалена");
      });
  }
}
