import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionSheetController} from "@ionic/angular";
import {combineLatest, Observable, of} from "rxjs";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {MasterListClient, SpecialityRes} from "../../api-clients/master-list/clients";
import {
  BookingWithFeedback,
  FeedbackClient,
  GetBookingsWithFeedbacksRes,
} from "../../api-clients/feedback/clients";
import {ClientNamePipe} from "../../utils/client-name.pipe";
import {SubscriptionsBag} from "../../utils/subscriptions-bag";
import {SpecialitiesDirectoryService} from "../../directories/specialities-directory.service";
import {SaloonsClient} from "../../api-clients/saloon/clients";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {ActivatedRouteFixService} from "../../utils/activated-route-fix.service";

@Component({
  selector: 'lib-bookings-with-feedback-list',
  templateUrl: './bookings-with-feedback-list.component.html',
  styleUrls: ['./bookings-with-feedback-list.component.scss'],
})
export class BookingsWithFeedbackListComponent implements OnInit {

  @Input()
  public mode: "client-bookings" | "master-bookings" | "master-feedbacks" = "client-bookings";

  @Input()
  public masterId: string = null;

  @Input()
  public saloonId: string = null;

  @Input()
  public clientId: string = null;

  @Input()
  public canceled: boolean = null;

  @Input()
  public actual: boolean = null;

  @Input()
  public hasAnyFeedback: boolean = null;

  @Input()
  public showFilters: boolean = true;

  @Input()
  public actionText: string = null;

  @Output()
  public detailsClicked: EventEmitter<BookingWithFeedback> = new EventEmitter<BookingWithFeedback>();

  @Output()
  public clientClicked: EventEmitter<BookingWithFeedback> = new EventEmitter<BookingWithFeedback>();

  @Output()
  public masterClicked: EventEmitter<BookingWithFeedback> = new EventEmitter<BookingWithFeedback>();

  @Output() // Для кнопки "оставить отзыв" и тп
  public actionClicked: EventEmitter<void> = new EventEmitter<void>();

  public withImages: FormControl = new FormControl(false);
  public withText: FormControl = new FormControl(false);

  public feedbacks: BookingWithFeedback[] = null;

  public loading: boolean = false;

  private pageIndex: number = 0;
  private pageSize: number = 20;
  private listEndMeet: boolean = false;

  public specialities: SpecialityRes[];

  private sb: SubscriptionsBag = new SubscriptionsBag();

  public constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private masterListClient: MasterListClient,
    private saloonsClient: SaloonsClient,
    private feedbackClient: FeedbackClient,
    public applicationIdService: ApplicationIdService,
    public actionSheetController: ActionSheetController
  ) {
  }

  public ngOnInit(): void {
    this.doRefresh(null);
  }

  public async loadFeedbacksNextPage($event: any): Promise<void> {

    if (this.listEndMeet) {
      $event?.target?.complete();
      return;
    }

    this.loading = true;

    const feedbacksPage = await this.feedbackClient.getBookingsWithFeedbacks(
      this.masterId,
      this.clientId,
      this.saloonId,
      [],
      this.canceled,
      false,
      this.actual,
      !this.actual,
      this.hasAnyFeedback,
      this.withImages.value || null,
      this.withText.value || null,
      (this.pageIndex) * this.pageSize,
      this.pageSize
    ).pipe(
      switchMap(res => this.updateClientNamesByMasterDataIfNeeded(res))
    ).toPromise();

    this.listEndMeet = feedbacksPage.bookingsWithFeedbacks.length !== this.pageSize;

    if (!this.feedbacks || this.pageIndex == 0) {
      this.feedbacks = [];
    }

    this.feedbacks.push(...feedbacksPage.bookingsWithFeedbacks);

    this.loading = false;

    this.pageIndex++;

    $event?.target?.complete();
  }

  public doRefresh($event: any): void {
    this.sb.unsubscribeAll();

    this.sb.sub = combineLatest([
      this.withImages.valueChanges.pipe(startWith(this.withImages.value)),
      this.withText.valueChanges.pipe(startWith(this.withText.value)),
    ]).pipe(
      tap(([withImages, withText]) => {
        this.pageIndex = 0;
        this.listEndMeet = false;
      }),
      switchMap(([withImages, withText]) => this.loadFeedbacksNextPage($event)),
    ).subscribe(res => { });
  }

  private updateClientNamesByMasterDataIfNeeded(res: GetBookingsWithFeedbacksRes): Observable<GetBookingsWithFeedbacksRes> {
    if (this.mode == 'master-bookings') {
      const clientIds = res.bookingsWithFeedbacks.map(f => f.clientId);

      if (this.masterId) {
        return this.masterListClient.getClients(this.masterId, null, clientIds, 0, this.pageSize * 10, null)
          .pipe(map(clientsRes => {
            for (let feedback of res.bookingsWithFeedbacks) {
              for (let client of clientsRes.clients) {
                if (feedback.clientId === client.id) {
                  feedback.clientName = new ClientNamePipe().transform(client.originalName, client.masterDefinedName);
                }
              }
            }

            return res;
          }));
      }

      if (this.saloonId) {
        return this.saloonsClient.getClients(this.saloonId, null, clientIds, 0, this.pageSize * 10, true)
          .pipe(map(clientsRes => {
            for (let feedback of res.bookingsWithFeedbacks) {
              for (let client of clientsRes.clients) {
                if (feedback.clientId === client.id) {
                  feedback.clientName = new ClientNamePipe().transform(client.originalName, client.saloonDefinedName);
                }
              }
            }

            return res;
          }));
      }
    }

    return of(res);
  }

  public async onDetailsClicked(bwf: BookingWithFeedback): Promise<void> {
    this.detailsClicked.emit(bwf);
    return;
  }

  public trackById(index, item: any): any {
    return item.id;
  }
}
