import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from '@ionic/angular';
import {TokenStoreService} from "./token-store.service";
import {AuthService} from "./auth.service";
import {ToastModule} from "../toast/toast.module";

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    ToastModule,
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class SecurityModule {
  static forRoot(): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule,
      providers: [
        AuthService,
        TokenStoreService
      ]
    };
  }
}
