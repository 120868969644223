import {NgModule} from "@angular/core";
import {BannerSliderComponent} from "./banner-slider.component";
import {BcCommonModule} from "../../bc-common.module";
import {InstallComponent} from "./banners/install/install.component";
import {ActiveMastersComponent} from "./banners/active-masters/active-masters.component";
import {PromosComponent} from "./banners/promos/promos.component";
import {BecomeMasterComponent} from "./banners/become-master/become-master.component";
import {AddBookingsComponent} from "./banners/add-bookings/add-bookings.component";
import {AddDaysComponent} from "./banners/add-days/add-days.component";

@NgModule({
  declarations: [
    BannerSliderComponent,
    InstallComponent,
    ActiveMastersComponent,
    PromosComponent,
    BecomeMasterComponent,
    AddBookingsComponent,
    AddDaysComponent
  ],
  exports: [
    BannerSliderComponent,
  ],
  imports: [
    BcCommonModule
  ],
})
export class BannerSliderModule{

}
