import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController} from "@ionic/angular";
import {FormControl} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

import {combineLatest, Subject} from "rxjs";
import {delay, startWith, switchMap, tap} from "rxjs/operators";
import {ClientVm} from "../../../client/clients-vm";
import {ClientRes, DeleteMasterClientReq, MasterListClient} from "../../../api-clients/master-list/clients";
import {SaloonsClient} from "../../../api-clients/saloon/clients";
import {ToastService} from "../../../toast/toast.service";
import {AuthService} from "../../../security/auth.service";
import {ActivatedRouteFixService} from "../../../utils/activated-route-fix.service";
import {ApplicationIdService} from "../../../application-id/application-id.service";
import {ClientsControllerProvider, MasterClientsController} from "../masterClientsController";
import {SubscriptionsBag} from "../../../utils/subscriptions-bag";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [
    {
      provide: MasterClientsController,
      useFactory: (provider: ClientsControllerProvider) => provider.provide(),
      deps: [ClientsControllerProvider]
    }
  ]
})
export class ListComponent implements OnInit, OnDestroy {

  public searchControl: FormControl = new FormControl(null);
  public masterId: string = null;
  public saloonId: string = null;
  public clientsVm: ClientVm[] = [];

  private pageSize: number = 20;
  private currentPageIndex: number = 0;
  private clientsEndMeet: boolean = false;

  private refreshRequiredSubj = new Subject<void>();
  private updateClientRequiredSubj = new Subject<string>();

  private sb = new SubscriptionsBag();
  public loading: boolean = true;

  constructor(
    private masterClientsController: MasterClientsController,
    private modalController: ModalController,
    private mastersListClient: MasterListClient,
    private saloonsClient: SaloonsClient,
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private applicationIdService: ApplicationIdService,
    private alertController: AlertController,
    private masterClientController: MasterClientsController) {
  }

  ngOnInit() {
    this.masterId = this.masterClientsController.masterId;

    this.sb.sub = combineLatest([
      this.searchControl.valueChanges.pipe(
        startWith(this.searchControl.value)
      ),
      this.refreshRequiredSubj.pipe(startWith(null))
    ]).pipe(
      tap(() => {
        this.currentPageIndex = 0;
        this.clientsEndMeet = false;
      }),
      switchMap(([search]) => this.loadClientsData(search, this.currentPageIndex))
    ).subscribe((res) => {
      this.clientsVm = res;
    });

    this.sb.sub = this.masterClientController.clientsUpdated$.subscribe(async () => {
      await this.mastersListClient.getClients(
        this.masterId,
        this.searchControl.value,
        [],
        0,
        (this.currentPageIndex + 1) * this.pageSize,
        false
      ).toPromise().then(client => {
        let clients: ClientVm[] = [];
        for(let item of client.clients) {
          clients.push(ClientVm.ParseFromMaster(item));
        }
        this.clientsVm = clients;
      });
    })

    this.updateClientRequiredSubj
      .pipe(delay(500))
      .subscribe(async (id) => {
        const clientIndex = this.clientsVm.findIndex(c => c.id == id);

        if (clientIndex == -1) {
          return;
        }

        await this.mastersListClient.getClients(
          this.masterId,
          null,
          [id],
          0,
          1,
          true
        ).toPromise().then(client => {
          this.clientsVm[clientIndex] = ClientVm.ParseFromMaster(client.clients[0]);
        });
      });
  }

  public ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

  public async openAddClientForm(): Promise<void> {
    this.router.navigate(['new'], {relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)}).then();
  }

  public async loadClientsData(search: string, pageIndex: number = 0): Promise<ClientVm[]> {
    this.loading = true;

    return await this.mastersListClient.getClients(
      this.masterId,
      search,
      [],
      pageIndex * this.pageSize,
      this.pageSize,
      false
    )
      .toPromise()
      .then(clientRes => {
        return clientRes.clients.map(c => ClientVm.ParseFromMaster(c))
      })
      .finally(() => this.loading = false);
  }

  public async loadClientsNextPage($event: any): Promise<void> {
    if (this.clientsEndMeet) {
      $event?.target?.complete();
      return;
    }

    let clientsRes = await this.loadClientsData(this.getSearchValue(), ++this.currentPageIndex);

    this.clientsEndMeet = clientsRes.length !== this.pageSize;
    this.clientsVm.push(...clientsRes);
    $event?.target?.complete();
  }

  public async onItemClick(client: ClientVm): Promise<void> {
    this.router.navigate([client.id], {relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)}).then();
  }

  public doRefresh($event: any = null): void {
    this.refreshRequiredSubj.next();
    $event?.target?.complete();
  }

  private getSearchValue(search = this.searchControl.value): string {
    if (search === '') {
      search = null;
    }
    if (search !== null) {
      search = search.trim();
    }
    return search;
  }
}
