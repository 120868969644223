import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'lib-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['star-rating.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StarRatingComponent),
    multi: true
  }]
})
export class StarRatingComponent implements ControlValueAccessor {
  @Input()
  set value(val) {
    this._value = val || null;
    this.onChange(this._value);
  }

  @Input()
  public activeColor: string = "#FFD700";

  @Input()
  public fontSize: number = 32;

  @Input()
  public applicationId: "client" | "master" | "saloon";

  get value() {
    return this._value;
  }

  private _value;

  onChange(_: any) {
  }

  writeValue(value: number) {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched() {
  }
}
