<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>Вход</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <form [formGroup]="formGroupCode">
      <div class="cards-list form">
        <div class="card padding">
          <ion-list lines="none" class="classic">
            <ion-item>
              <ion-label position="stacked">Код</ion-label>
              <ion-input #code
                         autofocus="true"
                         inputmode="numeric"
                         formControlName="codeControl">
              </ion-input>
            </ion-item>
          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button [disabled]="processing"
                expand="block"
                (click)="onSubmitCodeClick()">Далее
    </ion-button>
  </div>
</ng-template>
