import {Component, Input, OnInit} from '@angular/core';
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {validatorInstagramLink, validatorInstagramLogin} from "../validation";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'app-instagram-change-popup',
  templateUrl: './instagram-change-popup.component.html',
  styleUrls: ['./instagram-change-popup.component.scss'],
})
export class InstagramChangePopupComponent extends ModalComponentBase implements OnInit {

  @Input()
  public title: string = "Instagram";

  @Input()
  public isAcceptLogin: boolean = false;

  @Input()
  public isAcceptLink: boolean = false;

  public placeholder: string;
  public instagramLink: string;

  public instagramProfileControl: FormControl = new FormControl(null, [
    Validators.required
  ])

  constructor(
    modalController: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
    public _applicationIdService: ApplicationIdService,
  ) {
    super(modalController, router, activeRoute, location);
  }

  ngOnInit() {
    super.init().then();

    if (this.isAcceptLogin != true && this.isAcceptLink != true){
      this.isAcceptLogin = true;
    }

    const loginValidator = validatorInstagramLogin();
    const linkValidator = validatorInstagramLink();

    const comboValidator = (control: AbstractControl): ValidationErrors | null => {
      const errorsLink: ValidationErrors = linkValidator(control);
      const errorsLogin: ValidationErrors = loginValidator(control);

      if (this.isAcceptLogin && this.isAcceptLink) {
        if (errorsLink == null || errorsLogin == null) {
          return null;
        }
      }

      if (this.isAcceptLink) {
        return errorsLink;
      }

      if (this.isAcceptLogin) {
        return errorsLogin;
      }

      return null;
    }

    this.instagramProfileControl.setValidators(comboValidator);

    if (this.isAcceptLogin && this.isAcceptLink) {
      this.placeholder = "https://instagram.com/name или @name";
    }

    if (this.isAcceptLink) {
      this.placeholder = "https://instagram.com/name";
    }

    if (this.isAcceptLogin) {
      this.placeholder = "Через собачку";
    }
  }

  public async onCloseClick(): Promise<void> {
    await super.dismiss();
  }

  public async onSubmitInstagramClick(): Promise<void> {
    this.instagramLink = this.isAcceptLogin ?
      this.instagramProfileControl.value?.replace('@', 'https://instagram.com/')?.trim() :
      this.instagramProfileControl.value?.trim();
    await super.dismiss(this.instagramLink);
  }
}
