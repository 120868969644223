import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../security/auth.service";
import {BookingWizardController, BookingWizardSettings} from "../../controller";
import {ApplicationIdService} from "../../../application-id/application-id.service";
import {NavigationService} from "../../../utils/navigation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivatedRouteFixService} from "../../../utils/activated-route-fix.service";

@Component({
  selector: 'lib-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {

  public typeSaloonUser: string;

  public constructor(
    public wizardSettings: BookingWizardSettings,
    public wizardController: BookingWizardController,
    public applicationIdService: ApplicationIdService,
    private _authClient: AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _activatedRouteFix: ActivatedRouteFixService,
    private _navigationService: NavigationService,
  ) {
  }

  public async ngOnInit(): Promise<void> {

  }

  async ionViewWillEnter(){
    if (!this.wizardController.timeSlotData) {
      this.wizardController.dispose();
      await this._navigationService.goBack();
      return;
    }

    if (this._authClient.parsedToken.isSaloonOwner) {
      this.typeSaloonUser = 'owner';
      return;
    }

    if (this._authClient.parsedToken.isSaloonAdmin) {
      this.typeSaloonUser = 'admin';
      return;
    }
  }
}
