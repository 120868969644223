<div class="card padding" *ngIf="feedbacks !== null">
  <h2>
    Ваши записи
    <a [routerLink]="['/bookings']">Все</a>
  </h2>

  <lib-card-spinner *ngIf="processing == true && feedbacks?.length == 0"></lib-card-spinner>

  <p *ngIf="processing == false && feedbacks?.length == 0" class="ion-text-center">
    Пусто
  </p>

  <ion-list *ngIf="feedbacks?.length > 0;">
    <app-booking-with-feedback
      *ngFor="let item of feedbacks; trackBy:trackById"
      [bwf]="item"
      [mode]="this.mode">
    </app-booking-with-feedback>
  </ion-list>
</div>
