import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CreatePromoReq, MasterListClient, PromoRes, UpdatePromoReq} from "../../../api-clients/master-list/clients";
import {PriceListItem, PriceListVm} from "../../../price-list/price-list-vm";
import {ModalController} from "@ionic/angular";
import {AuthService} from "../../../security/auth.service";
import {SpecialitiesDirectoryService} from "../../../directories/specialities-directory.service";
import {ToastService} from "../../../toast/toast.service";
import {ChoosePriceListItemPage} from "../../../price-list/choose-price-list-item/choose-price-list-item.page";
import {NavigationService} from "../../../utils/navigation.service";
import {finalize, take} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PromoTypeEnum} from "../../../utils/promo-type.enum";

@Component({
  selector: 'lib-edit-promo',
  templateUrl: './edit-promo.component.html',
  styleUrls: ['./edit-promo.component.scss'],
})
export class EditPromoComponent implements OnInit {
  public processing: boolean = false;
  public promo: PromoRes;

  public priceListVm: PriceListVm;
  public priceListVmSelected: PriceListVm;
  public priceListItemIdsSelected: string[] = [];

  public backUrl: string;
  public formGroup: FormGroup;

  constructor(
    private _masterListClient: MasterListClient,
    private _activatedRoute: ActivatedRoute,
    private _toastService: ToastService,
    private _router: Router,
    private _specialitiesDirectory: SpecialitiesDirectoryService,
    private _authService: AuthService,
    private _modalController: ModalController,
    private _navigationService: NavigationService,
    private _formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.backUrl = this._router.createUrlTree(['../'], { relativeTo: this._activatedRoute }).toString();

    this.processing = true;
    const masterId = this._authService.parsedToken?.userId;
    Promise.all([
      this._masterListClient.getMasterPromo(this._activatedRoute.snapshot.paramMap.get('id')).toPromise(),
      this._masterListClient.getMasterById(masterId).toPromise(),
      this._specialitiesDirectory.getSpecialities([masterId], true).pipe(take(1)).toPromise(),
    ]).then(([promo,master, specialities]) => {
      this.promo = promo;
      this.initForm(promo.type.type);
      this.formGroup.patchValue(promo)
      this.priceListVm = PriceListVm.Parse(master, specialities, null, false);

      this.priceListItemIdsSelected = promo.priceListItems.map(i => i.id);
      this.updateSelectedVm().then();

      this.processing = false;
    });
  }

  public initForm(promoType: PromoTypeEnum) {
    this.formGroup = this._formBuilder.group({
      startTimeOffset: [null, [Validators.required]],
      endTimeOffset: [null, [Validators.required]],
      value: [null, [Validators.required]],
      isPercentValue: [false, [Validators.required]],
      promoType: promoType
    });

    if (promoType == PromoTypeEnum.bogof) {
      this.formGroup.addControl('bookingsCount',
        new FormControl(2, [Validators.required, Validators.min(2), Validators.max(10)]));
    }

    if (promoType == PromoTypeEnum.hotWindow) {
      this.formGroup.addControl('bufferDays',
        new FormControl(1, [Validators.required, Validators.min(1)]));
    }

    this.formGroup.controls.isPercentValue.valueChanges.subscribe(value => {
      if (value == true) {
        this.formGroup.controls.value.setValidators([Validators.min(1), Validators.max(100)]);
        this.formGroup.controls.value.updateValueAndValidity();
        this.formGroup.controls.value.markAsTouched();
      } else {
        this.formGroup.controls.value.clearValidators();
        this.formGroup.controls.value.updateValueAndValidity();
      }
    });
  }

  public async onSubmitClick() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.processing = false;
      return;
    }

    this.processing = true;

    let req: UpdatePromoReq;
    req = this.formGroup.getRawValue() as CreatePromoReq;
    req.id = this.promo.id;
    req.priceListItemIds = this.priceListItemIdsSelected;

    this._masterListClient.updatePromo(req)
      .pipe(finalize(() => this.processing = false))
      .subscribe(() => {
        this._toastService.success("Акция изменена");
        this._navigationService.goBack();
      }, () => {
        this._toastService.error("Произошла ошибка");
      });
  }

  public async onChooseItemClick() {
    const modal = await this._modalController.create({
      component: ChoosePriceListItemPage,
      componentProps: {
        priceList: this.priceListVm,
      },
    });

    modal.onDidDismiss().then(async (modal) => {
      if (this.priceListItemIdsSelected.some(i => i == modal.data.id)){
        return;
      }

      this.priceListItemIdsSelected.push(modal.data.id);
      await this.updateSelectedVm();

      await this._toastService.success("Услуга добавлена");
    });

    await modal.present();
  }

  public async onUnchooseItemClick(item: PriceListItem) {
    this.priceListItemIdsSelected = this.priceListItemIdsSelected.filter(i => i != item.id);
    await this.updateSelectedVm();
  }

  public async updateSelectedVm(){
    let priceListVm = this.priceListVm.clone();

    for (let spec of priceListVm.specialities){
      for (let group of spec.groups){
        group.items = group.items.filter(i => this.priceListItemIdsSelected.some(p => p == i.id));
      }
      spec.groups = spec.groups.filter(g => g.items.length != 0);
    }

    this.priceListVmSelected = priceListVm;
  }
}
