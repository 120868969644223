import {Component, OnInit} from '@angular/core';
import {AddressController} from "../addressController";
import {filter} from "rxjs";
import {CreateMasterAddressReq, MasterListClient} from "../../api-clients/master-list/clients";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivatedRouteFixService} from "../../utils/activated-route-fix.service";
import {SaloonsClient} from "../../api-clients/saloon/clients";
import {ToastService} from "../../toast/toast.service";

@Component({
  selector: 'lib-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
})
export class NewComponent implements OnInit {
  constructor(
    private _addressController: AddressController,
    private _masterListClient: MasterListClient,
    private _saloonsClient: SaloonsClient,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _activatedRouteFix: ActivatedRouteFixService,
    private _toastService: ToastService,
  ) {
  }

  private async goToStep2(): Promise<void> {
    await this._router.navigate(
      ['./step2'],
      {
        relativeTo: this._activatedRouteFix.getActivatedRoute(this._activatedRoute),
        replaceUrl: true
      }
    );
  }

  private async goToStep3(): Promise<void> {
    await this._router.navigate(
      ['./step3'],
      {
        relativeTo: this._activatedRouteFix.getActivatedRoute(this._activatedRoute),
        replaceUrl: true
      }
    );
  }

  private async goToList(): Promise<void> {
    await this._router.navigate(
      ['../'],
      {
        relativeTo: this._activatedRouteFix.getActivatedRoute(this._activatedRoute),
        replaceUrl: true
      }
    );
  }

  ngOnInit() {

    this._addressController.addressData.addressLineData$
      .pipe(filter(x => !!x))
      .subscribe(async data => {
        await this.goToStep2();
      });

    this._addressController.addressData.addressLocationData$
      .pipe(filter(x => !!x))
      .subscribe(async data => {
        await this.goToStep3();
      });

    this._addressController.addressData.addressHowToGetData$
      .pipe(filter(x => !!x))
      .subscribe(async data => {
        const address = new CreateMasterAddressReq();

        address.masterId = this._addressController.masterId;
        address.addressLine1 = this._addressController.addressData.addressLineData.addressLine1.trim();
        address.addressLine2 = this._addressController.addressData.addressLineData.addressLine2?.trim();
        address.addressLatitude = this._addressController.addressData.addressLocationData.addressLocation.lat();
        address.addressLongitude = this._addressController.addressData.addressLocationData.addressLocation.lng();
        address.howToGetText = data.howToGet;

        await this._addressController.addAddress(address, data.imagesAdded);

        await this.goToList();
      });
  }

  ionViewDidLeave() {
    this._addressController.clear();
  }
}
