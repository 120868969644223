import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {FormControl} from "@angular/forms";
import {BehaviorSubject, combineLatest, merge, Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {RegionsDirectoryService} from "../regions-directory.service";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {RegionRes} from "../../api-clients/geo/clients";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'app-choose-region',
  templateUrl: './choose-region.page.html',
  styleUrls: ['./choose-region.page.scss'],
})
export class ChooseRegionPage extends ModalComponentBase implements OnInit {

  public searchControl: FormControl = new FormControl(null);

  public regions$: Observable<RegionRes[]> = null;

  @Input()
  public set cityId(value: string) {
    this.cityIdSubj.next(value);
  }

  @Output()
  public citySelected: EventEmitter<string>;


  private cityIdSubj: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    modalCtrl: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
    private regionsService: RegionsDirectoryService,
    public applicationIdService: ApplicationIdService,
  ) {
    super(modalCtrl, router, activeRoute, location);
  }

  ngOnInit() {
    super.init().then();

    const regions$ = this.cityIdSubj.pipe(
      switchMap(id => this.regionsService.getRegions(id))
    );

    this.regions$ = combineLatest([
      regions$,
      merge(of(null), this.searchControl.valueChanges.pipe()),
    ]).pipe(map(([regions, searchValue]) => {
      const search = searchValue ? searchValue.toLowerCase() : searchValue;
      return regions.filter(c => !search || c.name.toLowerCase().includes(search));
    }));
  }

  public async onCloseClick(): Promise<void> {
    await this.dismiss();
  }

  public async onRegionClick(region: RegionRes): Promise<void> {
    await this.dismiss(region.id);
  }
}
