<ion-header>
  <ion-toolbar>
    <ion-title>
      <ion-searchbar mode="ios" [formControl]="searchControl" placeholder="Район"></ion-searchbar>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content forceOverscroll="true">

  <ng-container *ngIf="applicationIdService.applicationId == 'client'">

    <div class="content-section">

      <div class="card padding">

        <ion-list lines="full">
          <ion-item *ngFor="let region of regions$ | async" (click)="onRegionClick(region)" [button]="true" [detail]="false">
            {{region.name}}
          </ion-item>
        </ion-list>

      </div>

    </div>
  </ng-container>

</ion-content>
