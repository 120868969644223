import {Component} from '@angular/core';
import {MomentPipe} from "../../date-processing/moment.pipe";
import {ModalController} from "@ionic/angular";
import {DateTimeModalComponent} from "./date-time-modal/date-time-modal.component";
import {dateToString, minutesToDateString, removeTimeZoneOffset} from "../../date-processing/functions";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'lib-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent {
  constructor(private _momentPipe: MomentPipe,
              private _modalController: ModalController) {
  }

  async openDatePickerForControl(control: AbstractControl, minDate?: string): Promise<void> {
    const modal = await this._modalController.create({
      component: DateTimeModalComponent,
      componentProps: {
        presentation: "date",
        value: (new Date(control?.value)).toISOString() || (new Date()).toISOString(),
        minDate: minDate
      },
      cssClass: "date-picker",
      backdropDismiss: false,
      keyboardClose: false,
    })

    await modal.present();

    const data = (await modal.onDidDismiss()).data;

    if (data) {
      control.setValue(data);
    }
  }

  async openTimePickerForControl(control: AbstractControl, minuteValues?: number[], hourValues?: number[]): Promise<void> {
    const modal = await this._modalController.create({
      component: DateTimeModalComponent,
      componentProps: {
        presentation: "time",
        value: control.value || minutesToDateString(0),
        minuteValues: minuteValues,
        hourValues: hourValues,
      },
      cssClass: "time-picker",
      backdropDismiss: false,
      keyboardClose: false,
    })

    await modal.present();

    const data = (await modal.onDidDismiss()).data;

    if (data) {
      control.setValue(data);
    }
  }
}
