<ion-header>
  <ion-toolbar>
    <ion-title>Начало работы</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content force-overscroll="true">
  <div class="content-section">
    <form *ngIf="formGroupInfo" [formGroup]="formGroupInfo" id="scheduleSetting">
      <div class="cards-list form">
        <div class="card padding">
          <h2>Расписание</h2>
          <ion-list lines="none" class="classic">
            <ion-item [detail]="false" [ngClass]="{'lib-invalid': formGroupInfo.errors?.startTimeExceedsEndTime}">
              <ion-label position="stacked">Начало рабочего дня</ion-label>
              <ion-input formControlName="startTime"
                         (click)="dateTime.openTimePickerForControl(formGroupInfo.controls.startTime)"
                         class="clickable date-picker">
                {{formGroupInfo.controls.startTime.value | moment : 'LT'}}
              </ion-input>
              <ion-buttons slot="end" class="bottom">
                <ion-button (click)="strapiModalComponent.openHelp(9, $event)">
                  <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>

            <ion-item [detail]="false" [ngClass]="{'lib-invalid': formGroupInfo.errors?.startTimeExceedsEndTime}">
              <ion-label position="stacked">Конец рабочего дня</ion-label>
              <ion-input formControlName="endTime"
                         (click)="dateTime.openTimePickerForControl(formGroupInfo.controls.endTime)"
                         class="clickable date-picker">
                {{formGroupInfo.controls.endTime.value | moment : 'LT'}}
              </ion-input>
              <ion-buttons slot="end" class="bottom">
                <ion-button (click)="strapiModalComponent.openHelp(10, $event)">
                  <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>

            <h2>Дополнительно</h2>

            <ion-item [detail]="false">
              <ion-label position="stacked">Шаг расписания</ion-label>
              <ion-input formControlName="timeStepInMinutes"
                         (click)="dateTime.openTimePickerForControl(formGroupInfo.controls.timeStepInMinutes)"
                         class="clickable date-picker">
                {{formGroupInfo.controls.timeStepInMinutes.value | moment : 'LT'}}
              </ion-input>
              <ion-buttons slot="end" class="bottom">
                <ion-button (click)="strapiModalComponent.openHelp(2, $event)" size="small">
                  <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>

            <ion-item [detail]="false">
              <ion-label position="stacked">Буфер записи</ion-label>
              <ion-input formControlName="bookingBufferInMinutes" class="clickable date-picker"
                         (click)="onBookingBufferInMinutesClick()">
                {{formGroupInfo.controls.bookingBufferInMinutes.value}}
              </ion-input>
              <ion-buttons slot="end" class="bottom">
                <ion-button (click)="strapiModalComponent.openHelp(1, $event)">
                  <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>

            <ion-item [detail]="false">
              <ion-label position="stacked">Макс записей в день</ion-label>
              <ion-input formControlName="maxBookingsInDay" class="clickable date-picker"
                         (click)="onMaxBookingsInDayClick()">
                {{formGroupInfo.controls.maxBookingsInDay.value}}
              </ion-input>
              <ion-buttons slot="end" class="bottom">
                <ion-button (click)="strapiModalComponent.openHelp(8, $event)">
                  <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>

            <ion-item>
              <ion-toggle formControlName="connectedBookingsOnly"
                          slot="start"
                          [checked]="formGroupInfo.controls.connectedBookingsOnly.value"></ion-toggle>
              <ion-label>Не разбивать день</ion-label>
              <ion-buttons slot="end">
                <ion-button (click)="strapiModalComponent.openHelp(3, $event)">
                  <ion-icon name="help-circle-outline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block" [disabled]="this.formGroupInfo?.invalid"
                (click)="onOkClick()">Продолжить
    </ion-button>
  </div>
</ng-template>

<lib-strapi-modal #strapiModalComponent></lib-strapi-modal>
<lib-date-time #dateTime></lib-date-time>

