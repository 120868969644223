import {FormControl, FormGroup} from "@angular/forms";

export class DatesValidator {
  static pauseValidator(dateForm: FormGroup): { [key: string]: boolean } {

    const day = new Date(dateForm.value.day);
    const start = new Date(dateForm.value.start);
    const end = new Date(dateForm.value.end);

    if (!day || !start || !end) {
      return {"oneOrMoreValuesEmpty": true};
    }

    const startTime = new Date(day.getFullYear(), day.getMonth(), day.getDate(), start.getHours(), start.getMinutes());
    const endTime = new Date(day.getFullYear(), day.getMonth(), day.getDate(), end.getHours(), end.getMinutes());

    if ((new Date()).valueOf() >= startTime.valueOf()) {
      return {"startTimeIsPast": true};
    }

    if (endTime.valueOf() <= startTime.valueOf()) {
      return {"startTimeExceedsEndTime": true};
    }

    return null;
  }

  // static isInPast(date: FormControl): { [key: string]: boolean } {
  //   if (new Date(date.value).setSeconds(0, 0).valueOf() <= new Date().setSeconds(0, 0).valueOf()) {
  //     return {"dateInPast": true};
  //   }
  //
  //   return null;
  // }

  static scheduleTimeValidator(dateForm: FormGroup): { [key: string]: boolean } {
    const now = new Date();

    const start = new Date(dateForm.value.startTime);
    const end = new Date(dateForm.value.endTime);

    const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), start.getHours(), start.getMinutes());
    const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), end.getHours(), end.getMinutes());

    let error = null;

    if ((startTime.valueOf() >= endTime.valueOf())) {
      return {"startTimeExceedsEndTime": true};
    }
    return null;
  }

  static isZeroLength(control: FormControl): { [key: string]: boolean } {

    const date = new Date(control.value);

    const durationIsZero = !(date.getMinutes() > 0 || date.getHours() > 0);

    if (durationIsZero) {
      return {"zeroLength": true};
    }

    return null;
  }
}
