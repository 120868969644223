<ng-container *ngIf="this.formGroup" [formGroup]="this.formGroup">
  <ion-list lines="none" class="classic">
    <ion-item>
      <ion-label position="stacked">Дата начала</ion-label>
      <ion-input formControlName="startTimeOffset"
                 (click)="dateTime.openDatePickerForControl(this.formGroup.controls.startTimeOffset, this.now)"
                 class="clickable date-picker"
      >{{this.formGroup.controls.startTimeOffset?.value | moment : 'DD.MM.YYYY'}}</ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="stacked">Дата конца</ion-label>
      <ion-input formControlName="endTimeOffset"
                 (click)="dateTime.openDatePickerForControl(this.formGroup.controls.endTimeOffset, this.formGroup.controls?.startTimeOffset?.value ?? this.now)"
                 class="clickable date-picker"
      >{{this.formGroup.controls.endTimeOffset?.value | moment : 'DD.MM.YYYY'}}</ion-input>
    </ion-item>
    <ion-item *ngIf="this.promoType == PromoTypeEnum.bogof">
      <ion-label position="stacked">Количество записей</ion-label>
      <ion-input formControlName="bookingsCount" type="number">
      </ion-input>
    </ion-item>
    <ion-item *ngIf="this.promoType == PromoTypeEnum.hotWindow">
      <ion-label position="stacked">Буфер</ion-label>
      <ion-input formControlName="bufferDays" type="number">
      </ion-input>
    </ion-item>
    <ion-item>
      <ion-row>
        <ion-col size="9">
          <ion-label position="stacked">Размер скидки</ion-label>
          <ion-input formControlName="value" type="number">
          </ion-input>
        </ion-col>
        <ion-col size="3">
          <div style="margin-left: 10px;">
            <ion-select interface="action-sheet" cancel-text="Отмена" formControlName="isPercentValue">
              <ion-select-option [value]="false">BYN</ion-select-option>
              <ion-select-option [value]="true">%</ion-select-option>
            </ion-select>
          </div>
        </ion-col>
      </ion-row>
    </ion-item>
  </ion-list>
</ng-container>
<lib-date-time #dateTime></lib-date-time>
