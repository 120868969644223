import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from '@ionic/angular';
import {CitiesDirectoryService} from "./cities-directory.service";
import {SpecialitiesDirectoryService} from "./specialities-directory.service";
import {ChooseCityPage} from "./choose-city/choose-city.page";
import {ChooseSpecialityPage} from "./choose-speciality/choose-speciality.page";
import {ChooseRegionPage} from "./choose-region/choose-region.page";
import {RegionsDirectoryService} from "./regions-directory.service";
import {ChooseSortingPage} from "./choose-sorting/choose-sorting.page";
import {FiltersModule} from "../filters/filters.module";

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FiltersModule,
  ],
  declarations: [
    ChooseCityPage,
    ChooseSpecialityPage,
    ChooseRegionPage,
    ChooseSortingPage
  ],
  exports: [
  ],
  providers: [
    CitiesDirectoryService,
    RegionsDirectoryService,
    SpecialitiesDirectoryService
  ]
})
export class DirectoriesModule {
}
