<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>Запись</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div class="content-section">

    <div class="cards-list">

      <div class="card padding">
        <lib-card-spinner *ngIf="this.priceListVm == null"></lib-card-spinner>
        <ng-container *ngIf="this.priceListVm != null">
          <ion-list class="colored-secondary">
            <app-price-list [priceList]="this.priceListVmSelected"
                            [showGroups]="false"
                            [showCloseButton]="true"
                            [showInfoButton]="false"
                            [coloredSecondary]="false"
                            (deleteItemClicked)="onUnchooseItemClick($event)"
                            (itemClicked)="onChooseItemClick()">
            </app-price-list>
          </ion-list>

          <ion-buttons class="ion-justify-content-center"
                       [ngStyle]="{'padding-top': (this.serviceTypeIdsSelected.length > 0) ? '20px' : '0'}">
            <ion-button shape="circle" (click)="onChooseItemClick()">
              <ion-icon slot="icon-only" name="add-outline"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ng-container>
      </div>

      <div class="card padding"
           *ngIf="(applicationIdService.applicationId == 'master' || applicationIdService.applicationId == 'saloon') && this.serviceTypeIdsSelected?.length > 0">
        <form [formGroup]="form">
          <ion-list class="classic" lines="none">
            <ion-item button="false" detail="false">
              <ion-label>Нестандартная длительность</ion-label>
              <ion-toggle slot="start" formControlName="customTimeControl"></ion-toggle>
            </ion-item>
            <ion-item button="false" detail="false" *ngIf="form.controls.customTimeControl.value">
              <ion-label position="stacked">Длительность</ion-label>
              <ion-input formControlName="durationTimeControl"
                         class="clickable date-picker"
                         (click)="dateTime.openTimePickerForControl(form.controls.durationTimeControl, null, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])">
                {{form.controls.durationTimeControl.value | moment : 'LT'}}
              </ion-input>
            </ion-item>
            <ion-item button="false" detail="false" *ngIf="form.controls.customTimeControl.value">
              <ion-label position="stacked">Отдых (дополнительно)</ion-label>
              <ion-input formControlName="restTimeControl"
                         class="clickable date-picker"
                         (click)="dateTime.openTimePickerForControl(form.controls.restTimeControl, null, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])">
                {{form.controls.restTimeControl.value | moment : 'LT'}}
              </ion-input>
            </ion-item>
            <ion-item button="false" detail="false" *ngIf="!wc.baseBooking">
              <ion-label>Повторять</ion-label>
              <ion-toggle slot="start" formControlName="customFrequencyControl"></ion-toggle>
            </ion-item>
            <ion-item *ngIf="form.controls.customFrequencyControl.value">
              <ion-select placeholder="Select One" interface="action-sheet" [value]="0" cancelText="Назад"
                          formControlName="frequencyControl" [interfaceOptions]="customActionSheetHeader('Повторять')">
                <ion-select-option [value]=0>Не повторять</ion-select-option>
                <ion-select-option [value]="1">Каждую неделю</ion-select-option>
                <ion-select-option [value]="2">Каждые 2 недели</ion-select-option>
                <ion-select-option [value]="3">Каждые 3 недели</ion-select-option>
                <ion-select-option [value]="4">Каждые 4 недели</ion-select-option>
              </ion-select>
            </ion-item>

          </ion-list>
        </form>
      </div>


      <div class="card padding"
           *ngIf="applicationIdService.applicationId === 'client' && itemCanBeBookedByMasterOnlyExist">

        <h2>Нужна предварительная консультация!</h2>
        <p>
          Cвяжитесь с мастером или запишитесь на консультацию.
        </p>
        <p>
          <a (click)="onGoToInfoClick()">Контакты мастера</a>
        </p>

      </div>

      <div class="hide-mobile">
        <ng-container *ngTemplateOutlet="next_button"></ng-container>
      </div>

    </div>
  </div>

</ion-content>

<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="next_button"></ng-container>
</ion-footer>

<ng-template #next_button>
  <div class="card transparent padding-mobile">
    <ion-button *ngIf="!(applicationIdService.applicationId === 'client' && itemCanBeBookedByMasterOnlyExist)"
                [disabled]="this.serviceTypeIdsSelected.length == 0"
                expand="block"
                (click)="onNextClick()">
      Далее
      <ion-icon slot="end" name="arrow-forward-circle"></ion-icon>
    </ion-button>
  </div>
</ng-template>


<lib-date-time #dateTime></lib-date-time>




