import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  addDays,
  addMonths,
  calcNthDayOfMonthDate,
  calcRussianDayOfWeekDate,
  hasSameDate
} from "../functions";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {DayVm} from "../../utils/DayVm";

export interface IDaysDataProvider {
  getDaysData(from: Date, to: Date): Observable<CalendarDay[]>;
}

export class CalendarDay {
  public date: Date;
  public data: DayVm[];
  public scheduleData: any;
  public style: "empty" | "ready" | "full-1" | "half-1" | "full-2" = "empty";
}

class Month {
  date: Date;
  weeks: Week[] = [];

  sub: Subscription = null;

  public getDays(): Day[] {
    return this.weeks.reduce((prev, curr) => [...prev, ...curr.days], []);
  }

  public dispose(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

class Week {
  public days: Day[] = [];
}

class Day {
  public date: Date;
  public dayData: CalendarDay;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input()
  public date: Date = new Date();

  @Input()
  public set daysDataProvider(value: IDaysDataProvider){
    this.daysDataProviderSubj.next(value);
  }

  @Input()
  public areDaysActivatable: boolean = true;

  @Output()
  public monthActivated: EventEmitter<Date> = new EventEmitter<Date>();

  @Output()
  public dayActivated: EventEmitter<CalendarDay> = new EventEmitter<CalendarDay>();

  @Output()
  public dayClicked: EventEmitter<CalendarDay> = new EventEmitter<CalendarDay>();

  public months: Month[] = [];
  public activatedMonth: Month = null;
  public activatedDay: Day = null;

  private daysDataProviderSubj = new BehaviorSubject<IDaysDataProvider>(null);
  private getMondayDay(date: Date): Date {
    return calcRussianDayOfWeekDate(date, 1);
  }

  private getFirstDayOfMonth(date: Date): Date {
    const firstDayOfMonth = calcNthDayOfMonthDate(date, 1);
    return firstDayOfMonth;
  }

  private createMonth(dateInMonth: Date): Month {
    const firstDayOfMonth = this.getFirstDayOfMonth(dateInMonth);

    this.getFirstDayOfMonth(addMonths(firstDayOfMonth, 1));
    const month = new Month();
    month.date = firstDayOfMonth;

    for (let i = 0; i < 6; i++) {
      const week = new Week();
      const dayInWeek = addDays(firstDayOfMonth, 7 * i);
      const firstDayOfWeek = this.getMondayDay(dayInWeek);
      for (let x = 0; x < 7; x++) {
        const dayOfWeek = addDays(firstDayOfWeek, x);
        const day = new Day();
        day.date = dayOfWeek;
        week.days.push(day);
      }
      month.weeks.push(week);
    }

    return month;
  }

  public constructor(
    public applicationIdService: ApplicationIdService
  ) {
  }


  public ngOnInit(): void {
    this.daysDataProviderSubj.subscribe(value => {
      this.activateMonthByDate(this.date || new Date(), this.date).then();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['date'] &&
      !changes['date'].isFirstChange() &&
      !hasSameDate(this.activatedDay?.date, this.date)
    ) {
      this.activateMonthByDate(this.date, this.date).then();
    }
  }

  public onPrevMonthClick(): void {
    const prevMonthDate = addMonths(this.activatedMonth.date, -1);
    this.activateMonthByDate(prevMonthDate, this.date).then();
  }

  public onNextMonthClick(): void {
    const nextMonthDate = addMonths(this.activatedMonth.date, 1);
    this.activateMonthByDate(nextMonthDate, this.date).then();
  }

  public onDayClick(day: Day): void {
    this.dayClicked.emit(day?.dayData);
    this.activateDay(day);
  }

  public activateDay(day: Day): void {
    if (this.areDaysActivatable) {
      this.activatedDay = day;
      this.dayActivated.emit(day?.dayData);
    }
  }

  public async activateMonthByDate(dateInMonth: Date, dateToActivate: Date = null): Promise<void> {
    this.activatedDay = null;

    const month = this.createMonth(dateInMonth);

    const days = month.getDays();

    const from = days[0].date;
    const to = days[days.length - 1].date;

    if (this.daysDataProviderSubj.value) {
      const daysData$ = this.daysDataProviderSubj.value.getDaysData(from, to);

      month.sub = daysData$.subscribe(daysData => {
        for (let dayOfMonth of month.getDays()) {
          dayOfMonth.dayData = null;
          for (let dayData of daysData) {
            if (hasSameDate(dayOfMonth.date, dayData.date)) {
              dayOfMonth.dayData = dayData;
            }
          }
        }

        const dayToActivate =
          (
            this.activatedDay
              ? month.getDays().find(d => hasSameDate(d.date, this.activatedDay.date) && d.dayData)
              : null
          ) ||
          (
            dateToActivate
              ? month.getDays().find(d => hasSameDate(d.date, dateToActivate) && d.dayData)
              : null
          ) ||
          month.getDays().find(d => d.dayData && d.dayData.style == 'full-1') || // Пробуем выделить сначала полноценный день
          month.getDays().find(d => d.dayData) ||
          month?.getDays()[0];

        this.activateDay(dayToActivate);

      });

    }

    this.activatedMonth?.dispose();
    this.activatedMonth = month;
  }
}


