import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  AuthService,
  CitiesDirectoryService,
  FeedbackClient, FILES_BASE_URL, FileUrlPipe,
  MasterListClient, PriceListVm, RefreshService, SaloonClient,
  SpecialitiesDirectoryService, SpecialitiesPipe, SubscriptionsBag, ThumbnailUrlPipe, ToastService, TrackingService
} from "bc-common";
import {ActionSheetController, AlertController, ModalController} from "@ionic/angular";
import {Meta, Title} from "@angular/platform-browser";
import {distinctUntilChanged, map, startWith, switchMap, tap} from "rxjs/operators";
import {combineLatest, firstValueFrom, Observable, of} from "rxjs";

class Vm {
  public master: MasterListClient.MasterRes;
  public contacts: MasterListClient.MasterContactsRes = null;
  public saloonsContacts: any[] = [];
  public showMasterContacts: boolean;
}

@Component({
  selector: 'app-master-info',
  templateUrl: './master-info.component.html',
  styleUrls: ['./master-info.component.scss'],
})
export class MasterInfoComponent implements OnInit, OnDestroy {

  public vm = new Vm();
  public showMasterContacts: boolean = true;

  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private masterListClient: MasterListClient.MasterListClient,
    private feedbackClient: FeedbackClient.FeedbackClient,
    private citiesDirectoryService: CitiesDirectoryService,
    private specialitiesDirectoryService: SpecialitiesDirectoryService,
    public authService: AuthService,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private toastService: ToastService,
    private fileUrlPipe: FileUrlPipe,
    private thumbnailPipe: ThumbnailUrlPipe,
    private specialitiesPipe: SpecialitiesPipe,
    private meta: Meta,
    private titleService: Title,
    private trackingService: TrackingService,
    private saloonClient: SaloonClient.SaloonsClient,
    private refreshService: RefreshService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(FILES_BASE_URL) private filesBaseUrl: string
  ) {
  }

  public ngOnInit(): void {

    combineLatest([
      this.refreshService.refreshAndResume$.pipe(startWith(null)),
      this.activatedRoute.paramMap,
    ]).pipe(
      map(([x, paramMap]) => paramMap),
      map((paramMap): [string, number] => [paramMap.get('id'), parseInt(paramMap.get('refCode'))]),
      tap(([masterId, refCode]) => refCode ? this.trackingService.setMasterRefCode(refCode) : null),
      switchMap(([masterId, refCode]) =>
        firstValueFrom(this.masterListClient.getMasters(
          [masterId || null].filter(x => x != null),
          [refCode || null].filter(x => x != null)
        ).pipe(map(res => res[0])))
      ),
      switchMap((masterRes) => combineLatest([
        of(masterRes),
        firstValueFrom(
          this.authService.isAuthenticated
            ? this.masterListClient.getMasterContacts(masterRes.id)
            : of(null)
        ),
        this.authService.isAuthenticated
          ? Promise.all(
            masterRes.addresses
              .filter(a => !!a.addressSaloonId)
              .map(a => this.saloonClient.getSaloonContacts(a.addressSaloonId, masterRes.id).toPromise().then((c) => ({
                saloonAddress: a,
                contacts: c
              })))
          )
          : Promise.resolve([])
      ])),
      tap(([masterRes, contacts, saloonContacts]) => {
        const vm = new Vm();
        this.titleService.setTitle(masterRes.fullName);

        vm.master = masterRes;
        vm.contacts = contacts;
        vm.saloonsContacts = saloonContacts;
        vm.showMasterContacts = saloonContacts.some(c => c.contacts.showMasterContacts == true);

        this.vm = vm;
      })

    ).subscribe();
  }

  ngOnDestroy(): void {
    this.sb.unsubscribeAll();
  }

  public ifNullOrEmptyOrWhiteSpace(str: string): boolean {
    return str == null || str.trim().length == 0;
  }

  public async onContactsSignIn(): Promise<void> {
    let url = this.router.url;
    await this.router.navigate(['sign-in'], {queryParams: {backUrl: url}}).then();
  }


}
