import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({providedIn: 'root'})
@Pipe({
  name: 'mediumUrl'
})
export class MediumUrlPipe implements PipeTransform {

  transform(url: string): unknown {
    if (!url) {
      return null;
    }

    return url + "?size=medium";
  }

}
