import {Injectable, Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'points'
})
@Injectable({providedIn: 'root'})
export class PointsPipe implements PipeTransform {
  transform(point: number): string {
    if (point == null){
      return ''
    }

    if (point == 1){
      return point + ' балл'
    }

    if (point >= 2 && point <= 4 ){
      return point + ' балла'
    }

    return point + ' баллов'
  }
}
