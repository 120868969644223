import {Component, OnInit} from '@angular/core';
import {Platform} from "@ionic/angular";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'lib-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
  host: {'class': 'ion-page'},
})
export class Step2Component implements OnInit {

  constructor(
    public platform: Platform,
    public applicationIdService: ApplicationIdService
  ) {
  }

  ngOnInit() {
  }

}
