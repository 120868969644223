<ng-container *ngIf="presentation == 'time'">
  <ion-datetime #timePicker
                style="user-select: none; cursor: pointer;"
                locale="ru"
                presentation="time"
                first-day-of-week="1"
                [minuteValues]="minuteValues ?? [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
                [hourValues]="hourValues ?? undefined"
                [value]="value">
    <ion-buttons slot="buttons">
      <ion-button color="danger" (click)="onCancelClick()">Отмена</ion-button>
      <ion-button color="primary" (click)="onSubmitClick()">Ок</ion-button>
    </ion-buttons>
  </ion-datetime>
</ng-container>

<ng-container *ngIf="presentation == 'date'">
  <ion-datetime #datePicker
                locale="ru"
                presentation="date"
                first-day-of-week="1"
                [value]="value"
                [min]="minDate"
                style="height: 100%">
    <ion-buttons slot="buttons">
      <ion-button color="danger" (click)="onCancelClick()">Отмена</ion-button>
      <ion-button color="primary" (click)="onSubmitClick()">Ок</ion-button>
    </ion-buttons>
  </ion-datetime>
</ng-container>
