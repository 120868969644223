import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SsrHelper} from "./ssr-helper.service";

@NgModule({
  imports: [
    CommonModule,
  ]
})
export class SsrModule {
  static forRoot(): ModuleWithProviders<SsrModule> {
    return {
      ngModule: SsrModule,
      providers: [
        SsrHelper,
      ]
    };
  }
}
