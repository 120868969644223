import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {City} from "../filters/filters-model";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CityStore {
  public constructor(
    @Inject(PLATFORM_ID) private platformId: string
  ) {
  }

  public get(): City {
    if (!isPlatformBrowser(this.platformId)) {
      return null;
    }

    let city = localStorage?.getItem("city");
    return city ? JSON.parse(city) : null;
  }

  public remember(city: City) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    localStorage?.setItem("city", JSON.stringify(city));
  }
}
