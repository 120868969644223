import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Step1Component} from "./step1/step1.component";
import {Step2Component} from "./step2/step2.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'step1',
    pathMatch: 'prefix',
  },
  {
    path: 'step1',
    component: Step1Component,
    pathMatch: 'prefix',
  },
  {
    path: 'step2',
    component: Step2Component,
    pathMatch: 'prefix',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InstallRoutingModule { }
