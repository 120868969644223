import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-active-masters-banner',
  templateUrl: './active-masters.component.html',
  styleUrls: ['./active-masters.component.scss'],
})
export class ActiveMastersComponent implements OnInit {
  constructor() { }

  ngOnInit() {}
}
