import {Component, Input, OnInit} from '@angular/core';
import {AnimationBuilder, Config} from "@ionic/angular";
import {ActivatedRoute, Router, UrlTree} from "@angular/router";
import {NavigationService} from "../../utils/navigation.service";
import {ActivatedRouteFixService} from "../../utils/activated-route-fix.service";

// Списано с оригинального бэк баттона, с некоторыми правками

@Component({
  selector: 'lib-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Input()
  defaultHref: string | any[] | UrlTree | undefined | null;

  @Input()
  disableClick: boolean = false;

  public constructor(
    private config: Config,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
  ) {
  }

  public ngOnInit(): void {
  }

  public onClick(ev: Event = null): void {

    if (this.disableClick) {
      return;
    }

    const defaultHref = this.defaultHref || this.config.get('backButtonDefaultHref');

    this.navigationService.goBack(
      true,
      defaultHref,
      defaultHref?.length > 0 && defaultHref[0] == '/'
        ? null
        : this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
    ).then();
  }
}
