<div class="banner b2">
  <img src="assets/banners/banner2.png"/>
  <h2>
    Установите Бьюти&nbsp;Круг на&nbsp;телефон!
  </h2>
  <p>
    Это удобно и проще&nbsp;некуда!
  </p>
  <ion-button fill="clear" routerLink="/install">Установить</ion-button>
</div>
