enum Currency {
  BYN = 1,
}

export class CurrencyArrayBuilder {

  // public buildCurrencyArray(): Object[] {
  //   return Object.keys(Currency)
  //     .map(key => ({id: Currency[key], name: key}))
  // }

  public buildCurrencyArray(): Object[] {
    return [{id: Currency.BYN, name: "BYN"}]
  }
}

