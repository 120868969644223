import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AddressHowToGetData} from "../../addressController";

@Component({
  selector: 'lib-address-form-step3',
  templateUrl: './address-form-step3.component.html',
  styleUrls: ['./address-form-step3.component.scss'],
})
export class AddressFormStep3Component implements OnInit {
  @Input()
  public addressHowToGetText: string;
  @Input()
  public addressHowToGetImages: string[];
  @Output()
  public buttonTitle: string = "Продолжить";
  @Output()
  public submitted: boolean = false;
  @Output()
  public onFormSubmit: EventEmitter<AddressHowToGetData> = new EventEmitter<AddressHowToGetData>();

  public howToGetForm: FormGroup;

  public imagesAdded: string[] = [];
  public imagesDeleted: string[] = [];

  constructor() {
  }

  ngOnInit() {
    this.howToGetForm = new FormGroup({
      howToGet: new FormControl(this.addressHowToGetText),
    });
  }

  public onImageAddClicked(images: string[]) {
    this.imagesAdded.push(...images);
  }

  public onImageDeleteClicked(image: string) {
    if (this.imagesAdded.some(i => i == image)){
      this.imagesAdded = this.imagesAdded.filter(i => i != image);
    }
    else {
      this.imagesDeleted.push(image);
    }
  }

  public async onNextClick() {
    this.submitted = true;

    this.onFormSubmit.emit({
      howToGet: this.howToGetForm.controls.howToGet.value,
      imagesAdded: this.imagesAdded,
      imagesDeleted: this.imagesDeleted,
    } as AddressHowToGetData);
  }
}
