import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {MenuComponent} from "./menu/menu.component";
import {
  BcCommonModule,
  ApiClientsModule,
  DirectoriesModule,
  AvatarModule,
  AuthenticationClient,
  AuthInterceptorService,
  RetryInterceptorService,
  BookingClient,
  MasterListClient,
  ClientsClient,
  FeedbackClient,
  NotificationClient,
  FilesClient,
  AuthService,
  ImagesModule,
  FILES_BASE_URL,
  SecurityModule,
  SsrModule,
  ToastModule,
  NavigatorModule,
  SaloonClient,
  ApplicationIdService,
  GeoClient,
  checkForUpdates,
  STRAPI_URL,
  TrackingClient,
  RefreshService,
  GlobalErrorHandler,
  MetaTagsService,
} from "bc-common";
import {AlertController, IonicModule, IonicRouteStrategy} from "@ionic/angular";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MastersModule} from "./masters/masters.module";
import {SaloonsModule} from "./saloons/saloons.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HomePageModule} from "./home/home.module";
import {RouteReuseStrategy} from "@angular/router";
import {environment} from "../environments/environment";
import {ServiceWorkerModule, SwUpdate} from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
  ],
  imports: [
    BcCommonModule.forRoot(),
    IonicModule.forRoot({
      backButtonDefaultHref: '/',
      innerHTMLTemplatesEnabled: true
    }),
    BrowserModule.withServerTransition({appId: 'app'}),
    AppRoutingModule,
    MastersModule,
    SaloonsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HomePageModule,
    ApiClientsModule,
    DirectoriesModule,
    ImagesModule,
    SecurityModule.forRoot(),
    SsrModule.forRoot(),
    ToastModule.forRoot(),
    NavigatorModule.forRoot(),
    AvatarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: RetryInterceptorService, multi: true},

    {provide: ErrorHandler, useClass: GlobalErrorHandler},

    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: ApplicationIdService, useValue: new ApplicationIdService("client")},

    {provide: MasterListClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: ClientsClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: AuthenticationClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: BookingClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: FeedbackClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: NotificationClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: SaloonClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: GeoClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: TrackingClient.API_BASE_URL, useValue: environment.apiUrl},
    {provide: FilesClient.API_BASE_URL, useValue: environment.apiUrl},

    {provide: FILES_BASE_URL, useValue: environment.filesBaseUrl},
    {provide: STRAPI_URL, useValue: environment.strapiUrl},

    {
      provide: APP_INITIALIZER,
      useFactory: checkForUpdates,
      deps: [SwUpdate, AlertController],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (metaTagsService: MetaTagsService): Function => async () => {
        metaTagsService.init({
          title: "Бьюти Круг",
          url: "https://bk.style",
          desc: "Онлайн запись и каталог бьюти мастеров",
          imageUrl: "https://bk.style/assets/logo-v2.png",
        });
      },
      deps: [MetaTagsService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService, refreshService: RefreshService): Function => {
        return async () => {
          await authService.init();
          refreshService.init();
        };
      },
      deps: [AuthService, RefreshService],
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
