import {Component, Input, OnInit} from '@angular/core';
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'lib-price-list-item-info',
  templateUrl: './price-list-item-info.component.html',
  styleUrls: ['./price-list-item-info.component.scss'],
})
export class PriceListItemInfoComponent extends ModalComponentBase implements OnInit {

  @Input()
  public title: string;

  @Input()
  public text: string;

  constructor(
    modalController: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location
    ) {
    super(modalController, router, activeRoute, location);
  }

  ngOnInit() {
    super.init().then();
  }

  public async onCloseClick(): Promise<void> {
    await super.dismiss();
  }

}
