import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MastersListComponent} from "./masters/masters-list/masters-list.component";
import {MasterProfileComponent} from "./masters/master-profile/master-profile.component";
import {TokenGuard} from "bc-common";
import {SaloonProfileComponent} from "./saloons/saloon-profile/saloon-profile.component";
import {MasterInfoComponent} from "./masters/master-info/master-info.component";
import {MasterHistoryComponent} from "./masters/master-history/master-history.component";
import {SaloonMastersComponent} from "./saloons/saloon-masters/saloon-masters.component";
import {SaloonInfoComponent} from "./saloons/saloon-info/saloon-info.component";
import {SaloonHistoryComponent} from "./saloons/saloon-history/saloon-history.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'b/:id',
    redirectTo: 'bookings/:id',
    pathMatch: 'full',
  },

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    pathMatch: 'prefix',
  },

  {
    path: 'catalog',
    component: MastersListComponent,
    pathMatch: 'full',
  },

  {
    path: 'masters/:id',
    component: MasterProfileComponent,
    pathMatch: 'full',
  },
  {
    path: 'masters/:id/info',
    component: MasterInfoComponent,
    pathMatch: 'full',
  },
  {
    path: 'masters/:id/history',
    component: MasterHistoryComponent,
    pathMatch: 'full',
  },
  {
    path: 'm/:refCode',
    component: MasterProfileComponent,
    pathMatch: 'full',
  },
  {
    path: 'saloons/:id',
    component: SaloonProfileComponent,
    pathMatch: 'full',
  },
  {
    path: 'saloons/:id/info',
    component: SaloonInfoComponent,
    pathMatch: 'full',
  },
  {
    path: 'saloons/:id/history',
    component: SaloonHistoryComponent,
    pathMatch: 'full',
  },
  {
    path: 'saloons/:id/masters',
    component: SaloonMastersComponent,
    pathMatch: 'full',
  },
  {
    path: 's/:refCode',
    component: SaloonProfileComponent,
    pathMatch: 'full',
  },
  {
    path: 's/:refCode/masters',
    component: SaloonMastersComponent,
    pathMatch: 'full',
  },

  {
    path: 'install',
    loadChildren: () => import('./install/install.module').then(m => m.InstallModule),
    pathMatch: 'prefix',
  },
  {
    path: 'promo',
    loadChildren: () => import('./promos/promos.module').then(m => m.PromosModule),
    pathMatch: 'prefix',
  },
  {
    path: 'bookings',
    loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsPageModule),
    pathMatch: 'prefix',
  },
  {
    path: 'bookings/:bookingIdToOpen',
    loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsPageModule),
    canActivate: [TokenGuard()],
    pathMatch: 'prefix',
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [TokenGuard()],
    pathMatch: 'prefix',
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
    pathMatch: 'prefix',
  },

  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
    pathMatch: 'prefix',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
