<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" class="menu">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>

    <ion-title>
      <span>Прайс-лист</span>
      <span *ngIf="applicationIdService.applicationId == 'saloon'">: {{ master?.fullName }}</span>
    </ion-title>

    <ion-buttons slot="end">

      <ion-button *ngIf="!priceListComponent?.reorderEnabled"
                  (click)="onSettingClick()">
        <ion-icon name="settings-outline" slot="icon-only"></ion-icon>
      </ion-button>

      <ion-button *ngIf="priceListComponent?.reorderEnabled === true"
                  (click)="priceListComponent.finishReordering()">
        Готово
      </ion-button>

    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div *ngIf="priceListVm" class="content-section">
    <lib-card-spinner *ngIf="this.loading"></lib-card-spinner>
    <ng-container *ngIf="this.loading == false">
      <div class="cards-list">

        <div class="card padding">
          <h2>Специальность</h2>
          <lib-specs
            [master]="master"
            [priceList]="priceListVm"
            (changed)="ngOnInit()">
          </lib-specs>
        </div>

        <ng-template #empty>
          <lib-card-empty></lib-card-empty>
        </ng-template>

        <div class="card padding" *ngIf="priceListVm?.getAllItems()?.length != 0; else empty">
          <app-price-list-edit #priceListComponent
                               [priceList]="priceListVm">
          </app-price-list-edit>
        </div>

      </div>
    </ng-container>
  </div>
</ion-content>
