import {Pipe, PipeTransform} from '@angular/core';
import {MasterRes} from "../api-clients/master-list/clients";

@Pipe({
  name: 'specialitiesPipe',
})

export class SpecialitiesPipe implements PipeTransform {

  constructor(
  ) {
  }

  transform(master: MasterRes): string {
    var specialities: any[] = master.specialities

    for(let speciality of specialities) {
      speciality.countServies = 0;
      for(let item of master.priceList.priceListItems) {
        if(speciality.id === item.serviceType.specialityId) {
          speciality.countServies += 1;
        }
      }
    }

    specialities = specialities.sort((a, b) => b.countServies - a.countServies);
    let text = '';

    for(let i = 0; i < specialities.length; i++) {
      text += specialities[i].name.toLowerCase();
      if(i !== specialities.length - 1) {
        text += ', ';
      }
    }

    return text;
  }
}

