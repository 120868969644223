import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {combineLatest, firstValueFrom, of} from "rxjs";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {AuthService, RefreshService, SaloonClient, TrackingService} from "bc-common";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-saloon-info',
  templateUrl: './saloon-info.component.html',
  styleUrls: ['./saloon-info.component.scss'],
})
export class SaloonInfoComponent implements OnInit {

  public saloon: SaloonClient.Saloon;
  public saloonsContacts: SaloonClient.SaloonContactsRes;

  constructor(
    private router: Router,
    private saloonClient: SaloonClient.SaloonsClient,
    public authService: AuthService,
    private refreshService: RefreshService,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.refreshService.refreshAndResume$.pipe(startWith(null)),
      this.activatedRoute.paramMap,
    ]).pipe(
      map(([x, paramMap]) => paramMap),
      map((paramMap): [string, number] => [paramMap.get('id'), parseInt(paramMap.get('refCode'))]),
      tap(([saloonId, refCode]) => refCode ? this.trackingService.setMasterRefCode(refCode) : null),
      switchMap(([saloonId, refCode]) => saloonId ?
        firstValueFrom(this.saloonClient.getSaloonById(saloonId).pipe(map(res => res))) :
        firstValueFrom(this.saloonClient.getSaloonByReferalCode(refCode).pipe(map(res => res)))
      ),
      switchMap((saloonRes) => combineLatest([
        of(saloonRes.saloon),
        firstValueFrom(
          this.authService.isAuthenticated
            ? this.saloonClient.getSaloonContacts(saloonRes.saloon.id, null)
            : of(null)
        )
      ])),
      tap(([saloonRes, contacts]) => {
        this.titleService.setTitle(saloonRes.name);

        this.saloon = saloonRes;
        this.saloonsContacts = contacts;
      })
    ).subscribe();
  }

  public ifNullOrEmptyOrWhiteSpace(str: string): boolean {
    return str == null || str.trim().length == 0;
  }

  public async onContactsSignIn(): Promise<void> {
    let url = this.router.url;
    await this.router.navigate(['sign-in'], {queryParams: {backUrl: url}}).then();
  }

}
