import {RouterModule, Routes} from "@angular/router";
import {ListPromosComponent} from "./list/list-promos.component";
import {EditPromoComponent} from "./edit/edit-promo.component";
import {NewPromoComponent} from "./new/new-promo.component";
import {NgModule} from "@angular/core";

const routes: Routes = [
  {
    path: '',
    component: ListPromosComponent,
    pathMatch: 'prefix',
  },
  {
    path: 'new',
    component: NewPromoComponent,
    pathMatch: 'prefix',
  },
  {
    path: ':id',
    component: EditPromoComponent,
    pathMatch: 'prefix',
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PromosRoutingModule {}
