import {catchError, Observable, of, shareReplay} from "rxjs";
import {publishReplay, refCount, startWith, switchMap} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {GeoClient, RegionRes} from "../api-clients/geo/clients";
import {RefreshService} from "../utils/refresh.service";

@Injectable({
  providedIn: 'root'
})
export class RegionsDirectoryService {
  private cache = {};

  public constructor(
    private client: GeoClient,
    private refreshService: RefreshService,
  ) {
  }

  public getRegions(cityId: string): Observable<RegionRes[]> {
    const key = cityId || 'all';

    if (!this.cache[key]) {
      this.cache[key] = this.refreshService.refreshAndResume$.pipe(
        startWith(null),
        switchMap(() => this.client.getRegionsByCityId(cityId)),
        catchError(err => {
          this.cache[key] = null;
          throw err;
        }),
        shareReplay(1)
      );
    }

    return this.cache[key];
  }
}
