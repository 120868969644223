import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

export class MasterClientsController {
  public constructor(
    public masterId: string,
    public navigateToBooking: (id: string) => Promise<void>,) {
  }

  private clientsUpdatedSubj: Subject<void> = new Subject<void>();

  public notifyClientsUpdated(): void {
    this.clientsUpdatedSubj.next();
  }

  public get clientsUpdated$(): Observable<void> {
    return this.clientsUpdatedSubj.asObservable();
  }
}

export abstract class IMasterClientsControllerFactory {
  abstract create(): MasterClientsController;
}

@Injectable()
export class ClientsControllerProvider {

  private created: MasterClientsController = null;

  public constructor(private factory: IMasterClientsControllerFactory) {
  }

  public provide(): MasterClientsController {
    const newCreated = this.factory.create();

    if (!this.created || this.created.masterId != newCreated.masterId) {
      this.created = newCreated;
    }

    return this.created;
  }
}
