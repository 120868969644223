<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>
      <ion-searchbar [formControl]="searchControl" mode="ios" placeholder="Клиент"></ion-searchbar>
    </ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="onAddClientClick()">
        <ion-icon slot="icon-only" name="add"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <!--<ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">-->
  <!--  <ion-refresher-content></ion-refresher-content>-->
  <!--</ion-refresher>-->

  <div class="content-section">
    <div class="cards-list">
      <div class="card padding">
        <div *ngIf="vm.clients.length == 0" class="ion-text-center">Пусто</div>
        <ion-list *ngIf="vm.clients?.length > 0">
          <div class="item-container" *ngFor="let client of vm.clients">
            <ion-item class="client" (click)="onClientClick(client)" button="true"
                      detail="false">
              <lib-avatar *ngIf="client"
                          class="ion-margin-end" slot="start"
                          style="width: 40px; height: 40px"
                          [name]="client?.definedName ?? client?.originalName"
                          [avatarFileName]="client?.avatarFileName">
              </lib-avatar>
              <ion-label>
                <h2>
                  {{client.originalName | clientName :(client.definedName)}}
                </h2>
                <p>
                  {{client.phone ?? 'Номер скрыт'}}
                </p>
              </ion-label>
            </ion-item>
          </div>

          <!--          <ion-virtual-scroll #scroll [items]="vm.clients" approxItemHeight="250px">-->
          <!--            <div class="item-container" *virtualItem="let client">-->
          <!--              <ion-item class="client" (click)="onClientClick(client)" button="true"-->
          <!--                        detail="false">-->
          <!--                <lib-avatar *ngIf="client"-->
          <!--                            class="ion-margin-end" slot="start"-->
          <!--                            style="width: 40px; height: 40px"-->
          <!--                            [name]="client?.definedName ?? client?.originalName"-->
          <!--                            [avatarFileName]="client?.avatarFileName">-->
          <!--                </lib-avatar>-->
          <!--                <ion-label>-->
          <!--                  <h2>-->
          <!--                    {{client.originalName | clientName :(client.definedName)}}-->
          <!--                  </h2>-->
          <!--                  <p>-->
          <!--                    {{client.phone ?? 'Номер скрыт'}}-->
          <!--                  </p>-->
          <!--                </ion-label>-->
          <!--              </ion-item>-->
          <!--            </div>-->
          <!--          </ion-virtual-scroll>-->

          <ion-infinite-scroll (ionInfinite)="loadClientsNextPage($event)">
            <ion-infinite-scroll-content loadingText="Загрузка..."></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-list>

      </div>
    </div>
  </div>

</ion-content>
