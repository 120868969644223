import {Injectable} from "@angular/core";
import {NavController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivatedRouteFixService} from "./activated-route-fix.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public constructor(
    private navCtrl: NavController,
  ) {
  }

  public async goBack(replaceUrl: boolean = false, defaultHref: string = null, relativeTo: ActivatedRoute = null): Promise<void> {
    // const canGoBack = window.history.state?.navigationId > 1;
    const canGoBack = window.history.length > 2;

    if (canGoBack) {
      this.navCtrl.back();
    } else {
      this.navCtrl.navigateBack([defaultHref || '/'], {
        animation: null,
        replaceUrl: replaceUrl,
        relativeTo: relativeTo
      }).then();
    }
  }
}
