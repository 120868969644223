import {Pipe, PipeTransform} from '@angular/core';

import * as tmp from 'moment';
const moment = tmp['default'];

@Pipe({
  name: 'calendar'
})
export class CalendarPipe implements PipeTransform {

  transform(value, format: string = 'dddd, D MMMM, LT'): string {
    if (!value) {
      return null;
    }

    return moment(value).locale('ru').calendar({
      sameDay: '[сегодня], LT',
      nextDay: '[завтра], LT',
      nextWeek: format,
      lastDay: format,
      lastWeek: format,
      sameElse: format
    });
  }

}

