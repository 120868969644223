import {Injectable, Pipe, PipeTransform} from "@angular/core";

@Injectable({providedIn: 'root'})
@Pipe({
  name: 'avatarUrlPipe'
})
export class AvatarUrlPipe implements PipeTransform {

  transform(url: string): string {
    if (!url) {
      return null;
    }

    return url + "?size=avatar";
  }
}
