import {Component, Inject, OnInit} from '@angular/core';
import {Platform} from "@ionic/angular";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {RetryInterceptorService} from "../../utils/retry-interceptor.service";
import {RefreshService} from "../../utils/refresh.service";

@Component({
  selector: 'lib-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.scss'],
})
export class NetworkStatusComponent implements OnInit {

  public retryInterceptor: RetryInterceptorService;

  constructor(
    public platform: Platform,
    public refreshService: RefreshService,
    @Inject(HTTP_INTERCEPTORS) private interceptors: any[]) {

    this.retryInterceptor = interceptors.find(i => i instanceof RetryInterceptorService) || null;
  }

  ngOnInit() {}

}
