import { Component, OnInit } from '@angular/core';
import {
  MasterFirstSetupWizardController
} from "../../../master-first-setup-wizard.controller";
import {AddressLocationData} from "../../../../../address/addressController";

@Component({
  selector: 'lib-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements OnInit {

  constructor(
    public _wizardController: MasterFirstSetupWizardController,
  ) { }

  ngOnInit() {}

  onFormSubmit(data: AddressLocationData) {
    this._wizardController.addressData.setAddressLocationData(data);
  }

}
