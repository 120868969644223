import {NgModule} from '@angular/core';

import {BcCommonModule} from "../bc-common.module";

import {DateProcessingModule} from "../date-processing/date-processing.module";
import {AvatarModule} from "../avatar/avatar.module";
import {StarsModule} from "../stars/stars.module";
import {ImagesModule} from "../images/images.module";
import {EditorsModule} from "../editors/editors.module";
import {PriceListModule} from "../price-list/price-list.module";

import {BookingsRoutingModule} from "./bookings-routing.module";
import {BookingPage} from "./booking/booking.page";
import {UtilsModule} from "../utils/utils.module";
import {SecurityModule} from "../security/security.module";

import {NewComponent} from "./new/new.component";
import {ServicesComponent} from "./new/services/services.component";
import {TimeSlotsComponent} from "./new/time-slots/time-slots.component";
import {ClientComponent} from "./new/client/client.component";
import {SignInComponent} from "./new/sign-in/sign-in.component";
import {CheckComponent} from "./new/check/check.component";
import {ConfirmationComponent} from "./new/confirmation/confirmation.component";

import {MoveComponent} from "./move/move.component";

@NgModule({
  declarations: [
    BookingPage,

    NewComponent,
    ServicesComponent,
    TimeSlotsComponent,
    SignInComponent,
    ClientComponent,
    CheckComponent,
    ConfirmationComponent,
    MoveComponent,
  ],
  imports: [
    BcCommonModule,
    BookingsRoutingModule,
    DateProcessingModule,
    AvatarModule,
    StarsModule,
    ImagesModule,
    EditorsModule,
    PriceListModule,
    UtilsModule,
    SecurityModule,
  ],
})
export class BookingsModule {
}
