import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DatesValidator} from "../../utils/dates.validator";

@Component({
  selector: 'lib-name-change-popup',
  templateUrl: './name-change-popup.component.html',
  styleUrls: ['./name-change-popup.component.scss'],
})
export class NameChangePopupComponent extends ModalComponentBase implements OnInit {

  public form: FormGroup;

  @Input()
  public firstName: string;

  @Input()
  public lastName: string;

  constructor(
    modalController: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
    public _applicationIdService: ApplicationIdService,
  ) {
    super(modalController, router, activeRoute, location);
  }

  ngOnInit() {
    this.form = new FormGroup({
      firstName: new FormControl(this.firstName, [Validators.required]),
      lastName: new FormControl(this.lastName, [Validators.required])
    },[
      DatesValidator.scheduleTimeValidator
    ]);
  }

  public async onSubmitClick($event: MouseEvent): Promise<void> {
    if (!this.form.valid) {
      return;
    }

    await super.dismiss({
      firstName: this.form.controls.firstName.value,
      lastName: this.form.controls.lastName.value
    });
  }

  public async onCloseClick(): Promise<void> {
    await super.dismiss();
  }
}
