<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button default-href="/"></lib-back-button>
    </ion-buttons>
    <ion-title>
      <ion-segment mode="ios" [value]="selectedTab" (ionChange)="onSegmentChanged($event)">
        <ion-segment-button value="actual">
          <ion-label>Записи</ion-label>
        </ion-segment-button>
        <ion-segment-button value="others">
          <ion-label>Архив</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true" *ngIf="masterId && clientId">

  <div class="content-section">

    <div class="cards-list">
      <div class="card padding">
        <lib-bookings-with-feedback-list *ngIf="selectedTab === 'actual'"
                                         mode="master-bookings"
                                         [masterId]="masterId"
                                         [clientId]="clientId"
                                         [canceled]="false"
                                         [actual]="true"
                                         [hasAnyFeedback]="null"
                                         [showFilters]="false"
                                         (detailsClicked)="onDetailsClicked($event)">
        </lib-bookings-with-feedback-list>
        <lib-bookings-with-feedback-list *ngIf="selectedTab === 'others'"
                                         mode="master-bookings"
                                         [masterId]="masterId"
                                         [clientId]="clientId"
                                         [canceled]="null"
                                         [actual]="false"
                                         [hasAnyFeedback]="null"
                                         (detailsClicked)="onDetailsClicked($event)">
        </lib-bookings-with-feedback-list>
      </div>
    </div>

  </div>

</ion-content>
