<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button default-href="/"></lib-back-button>
    </ion-buttons>
    <ion-title>Клиент</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div *ngIf="client" class="content-section">

    <div class="cards-list safe-area-space">

      <lib-card-spinner *ngIf="this.processing == true"></lib-card-spinner>

      <div class="client card padding">
        <div class="chips">
          <ion-chip *ngIf="client.isAd" color="success">
            <ion-label>Привлечён БК</ion-label>
            <ion-icon name="person-add-outline" style="font-size: 14px"></ion-icon>
          </ion-chip>

          <ion-chip *ngIf="client.deleted && !client.isBlockedByMaster" color="danger" (click)="restoreClient()">
            <ion-label>Удалён. Восстановить</ion-label>
            <ion-icon name="reload-outline" style="font-size: 14px"></ion-icon>
          </ion-chip>

          <ion-chip *ngIf="client.isBlockedByMaster" color="danger" (click)="unblockClient()">
            <ion-label>Удалён и в ЧС. Восстановить</ion-label>
            <ion-icon name="reload-outline" style="font-size: 14px"></ion-icon>
          </ion-chip>
        </div>

        <ion-list>

          <div class="image-item">
            <lib-avatar *ngIf="client"
                        class="image"
                        [name]="client.definedName || client?.originalName || 'Аноним'"
                        [avatarFileName]="client?.avatarFileName">
            </lib-avatar>
          </div>

          <ion-item (click)="onClientNameClick()" button="true" detail="false">
            <ion-label>
              <p>Имя</p>
              <h4>{{client.originalName | clientName : client.definedName}}</h4>
            </ion-label>
          </ion-item>

          <ion-item (click)="onPhoneClick()" button="true" detail="false">
            <ion-label>
              <p>Телефон</p>
              <h4 *ngIf="!relatedSaloonId">{{client.phone}}</h4>
              <div *ngIf="relatedSaloonId"><span
                class="accent-down">Клиент салона {{relatedSaloonName ? '(' + relatedSaloonName + ')' : ''}}. Номер скрыт</span>
              </div>
            </ion-label>
            <ion-buttons slot="end" *ngIf="client.phone">
              <ion-button (click)="onPhoneCallClick($event)">
                <ion-icon name="call-outline" slot="icon-only"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>

          <ion-item (click)="onInstagramClick()" button="true" detail="false">
            <ion-label>
              <p>Instagram</p>
              <h4 *ngIf="!relatedSaloonId && (client.definedInstagramProfile || client.originalInstagramProfile)">
                {{(client.originalInstagramProfile ?? client.definedInstagramProfile) | instagramProfileName}}
              </h4>
              <div *ngIf="!relatedSaloonId && !((client.definedInstagramProfile || client.originalInstagramProfile))">
                <span class="accent-down">Пусто</span>
              </div>
              <div *ngIf="relatedSaloonId">
                <span class="accent-down">
                  Клиент салона {{relatedSaloonName ? '(' + relatedSaloonName + ')' : ''}}. Instagram скрыт
                </span>
              </div>
            </ion-label>
            <ion-buttons slot="end"
                         *ngIf="client.phone && (client.definedInstagramProfile || client.originalInstagramProfile)">
              <ion-button slot="end"
                          (click)="onInstagramLinkClick($event)">
                <ion-icon name="open-outline" slot="icon-only"></ion-icon>
              </ion-button>
            </ion-buttons>

          </ion-item>

          <ion-item (click)="onClientNoteClick()" button="true" detail="false">
            <ion-label>
              <p>Примечание (скрыто от клиента)</p>
              <div *ngIf="client.note && client.note.length > 0">
                {{client.note}}
              </div>
              <div *ngIf="!(client.note && client.note.length > 0)">
                <span class="accent-down">Пусто</span>
              </div>
            </ion-label>
          </ion-item>

          <ion-item *ngIf="client.isBlockedByMaster" (click)="onClientNoteBlockingClick()" button="true" detail="false">
            <ion-label>
              <p>Примечание о блокировке (скрыто от клиента)</p>
              <div *ngIf="client.noteBlocking && client.noteBlocking.length > 0">
                {{client.noteBlocking}}
              </div>
              <div *ngIf="!(client.noteBlocking && client.noteBlocking.length > 0)">
                <span class="accent-down">Пусто</span>
              </div>
            </ion-label>
          </ion-item>

        </ion-list>

        <ion-buttons class="action-buttons">
          <ion-button shape="round" (click)="onClientBookingsClick()">
            <ion-label>
              <ion-icon class="info" name="book-outline"></ion-icon>
              <label>Записи</label>
            </ion-label>
          </ion-button>
          <ion-button shape="round" *ngIf="!client.deleted" (click)="deleteClient()">
            <ion-label>
              <ion-icon class="cancel" name="trash-bin-outline"></ion-icon>
              <label>Удалить</label>
            </ion-label>
          </ion-button>
        </ion-buttons>

      </div>
    </div>
  </div>

</ion-content>
