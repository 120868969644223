<ion-header>
  <ion-toolbar>
    <ion-title>Удаление клиента</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div class="content-section">
    <form [formGroup]="form" *ngIf="form">
      <div class="cards-list">
        <div class="card padding">
          <ion-list lines="none" class="classic">
            <ion-item>
              <ion-label>Добавить в черный список</ion-label>
              <ion-toggle #toggle formControlName="block" slot="start"></ion-toggle>
            </ion-item>
            <ion-item *ngIf="toggle.checked == true">
              <ion-label position="stacked">Примечание</ion-label>
              <ion-textarea #inp
                            formControlName="note"
                            [autoGrow]="true"
                            (keyup.shift.enter)="onSaveClick()"
                            placeholder="Введите текст">
              </ion-textarea>
            </ion-item>
          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>

  </div>

</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block"
                (click)="onSaveClick()">Продолжить
    </ion-button>
  </div>
</ng-template>
