import {Component, OnInit} from '@angular/core';
import {RefreshService} from "../../utils/refresh.service";
import {Platform} from "@ionic/angular";
import {interval} from 'rxjs';
import {take} from "rxjs/operators";

@Component({
  selector: 'lib-refresher',
  templateUrl: './refresher.component.html',
  styleUrls: ['./refresher.component.scss'],
})
export class RefresherComponent implements OnInit {

  public get showRefresher(): boolean {
    return this.platform.is('mobile');
  }

  constructor(
    private refreshService: RefreshService,
    private platform: Platform,
  ) {
  }

  ngOnInit() {
  }

  public handleRefresh($event: any): void {
    this.refreshService.refresh();
    interval(250).pipe(take(1)).subscribe(() => {
      $event?.target?.complete();
    });
  }
}
