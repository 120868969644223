import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef,
  Input,
} from '@angular/core';

@Component({
  selector: 'lib-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {

  @Input()
  public name: string = '';

  @Input()
  public avatarFileName: string = '';

  @Input()
  public imagePath: string = '';

  public h: string = "1em";

  public constructor(
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit(): void {

    const pixels = this.elRef.nativeElement.offsetHeight;
    if (pixels) {
      this.h = 0.3 * pixels + 'px';
      this.changeDetectorRef.markForCheck();
    }

    // if (!(window as any)?.ResizeObserver) {
    //   return;
    // }
    //
    // const observer = new (window as any).ResizeObserver(() => {
    //   const pixels = this.elRef.nativeElement.offsetHeight;
    //   if (pixels) {
    //     this.h = 0.3 * pixels + 'px';
    //     this.changeDetectorRef.detectChanges();
    //   }
    // });
    //
    //
    // observer.observe(this.elRef.nativeElement);
  }
}
