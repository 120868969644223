import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


import {PhoneComponent} from "./phone/phone.component";
import {CodeComponent} from "./code/code.component";
import {SignInRootComponent} from "./sign-in-root/sign-in-root.component";

import {TokenlessGuard} from "../security/tokenless-guard.service";
import {InfoComponent} from "./info/info.component";

const routes: Routes = [
  {
    path: '',
    component: SignInRootComponent,
    canActivate: [TokenlessGuard()],
    pathMatch: 'prefix',
    children: [
      {
        path: '',
        redirectTo: 'phone',
        pathMatch: 'prefix',
      },
      {
        path: 'phone',
        component: PhoneComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'code',
        component: CodeComponent,
        pathMatch: 'prefix',
      },
      {
        path: 'info',
        component: InfoComponent,
        pathMatch: 'prefix',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignInRoutingModule {
}
