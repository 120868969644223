import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {AddressPipe} from "./address.pipe";
import {DatesValidator} from "./dates.validator";
import {SpecialitiesPipe} from "./specialities.pipe";
import {ClientNamePipe} from "./client-name.pipe";
import {NavigationService} from "./navigation.service";
import {CityStore} from "./city-store";
import {GuidService} from "./guid.service";
import {InstagramProfileNamePipe} from "./instagram-profile-name.pipe";
import {PointsPipe} from "./points.pipe";
import {IconOperationPipe} from "./icon-operation.pipe";
import {TypeOperationPipe} from "./type-operation.pipe";

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [
    ClientNamePipe,
    AddressPipe,
    SpecialitiesPipe,
    InstagramProfileNamePipe,
    PointsPipe,
    IconOperationPipe,
    TypeOperationPipe,
  ],
  exports: [
    ClientNamePipe,
    AddressPipe,
    SpecialitiesPipe,
    InstagramProfileNamePipe,
    PointsPipe,
    IconOperationPipe,
    TypeOperationPipe,
  ],
  providers: [
    AddressPipe,
    DatesValidator,
    SpecialitiesPipe,
    NavigationService,
    CityStore,
    GuidService,
    InstagramProfileNamePipe,
    PointsPipe,
    IconOperationPipe,
    TypeOperationPipe,
  ]
})
export class UtilsModule {
}
