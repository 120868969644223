<ion-header translucent="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button defaultHref="/"></lib-back-button>
    </ion-buttons>
    <ion-title>Установка</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">

    <div class="cards-list safe-area-space">
      <div class="card padding">

        <div *ngIf="applicationIdService.applicationId == 'client'" class="image">
          <ion-avatar>
            <img src="https://landing.bk.style/images/popup-card1.png" alt="Бьюти Круг">
          </ion-avatar>
          <h1>Бьюти Круг</h1>
        </div>

        <div *ngIf="applicationIdService.applicationId == 'master'" class="image">
          <ion-avatar>
            <img src="https://landing.bk.style/images/popup-card2.png" alt="БК: Мастер">
          </ion-avatar>
          <h1>БК: Мастер</h1>
        </div>

        <div *ngIf="applicationIdService.applicationId == 'saloon'" class="image">
          <ion-avatar>
            <img src="https://landing.bk.style/images/popup-card3.png" alt="БК: Салон">
          </ion-avatar>
          <h1>БК: Салон</h1>
        </div>

        <ol *ngIf="platform.is('android')">
          <li>Войдите в меню Google&nbsp;Chrome</li>
          <li>Выберите "Установить приложение"</li>
        </ol>

        <ol *ngIf="platform.is('ios')">
          <li>Нажмите на кнопку "Поделиться" <ion-icon name="share-outline" style="font-size: 18px; vertical-align: bottom; color: royalblue"></ion-icon> внизу экрана</li>
          <li>Выберите "Добавить на экран домой"</li>
        </ol>

        <p *ngIf="platform.is('desktop')" class="ion-text-center">
          На компьютер не надо ничего устанавливать, просто сделайте закладку на главную страницу
        </p>

      </div>
    </div>

    <div class="card padding-mobile transparent" *ngIf="platform.is('desktop')">
      <ion-button expand="block" [routerLink]="['/']">На главную</ion-button>
    </div>

  </div>
</ion-content>
