<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>Работы и отзывы</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true" *ngIf="saloon?.id">

  <lib-refresher slot="fixed"></lib-refresher>

  <div class="content-section cards-list safe-area-space" [ngClass]="{'mh': list.feedbacks == null}">

    <lib-bookings-with-feedback-list #list
                                     mode="master-feedbacks"
                                     [saloonId]="saloon?.id"
                                     [canceled]="false"
                                     [actual]="false"
                                     [hasAnyFeedback]="true"
                                     (detailsClicked)="onBwfDetailsClicked($event)">
    </lib-bookings-with-feedback-list>

  </div>

</ion-content>
