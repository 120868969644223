<div class="banner b2">
  <img src="assets/banners/banner5.png"/>
  <h2>
    Отметьте рабочие дни
  </h2>
  <p>
    Желтый - рабочий
    <br />
    Зелёный - выходной
  </p>
</div>
