import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'sortingName'
})
export class SortingNamePipe implements PipeTransform {

  public transform(value: number): string {
    switch (value){
      case 1: return "Рядом с вами";
      case 2: return "Посвободнее / с окошками";
      case 3: return "По рейтингу";
      case 4: return "Цена ниже";
      default: return value?.toString();
    }
  }
}
