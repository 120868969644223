import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {AuthService} from "../security/auth.service";
import {GuidService} from "./guid.service";
import {TrackingClient, TrackReq} from "../api-clients/tracking/clients";
import {ApplicationIdService} from "../application-id/application-id.service";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  public constructor(
    private _authService: AuthService,
    private _trackingClient: TrackingClient,
    private _applicationIdService: ApplicationIdService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
  }

  public masterRefCode: number = null;
  public saloonRefCode: number = null;

  public async track(masterId: string, saloonId: string): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this._applicationIdService.applicationId != "client") {
      return;
    }

    let clientTrackingCode = localStorage?.getItem("clientTrackingCode");
    if (!clientTrackingCode) {
      clientTrackingCode = GuidService.create();
      localStorage?.setItem("clientTrackingCode", clientTrackingCode);
    }

    let clientId = this._authService.isAuthenticated ? this._authService.parsedToken.userId : null;

    await this._trackingClient.track(
      {
        clientId: clientId,
        masterId: masterId,
        saloonId: saloonId,
        clientTrackingCode: clientTrackingCode,
        masterRefCode: this.masterRefCode,
        saloonRefCode: this.saloonRefCode,
      } as TrackReq).toPromise();
  }

  public setMasterRefCode(refCode: number): void {
    this.masterRefCode = refCode;
  }

  public async setSaloonRefCode(refCode: number): Promise<void> {
    this.saloonRefCode = refCode;
  }
}
