import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  AuthService,
  RefreshService,
  CitiesDirectoryService,
  FiltersModel,
  MasterListClient,
  NavigatorService,
  RegionsDirectoryService,
  SpecialitiesDirectoryService,
  SsrHelper, SubscriptionsBag,
  CityStore, hasSameDate,
} from "bc-common";
import {ActivatedRoute, Router} from "@angular/router";
import {
  distinctUntilChanged, map, startWith, switchMap, take, tap,
} from "rxjs/operators";
import {from, combineLatest, firstValueFrom, BehaviorSubject, filter} from "rxjs";
import * as moment from "moment/moment";

class Vm {
  public Masters: MasterListClient.MasterRes[] = null;
}

@Component({
  selector: 'app-masters-list',
  templateUrl: './masters-list.component.html',
  styleUrls: ['./masters-list.component.scss']
})

export class MastersListComponent implements OnInit, OnDestroy {
  public vm: Vm = new Vm();
  public filtersModel: FiltersModel = null;

  public now: Date = new Date();
  public loading: boolean = false;

  private _isThisViewEnteredSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private _pageSize: number = 30;
  private _currentPageIndex: number = 0;
  private _mastersEndMeet: boolean = false;

  private _sb = new SubscriptionsBag();

  constructor(
    public authService: AuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _masterListClient: MasterListClient.MasterListClient,
    private _citiesDirectoryService: CitiesDirectoryService,
    private _regionsDirectoryService: RegionsDirectoryService,
    private _specialitiesDirectoryService: SpecialitiesDirectoryService,
    private _navigatorService: NavigatorService,
    private _ssrHelper: SsrHelper,
    private _cityStore: CityStore,
    private _refreshService: RefreshService,
  ) {
  }

  public async ngOnInit(): Promise<void> {
    this._sb.sub = combineLatest([
      this._refreshService.refreshAndResume$.pipe(
        startWith(null),
        filter(() => this._isThisViewEnteredSubj.value),
      ),
      this._activatedRoute.queryParamMap.pipe(
        switchMap(qpm => FiltersModel.fromParamMap(
          qpm,
          this._citiesDirectoryService,
          this._regionsDirectoryService,
          this._specialitiesDirectoryService,
          this._navigatorService,
        )),
        distinctUntilChanged((prev, curr) => {
          return JSON.stringify(prev.toQueryParams()) === JSON.stringify(curr.toQueryParams());
        }),
        tap(filtersModel => filtersModel.city && this._cityStore.remember(filtersModel.city)),
        switchMap(filtersModel => filtersModel.isEmpty
          ? filtersModel.setCity(this._cityStore.get()?.id).then(() => filtersModel)
          : Promise.resolve(filtersModel)
        ),
      ),
    ]).pipe(
      map(([x, filtersModel]) => filtersModel),
      switchMap((filtersModel) => filtersModel.clone()),
      tap(filtersModel => this.filtersModel = filtersModel),
      switchMap(filtersModel => this.loadFirstPageOfMasters(filtersModel).then(() => filtersModel)),
      switchMap(filtersModel => filtersModel.changed$.pipe(startWith(filtersModel))),
      switchMap(filtersModel => this.navigate(filtersModel)),
    ).subscribe();
  }

  public ngOnDestroy() {
    this._sb.unsubscribeAll();
  }

  public async ionViewWillEnter(): Promise<void> {
    this._isThisViewEnteredSubj.next(true);
  }

  public ionViewWillLeave(): void {
    this._isThisViewEnteredSubj.next(false);
  }

  public async onItemClick(masterId: string) {
    await this._router.navigate(['/masters', masterId]);
  }

  public async navigate(filtersModel: FiltersModel): Promise<FiltersModel> {
    return this._router.navigate(['/catalog'], {
      queryParams: {
        ...filtersModel.toQueryParams(),
      }
    }).then(() => filtersModel)
  }

  public async searchMasters(filtersModel: FiltersModel, pageIndex: number): Promise<MasterListClient.MasterRes[]> {

    const masters$ = this._masterListClient.searchMasters(
      null,
      null,
      null,
      filtersModel.city?.id,
      filtersModel.region?.id,
      filtersModel.specialities.map(s => s.id)[0] || null,
      filtersModel.specialities.map(s => s.serviceTypes).reduce((curr, next) => [...curr, ...next], []).map(st => st.id),
      pageIndex * this._pageSize,
      this._pageSize,
      filtersModel.sorting || 2,
      filtersModel.longitude || null, // 30.30248699844101,
      filtersModel.latitude || null, // 53.87353900004283
    ).pipe(
      tap(masters => {
        this._mastersEndMeet = masters.length !== this._pageSize;
        return masters;
      })
    );

    return await firstValueFrom(masters$);
  }

  public async loadFirstPageOfMasters(filtersModel: FiltersModel): Promise<void> {
    this._currentPageIndex = 0;
    this._mastersEndMeet = false;
    this.loading = true;

    const masters = await this._ssrHelper.getCachedData(
      "masters",
      () => from(this.searchMasters(filtersModel, this._currentPageIndex++))
    ).pipe(take(1)).toPromise();

    this.loading = false;

    let now = new Date();
    this.vm.Masters = masters.map(m => {
      m.promos = m.promos.filter(p => p.startTimeOffset < now && p.endTimeOffset > now || hasSameDate(now, p.startTimeOffset) || hasSameDate(now, p.endTimeOffset));
      return m;
    });
  }

  public async loadNextPageOfMasters($event: any): Promise<void> {
    if (this._mastersEndMeet) {
      $event?.target?.complete();
      return;
    }

    this.loading = true;

    try {
      let masters = await this.searchMasters(this.filtersModel, this._currentPageIndex++);
      this.vm.Masters.push(...masters);
    } finally {
      this.loading = false;
      $event?.target?.complete();
    }

  }

  public dateToFromNowDaily(date): string {
    return moment(date).locale('ru').calendar(null, {
      sameDay: '[сегодня], D MMMM',
      nextDay: '[завтра], D MMMM',
      nextWeek: 'dddd, D MMMM',
      sameElse: 'dddd, D MMMM'
    });
  }

  protected readonly hasSameDate = hasSameDate;

  public trackById(index, item: any): any {
    return item.id;
  }

  public onFiltersClearClick() {
    this.filtersModel.clear().then();
    this._cityStore.remember(null);
  }
}
