<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>Проверка</ion-title>
  </ion-toolbar>
</ion-header>

<ng-container *ngIf="timeSlotData">
  <ion-content force-overscroll="true">

    <div class="content-section">

      <div class="cards-list">

        <div class="card bwf-2 padding">

          <div class="message">

            <ion-list class="transparent">
              <ion-item [button]="false" [detail]="false">
                <ion-avatar slot="start">
                  <lib-avatar [name]="wc.timeSlotData.master.fullName"
                              [avatarFileName]="wc.timeSlotData.master.avatarFileName">
                  </lib-avatar>
                </ion-avatar>
                <ion-label>
                  <h4>{{ wc.timeSlotData.master.fullName }}</h4>
                  <p>{{ getBookingSpecialises() }}</p>
                </ion-label>
              </ion-item>
            </ion-list>

          </div>

          <div class="message">
            <ion-list class="transparent">
              <ion-item [button]="false" [detail]="false">
                <ion-avatar slot="start">
                  <lib-avatar [name]="wc.clientVm?.definedName || wc.clientVm?.originalName"
                              [avatarFileName]="wc.clientVm?.avatarFileName">
                  </lib-avatar>
                </ion-avatar>
                <ion-label>
                  <h4>{{ wc.clientVm?.definedName | clientName :wc.clientVm?.originalName }}</h4>
                </ion-label>
              </ion-item>
            </ion-list>
          </div>

          <div class="separator v2"></div>

          <div class="info">
            <div class="date-big">
          <span>
            {{ timeSlotData.timeSlot.startTime | moment :'D MMM' }}
            , {{ timeSlotData.timeSlot.startTime | moment :'LT' }}
            - {{ endTime | moment :'LT' }}
          </span>
            </div>

            <div class="address figure-1">

              <ng-container *ngIf="wc.timeSlotData.address.addressSaloonId">
                <ion-avatar class="avatar">
                  <div class="icon-container">
                    <lib-avatar [name]="wc.timeSlotData.address?.addressSaloonName"
                                [avatarFileName]="wc.timeSlotData.address?.addressSaloonAvatar">
                    </lib-avatar>
                  </div>
                </ion-avatar>
                <div class="text">
                  <h4>{{ wc.timeSlotData.address?.addressSaloonType }} "{{ wc.timeSlotData.address?.addressSaloonName }}
                    "</h4>
                  <p>{{ wc.timeSlotData.address?.addressLine1 }}, {{ wc.timeSlotData.address?.addressLine2 }}</p>
                </div>
              </ng-container>

              <ng-container *ngIf="!wc.timeSlotData.address?.addressSaloonId">
                <ion-avatar class="avatar">
                  <div class="icon-container">
                    <ion-icon name="location-outline"></ion-icon>
                  </div>
                </ion-avatar>
                <div class="text">
                  <h4>{{ wc.timeSlotData.address?.addressLine1 }}</h4>
                  <p>{{ wc.timeSlotData.address?.addressLine2 }}</p>
                </div>
              </ng-container>

            </div>

          </div>

          <div class="services">
            <div class="line" *ngFor="let item of wc.servicesData.serviceItems">
              <div class="data">
                <span>{{ item.serviceTypeName }}</span>
                <span>{{ getPriceItem(item) }}</span>
              </div>
              <ng-container *ngIf="item.promo != null">
                <div class="data primary">
                  <span>{{ item.promo.title }}</span>
                  <span>-{{ item.promo.value }}{{ item.promo.isPercentValue == true ? '%' : ' BYN' }}</span>
                </div>
                <div class="subtotal" *ngIf="item.promo.id != null">
                  {{ getPriceItemTotal(item) }}
                </div>
              </ng-container>
            </div>
            <div class="total" *ngIf="wc.servicesData.serviceItems.length > 1">
              <span>Итого</span>
              <span>{{ getPriceTotal() }}</span>
            </div>
          </div>

          <div class="separator v2"></div>

          <ng-container *ngIf="wc.servicesData.frequency">
            <div>
              Автоповтор:
              <span *ngIf="wc.servicesData.frequency === 1">каждую неделю</span>
              <span *ngIf="wc.servicesData.frequency === 2">каждые 2 недели</span>
              <span *ngIf="wc.servicesData.frequency === 3">каждые 3 недели</span>
              <span *ngIf="wc.servicesData.frequency === 4">каждые 4 недели</span>
            </div>

            <div class="separator v2"></div>
          </ng-container>

          <div class="actions-1">
            <div class="figure-2">
              <ion-toggle class="toggle" [checked]="reminderActivated"
                          (ionChange)="reminderActivated = $event.detail.checked; toggleReminder($event)"></ion-toggle>
              <div class="label">Напомнить</div>
              <div class="options" *ngIf="reminderActivated && reminderItemSelected" (click)="onReminderClick()">
                <div class="current">{{ reminderItemSelected.text.toLowerCase() }}</div>
                <div class="details">
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="actions-1" *ngIf="applicationIdService.applicationId == 'master'">
            <div class="figure-2">
              <ion-toggle class="toggle" [checked]="masterNoteActivated"
                          (ionChange)="masterNoteActivated = $event.detail.checked; onMasterNoteEnabledClick($event)"></ion-toggle>
              <div class="label">Примечание</div>
              <div class="options" *ngIf="masterNoteActivated">
                <div class="current" (click)="onMasterNoteClick()">{{ wc.masterNote ? wc.masterNote : 'пусто' }}</div>
                <div class="details">
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="hide-mobile"
             *ngIf="(wc?.clientVm?.isBlockedBySaloon == false && wc.clientVm?.isBlockedByMaster == false) && wc.timeSlotData.master.isAdvertisingBlocked == false">
          <ng-container *ngTemplateOutlet="next_button"></ng-container>
        </div>

        <div class="card padding hide-mobile"
             *ngIf="wc.timeSlotData.master.isAdvertisingBlocked === true">
          <ng-container *ngTemplateOutlet="block"></ng-container>
        </div>

        <div class="card padding hide-mobile"
             *ngIf="wc.clientVm && (wc.clientVm?.isBlockedBySaloon || wc.clientVm?.isBlockedByMaster)">
          <ng-container *ngTemplateOutlet="black_list"></ng-container>
        </div>

      </div>

    </div>
  </ion-content>

  <ion-footer class="hide-desktop ion-no-border"
              *ngIf="(wc.clientVm?.isBlockedByMaster == false && wc.clientVm?.isBlockedBySaloon == false) && wc.timeSlotData.master.isAdvertisingBlocked == false">
    <ng-container *ngTemplateOutlet="next_button"></ng-container>
  </ion-footer>

  <ion-footer class="hide-desktop ion-no-border"
              *ngIf="wc.timeSlotData.master.isAdvertisingBlocked === true">
    <div class="card padding">
      <ng-container *ngTemplateOutlet="block"></ng-container>
    </div>
  </ion-footer>

  <ion-footer class="hide-desktop ion-no-border"
              *ngIf="wc.clientVm && (wc.clientVm?.isBlockedBySaloon || wc.clientVm?.isBlockedByMaster)">
    <div class="card">
      <ng-container *ngTemplateOutlet="black_list"></ng-container>
    </div>
  </ion-footer>

  <ng-template #next_button>
    <div class="card transparent padding-mobile">
      <ion-button expand="block"
                  (click)="onNextClick()">
        Далее
        <ion-icon slot="end" name="arrow-forward-circle"></ion-icon>
      </ion-button>
    </div>
  </ng-template>

  <ng-template #block>
    <ng-container *ngIf="applicationIdService.applicationId == 'client'">
      <h2>
        У мастера заблокирована онлайн запись!
      </h2>
      <div>
        <ion-button expand="block" (click)="onCallMasterClick()">Связаться с мастером</ion-button>
      </div>
    </ng-container>
    <ng-container *ngIf="applicationIdService.applicationId == 'master'">
      <h2>
        Онлайн запись заблокирована!
      </h2>
      <div>
        <ion-button expand="block" (click)="onCheckBalanceClick()">Проверить баланс</ion-button>
      </div>
    </ng-container>
    <ng-container *ngIf="applicationIdService.applicationId == 'saloon'">
      <h2>
        Онлайн запись заблокирована!
      </h2>
      <div>
        <ion-button expand="block">Проверить баланс</ion-button>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #black_list>
    <div class="card transparent padding-mobile">
      <ng-container *ngIf="applicationIdService.applicationId == 'client'">
        <p>Вы у
          <ng-container *ngIf="wc.clientVm && wc.clientVm?.isBlockedBySaloon; else masterBlocked"> салона
          </ng-container>
          <ng-template #masterBlocked> мастера</ng-template>
          в черном списке.
        </p>
        <ion-button expand="block" routerLink="/">На главную</ion-button>
      </ng-container>
      <ng-container *ngIf="applicationIdService.applicationId == 'master'">
        <ng-container *ngIf="wc.clientVm?.isBlockedBySaloon; else masterBlocked">
          <p>Клиент в черном списке салона</p>
          <ion-button expand="block" routerLink="/">На главную</ion-button>
        </ng-container>
        <ng-template #masterBlocked>
          <p>Клиент в вашем черном списке</p>
          <ng-container *ngIf="wc.clientVm.noteBlocking && wc.clientVm.noteBlocking.length > 0">
            <p>Примечание:<br>
              {{ wc.clientVm.noteBlocking }}</p>
          </ng-container>
          <ion-button expand="block" (click)="unblockMasterClient()">Разблокировать</ion-button>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="applicationIdService.applicationId == 'saloon'">
        <ng-container *ngIf="wc.clientVm?.isBlockedBySaloon; else masterBlocked">
          <p>Клиент в вашем черном списке</p>
          <ng-container *ngIf="wc.clientVm.noteBlocking && wc.clientVm.noteBlocking.length > 0">
            <p>Примечание:<br>
              {{ wc.clientVm.noteBlocking }}</p>
          </ng-container>
          <ion-button expand="block" (click)="unblockSaloonClient()">Разблокировать</ion-button>
        </ng-container>
        <ng-template #masterBlocked>
          <p>Клиент в черном списке мастера</p>
          <ion-button expand="block" routerLink="/">На главную</ion-button>
        </ng-template>
      </ng-container>
    </div>
  </ng-template>
</ng-container>

