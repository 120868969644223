<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button defaultHref="./"></lib-back-button>
    </ion-buttons>
    <ion-title>Изменение адреса</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <div class="content-section">
      <form [formGroup]="formGroup">
        <div class="cards-list">
          <div class="card padding">
            <lib-card-spinner *ngIf="processing == true"></lib-card-spinner>
            <ion-list *ngIf="processing == false" lines="none" class="classic">
              <ion-item (click)="onAddressLine1Click()">
                <ion-label position="stacked">Адрес</ion-label>
                <ion-input formControlName="addressLine1" [disabled]="true" [readonly]="true"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">Уточнение (кабинет, этаж)</ion-label>
                <ion-input formControlName="addressLine2"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">Как добраться (не обязательно)</ion-label>
                <ion-textarea formControlName="howToGet"></ion-textarea>
              </ion-item>
              <div>
                <app-images-set [imagesInit]="this.address.howToGetImagesNames"
                                title="Как добраться"
                                [allowAdding]="true"
                                [allowDeleting]="true"
                                (deleteClicked)="onImageDeleteClicked($event); formGroup.markAsDirty()"
                                (addClicked)="onImageAddClicked($event); formGroup.markAsDirty()">
                </app-images-set>
              </div>
            </ion-list>
          </div>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </form>
    </div>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile" *ngIf="this.processing == false">
    <ion-button (click)="onFormSubmit()"
                expand="block"
                size="default"
                [disabled]="formGroup.dirty === false">
      Сохранить
    </ion-button>
  </div>
</ng-template>
