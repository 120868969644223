import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AlertController,
  IonContent,
  IonicSafeString,
  ModalController,
} from '@ionic/angular';
import {
  AuthService,
  MasterListClient,
  PriceListVm,
  SpecialitiesDirectoryService,
  ToastService,
  FileUrlPipe,
  ThumbnailUrlPipe,
  SpecialitiesPipe,
  TrackingService,
  ActivatedRouteFixService,
  BookingClient,
  NotificationClient,
  AddressInfoComponent,
  ClientsClient, hasSameDate, CityStore, SubscriptionsBag, RefreshService, MetaTagsService
} from "bc-common";
import {map, startWith, switchMap, take, tap} from "rxjs/operators";
import {combineLatest, firstValueFrom, Observable, of} from "rxjs";
import {NgEventBus} from "ng-event-bus";
import {CreateCallbackRequestReq} from "../../../../../bc-common/src/lib/api-clients/notification/client";

class Vm {
  public master: MasterListClient.MasterRes;
  public priceList: PriceListVm.PriceListVm;
}

export class PromoResVm extends MasterListClient.PromoRes {
  public status: "lock" | "allow" | "used" | undefined;
  public description: string;
}

@Component({
  selector: 'app-master-profile',
  styleUrls: ['./master-profile.component.scss'],
  templateUrl: './master-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterProfileComponent implements OnInit, OnDestroy {
  @ViewChild(IonContent, {static: false})
  public content: IonContent;

  public sb: SubscriptionsBag = new SubscriptionsBag();

  public vm: Vm = new Vm();

  public promos: PromoResVm[] = [];
  public masterIsFavorites: boolean = false;

  public calcPromos;

  public botConnection: NotificationClient.GetActiveConnectionRes = null;

  constructor(
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private masterListClient: MasterListClient.MasterListClient,
    private bookingClient: BookingClient.BookingClient,
    private clientsClient: ClientsClient.ClientsClient,
    private specialitiesDirectoryService: SpecialitiesDirectoryService,
    private alertController: AlertController,
    private fileUrlPipe: FileUrlPipe,
    private thumbnailPipe: ThumbnailUrlPipe,
    private specialitiesPipe: SpecialitiesPipe,
    private trackingService: TrackingService,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private notificationClient: NotificationClient.NotificationClient,
    private cityStore: CityStore,
    private refreshService: RefreshService,
    private metaTagsService: MetaTagsService,
    private eventBus: NgEventBus,
    private toastService: ToastService,
  ) {
  }

  public ngOnInit(): void {
    this.sb.sub = combineLatest([
      this.refreshService.refreshAndResume$.pipe(startWith(null)),
      this.activatedRoute.paramMap,
    ]).pipe(
      map(([x, paramMap]) => paramMap),
      map((paramMap): [string, number] => [paramMap.get('id'), parseInt(paramMap.get('refCode'))]),
      tap(([masterId, refCode]) => refCode ? this.trackingService.setMasterRefCode(refCode) : null),
      switchMap(([masterId, refCode]): Observable<string> =>
        masterId
          ? of(masterId)
          : this.masterListClient.getMasters(null, [refCode]).pipe(
            map(res => res[0].id)
          )
      ),
      switchMap((masterId) =>
        combineLatest([
          this.masterListClient.getMasters([masterId], null).pipe(map(res => res[0])),
          this.specialitiesDirectoryService.getSpecialities([masterId]).pipe(take(1)),
          this.authService.isAuthenticated
            ? this.masterListClient.calcPromos(masterId, this.authService.parsedToken?.userId, null)
            : Promise.resolve([]),
          this.authService.isAuthenticated
            ? this.clientsClient.getClient(this.authService.parsedToken?.userId, null)
            : Promise.resolve(null)
        ])),
      tap(([masterRes, specialities, calcPromos, clientRes]) => {
        this.trackingService.track(masterRes.id, null).then();

        this.masterIsFavorites = clientRes?.favoriteMasters?.some(f => f.masterId == masterRes.id) ?? false;

        if (this.cityStore.get() == undefined && masterRes.addresses?.length > 0) {
          this.cityStore.remember({
            id: masterRes.addresses[0]?.addressCityId,
            name: masterRes.addresses[0]?.addressCityName
          })
        }
      }),
      map(([masterRes, specialities, calcPromos, clientRes]) => {
        const vm = new Vm();
        vm.master = masterRes;
        vm.priceList = PriceListVm.PriceListVm.Parse(masterRes, specialities, calcPromos);
        this.vm = vm;
        return vm;
      }),
      tap((vm) =>
        this.metaTagsService.set(this, {
          title: vm?.master?.fullName,
          imageUrl: this.thumbnailPipe.transform(this.fileUrlPipe.transform(this.vm.master.avatarFileName)),
          url: `https://bk.style/masters/${vm.master.id}`,
          desc: this.specialitiesPipe.transform(vm.master),
        })
      ),
      switchMap(vm => this.loadPromos()),
      tap(() => this.cdr.detectChanges())
    ).subscribe();

    this.sb.sub = this.authService.parsedToken$.pipe(
      map(t => t?.userId),
      switchMap(id => id
        ? this.notificationClient.getActiveConnection(id)
        : of(null)
      ),
      tap(conn => this.botConnection = conn)
    ).subscribe();
  }

  public ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

  public async onBookClick(priceListItemVm: PriceListVm.PriceListItem = null): Promise<void> {

    if (this.vm.master.nearestDays.length == 0) {
      await this.router.navigate(['masters', this.vm.master.id, 'call-me'], {});
      return;
    }

    await this.router.navigate(['bookings', 'new'], {
      queryParams: {
        masterId: this.vm.master.id,
        saloonId: null,
        serviceTypeIds: [priceListItemVm?.serviceTypeId]
      }
    });
  }

  public async onCallMeClick() {
    if (!this.authService.isAuthenticated) {
      const urlTree = this.router.createUrlTree(['/sign-in'], {
        queryParams: {backUrl: this.router.url}
      });

      await this.router.navigateByUrl(urlTree, {replaceUrl: true});
      return;
    }

    let message = this.authService.isAuthenticated ? 'Закажите звонок и мастер вам перезвонит' : 'Оставьте номер и мастер вам перезвонит';
    let submitButton = this.authService.isAuthenticated ? 'Заказать звонок' : 'Оставить номер';

    const alert = await this.alertController.create({
      message: message,
      cssClass: 'center',
      buttons: [
        {
          text: submitButton,
          role: 'ok'
        }, {
          text: 'Отмена',
          role: 'cancel'
        }
      ]
    });

    await alert.present();

    const role = (await alert.onDidDismiss()).role;

    if (role == 'ok') {
      if (!this.authService.isAuthenticated) {
        const urlTree = this.router.createUrlTree(['/sign-in'], {
          queryParams: {backUrl: this.router.url}
        });

        await this.router.navigateByUrl(urlTree, {replaceUrl: true});
        return;
      }

      let req = {masterId: this.vm.master.id, clientId: this.authService.parsedToken.userId} as CreateCallbackRequestReq;
      await this.notificationClient.createCallbackRequest(req)
        .subscribe(async () => {
          await this.toastService.success('Мы отправили мастеру сообщение с вашим номером.');
        }, async () => {
          await this.toastService.warning('Вы уже отправляли мастеру сообщение');
        });
    }
  }

  public async onAddressClick(address): Promise<void> {
    let options = {
      component: AddressInfoComponent,
      initialBreakpoint: undefined,
      breakpoints: undefined,
      componentProps: {
        title: this.vm.master.fullName,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        addressSaloonId: address.addressSaloonId,
        addressSaloonType: address.addressSaloonType,
        addressSaloonName: address.addressSaloonName,
        addressLongitude: address.addressLongitude,
        addressLatitude: address.addressLatitude,
        addressHowToGetText: address.howToGetText,
        addressHowToGetImagesNames: address.howToGetImagesNames,
      }
    };

    const addressInfoModal = await this.modalCtrl.create(options);

    await addressInfoModal.present();
  }

  public ifNullOrEmptyOrWhiteSpace(str: string): boolean {
    return str == null || str.trim().length == 0;
  }

  public async onNewFeedbackClick(): Promise<void> {
    const alert = await this.alertController.create({
      message: new IonicSafeString('<p>Клиенты могут оставлять отзывы только на свои записи.<p/><p>Перейти к вашим записям?<p/>'),
      cssClass: 'center',
      buttons: [
        {
          text: 'Да',
          role: 'ok'
        }, {
          text: 'Нет',
          role: 'cancel'
        }
      ]
    });

    await alert.present();

    const role = (await alert.onDidDismiss()).role;

    if (role == 'ok') {
      await this.router.navigate(['bookings'], {
        queryParams: {
          tab: 'others'
        }
      });
    }
  }

  public async onInfoClick(): Promise<void> {
    await this.router.navigate(['/masters', this.vm.master?.id, 'info'], {
      relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
    });
  }

  public async onHistoryClick(): Promise<void> {
    await this.router.navigate(['/masters', this.vm?.master?.id, 'history'], {
      relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
    });
  }

  public async onAddToBkClick(): Promise<void> {
    if (this.authService.isAuthenticated) {
      let req = {
        masterId: this.vm.master.id,
        clientId: this.authService.parsedToken?.userId
      } as ClientsClient.AddFavoriteMasterReq;

      await firstValueFrom(this.clientsClient.addFavoriteMaster(req))
        .then(() => {
          this.masterIsFavorites = true;
          this.cdr.markForCheck();
        });

    } else {
      let url = this.router.url;
      await this.router.navigate(['sign-in'], {queryParams: {backUrl: url}}).then();
    }

    this.eventBus.cast('favorite-masters:add');
  }

  public async onDeleteBkClick(): Promise<void> {
    let req = {
      masterId: this.vm.master.id,
      clientId: this.authService.parsedToken.userId,
    } as ClientsClient.DeleteFavoriteMasterReq;

    await firstValueFrom(this.clientsClient.deleteFavoriteMaster(req))
      .then(() => {
        this.masterIsFavorites = false;
        this.cdr.markForCheck();
      });

    this.eventBus.cast('favorite-masters:remove');
  }

  public async loadPromos(): Promise<void> {
    if (this.vm.master == null) {
      return;
    }

    let promise = Promise.all([
      this.authService.isAuthenticated
        ? this.masterListClient.calcPromos(this.vm.master.id, this.authService.parsedToken?.userId, null).toPromise()
        : Promise.resolve(null),
      this.authService.isAuthenticated
        ? this.bookingClient.getBookings(this.authService.parsedToken?.userId, this.vm.master.id, true, true, 0, 0).toPromise()
        : Promise.resolve(null),
    ]).then(async ([calcPromos, bookings]) => {
      let resPromo: PromoResVm[] = [];
      this.calcPromos = calcPromos;

      if (calcPromos == null) {
        resPromo.push(...this.vm.master.promos.map(p => p as PromoResVm));
      } else {
        this.vm.master.promos.forEach(p => {
          let promoVm = p as PromoResVm;
          let exist = calcPromos.some(calcPromo => calcPromo.promo.id == p.id);
          if (exist == true) {
            promoVm.status = "allow";
          } else {
            let used = bookings.bookings.some(b => b.items.some(i => i.promoId == p.id));

            if (used) {
              promoVm.status = "used";
            } else {
              promoVm.status = "lock";
            }
          }

          resPromo.push(promoVm);
        });
      }

      let now = new Date();
      this.promos = resPromo.filter(p => (p.startTimeOffset < now && p.endTimeOffset > now) || p.status == 'allow' || hasSameDate(now, p.startTimeOffset) || hasSameDate(now, p.endTimeOffset));
    });

    return await promise;
  }

  public trackById(index, item: any): any {
    return item.id;
  }
}
