<ion-header>
  <ion-toolbar>
    <ion-title>
      <ion-searchbar mode="ios" [formControl]="searchControl" placeholder="Город"></ion-searchbar>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content forceOverscroll="true">

  <div class="content-section">
    <div class="cards-list">
      <div class="card padding">
        <ion-list lines="full">
          <ion-item *ngFor="let city of cities$ | async" (click)="onCityClick(city)" button="true" detail="false">
            {{city.name}}
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>

</ion-content>
