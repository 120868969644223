import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListComponent} from "./list/list.component";
import {ClientComponent} from "./client/client.component";
import {NewComponent} from "./new/new.component";
import {BookingsComponent} from "./bookings/bookings.component";

const routes: Routes = [
  {
    path: '',
    component: ListComponent,
    pathMatch: 'prefix',
  },
  {
    path: 'new',
    component: NewComponent,
    pathMatch: 'prefix',
  },
  {
    path: ':id',
    component: ClientComponent,
    pathMatch: 'prefix',
  },
  {
    path: ':id/bookings',
    component: BookingsComponent,
    pathMatch: 'prefix',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule {
}
