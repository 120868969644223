import {NgModule} from '@angular/core';

import {BcCommonModule} from "../bc-common.module";

import {InstallRoutingModule} from './install-routing.module';

import {Step1Component} from "./step1/step1.component";
import {Step2Component} from "./step2/step2.component";


@NgModule({
  declarations: [
    Step1Component,
    Step2Component
  ],
    imports: [
        BcCommonModule,
        InstallRoutingModule,
    ]
})
export class InstallModule {
}
