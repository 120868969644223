import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {filter, firstValueFrom, of} from 'rxjs';
import {NgEventBus} from "ng-event-bus";
import {BookingWizardController, BookingWizardSettings} from "../controller";
import {ActivatedRouteFixService} from "../../utils/activated-route-fix.service";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {AuthService} from "../../security/auth.service";
import {ClientsClient} from "../../api-clients/clients/clients";
import {MasterListClient} from "../../api-clients/master-list/clients";
import {SaloonsClient} from "../../api-clients/saloon/clients";
import {BookingClient, MoveBookingReq} from "../../api-clients/booking/clients";
import {ClientVm} from "../../client/clients-vm";
import {removeTimeZoneOffset} from "../../date-processing/functions";
import {SubscriptionsBag} from "../../utils/subscriptions-bag";
import {delay} from "rxjs/operators";
import {LoadingController} from "@ionic/angular";
import {BookingWaiter} from "../../utils/booking-waiter";

@Component({
  selector: 'lib-move-book',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss'],
  host: {'class': 'ion-page'},
  providers: [BookingWizardController]
})
export class MoveComponent implements OnInit, OnDestroy {

  private sb = new SubscriptionsBag();

  private async goToTimeSlots(): Promise<void> {
    await this.router.navigate(
      ['time-slots'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  private async goToSignIn(): Promise<void> {
    await this.router.navigate(
      ['sign-in'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  private async goToClient(): Promise<void> {
    await this.router.navigate(
      ['client'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  private async goToCheck(): Promise<void> {
    await this.router.navigate(
      ['check'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  private async goToConfirmation(): Promise<void> {
    await this.router.navigate(
      ['confirmation'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  public constructor(
    private wizardSettings: BookingWizardSettings,
    private wizardController: BookingWizardController,
    private eventBus: NgEventBus,
    private router: Router,
    private _loadingController: LoadingController,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private applicationIdService: ApplicationIdService,
    private authService: AuthService,
    private clientsClient: ClientsClient,
    private masterListClient: MasterListClient,
    private saloonClient: SaloonsClient,
    private bookingClient: BookingClient,
    private bookingWaiter: BookingWaiter,
  ) {
  }

  public ngOnInit(): void {
    this.wizardSettings.fillByQueryParamMap(this.activatedRoute.snapshot.queryParamMap);

    this.wizardController.init(async () => {

      const bookingId = this.activatedRoute.snapshot.paramMap.get('id');

      if (bookingId == null) {
        throw new Error('Base booking is required');
      }

      const baseBooking = await this.bookingClient.getBooking(bookingId).toPromise();
      this.wizardController.setBaseBooking(baseBooking);

      this.wizardSettings.baseBookingId = bookingId;
      this.wizardSettings.clientId = this.wizardController.baseBooking.clientId;
      this.wizardSettings.masterId = this.wizardController.baseBooking.masterId;
      this.wizardSettings.saloonId = this.wizardController.baseBooking.addressSaloonId;
      this.wizardSettings.serviceTypeIds = this.wizardController.baseBooking.items.map(i => i.serviceTypeId);

    });

    this.sb.sub = this.wizardController.servicesData$.subscribe(async x => {
      if (!x) {
        return;
      }

      await this.goToTimeSlots();
    });

    this.sb.sub = this.wizardController.timeSlotData$.subscribe(async timeSlotData => {
      if (!timeSlotData) {
        return;
      }

      if (!this.wizardSettings.clientId) {

        if (this.applicationIdService.applicationId === "client") {
          if (this.authService.isAuthenticated) {
            this.wizardSettings.clientId = this.authService.parsedToken.userId;
          } else {
            await this.goToSignIn();
            return;
          }
        }

        if (this.applicationIdService.applicationId === "master") {
          await this.goToClient();
          return;
        }

        if (this.applicationIdService.applicationId === "saloon") {
          await this.goToClient();
          return;
        }

      }

      if (this.applicationIdService.applicationId === "client") {
        const clientRes = await firstValueFrom(this.clientsClient.getClient(this.wizardSettings.clientId, null));
        this.wizardController.setClientVm(ClientVm.ParseFromClient(clientRes));
      }

      if (this.applicationIdService.applicationId === "master") {
        const res = await firstValueFrom(this.masterListClient.getClients(
          this.wizardController.baseBooking.masterId,
          null,
          [this.wizardSettings.clientId],
          0,
          1,
          true
        ));

        this.wizardController.setClientVm(ClientVm.ParseFromMaster(res.clients[0]));
      }

      if (this.applicationIdService.applicationId === "saloon") {
        const res = await firstValueFrom(this.saloonClient.getClients(
          this.wizardController.timeSlotData.address.addressSaloonId,
          null,
          [this.wizardSettings.clientId],
          0,
          1,
          true
        ));

        this.wizardController.setClientVm(ClientVm.ParseFromSaloon(res.clients[0]));
      }

      await this.goToCheck();
    });

    this.sb.sub = this.wizardController.confirmed$
      .pipe(filter(x => !!x))
      .subscribe(async () => {
        const loading = await this.createAndShowLoading("Перенос записи");

        const req = new MoveBookingReq();
        req.bookingId = this.wizardController.baseBooking.id;

        req.newMasterId = this.wizardController.timeSlotData.master.id;

        req.newStartTime = removeTimeZoneOffset(this.wizardController.timeSlotData.timeSlot.startTime);

        req.newPriceListItemsIds = this.wizardController.servicesData.serviceItems
          .map(i => this.wizardController.timeSlotData.master.priceList.priceListItems.find(ii => ii.serviceType.id == i.serviceTypeId))
          .filter(i => !!i)
          .map(i => i.id);

        req.newServiceDurationInMinutes = this.wizardController.servicesData.durationInMinutes;
        req.newRestInMinutes = this.wizardController.servicesData.restInMinutes;

        req.withSequence = this.wizardSettings.withSequence;

        req.notificationRequired = this.wizardController.notificationRequired || false;
        req.reminderBufferInMinutes = this.wizardController.reminderTimeInMinutes || null;

        await firstValueFrom(this.bookingClient.moveBooking(req));

        // Чтобы эвенты на беке успели отработать
        await this.bookingWaiter.wait(
          this.wizardController.timeSlotData.master.id,
          this.wizardController.clientVm.id,
          req.newStartTime
        );

        this.eventBus.cast('booking:moved');

        this.wizardController.dispose();
        await this.closeLoading(loading);
        await this.goToConfirmation();
      });

  }

  private async createAndShowLoading(text: string = null): Promise<HTMLIonLoadingElement> {
    const loading = await this._loadingController.create({
      message: text || 'Загрузка',
      backdropDismiss: false,
    });

    await loading.present();

    return loading;
  }

  private async closeLoading(loading: HTMLIonLoadingElement): Promise<void> {
    await this._loadingController.dismiss(loading);
  }

  public ngOnDestroy() {
    this.wizardSettings.clear();
    this.sb.unsubscribeAll();
  }

}
