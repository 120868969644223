import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BookingWizardController, BookingWizardSettings} from "../../controller";
import {SignInSettings} from "../../../sign-in/signInSettings";
import {ActivatedRouteFixService} from "../../../utils/activated-route-fix.service";
import {AuthService} from "../../../security/auth.service";
import {SubscriptionsBag} from "../../../utils/subscriptions-bag";
import {ClientVm} from "../../../client/clients-vm";
import {ClientsClient} from "../../../api-clients/clients/clients";
import {filter, firstValueFrom} from "rxjs";
import {NavigationService} from "../../../utils/navigation.service";
import {NgEventBus} from "ng-event-bus";

@Component({
  selector: 'lib-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [SignInSettings]
})
export class SignInComponent implements OnInit, OnDestroy {

  private sb = new SubscriptionsBag();

  constructor(
    private _wizardSettings: BookingWizardSettings,
    private _wizardController: BookingWizardController,
    private _signInSettings: SignInSettings,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _activatedRouteFix: ActivatedRouteFixService,
    private _navigationService: NavigationService,
    private _authService: AuthService,
    private _clientsClient: ClientsClient,
    private _eventBus: NgEventBus,
  ) {
  }

  public async ngOnInit(): Promise<void> {

    if (this._wizardController.disposed || !this._wizardController.timeSlotData) {
      await this._navigationService.goBack();
      return;
    }

    this._signInSettings.role = 'Client';

    this._signInSettings.backUrl = this._router.createUrlTree(
      ['../check'],
      {
        relativeTo: this._activatedRouteFix.getActivatedRoute(this._activatedRoute)
      }).toString();
  }

  ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

}
