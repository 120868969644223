import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-add-days-banner',
  templateUrl: './add-days.component.html',
  styleUrls: ['./add-days.component.scss'],
})
export class AddDaysComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
