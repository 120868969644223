import {ErrorHandler, Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {RefreshService} from "./refresh.service";
import {AlertController} from "@ionic/angular";
import {NetworkError} from "./network.error";

import * as Sentry from "@sentry/angular-ivy";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  private _alert: HTMLIonAlertElement = null;

  public constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private refreshService: RefreshService,
    private alertController: AlertController
  ) {
    super();

    this.refreshService.refreshAndResume$.subscribe(() => {
      this._alert?.dismiss().then();
      this._alert = null;
    });
  }

  handleError(error: any): void {

    super.handleError(error);

    if (error instanceof NetworkError) {
      return;
    }

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this._alert) {

      this.alertController.create({
        message: 'Ошибка :(',
        backdropDismiss: true,
        cssClass: 'center',
        buttons: [
          {
            text: 'Обновить страницу',
            handler: () => {
              this._alert?.dismiss().then();
              this._alert = null;
              window?.location?.reload();
            }
          },
        ]
      }).then(alert => {
        if (!alert) { // При ssr алерта нет, например
          return Promise.resolve();
        }

        if (!this._alert) {
          this._alert = alert;
        } else {
          return Promise.resolve();
        }

        this._alert.onWillDismiss().then(() => this._alert = null);
        return this._alert.present();
      });
    }

    if (window?.location?.href && window?.location?.href?.includes('localhost') == false) {
      Sentry.captureException(error);
    }
  }
}
