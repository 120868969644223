<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Скидки и акции</ion-title>
    <ion-buttons slot="end">
      <ion-button routerLink="new">
        <ion-icon name="add-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <lib-card-spinner *ngIf="this.processing == true"></lib-card-spinner>
    <div class="card padding ion-text-center" *ngIf="this.promos?.length == 0 && this.processing == false">Пусто</div>
    <ng-container *ngIf="this.promos?.length != 0 && this.processing == false">
      <div class="cards-list">
        <div class="card padding">
          <ion-list class="colored-primary" lines="none">
            <ion-item *ngFor="let promo of this.promos" class="promo" detail="false" button="true"
                      [routerLink]="promo.id">
              <ion-label>
                <div>{{promo.title}}</div>
                <div class="info">
                  <span class="discount">
                    {{promo.isPercentValue == true ? '- ' + promo.value + ' %' : '- ' + promo.value + ' BYN'}}
                  </span>
                  <span class="dates">
                    <span *ngIf="promo.startTimeOffset">
                      c {{promo.startTimeOffset | moment:'D MMM'}}
                    </span>
                    <span *ngIf="promo.endTimeOffset">
                      до {{promo.endTimeOffset | moment:'D MMM'}}
                    </span>
                  </span>
                </div>
              </ion-label>
              <ion-buttons slot="end">
                <ion-button (click)="onDeleteClick(promo, $event)" size="small">
                  <ion-icon name="close-circle-outline" slot="icon-only"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-list>
        </div>
      </div>
    </ng-container>
  </div>
</ion-content>

