import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from "@angular/router";
import {IonDatetime, ModalController} from "@ionic/angular";
import {ModalComponentBase} from "../../../modal-window/modal-window";

@Component({
  selector: 'lib-date-time-modal',
  templateUrl: './date-time-modal.component.html',
  styleUrls: ['./date-time-modal.component.scss'],
})
export class DateTimeModalComponent extends ModalComponentBase implements OnInit {
  @ViewChild("timePicker")
  public timePicker: IonDatetime;

  @ViewChild("datePicker")
  public datePicker: IonDatetime;

  @Input()
  public presentation: "date" | "time";

  @Input()
  public value: string;

  @Input()
  public minDate: string;

  @Input()
  public minuteValues: number[] = null;

  @Input()
  public hourValues: number[] = null;

  constructor(
    modalController: ModalController,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location) {
    super(modalController, router, activatedRoute, location);
  }

  public ngOnInit(): void {
  }

  public async onSubmitClick(): Promise<void> {
    if (this.presentation == "time") {
      await this.timePicker.confirm();
      await super.dismiss(this.timePicker.value);
    }
    if (this.presentation == "date") {
      await this.datePicker.confirm();
      await super.dismiss(this.datePicker.value);
    }
  }

  public async onCancelClick(): Promise<void> {
    await super.dismiss();
  }
}
