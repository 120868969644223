import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {FormControl, Validators} from "@angular/forms";
import {AuthenticationClient, ChangePhoneStep1Req, ChangePhoneStep2Req} from "../../api-clients/authentication/clients";
import {AuthService} from "../../security/auth.service";
import {ToastService} from "../../toast/toast.service";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {startWith} from "rxjs/operators";

@Component({
  selector: 'app-phone-change-popup',
  templateUrl: './phone-change-popup.component.html',
  styleUrls: ['./phone-change-popup.component.scss'],
})
export class PhoneChangePopupComponent extends ModalComponentBase implements OnInit {

  @Input()
  public title: string = "Введите что-нибудь";

  public loading: boolean = false;
  public step: "phone" | "code" = "phone";

  public phoneControl: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(12),
    Validators.maxLength(15),
    Validators.pattern("^([+][0-9]{12,15})$")
  ]);
  public codeControl: FormControl = new FormControl(null, [
    Validators.minLength(6)
  ]);

  constructor(
    modalController: ModalController,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location,
    private authClient: AuthenticationClient,
    private authService: AuthService,
    private toastService: ToastService,
    public _applicationIdService: ApplicationIdService,
  ) {
    super(modalController, router, activatedRoute, location);
  }

  ngOnInit() {
  }

  // ngAfterViewInit(): void {
  //   this.swiperContainer?.swiper.lockSwipes(true).then();
  // }

  public async onSubmitPhoneClick(): Promise<void> {
    if (!this.phoneControl.valid) {
      return;
    }

    const phoneNumber = this.phoneControl.value;

    await this.authClient.changePhoneNumberStep1({
      userId: this.authService.parsedToken?.userId,
      phone: phoneNumber
    } as ChangePhoneStep1Req).pipe(
      startWith(() => this.loading = true),
    ).toPromise().then(async () => {
      this.step = "code";
      // await this.swiperContainer?.swiper.lockSwipes(false);
      // await this.swiperContainer?.swiper.slideNext();
      // await this.swiperContainer?.swiper.lockSwipes(true);
    })
      .catch(err => {
        this.toastService.error(err.result.messages[0]);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public async onSubmitCodeClick(): Promise<void> {
    const phoneNumber = this.phoneControl.value;
    const code = this.codeControl.value;
    let phoneChanged = false;

    await this.authClient.changePhoneNumberStep2({
      userId: this.authService.parsedToken?.userId,
      phone: phoneNumber,
      code: code
    } as ChangePhoneStep2Req).toPromise()
      .then(async () => {
        await this.toastService.success("Телефон изменен");
        phoneChanged = true;

      })
      .catch(async err => {
        await this.toastService.error(err.result.messages[0]);
      });

    await super.dismiss(phoneChanged ? {newPhone: phoneNumber} : null);
  }

  public async onCloseClick(): Promise<void> {
    await super.dismiss();
  }

  // slideDidLoad(event) {
  //   this.swiperContainer.swiper.update().then();
  // }

  inputOnlyDigits($event: any) {
    const pattern = /[0-9+]/;
    let inputChar = String.fromCharCode($event.charCode);
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
    }
  }

  onPaste($event: ClipboardEvent) {
    let pastedText = $event.clipboardData.getData("text");
    const pattern = new RegExp("^([+][0-9]{12,15})$");
    let test = pattern.test(pastedText);
    if (!test) {
      let trimmedText = pastedText.replace(/[^0-9+]/g, '');
      this.phoneControl.setValue(trimmedText);
      $event.preventDefault();
    }
  }
}
