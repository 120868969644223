<form [formGroup]="this.settingsForm" class="form-meta">
  <div class="cards-list">
    <div class="card padding">
      <ion-list lines="none" class="classic">
        <h2 class="ion-no-margin">Настройки контактов</h2>
        <ion-list-header>
          <h3 class="ion-no-margin" style="line-height: 30px;">Клиент записался сам</h3>
        </ion-list-header>
        <ion-item>
          <ion-text>Салон видит контакты</ion-text>
          <ion-toggle *ngIf="this.changeMode == true" slot="end"
                      formControlName="saloonSeesContactsClientReg"></ion-toggle>
          <ion-text *ngIf="this.changeMode == false"
                    slot="end">{{this.YesNo[this.settingsForm.controls.saloonSeesContactsClientReg.value]}}</ion-text>
        </ion-item>
        <ion-item>
          <ion-text>Мастер видит контакты</ion-text>
          <ion-toggle *ngIf="this.changeMode == true" slot="end"
                      formControlName="masterSeesContactsClientReg"></ion-toggle>
          <ion-text *ngIf="this.changeMode == false"
                    slot="end">{{this.YesNo[this.settingsForm.controls.masterSeesContactsClientReg.value]}}</ion-text>
        </ion-item>
        <ion-list-header>
          <h3 class="ion-no-margin" style="line-height: 30px;">Клиента записал салон</h3>
        </ion-list-header>
        <ion-item>
          <ion-text>Мастер видит контакты</ion-text>
          <ion-toggle *ngIf="this.changeMode == true" slot="end"
                      formControlName="masterSeesContactsSaloonReg"></ion-toggle>
          <ion-text *ngIf="this.changeMode == false"
                    slot="end">{{this.YesNo[this.settingsForm.controls.masterSeesContactsSaloonReg.value]}}</ion-text>
        </ion-item>
        <ion-list-header>
          <h3 class="ion-no-margin" style="line-height: 30px;">Клиента записал мастер</h3>
        </ion-list-header>
        <ion-item>
          <ion-text>Салон видит контакты</ion-text>
          <ion-toggle *ngIf="this.changeMode == true" slot="end"
                      formControlName="saloonSeesContactsMasterReg"></ion-toggle>
          <ion-text *ngIf="this.changeMode == false"
                    slot="end">{{this.YesNo[this.settingsForm.controls.saloonSeesContactsMasterReg.value]}}</ion-text>
        </ion-item>
      </ion-list>
    </div>
    <div class="card padding">
      <ion-list lines="none" class="classic">
        <h2 class="ion-no-margin">Другие настройки</h2>
        <ion-item [button]="this.changeMode">
          <ion-label style="min-height: 0;">Кто оплачивает привлечение клинетов</ion-label>
          <ion-text *ngIf="this.changeMode == false"
                    style="padding-top: 8px; padding-bottom: 8px;">{{this.WhoBookingsPays[this.settingsForm.controls.whoPays.value]}}</ion-text>
          <ion-select *ngIf="this.changeMode == true" formControlName="whoPays" interface="action-sheet"
                      cancel-text="Отмена">
            <ion-select-option *ngFor="let item of pickerItems" [value]="item.id">{{item.name}}</ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-text>Показывать контакты мастера</ion-text>
          <ion-toggle *ngIf="this.changeMode == true" slot="end"
                      formControlName="showMasterContacts"></ion-toggle>
          <ion-text *ngIf="this.changeMode == false"
                    slot="end">{{this.YesNo[this.settingsForm.controls.showMasterContacts.value]}}</ion-text>
        </ion-item>
      </ion-list>
    </div>
  </div>
</form>
