import {Component, OnInit} from '@angular/core';
import {
  BookingClient,
  BookingRes,
} from "../../../api-clients/booking/clients";
import {FormControl} from "@angular/forms";
import {
  CalcPromosRes,
  HotWindowRes,
  MasterListClient,
  MasterRes,
  PromoRes, PromoTypeRes
} from "../../../api-clients/master-list/clients";
import {ToastService} from "../../../toast/toast.service";
import {ApplicationIdService} from "../../../application-id/application-id.service";
import {finalize, map, mergeMap, startWith, switchMap, take} from "rxjs/operators";
import {addMinutes, dateStringToMinutes, hasSameDate} from "../../../date-processing/functions";
import {CalendarDay, IDaysDataProvider} from "../../../date-processing/calendar/calendar.component";
import {ActionSheetOptions} from "@ionic/core/dist/types/components/action-sheet/action-sheet-interface";
import {BehaviorSubject, combineLatest, forkJoin, Observable, of} from "rxjs";
import {BookingWizardController, BookingWizardSettings, ServiceItem, TimeSlotData} from "../../controller";
import {NavigationService} from "../../../utils/navigation.service";
import {AlertController, PickerController} from "@ionic/angular";
import {AuthService} from "../../../security/auth.service";
import {SpecialitiesDirectoryService} from "../../../directories/specialities-directory.service";
import {PriceListItem, PriceListVm} from "../../../price-list/price-list-vm";
import {PromoTypeEnum} from "../../../utils/promo-type.enum";
import {convertDays, DaysVm, DayVm, TimeSlotVm} from "../../../utils/DayVm";

export class DaysDataProvider implements IDaysDataProvider {
  constructor(
    private _applicationIdService: ApplicationIdService,
    private _authService: AuthService,
    private _masterId: string,
    private _saloonId: string,
    private _clientId: string,
    private _serviceTypeIds: string[],
    private _serviceItems: ServiceItem[],
    private _durationInMinutes: number,
    private _restInMinutes: number,
    private _date: Date,
    private _bookingToExclude: BookingRes,
    private _excludeWithSequence: boolean,
    private _bookingClient: BookingClient,
    private _masterListClient: MasterListClient,
    private _specialitiesDirectoryService: SpecialitiesDirectoryService,
    private _calcPromos: CalcPromosRes[]
  ) {
  }

  private _loadingSubj: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private frequencySubj = new BehaviorSubject<number>(null);
  private addressesSubj = new BehaviorSubject<string>(null);
  private timeStepInMinutesSubj = new BehaviorSubject<number>(null);

  public get loading(): boolean {
    return this._loadingSubj.value;
  }

  public get loading$(): Observable<boolean> {
    return this._loadingSubj.asObservable();
  }

  public set frequency(value: number) {
    this.frequencySubj.next(value);
  }

  public set addresses(value: string) {
    this.addressesSubj.next(value);
  }

  public set timeStepInMinutes(value: number) {
    this.timeStepInMinutesSubj.next(value);
  }

  public getDaysData(from: Date, to: Date): Observable<CalendarDay[]> {
    this.loaderOn();

    return combineLatest([
      this.getMasters(this._masterId, this._saloonId, this._serviceTypeIds),
      this.frequencySubj,
      this.addressesSubj,
      this.timeStepInMinutesSubj,
    ]).pipe(
      switchMap(([masters, freq, address, timeStep]) => {

        if (!masters || masters.length == 0) {
          this.loaderOff();
          return of([]);
        }

        return combineLatest(
          masters
            .map((m): [MasterRes, number, number] => {
              const relatedPriceListItems = this._serviceTypeIds
                .map(id => m.priceList.priceListItems.find(i => i.serviceType.id == id))
                .filter(i => i);

              let durationInMinutes = this._durationInMinutes;
              let restInMinutes = this._restInMinutes;

              if (durationInMinutes === null || restInMinutes === null) {
                durationInMinutes = relatedPriceListItems
                  .map(i => i.durationInMinutesMax)
                  .reduce((res, curr) => res + curr, 0);

                restInMinutes = relatedPriceListItems
                  .map(i => i.requiredRestInMinutes)
                  .reduce((res, curr) => curr > res ? curr : res, 0);
              }

              // Если в прайсе есть не все нужные услуги, то по этому мастеру не ищем тайм слоты
              if (relatedPriceListItems.length < this._serviceTypeIds.length) {
                return null;
              }

              return [m, durationInMinutes, restInMinutes];
            })
            .filter(x => !!x)
            .map(([m, durationInMinutes, restInMinutes]) => {
              return this._bookingClient.getTimeSlots(
                m.id,
                m.priceList?.priceListItems
                  .filter(pli => this._serviceTypeIds.indexOf(pli.serviceType.id) >= 0)
                  .map(pli => pli.id),
                freq ||
                (
                  this._excludeWithSequence
                    ? this._bookingToExclude?.sequenceFrequency
                    : null
                ),
                durationInMinutes,
                restInMinutes,
                this._bookingToExclude?.id,
                this._excludeWithSequence,
                timeStep,
                this._applicationIdService.applicationId == 'master' || this._applicationIdService.applicationId == 'saloon'
              ).pipe(
                mergeMap(timeSlotsRes => {
                  return forkJoin([
                    this._specialitiesDirectoryService.getSpecialities([m.id]).pipe(take(1)),
                    of(timeSlotsRes)
                  ])
                }),
                map(([spec, timeSlotsRes]) => {
                  let timeSlotsVm = convertDays(timeSlotsRes.days);

                  let priceListVm = PriceListVm.Parse(m, spec, this._calcPromos);

                  const relatedPriceListItems = priceListVm.getAllItems()
                    .filter(pli => this._serviceTypeIds.indexOf(pli.serviceTypeId) >= 0);

                  // Прикрепляем мастера к дню, для удобства
                  for (let day of timeSlotsVm.days) {
                    day.master = m;
                    day.durationInMinutes = durationInMinutes;
                    day.restInMinutes = restInMinutes;
                    day.priceMin = relatedPriceListItems
                      .map(pli => pli.priceMin)
                      .reduce((sum, curr) => sum + curr, 0);
                    day.priceMax = relatedPriceListItems
                      .map(pli => pli.priceMax)
                      .reduce((sum, curr) => sum + curr, 0);

                    // let priceMinWithDiscountSum = 0;
                    // let priceMaxWithDiscountSum = 0;
                    // for (let item of relatedPriceListItems) {
                    //   priceMinWithDiscountSum += item.priceMinWithDiscount != null ? item.priceMinWithDiscount : item.priceMin;
                    //   priceMaxWithDiscountSum += item.priceMaxWithDiscount != null ? item.priceMaxWithDiscount : item.priceMax;
                    // }
                    // day.priceMinWithDiscount = Number(priceMinWithDiscountSum.toFixed(2));
                    // day.priceMaxWithDiscount = Number(priceMaxWithDiscountSum.toFixed(2));
                  }

                  //Оставляем только дни без проблем для клиент апп
                  if (this._applicationIdService.applicationId == "client") {
                    for (let day of timeSlotsVm.days) {
                      day.timeSlots = day.timeSlots.filter(s => s.fatalProblems.length == 0);
                    }
                    timeSlotsVm.days = timeSlotsVm.days.filter(d => d.timeSlots.length > 0);
                  }

                  if (this._applicationIdService.applicationId == 'client' && this._authService.isAuthenticated){
                    timeSlotsVm.days = CalcDaysPromos(timeSlotsVm.days, relatedPriceListItems, this._calcPromos.filter(c => c.promo.masterId == this._masterId));

                    if (this._calcPromos.length) {
                      let hotWindowPromo =
                        this._calcPromos.find(c => c.promo.type.type == PromoTypeEnum.hotWindow) as CalcPromosRes;

                      if (hotWindowPromo != null) {
                        for (let day of timeSlotsVm.days) {
                          for (let slot of day.timeSlots) {
                            if (hotWindowPromo.promo.hotWindows.some(w => w.startTimeOffset <= slot.startTime &&
                              w.endTimeOffset >= addMinutes(slot.startTime, durationInMinutes + restInMinutes))) {
                              slot.hotWindow = true;
                            }
                          }
                        }
                      }
                    }
                  }

                  //Оставляем только дни с айдишкой выбранного салона
                  if (this._saloonId) {
                    timeSlotsVm.days = timeSlotsVm.days.filter(d => d.address.addressSaloonId == this._saloonId);
                  }

                  // Если мастер не основной, а добавочный, то нам нужны от него только дни, которые он работает в тех же студиях, что и основной искомый мастер
                  if (m.id != this._masterId) {
                    timeSlotsVm.days = timeSlotsVm.days.filter(d => m.addresses.some(a => !!a.addressSaloonId && a.addressSaloonId == d.address.addressSaloonId));

                    if (this._bookingToExclude) {
                      timeSlotsVm.days = timeSlotsVm.days
                        .filter(d =>
                          d.address.addressSaloonId == this._bookingToExclude.addressSaloonId &&
                          d.address.addressLine1 == this._bookingToExclude.addressLine1 &&
                          d.address.addressLine2 == this._bookingToExclude.addressLine2 &&
                          d.address.addressLatitude == this._bookingToExclude.addressLatitude &&
                          d.address.addressLongitude == this._bookingToExclude.addressLongitude
                        );
                    }

                  }

                  return timeSlotsVm;
                })
              );

            })
        ).pipe(
          map(timeSlotsResults => {
            let daysData: CalendarDay[] = [];

            for (let timeSlotsResult of timeSlotsResults) {
              for (let d of timeSlotsResult.days) {

                let calendarDay: CalendarDay = daysData.find(x => hasSameDate(x.date, d.startTime));

                if (!calendarDay) {
                  calendarDay = new CalendarDay();
                  calendarDay.data = [];
                  daysData.push(calendarDay);
                }

                calendarDay.date = d.startTime;
                calendarDay.data.push(d);
              }
            }

            for (let calendarDay of daysData) {
              calendarDay.style = (this._masterId && calendarDay.data.some(d => (d as any).master.id == this._masterId)) || !this._masterId
                ? "full-1"
                : "half-1";
            }

            return daysData;
          }),
          finalize(() => {
            this.loaderOff();
          })
        );
      }),
    );
  }

  public getMasters(masterId: string, saloonId: string, serviceTypeIds: string[]): Observable<MasterRes[]> {
    let masters$: Observable<MasterRes[]>;

    if (saloonId) {
      masters$ = this._masterListClient.searchMasters(
        null,
        null,
        [saloonId],
        null,
        null,
        null,
        serviceTypeIds,
        0,
        100,
        2,
        null,
        null
      );
    } else {
      masters$ = this._masterListClient
        .getMasters(
          [masterId],
          []
        )
        .pipe(
          map(masters => masters[0]),
          switchMap(master => {
            const saloonIds = master.addresses.map(a => a.addressSaloonId).filter(a => a);

            if (saloonIds.length) {
              return this._masterListClient.searchMasters(
                null,
                null,
                saloonIds,
                null,
                null,
                null,
                serviceTypeIds,
                0,
                100,
                2,
                null,
                null
              );
            } else {
              return of([master]);
            }
          })
        );
    }

    const res = masters$.pipe(
      map(masters => masters.sort((a, b) => {
        const x = a.id == masterId ? 0 : 1;
        const y = b.id == masterId ? 0 : 1;
        return x - y;
      })));

    return res;
  }

  private loaderOn(): void {
    this._loadingSubj.next(true);
  }

  private loaderOff(): void {
    this._loadingSubj.next(false);
  }
}

export class masterAddress {
  public addressLine1: string;
  public addressLine2: string;
}

@Component({
  selector: 'lib-time-slots',
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.scss'],
})
export class TimeSlotsComponent implements OnInit {
  public date: Date = null;

  public daysDataProvider: DaysDataProvider = null;

  public activatedDate: Date = null;
  public days: DayVm[] = null;

  public addressControl = new FormControl("any");
  public timeStepControl = new FormControl();

  public masterAddresses: masterAddress[] = [];

  public appId = this._applicationIdService.applicationId;

  public constructor(
    private _wizardSettings: BookingWizardSettings,
    private _wizardController: BookingWizardController,
    private _bookingClient: BookingClient,
    private _masterListClient: MasterListClient,
    private _toastService: ToastService,
    private _navigationService: NavigationService,
    private _applicationIdService: ApplicationIdService,
    private _pickerController: PickerController,
    private _alertController: AlertController,
    private _authService: AuthService,
    private _specialitiesDirectoryService: SpecialitiesDirectoryService
  ) {
  }

  async ngOnInit(): Promise<void> {

    if (this._wizardController.disposed || !this._wizardController.servicesData) {
      await this._navigationService.goBack();
      return;
    }

    this.date = this._wizardSettings.date || this._wizardController.baseBooking?.startTime;

    this.daysDataProvider = new DaysDataProvider(
      this._applicationIdService,
      this._authService,
      this._wizardSettings.masterId,
      this._wizardSettings.saloonId,
      this._wizardSettings.clientId,
      this._wizardController.servicesData.serviceTypeIds,
      this._wizardController.servicesData.serviceItems,
      this._wizardController.servicesData.durationInMinutes,
      this._wizardController.servicesData.restInMinutes,
      this.date,
      this._wizardController.baseBooking,
      this._wizardSettings.withSequence,
      this._bookingClient,
      this._masterListClient,
      this._specialitiesDirectoryService,
      this._wizardController.calcPromosAllMasters
    );

    this.daysDataProvider.frequency = this._wizardController.servicesData.frequency || null;


    const addresses$ = this.addressControl.valueChanges.pipe(
      startWith(this.addressControl.value),
      map(a => a || undefined)
    );

    const timeStepInMinutes$ = this.timeStepControl.valueChanges.pipe(
      startWith(this.timeStepControl.value),
      map(timeStep => dateStringToMinutes(timeStep))
    );

    addresses$.subscribe(address => {
      this.daysDataProvider.addresses = address;
    });

    timeStepInMinutes$.subscribe(timeStepInMinutes => {
      this.daysDataProvider.timeStepInMinutes = timeStepInMinutes;
    });
  }

  private async confirmProblems(slot: TimeSlotVm): Promise<boolean> {

    let message = "";
    let buttons = [];

    if (slot.fatalProblems.length > 0) {

      message = "<ul>";

      if (slot.fatalProblems.filter(p => p == 100).length == 1) {
        message += "<li>Все рабочие места заняты</li>";
      }

      message += "</ul>";

      buttons = [
        {text: "Понятно", role: 'no'}
      ];
    } else if (slot.problems.length > 0) {

      message = "<ul>";

      if (slot.problems.filter(p => p == 1).length == 1) {
        message += "<li>Разобьёт день</li>";
      }

      if (slot.problems.filter(p => p == 2).length == 1) {
        message += "<li>Неудобное время для маленькой услуги</li>";
      }

      if (slot.problems.filter(p => p == 3).length == 1) {
        message += "<li>Нарушит буфер записи</li>";
      }

      if (slot.problems.filter(p => p == 4).length == 1) {
        message += "<li>Превысит макс. записей в день</li>";
      }

      if (slot.problems.filter(p => p == 5).length == 1) {
        message += "<li>Не для всех повторений есть место</li>";
      }

      message += "</ul>";

      message += "<p>Всё равно записать?</p>";

      buttons = [
        {text: "Да", role: 'yes'},
        {text: "Нет", role: 'no'}
      ];
    } else {
      return true;
    }

    const alert = await this._alertController.create({
      header: "Конфликт",
      message: message,
      buttons: buttons
    });

    await alert.present();
    const res = await alert.onDidDismiss();

    return res.role == 'yes';
  }

  public async onSlotClick(master: MasterRes, slot: TimeSlotVm, address): Promise<void> {

    if (await this.confirmProblems(slot) == false) {
      return;
    }

    const result = new TimeSlotData();
    result.master = master;
    result.timeSlot = slot;
    result.address = address;

    this._wizardController.setTimeSlotData(result);
  }

  public async onDayActivated($event: CalendarDay): Promise<void> {
    this.activatedDate = $event?.date;
    const days = $event ? $event.data as DayVm[] : [];
    this.days = days;
  }

  public customActionSheetHeader(header: string): ActionSheetOptions {
    return {
      buttons: [],
      header: header,
    }
  }

  public isDiscountOnDay(day: DayVm): boolean {
    return this._applicationIdService.applicationId == 'client' &&
      (!day.timeSlots.some(s => s.priceMin == day.priceMin) ||
        !day.timeSlots.some(s => s.priceMax == day.priceMax));
  }
}

export function CalcDaysPromos(days: DayVm[], relatedPriceListItems: PriceListItem[], calcPromos: CalcPromosRes[]): DayVm[] {
  for (let day of days) {
    for (let slot of day.timeSlots) {
      slot.priceMin = 0;
      slot.priceMax = 0;

      for (let item of relatedPriceListItems) {
        let itemPromos: PromoRes[] = [];

        for (let calc of calcPromos) {
          if (calc.priceListItemId != item.id) {
            continue;
          }

          let promo = calc.promo;
          if (promo.type.type == PromoTypeEnum.hotWindow) {
            if (promo.hotWindows.some(w =>
              w.startTimeOffset <= slot.startTime &&
              w.endTimeOffset >= slot.endTime)) {
              itemPromos.push(promo);
            }

            continue;
          }

          itemPromos.push(promo);
        }

        if (itemPromos.length) {
          let priceMin = item.priceMin;
          let priceMax = item.priceMax;

          for (let promo of itemPromos) {
            let priceMinTmp;
            let priceMaxTmp;
            if (promo.isPercentValue) {
              priceMinTmp = item.priceMin - promo.value * item.priceMin / 100;
              priceMaxTmp = item.priceMax - promo.value * item.priceMax / 100;
            } else {
              priceMinTmp = item.priceMin - promo.value;
              if (priceMinTmp < 0) {
                priceMinTmp = 0;
              }
              priceMaxTmp = item.priceMax - promo.value;
              if (priceMaxTmp < 0) {
                priceMaxTmp = 0;
              }
            }

            priceMin = priceMin > priceMinTmp ? priceMinTmp : priceMin;
            priceMax = priceMax > priceMaxTmp ? priceMaxTmp : priceMax;
          }

          slot.priceMin += priceMin;
          slot.priceMax += priceMax;
        } else {
          slot.priceMin += item.priceMin;
          slot.priceMax += item.priceMax;
        }
      }
    }
  }

  return days;
}
