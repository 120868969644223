<ion-header class="hide-mobile">
  <ion-toolbar>
    <ion-title>Готово</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <div class="cards-list">
      <div class="card transparent-mobile padding">
        <div class="confirm-banner">
          <img src="assets/check_mark.svg" style="margin-right: 17px;">
          <span>Все получилось!</span>
        </div>
        <h3>
          Начать работу просто!
        </h3>
        <ul>
          <li>Отметьте рабочие дни</li>
          <li>Внесите записи</li>
        </ul>
      </div>

      <div class="hide-mobile">
        <ng-container *ngTemplateOutlet="next_button"></ng-container>
      </div>
    </div>

  </div>
</ion-content>

<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="next_button"></ng-container>
</ion-footer>

<ng-template #next_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block"
                [routerLink]="this._wizardSettings.scheduleUrl"
                replaceUrl="true">
      Вперёд!
    </ion-button>
  </div>
</ng-template>
