import {Injectable} from "@angular/core";

export class PriceListController {
  public constructor(
    public masterId: string,
    public saloonId: string,
  ) {
  }
}

export abstract class IPriceListControllerFactory {
  public abstract create(): PriceListController;
}

@Injectable()
export class PriceListControllerProvider {

  private created: PriceListController = null;

  public constructor(private factory: IPriceListControllerFactory) {
  }

  public provide(): PriceListController {
    const newCreated = this.factory.create();

    if (!this.created || this.created.masterId != newCreated.masterId) {
      this.created = newCreated;
    }

    return this.created;
  }
}
