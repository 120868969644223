import {Component, OnInit, signal} from '@angular/core';
import {PickerColumnOption} from "@ionic/core";
import {ModalController, PickerController} from "@ionic/angular";
import {
  CalcPromosRes,
  MasterListClient,
  PromoRes,
  PriceListItemRes, UnblockClientReq as UnblockMasterClientReq
} from "../../../api-clients/master-list/clients";
import {ClientNamePipe} from "../../../utils/client-name.pipe";
import {BookingClient, SuggestNotificationSettingsRes} from "../../../api-clients/booking/clients";
import {BookingWizardController, BookingWizardSettings, ServiceItem, TimeSlotData} from "../../controller";
import {ApplicationIdService} from "../../../application-id/application-id.service";
import {NavigationService} from "../../../utils/navigation.service";
import {TrackingService} from "../../../utils/tracking.service";
import {SaloonsClient, UnblockClientReq as UnblockSaloonClientReq} from "../../../api-clients/saloon/clients";
import {combineLatest, firstValueFrom, tap} from "rxjs";
import {ClientVm} from "../../../client/clients-vm";
import {ClientsClient} from "../../../api-clients/clients/clients";
import {AuthService} from "../../../security/auth.service";
import {TextAreaPopupComponent} from "../../../editors/text-area-popup/text-area-popup.component";
import {PromoTypeEnum} from "../../../utils/promo-type.enum";

@Component({
  selector: 'lib-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
})
export class CheckComponent implements OnInit {

  public get wc(): BookingWizardController {
    return this._wizardController;
  }

  public get timeSlotData(): TimeSlotData {
    return this.wc.timeSlotData;
  }

  public get selectedPriceListItems(): PriceListItemRes[] {
    return this.wc.timeSlotData.master.priceList?.priceListItems.filter(i => this.wc.servicesData.serviceTypeIds.find(stid => stid == i.serviceType.id));
  }

  public get minPrice(): number {
    return this.selectedPriceListItems.map(i => i.priceMin).reduce((prev, curr) => prev + curr, 0);
  }

  public get maxPrice(): number {
    return this.selectedPriceListItems.map(i => i.priceMax).reduce((prev, curr) => prev + curr, 0);
  }

  public get titleName(): string {
    if (this.applicationIdService.applicationId == "client") {
      return this.wc.timeSlotData.master.fullName;
    }
    if (this.applicationIdService.applicationId == "master" || this.applicationIdService.applicationId == "saloon") {
      return new ClientNamePipe().transform(this.wc.clientVm?.originalName, this.wc.clientVm.definedName);
    }

    return this.wc.timeSlotData.master.fullName;
  }

  public get endTime(): Date {
    const durationInMinutes = this.selectedPriceListItems
      .map(item => item.durationInMinutesMax)
      .reduce((sum, x) => sum + x, 0);

    const requiredRestInMinutes = this.selectedPriceListItems
      .map(item => item.requiredRestInMinutes)
      .reduce((sum, x) => sum + x, 0);

    const endTime = new Date(this.wc.timeSlotData.timeSlot.startTime.valueOf() + (durationInMinutes + requiredRestInMinutes) * 60 * 1000);

    return endTime;
  }

  public get reminderPickerOptions(): PickerColumnOption[] {
    return [
      {text: 'За 1 час', value: 60},
      {text: 'За 2 часа', value: 120},
      {text: 'За 3 часа', value: 180},
      {text: 'За 6 часов', value: 360},
      {text: 'За 12 часов', value: 720},
      {text: 'За 24 часа', value: 1440},
      {text: 'За 2 дня', value: 2880},
      {text: 'За 3 дня', value: 4320},
    ];
  }

  public masterNoteActivated: boolean = false;
  public reminderActivated: boolean = true;
  public reminderItemSelected: PickerColumnOption = null;
  public reminderItemSelectedIndex: number = null;
  public suggestNotificationSettings: SuggestNotificationSettingsRes;

  public constructor(
    public applicationIdService: ApplicationIdService,
    private _wizardSettings: BookingWizardSettings,
    private _wizardController: BookingWizardController,
    private _pickerController: PickerController,
    private _bookingClient: BookingClient,
    private _authService: AuthService,
    private _clientsClient: ClientsClient,
    private _masterListClient: MasterListClient,
    private _saloonsClient: SaloonsClient,
    private _navigationService: NavigationService,
    private _trackingService: TrackingService,
    private _modalController: ModalController,
  ) {

  }

  public async ngOnInit(): Promise<void> {

    if (this.wc.disposed || !this.wc.timeSlotData) {
      await this._navigationService.goBack(true);
      return;
    }

    // При анонимной записи, происходит вход, и клиент не сетается, надо его докачать
    if (this.applicationIdService.applicationId == 'client') {
      if (this.wc.clientVm == null) {
        const clientRes = await firstValueFrom(this._clientsClient.getClient(this._authService.parsedToken?.userId, null));
        this.wc.setClientVm(ClientVm.ParseFromClient(clientRes));
      }
    }

    // Трэкаем клиента, даже если он делал анонимную запись, здесь он уже будет виден точно
    if (this.applicationIdService.applicationId == 'client') {
      if (this.wc.timeSlotData?.address?.addressSaloonId) {
        await this._trackingService.track(
          null,
          this.wc.timeSlotData?.address?.addressSaloonId
        );
      } else {
        await this._trackingService.track(
          this.wc.timeSlotData?.master?.id,
          null
        );
      }
    }

    // Подгружаем рекомендованные настройки напоминаний и уведомлений. Они берутся с бэка по статистике прошлых записей.
    const reminderPickerOptions = this.reminderPickerOptions;
    firstValueFrom(this._bookingClient.suggestNotificationSettings(
      this.wc.timeSlotData.master.id,
      this.wc.clientVm.id,
      this.wc.servicesData.serviceTypeIds
    )).then((data) => {
      this.suggestNotificationSettings = data;

      const reminderPickerItemsSelected = reminderPickerOptions
        .sort((a, b) => a.value - b.value)
        .find(x => x.value >= data.reminderBufferInMinutes);

      if (reminderPickerItemsSelected) {
        this.reminderItemSelected = reminderPickerItemsSelected;
        this.reminderItemSelectedIndex = this.reminderPickerOptions.indexOf(this.reminderItemSelected);
      }

    }).finally(() => {
      this.wc.setNotificationRequired(true);
      this.wc.setReminderTimeInMinutes(this.reminderItemSelected?.value);
    });


    // Расчитываем скидки
    if (this.applicationIdService.applicationId == "client" && this._authService.isAuthenticated){
      for (let item of this.wc.servicesData.serviceItems) {
        let itemPromos: PromoRes[] = [];
        item.promo = null;

        for (let calc of this.wc.calcPromosByMasterId(this.wc.timeSlotData.master.id)) {
          if (calc.priceListItemId != item.priceListItemId) {
            continue;
          }

          let promo = calc.promo;
          if (promo.type.type == PromoTypeEnum.hotWindow) {
            if (promo.hotWindows.some(w =>
              w.startTimeOffset <= this.wc.timeSlotData.timeSlot.startTime &&
              w.endTimeOffset >= this.wc.timeSlotData.timeSlot.endTime)) {
              itemPromos.push(promo);
            }

            continue;
          }

          itemPromos.push(promo);
        }

        if (itemPromos.length) {
          for (let promo of itemPromos) {
            if (this.calcPrice(item.priceMin, item.promo ?? null) > this.calcPrice(item.priceMin, promo)) {
              item.promo = promo;
            }
          }
        }
      }
    }
    // if (this.applicationIdService.applicationId == "client") {
    //   this._masterListClient.calcPromos(
    //     this.wc.timeSlotData.master.id,
    //     this.wc.clientVm.id,
    //     null)
    //     .subscribe(calcPromos => {
    //
    //
    //       // let serviceItems: ServiceItem[] = [];
    //       // let masterItems = this.wc.timeSlotData.master.priceList.priceListItems
    //       //   .filter(i => this.wc.servicesData.serviceItems.map(s => s.serviceTypeId).indexOf(i.serviceType.id) != -1);
    //       //
    //       // for (let item of this.wc.servicesData.serviceItems) {
    //       //   let calcPromos = promos.filter(p => masterItems.find(i => i.serviceType.id == item.serviceTypeId).id == p.priceListItemId);
    //       //   if (this.wc.baseBooking) {
    //       //     let promos = this.wc.timeSlotData.master.promos.filter(p => this.wc.baseBooking.items.map(i => i.promoId).indexOf(p.id) != -1);
    //       //
    //       //     for (let promo of promos) {
    //       //       calcPromos = [...calcPromos, ...promo.priceListItems.map(pli => {
    //       //         return {priceListItemId: pli.id, promo: promo} as CalcPromosRes
    //       //       })];
    //       //     }
    //       //   }
    //       //
    //       //   item.promo = null;
    //       //
    //       //   for (let calcPromo of calcPromos) {
    //       //     if (this.calcPrice(item.priceMin, item.promo ?? null) > this.calcPrice(item.priceMin, calcPromo.promo)) {
    //       //       item.promo = calcPromo.promo;
    //       //     }
    //       //   }
    //       //   serviceItems.push(item);
    //       // }
    //       //
    //       // this.wc.servicesData.serviceItems = serviceItems;
    //     });
    // }


    // Определяем нахождение клиента в ЧС
    await firstValueFrom(
      combineLatest([
        this._masterListClient.checkBlocking(this.wc.clientVm.id, this.wc.timeSlotData.master.id),
        this.wc.timeSlotData.address.addressSaloonId != null ?
          this._saloonsClient.checkBlocking(this.wc.clientVm.id, this.wc.timeSlotData.address.addressSaloonId) :
          Promise.resolve(false)
      ])
    ).then(([blockedByMaster, blockedBySaloon]) => {
      let client = this.wc.clientVm;
      client.isBlockedByMaster = blockedByMaster;
      client.isBlockedBySaloon = blockedBySaloon;
      this.wc.setClientVm(client);
    });

  }

  public async ionViewWillEnter(): Promise<void> {
    if (this.wc.disposed || !this.wc.timeSlotData) {
      this.wc.dispose();
      await this._navigationService.goBack();
      return;
    }
  }


  public onNextClick(): void {
    this.wc.confirm();
  }

  public async onCheckBalanceClick(): Promise<void> {
    await this._wizardSettings.navigateToBalance();
  }

  public async onCallMasterClick(): Promise<void> {
    await this._wizardSettings.navigateToMasterInfo(this._wizardController.timeSlotData.master.id);
  }

  public getPriceItem(item: ServiceItem): string {
    let pli = this.wc.timeSlotData.master.priceList.priceListItems.find(p => p.serviceType.id == item.serviceTypeId);
    if (pli.priceMin != pli.priceMax) {
      return `${pli.priceMin} - ${pli.priceMax} BYN`;
    } else {
      return `${pli.priceMin.toString()} BYN`;
    }
  }

  public getPriceItemTotal(item: ServiceItem): string {
    let pli = this.wc.timeSlotData.master.priceList.priceListItems.find(p => p.serviceType.id == item.serviceTypeId);
    let totalPriceMin = this.calcPrice(pli.priceMin, item.promo);
    let totalPriceMax = this.calcPrice(pli.priceMax, item.promo);

    if (totalPriceMin != totalPriceMax) {
      return `${totalPriceMin} - ${totalPriceMax} BYN`;
    } else {
      return `${totalPriceMax.toFixed(2)} BYN`;
    }
    return null
  }

  public calcPrice(price: number, promo: PromoRes): number {
    if (promo?.id == null) {
      return price;
    }

    let res: number;
    if (promo.isPercentValue == true) {
      res = price - promo.value * price / 100;
    } else {
      res = price - promo.value;
    }

    return res > 0 ? parseFloat(res.toFixed(2)) : 0;
  }

  public getPriceTotal(): string {
    let totalPriceMin: number = 0;
    let totalPriceMax: number = 0;

    this.wc.servicesData.serviceItems.map(item => {
      let pli = this.wc.timeSlotData.master.priceList.priceListItems.find(p => p.serviceType.id == item.serviceTypeId);
      totalPriceMin += this.calcPrice(pli.priceMin, item.promo);
      totalPriceMax += this.calcPrice(pli.priceMax, item.promo);
    })
    if (totalPriceMin != totalPriceMax) {
      return `${totalPriceMin.toFixed(2)} - ${totalPriceMax.toFixed(2)} BYN`;
    } else {
      return `${totalPriceMax.toFixed(2)} BYN`;
    }
  }

  public getBookingSpecialises(): string {
    return this.wc.timeSlotData.master.specialities
      // .filter(spec => spec.groups.filter(group => group.serviceTypes.filter(st => this.wc.servicesData.serviceTypeIds.findIndex(stid => stid === st.id) !== -1).length > 0).length > 0)
      .map(s => s.name.toLowerCase())
      .join(', ');
  }

  public async activateReminder() {
    if (!this.reminderItemSelected) {
      const reminderPickerItemsSelected = this.reminderPickerOptions
        .sort((a, b) => a.value - b.value)
        .find(x => x.value >= this.suggestNotificationSettings.reminderBufferInMinutes);

      if (reminderPickerItemsSelected) {
        this.reminderItemSelected = reminderPickerItemsSelected;
      }
      else{
        this.reminderItemSelected = this.reminderPickerOptions[5]; // 24 часа
      }

      this.reminderItemSelectedIndex = this.reminderPickerOptions.indexOf(this.reminderItemSelected);

    }
  }

  public async deactivateReminder() {
    this.reminderItemSelected = null;
  }

  public async toggleReminder($event): Promise<void> {
    $event.cancelBubble = true;
    $event.stopPropagation();
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.reminderActivated) {
      this.reminderActivated = true;
      this.reminderItemSelected = {text: 'Пусто', value: null} as PickerColumnOption;
      await this.onReminderClick();
    } else {
      await this.deactivateReminder();
    }
  }

  public async onReminderClick() {
    const options = this.reminderPickerOptions.map(x => ({...x})); // Копируем, потому-что, если юзать так, то айтемы в пикере скукожит https://stackoverflow.com/questions/56555593/ion-picker-options-overlaps
    const index = this.suggestNotificationSettings?.reminderBufferInMinutes
      ? options.map(o => o.value).indexOf(this.suggestNotificationSettings?.reminderBufferInMinutes)
      : 5;

    const reminderPicker = await this._pickerController.create({
      buttons: [
        {
          text: "ОТМЕНА",
          role: 'cancel'
        },
        {
          text: 'ОК',
          role: 'ok'
        }
      ],
      columns: [{
        name: 'reminderItems',
        options: options,
        selectedIndex: index,
      }],
    });

    reminderPicker.onDidDismiss().then(data => {
      if (data.data == null) {
        this.reminderItemSelected = null;
        this.reminderActivated = false;
        return;
      }

      this.reminderItemSelected = data.data.reminderItems;
      this.wc.setReminderTimeInMinutes(this.reminderItemSelected.value);
    });

    await reminderPicker.present();
  }

  public async onMasterNoteClick() {
    const modal = await this._modalController.create({
      component: TextAreaPopupComponent,
      componentProps: {
        title: 'Примечание',
        val: this.wc.masterNote,
      },
    });

    modal.onDidDismiss().then(async e => {
      if(e.data == null){
        if (this.wc.masterNote == null){
          this.masterNoteActivated = false;
        }
        return;
      }

      if (e.data == '') {
        this.masterNoteActivated = false;
      }

      this.wc.setMasterNote(e.data);
    });


    await modal.present();
  }

  public async onMasterNoteEnabledClick($event) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.masterNoteActivated){
      await this.onMasterNoteClick();
    }
    else{
      this.wc.setMasterNote(null);
    }
  }

  public async unblockMasterClient() {
    await firstValueFrom(
      this._masterListClient.unblockClient({
        clientId: this.wc.clientVm.id,
        masterId: this.wc.timeSlotData.master.id
      } as UnblockMasterClientReq)
        .pipe(
          tap(() => {
            let client = this.wc.clientVm;
            client.isBlockedByMaster = false;
            this.wc.setClientVm(client);
          })
        )
    );
  }

  public async unblockSaloonClient() {
    await firstValueFrom(this._saloonsClient.unblockClient({
        clientId: this.wc.clientVm.id,
        saloonId: this.wc.timeSlotData.address.addressSaloonId
      } as UnblockSaloonClientReq)
        .pipe(
          tap(() => {
            let client = this.wc.clientVm;
            client.isBlockedBySaloon = false;
            this.wc.setClientVm(client);
          })
        )
    );
  }
}
