import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {FormControl} from "@angular/forms";
import {combineLatest, merge, Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {CityRes} from "../../api-clients/geo/clients";
import {CitiesDirectoryService} from "./../../directories/cities-directory.service";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'app-choose-city',
  templateUrl: './choose-city.page.html',
  styleUrls: ['./choose-city.page.scss'],
})
export class ChooseCityPage extends ModalComponentBase implements OnInit {

  public searchControl: FormControl = new FormControl(null);

  public cities$: Observable<CityRes[]> = null;

  @Output()
  public citySelected: EventEmitter<string>;

  public constructor(
    modalCtrl: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
    private citiesService: CitiesDirectoryService,
    public applicationIdService: ApplicationIdService,
  ) {
    super(modalCtrl, router, activeRoute, location);
  }

  ngOnInit() {
    super.init().then();

    this.cities$ = combineLatest([
      this.citiesService.getCities(),
      merge(of(null), this.searchControl.valueChanges.pipe()),
    ]).pipe(map(([cities, searchValue]) => {
      const search = searchValue ? searchValue.toLowerCase() : searchValue;
      return cities.filter(c => !search || c.name.toLowerCase().includes(search));
    }));
  }

  public async onCloseClick(): Promise<void> {
    await this.dismiss();
  }

  public async onCityClick(city: CityRes): Promise<void> {
    await this.dismiss(city.id);
  }
}
