import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {
  AuthService,
  CitiesDirectoryService,
  FeedbackClient, FILES_BASE_URL, FileUrlPipe,
  MasterListClient,
  PriceListVm, RefreshService,
  SpecialitiesDirectoryService, SpecialitiesPipe, ThumbnailUrlPipe, ToastService, TrackingService
} from "bc-common";
import {ActivatedRoute, Router} from "@angular/router";
import {ActionSheetController, AlertController, IonicSafeString, ModalController} from "@ionic/angular";
import {Meta, Title} from "@angular/platform-browser";
import {combineLatest, firstValueFrom, Observable, of} from "rxjs";
import {map, startWith, switchMap, tap} from "rxjs/operators";

class Vm {
  public master: MasterListClient.MasterRes;
  public priceList: PriceListVm.PriceListVm;
}

@Component({
  selector: 'app-master-history',
  templateUrl: './master-history.component.html',
  styleUrls: ['./master-history.component.scss'],
})
export class MasterHistoryComponent implements OnInit {

  public vm = new Vm();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private masterListClient: MasterListClient.MasterListClient,
    private feedbackClient: FeedbackClient.FeedbackClient,
    private citiesDirectoryService: CitiesDirectoryService,
    private specialitiesDirectoryService: SpecialitiesDirectoryService,
    private authService: AuthService,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private toastService: ToastService,
    private fileUrlPipe: FileUrlPipe,
    private thumbnailPipe: ThumbnailUrlPipe,
    private specialitiesPipe: SpecialitiesPipe,
    private meta: Meta,
    private titleService: Title,
    private refreshService: RefreshService,
    private trackingService: TrackingService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(FILES_BASE_URL) private filesBaseUrl: string
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.refreshService.refreshAndResume$.pipe(startWith(null)),
      this.activatedRoute.paramMap,
    ]).pipe(
      map(([x, paramMap]) => paramMap),
      map((paramMap): [string, number] => [paramMap.get('id'), parseInt(paramMap.get('refCode'))]),
      tap(([masterId, refCode]) => refCode ? this.trackingService.setMasterRefCode(refCode) : null),
      switchMap(([masterId, refCode]) =>
        firstValueFrom(this.masterListClient.getMasters(
          [masterId || null].filter(x => x != null),
          [refCode || null].filter(x => x != null)
        ).pipe(map(res => res[0])))
      ),
      switchMap((masterRes) => combineLatest([
        of(masterRes),
        firstValueFrom(this.specialitiesDirectoryService.getSpecialities([masterRes.id])),
      ])),
      tap(([masterRes, specialities]) => {
        const vm = new Vm();
        vm.priceList = PriceListVm.PriceListVm.Parse(masterRes, specialities, null);
        vm.master = masterRes;
        this.vm = vm;
      })
    ).subscribe();
  }

  public async onBwfDetailsClicked(bwf: FeedbackClient.BookingWithFeedback): Promise<void> {
    const actionSheet = await this.actionSheetController.create({
      header: bwf.serviceTypes.map(t => t.name).join(", "),
      buttons: [
        {
          text: 'Записаться так же',
          handler: async () => {
            await this.router.navigate(['bookings', 'new'], {
              queryParams: {
                masterId: bwf.masterId,
                serviceTypeIds: bwf.serviceTypes.map(st => st.id)
              }
            });
          }
        }
      ]
    });

    await actionSheet.present();
    return;
  }

  public async onDoFeedbackClicked(): Promise<void> {
    const alert = await this.alertController.create({
      message: new IonicSafeString('Клиенты могут оставлять отзывы только на свои записи.<br/><br/>Перейти к Вашим записям?'),
      cssClass: 'center',
      buttons: [
        {
          text: 'Да',
          role: 'ok'
        }, {
          text: 'Отмена',
          role: 'cancel'
        }
      ]
    });

    await alert.present();

    const role = (await alert.onDidDismiss()).role;

    if (role == 'ok') {
      await this.router.navigate(['bookings'], {
        queryParams: {
          tab: 'others'
        }
      });
    }
  }
}
