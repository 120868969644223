<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>{{ vm.master?.fullName }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content forceOverscroll="true">

  <div class="content-section">
    <lib-refresher slot="fixed"></lib-refresher>
    <div class="cards-list safe-area-space">

      <div class="card main no-vertical-padding">

        <div class="image" style="position: relative">
          <div style="padding-bottom: 100%"></div>
          <img
            #firstImage
            [src]="vm.master?.avatarFileName | fileUrl | mediumUrl"
            style="width: 100%; height: 100%; position: absolute; top: 0; right: 0; bottom: 0; left: 0; object-fit: cover; visibility: hidden"
            (load)="firstImage.style.visibility = 'visible'"/>
          <div
            *ngIf="(vm?.master?.stars && vm?.master?.reviewsCount) || vm?.master?.returnabilityPercent || (vm?.master?.cancellationsPercent !== null || vm?.master?.movedPercent !== null)"
            class="digits ion-activatable ripple-parent"
            [routerLink]="['/masters', vm?.master?.id, 'history']"
          >

            <span *ngIf="vm?.master?.stars && vm?.master?.reviewsCount" class="digit star">
              <span title="Оценка">
                <ion-icon name="star"></ion-icon>
                <ion-text>
                  {{ vm?.master?.stars | number: '1.1-1' }}
                  <span class="top">{{ vm?.master?.reviewsCount }}</span>
                </ion-text>
              </span>
            </span>

            <span *ngIf="vm?.master?.returnabilityPercent" class="digit returnability">
              <span title="Возвращаемость">
                <ion-icon name="repeat-outline"></ion-icon>
                <ion-text>
                  {{ vm?.master?.returnabilityPercent }}
                  <span class="bottom">%</span>
                </ion-text>
              </span>
            </span>

            <span *ngIf="vm?.master?.cancellationsPercent !== null || vm?.master?.movedPercent !== null"
                  class="digit reliability">
              <span title="Надёжность">
                <ion-icon name="shield-checkmark-outline"></ion-icon>
                <ion-text>
                  {{ 100 - (vm?.master?.cancellationsPercent + vm?.master?.movedPercent) }}
                  <span class="bottom">%</span>
                </ion-text>
              </span>
            </span>

            <ion-ripple-effect></ion-ripple-effect>
          </div>
        </div>
        <div class="info" *ngIf="vm.master">
          <div class="name">
            <h1>
              {{ vm.master?.fullName }}
            </h1>
            <div>
              {{ vm.master | specialitiesPipe }}
            </div>
          </div>

          <div class="addresses">
            <ion-list class="transparent">
              <ng-container *ngFor="let address of vm.master?.addresses; trackBy: trackById">
                <ion-item *ngIf="!address?.addressSaloonId" button="true" detail="false"
                          (click)="$event.preventDefault(); $event.stopPropagation(); onAddressClick(address)">
                  <ion-avatar slot="start">
                    <ion-icon name="location-outline"></ion-icon>
                  </ion-avatar>
                  <ion-label>
                    <h4>{{ address.addressLine1 }}</h4>
                    <p>{{ address.addressLine2 }}</p>
                  </ion-label>
                  <ion-button slot="end"
                              (click)="$event.preventDefault(); $event.stopPropagation(); onAddressClick(address)">Карта
                  </ion-button>
                </ion-item>
                <ion-item *ngIf="address?.addressSaloonId" button="true" detail="false"
                          [routerLink]="'/saloons/' + address.addressSaloonId">
                  <lib-avatar [name]="address.addressSaloonName"
                              [avatarFileName]="address.addressSaloonAvatar"
                              slot="start">
                  </lib-avatar>
                  <ion-label>
                    <h4>{{ address.addressSaloonName }}, {{ address.addressSaloonType }}</h4>
                    <p>{{ address.addressLine1 | addressPipe :address.addressLine2 }}</p>
                  </ion-label>
                  <ion-button slot="end"
                              (click)="$event.preventDefault(); $event.stopPropagation(); onAddressClick(address)">Карта
                  </ion-button>
                </ion-item>
              </ng-container>
            </ion-list>
          </div>

          <div class="about-me" (click)="onInfoClick()">
            <div class="description" [innerText]="vm.master.aboutMeText"></div>
            <!--          <lib-short-description-->
            <!--            [description]="vm.master.aboutMeText"-->
            <!--            mergeWithNewLine="true"-->
            <!--            linesCount="3">-->
            <!--          </lib-short-description>-->
          </div>

          <ion-buttons class="action-buttons">
            <ion-button shape="round" (click)="onInfoClick()">
              <ion-label>
                <ion-icon class="info " name="information-circle-outline"></ion-icon>
                <label>Информация<br/>и контакты</label>
              </ion-label>
            </ion-button>
            <ion-button shape="round" (click)="onHistoryClick()">
              <ion-label>
                <ion-icon class="history" name="chatbubbles-outline"></ion-icon>
                <label>Работы<br/>и отзывы</label>
              </ion-label>
            </ion-button>
            <ion-button shape="round" (click)="onAddToBkClick()" *ngIf="masterIsFavorites == false">
              <ion-label>
                <ion-icon class="add-green" name="heart-outline"></ion-icon>
                <label>Добавить в <br/> Бьюти Круг</label>
              </ion-label>
            </ion-button>
            <ion-button shape="round" (click)="onDeleteBkClick()" *ngIf="masterIsFavorites == true">
              <ion-label>
                <ion-icon class="add-green" name="heart"></ion-icon>
                <label>У вас в <br/> Бьюти Круге</label>
              </ion-label>
            </ion-button>
          </ion-buttons>
        </div>

      </div>

      <app-your-bookings *ngIf="authService.isAuthenticated && vm?.master"
                         [masterId]="vm.master.id"></app-your-bookings>

      <div class="card padding" *ngIf="this.promos && this.promos.length > 0">
        <h2>
          Скидки и акции
          <a *ngIf="this.botConnection?.botConnectionType == 0" routerLink="/promo/unlock">Как разблокировать?</a>
        </h2>

        <ion-list class="colored-primary">
          <ion-item *ngFor="let promo of this.promos; trackBy: trackById" class="promo" detail="false" button="true"
                    [routerLink]="'/promo/' + promo.id">

            <ion-label>
              <div>{{ promo.title }}</div>
              <div class="info">
                  <span class="discount">
                    {{ promo.isPercentValue == true ? '- ' + promo.value + ' %' : '- ' + promo.value + ' BYN' }}
                  </span>
                <span class="dates">
                    <span *ngIf="promo.endTimeOffset">
                      до {{ promo.endTimeOffset | moment:'D MMM' }}
                    </span>
                  </span>
              </div>
            </ion-label>

            <ion-buttons slot="end">
              <ion-button size="small">
                <ion-icon *ngIf="this.botConnection?.botConnectionType == 0; else botExist" name="lock-closed-outline"
                          size="small"
                          slot="icon-only"></ion-icon>
                <ng-template #botExist>
                  <!--                  <ion-icon  *ngIf="promo.status == 'lock'" name="lock-closed-outline" slot="icon-only"></ion-icon>-->
                  <ion-icon *ngIf="promo.status == 'allow'" name="flame-outline" style="color: #F73E32" size="small"
                            slot="icon-only"></ion-icon>
                  <ion-icon *ngIf="promo.status == 'used' && promo.type.reused == false" name="checkmark-outline"
                            style="color: #1ABE79" size="small"
                            slot="icon-only"></ion-icon>
                </ng-template>
              </ion-button>
            </ion-buttons>


          </ion-item>
        </ion-list>
      </div>

      <div  *ngIf="this.vm.master?.nearestDays?.length === 0" class="card padding">
        <h2 class="offline">Запись отключена</h2>
        <ion-searchbar
          mode="ios"
          placeholder="Поиск"
          (ionChange)="this.vm.priceList.applySearch($event?.detail?.value)"
          style="margin: 20px 0">
        </ion-searchbar>

        <app-price-list
          class="ion-margin-vertical"
          (itemClicked)="onCallMeClick()"
          [priceList]="vm.priceList"
          [coloredSecondary]="true">
        </app-price-list>
      </div>

      <div *ngIf="this.vm.master?.nearestDays?.length > 0" class="card padding">
        <h2 class="online">Онлайн запись</h2>
        <ion-searchbar
          mode="ios"
          placeholder="Поиск"
          (ionChange)="this.vm.priceList.applySearch($event?.detail?.value)"
          style="margin: 20px 0">
        </ion-searchbar>

        <app-price-list
          class="ion-margin-vertical"
          (itemClicked)="onBookClick($event)"
          [priceList]="vm.priceList"
          [coloredSecondary]="true">
        </app-price-list>
      </div>
    </div>
  </div>

</ion-content>

