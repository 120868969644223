<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <form id="instagramForm">
      <div class="cards-list form">
        <div class="card padding">
          <ion-list lines="none" class="classic">
            <ion-item>
              <ion-label position="stacked">Новый Instagram</ion-label>
              <ion-input [formControl]="instagramProfileControl"
                         placeholder="{{placeholder}}">
              </ion-input>
            </ion-item>
          </ion-list>
        </div>
        <div class="card padding-mobile transparent bottom-fixed-mobile">
          <ion-button (click)="onSubmitInstagramClick()"
                      [disabled]="!instagramProfileControl.valid"
                      expand="block"
                      size="default">Сохранить
          </ion-button>
        </div>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer *ngIf="this._applicationIdService.applicationId == 'client'" class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button (click)="onSubmitInstagramClick()"
                [disabled]="!instagramProfileControl.valid"
                expand="block"
                size="default"
                form="instagramForm">Сохранить
    </ion-button>
  </div>
</ng-template>
