import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Group, PriceListItem, PriceListVm} from "../price-list-vm";
import {ModalController} from "@ionic/angular";
import {PriceListItemInfoComponent} from "../price-list-item-info/price-list-item-info.component";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {
  MasterListClient,
  UpdatePriceListItemsRankReq
} from "../../api-clients/master-list/clients";
import {ToastService} from "../../toast/toast.service";
import {NgEventBus} from "ng-event-bus";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss'],
})
export class PriceListComponent implements OnInit {
  @Input()
  public priceList: PriceListVm = new PriceListVm();

  @Input()
  public showGroups: boolean = true;

  @Input()
  public showCloseButton: boolean = false;

  @Input()
  public showInfoButton: boolean = true;

  @Input()
  public coloredSecondary: boolean = false;

  @Input()
  public showSpecialities: boolean = false;

  @Output()
  public itemClicked: EventEmitter<PriceListItem> = new EventEmitter<PriceListItem>();

  @Output()
  public deleteItemClicked: EventEmitter<PriceListItem> = new EventEmitter<PriceListItem>();

  public reorderEnabled: boolean = false;
  public loading: boolean = false;

  public constructor(
    public applicationIdService: ApplicationIdService,
    private _modalController: ModalController,
    private _mastersListClient: MasterListClient,
    private _eventBus: NgEventBus,
    private _toastService: ToastService,
  ) {
  }

  public ngOnInit(): void {

  }

  public async onItemClick($event: MouseEvent, item: PriceListItem): Promise<void> {
    if (this.reorderEnabled == true) {
      return;
    }

    $event?.preventDefault();
    $event?.stopPropagation();
    this.itemClicked.emit(item);
  }

  public async onTrashClick(item: PriceListItem): Promise<void> {
    this.deleteItemClicked.emit(item);
  }

  public async onInfoClick(item: PriceListItem): Promise<void> {
    const modal = await this._modalController.create({
      component: PriceListItemInfoComponent,

      componentProps: {
        title: item.serviceTypeName,
        text: item.infoText,
      }
    });

    await modal.present();
  }

  public async onItemReorder(event: any, group: Group) {
    event.detail.complete(group.items);
    group.updateRanks();
  }

  public async finishReordering() {
    this.reorderEnabled = false;
    const req: UpdatePriceListItemsRankReq[] = [];

    for (let item of this.priceList.getAllItems()) {
      if (item.id == null) {
        continue;
      }

      const itemsRankReq = new UpdatePriceListItemsRankReq();
      itemsRankReq.priceListItemId = item.id;
      itemsRankReq.rank = item.rank;

      req.push(itemsRankReq);
    }

    await firstValueFrom(this._mastersListClient.updatePriceListItemsRank(req));
  }

  public trackById(index, item: any): any {
    return item.id;
  }
}
