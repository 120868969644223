<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button routerLink="./"></lib-back-button>
    </ion-buttons>
    <ion-title>Новый адрес</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <lib-address-form-step1 #formStep1Component (onFormSubmit)="this.onFormSubmit($event)"></lib-address-form-step1>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <lib-submit-button [disabled]="formStep1Component.addressForm.invalid"
                     (click)="formStep1Component.onNextClick()">Продолжить</lib-submit-button>
</ion-footer>
