import {NgModule} from '@angular/core';
import {SortingNamePipe} from "./sorting-name.pipe";

@NgModule({
  imports: [],
  declarations: [SortingNamePipe],
  exports: [SortingNamePipe],
  providers: []
})
export class FiltersModule {
}
