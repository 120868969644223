import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'iconOperation'
})
export class IconOperationPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    switch (value) {
      case 'EvaluationGift':
        return 'gift';
      case 'BookingPayment':
        return 'person';
      case 'LeadPayment':
        return 'cash';
      case 'AdBookingPayment':
        return 'people';
      default:
        return 'card';
    }
  }
}
