import {Component, Input, OnInit} from '@angular/core';
import {ApplicationIdService} from "../application-id/application-id.service";

@Component({
  selector: 'lib-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss'],
})
export class StarsComponent implements OnInit {

  @Input()
  public stars: number = null;

  @Input()
  public mode: "long" | "small" = "long";

  constructor(
    public _applicationIdService: ApplicationIdService,
  ) { }

  ngOnInit() {}

}
