<ion-header>
  <ion-toolbar>
    <ion-buttons class="menu" slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>Бьюти Круг</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <lib-refresher slot="fixed"></lib-refresher>

  <div class="content-section">

    <div class="cards-list">

      <div class="card padding" *ngIf="masters.hidden">
        <h1>Окружите себя лучшими мастерами</h1>
        <app-services-grid></app-services-grid>
      </div>

      <app-your-masters #masters [hideIfEmpty]="true"></app-your-masters>

      <div class="card padding steps" *ngIf="bookings.hidden">
        <h2>Начать просто</h2>

        <ul>
          <li class="ion-activatable ripple-parent" routerLink="/catalog">
            Выберите мастера
            <ion-ripple-effect></ion-ripple-effect>
          </li>
          <li class="ion-activatable ripple-parent" routerLink="/catalog">
            Выберите услугу
            <ion-ripple-effect></ion-ripple-effect>
          </li>
          <li class="ion-activatable ripple-parent" routerLink="/catalog">
            Запишитесь онлайн
            <ion-ripple-effect></ion-ripple-effect>
          </li>
        </ul>
      </div>

      <app-your-bookings #bookings [hideIfEmpty]="true"></app-your-bookings>

    </div>

    <lib-banner-slider [banners]="['install', 'become-master', 'promos']" [maxLength]="2"></lib-banner-slider>
  </div>

</ion-content>
