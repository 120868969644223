<ion-item
  *ngIf="mode == 'client-bookings' || mode == 'master-bookings' || mode == 'profile-bookings' || mode == 'home-client-bookings'"
  button="true" detail="false"
  class="bwf-2"
  (click)="this.onItemClick($event)"
  [ngClass]="{'disabled': (mode == 'profile-bookings' || mode == 'home-client-bookings') && isInPast}">

  <ion-avatar *ngIf="mode == 'client-bookings' || mode == 'master-bookings' || mode == 'home-client-bookings'"
              class="avatar" slot="start">
    <lib-avatar [name]="bwf.masterName"
                [avatarFileName]="bwf.masterAvatarFileName">
    </lib-avatar>
  </ion-avatar>
  <ion-label>
    <div *ngFor="let st of bwf.serviceTypes; index as i">
      <span>{{st.name}}</span>
      <ng-container
        *ngIf="i + 1 < bwf.serviceTypes.length">,
      </ng-container>
    </div>

    <p>
      <span class="date">
        {{bwf?.startTime | moment :'D MMM'}}, {{bwf?.startTime | moment :'LT' }}
      </span>

      <span class="day"
            *ngIf="(!isOnline && !isToday && !isTomorrow && !isCancelled && !isMoved && !isInPast && mode == 'client-bookings') || mode == 'profile-bookings' || (mode == 'home-client-bookings' && !isInPast)">
          {{bwf?.startTime | moment :'dddd'}}
      </span>
    </p>
  </ion-label>

  <lib-stars *ngIf="mode == 'client-bookings' || mode == 'master-bookings'" mode="small"
             [stars]="this.bwf.clientStars" slot="end"></lib-stars>

  <ion-icon *ngIf="isCancelled && !isMoved" [routerLink]="'/bookings/' + bwf.id" slot="end"
            name="ban"></ion-icon>

  <ion-button *ngIf="isMoved" [routerLink]="'/bookings/' + bwf.id" slot="end">
    Перенесено
  </ion-button>

  <ion-button *ngIf="(mode == 'profile-bookings' || mode == 'home-client-bookings') && isInPast"
              [routerLink]="'/bookings/' + bwf.id" slot="end">
    Оценить
  </ion-button>
  <ion-button *ngIf="isToday && !isCancelled && isInFuture" class="today" [routerLink]="'/bookings/' + bwf.id"
              slot="end">
    Сегодня
  </ion-button>
  <ion-button *ngIf="isTomorrow && !isCancelled" class="tomorrow" [routerLink]="'/bookings/' + bwf.id" slot="end">
    Завтра
  </ion-button>
  <ion-button *ngIf="isOnline && !isCancelled" class="online" [routerLink]="'/bookings/' + bwf.id" slot="end">
    В процессе
  </ion-button>

</ion-item>

<div class="bwf-2 card padding" *ngIf="mode == 'master-feedbacks'" (click)="onItemClick($event)">

  <div class="message" *ngIf="showMasterMessage">

    <ion-list class="transparent">
      <ion-item [button]="isMasterClickable" [detail]="isMasterClickable"
                (click)="isMasterClickable && masterClicked.emit()">
        <ion-avatar slot="start">
          <lib-avatar [name]="bwf.masterName"
                      [avatarFileName]="bwf.masterAvatarFileName">
          </lib-avatar>
        </ion-avatar>
        <ion-label>
          <h4>{{bwf.masterName}}</h4>
          <p>{{bookingSpecialises | async}}</p>
        </ion-label>
      </ion-item>
    </ion-list>

    <div class="bubble" *ngIf="isThereMasterFeedback">
      <p *ngIf="bwf.masterText" [innerText]="bwf.masterText"></p>
      <app-images-set *ngIf="bwf.masterImagesFilesNames.length > 0"
                      [imagesInit]="bwf.masterImagesFilesNames"
                      title="Фото">
      </app-images-set>
    </div>

    <div class="bubble danger"
         *ngIf="isCancelled && !isMoved && bwf?.cancelationText && bwf?.canceledByUserWithId == bwf?.masterId ">
      <p>
        <span style="font-weight: 600">Отмена:</span>
        {{bwf?.cancelationText}}
      </p>
    </div>

  </div>

  <div class="message" *ngIf="showClientMessage">

    <ion-list class="transparent">
      <ion-item [button]="isClientClickable" [detail]="isClientClickable"
                (click)="isClientClickable && clientClicked.emit()">
        <ion-avatar slot="start">
          <lib-avatar [name]="bwf.clientName | clientName"
                      [avatarFileName]="bwf.clientAvatarFileName">
          </lib-avatar>
        </ion-avatar>
        <ion-label>
          <h4>{{bwf.clientName | clientName}}</h4>
          <div *ngIf="isThereClientFeedback">
            <div *ngFor="let st of bwf.serviceTypes; index as i">
              <span>{{st.name}}</span>
              <ng-container
                *ngIf="i + 1 < bwf.serviceTypes.length">,
              </ng-container>
            </div>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>

    <div class="bubble" *ngIf="isThereClientFeedback">
      <lib-stars [stars]="bwf.clientStars"></lib-stars>
      <p *ngIf="bwf.clientText" [innerText]="bwf.clientText"></p>
      <app-images-set *ngIf="bwf.clientImagesFilesNames?.length > 0"
                      [imagesInit]="bwf.clientImagesFilesNames"
                      title="Фото">
      </app-images-set>
    </div>

    <div class="bubble danger"
         *ngIf="isCancelled && !isMoved && bwf?.cancelationText && bwf?.canceledByUserWithId == bwf?.clientId ">
      <p>
        <span style="font-weight: 600">Отмена:</span>
        {{bwf?.cancelationText}}
      </p>
    </div>

  </div>

  <div class="message" *ngIf="showMasterReply">

    <ion-list class="transparent">
      <ion-item [button]="isMasterClickable" [detail]="isMasterClickable"
                (click)="isMasterClickable && masterClicked.emit()">
        <ion-avatar slot="start">
          <lib-avatar [name]="bwf.masterName"
                      [avatarFileName]="bwf.masterAvatarFileName">
          </lib-avatar>
        </ion-avatar>
        <ion-label>
          <h4>{{bwf.masterName}}</h4>
          <p>ответ на отзыв:</p>
        </ion-label>
      </ion-item>
    </ion-list>


    <div class="bubble" *ngIf="isThereClientFeedback">
      <p *ngIf="bwf.masterReply" [innerText]="bwf.masterReply"></p>
    </div>

  </div>

</div>
