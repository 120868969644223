import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonInput, ModalController} from "@ionic/angular";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {validatorInstagramLink} from "../../editors/validation";
import {Location} from "@angular/common";

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent extends ModalComponentBase  implements OnInit {

  @Input()
  public val: string;

  @Input()
  public title: string = "Введите что-нибудь";

  @ViewChild("inp", {static: true})
  public inp: IonInput;

  public formAddClient: FormGroup;
  public masterDefinedInstagramProfileControl: FormControl;

  constructor(
    modalController: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location
  ) {
    super(modalController, router, activeRoute, location);
  }

  ngOnInit() {
    super.init().then();

    this.formAddClient = new FormGroup({
      nameControl: new FormControl(this.val, [
        Validators.required
      ]),
      phoneControl: new FormControl(this.val, [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(15),
        Validators.pattern("^([+][0-9]{12,15})$")
      ])
    });

    this.masterDefinedInstagramProfileControl = new FormControl(null, [
      validatorInstagramLink()
    ]);

    setTimeout(async () =>
      await this.inp?.setFocus());
  }

  public async onCloseClick(){
    await this.dismiss();
  }

  public async onSaveClick(){
    if (!this.formAddClient.valid){
      return;
    }
    await this.dismiss({
      name: this.formAddClient.controls.nameControl.value?.toString()?.trim(),
      phone: this.formAddClient.controls.phoneControl.value,
      masterDefinedInstagramProfile: this.masterDefinedInstagramProfileControl.value?.toString()?.trim()
    });
  }


  inputOnlyDigits($event: any) {
    const pattern = /[0-9+]/;
    let inputChar = String.fromCharCode($event.charCode);
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
    }
  }

  onPaste($event: ClipboardEvent) {
    let pastedText = $event.clipboardData.getData("text");
    const pattern = new RegExp("^([+][0-9]{12,15})$");
    let test = pattern.test(pastedText);
    if(!test){
      let trimmedText = pastedText.replace(/[^0-9+]/g, '');
      this.formAddClient.controls.phoneControl.setValue(trimmedText);
      $event.preventDefault();
    }
  }

}
