<div class="cards-list">
  <div class="card no-vertical-padding padding-mobile transparent" *ngFor="let banner of this.bannersView">
    <lib-install-banner *ngIf="banner == 'install'"></lib-install-banner>
    <lib-active-masters-banner *ngIf="banner == 'active-masters'"></lib-active-masters-banner>
    <lib-promos-promo *ngIf="banner == 'promos'"></lib-promos-promo>
    <lib-become-master-promo *ngIf="banner == 'become-master'"></lib-become-master-promo>

    <lib-add-days-banner *ngIf="banner == 'empty-days'"></lib-add-days-banner>
    <lib-add-bookings-banner *ngIf="banner == 'no-bookings'"></lib-add-bookings-banner>

    <ion-icon *ngIf="['become-master'].includes(banner)" class="close" name="close-circle-outline" color="light" (click)="onCloseBannerClick(banner)"></ion-icon>
  </div>

</div>


