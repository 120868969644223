import {ClientRes as ClientMasterRes} from "../api-clients/master-list/clients";
import {ClientRes as ClientSaloonRes} from "../api-clients/saloon/clients";
import {GetClientRes} from "../api-clients/clients/clients";

export class ClientVm {
  public id?: string;
  public originalName?: string | undefined;
  public definedName?: string | undefined;
  public avatarFileName?: string | undefined;
  public phone?: string | undefined;
  public note?: string | undefined;
  public noteBlocking?: string | undefined;
  public originalInstagramProfile?: string | undefined;
  public definedInstagramProfile?: string | undefined;
  public deleted?: boolean;
  public isAd?: boolean;
  public isBlockedBySaloon?: boolean;
  public isBlockedByMaster?: boolean;

  public static ParseFromMaster(clientRes: ClientMasterRes) : ClientVm {
    return {
      id: clientRes.id,
      originalName: clientRes.originalName,
      definedName: clientRes.masterDefinedName,
      avatarFileName: clientRes.avatarFileName,
      phone: clientRes.phone,
      note: clientRes.note,
      noteBlocking: clientRes.noteBlocking,
      originalInstagramProfile: clientRes.originalInstagramProfile,
      definedInstagramProfile: clientRes.masterDefinedInstagramProfile,
      deleted: clientRes.deleted,
      isAd: clientRes.isAd,
      isBlockedByMaster: clientRes.isBlocked,
    } as ClientVm;
  }

  public static ParseFromSaloon(clientRes: ClientSaloonRes) : ClientVm {
    return {
      id: clientRes.id,
      originalName: clientRes.originalName,
      definedName: clientRes.saloonDefinedName,
      avatarFileName: clientRes.avatarFileName,
      phone: clientRes.phone,
      note: clientRes.note,
      noteBlocking: clientRes.noteBlocking,
      originalInstagramProfile: clientRes.originalInstagramProfile,
      definedInstagramProfile: clientRes.saloonDefinedInstagramProfile,
      deleted: clientRes.deleted,
      isAd: clientRes.isAd,
      isBlockedBySaloon: clientRes.isBlocked,
    } as ClientVm;
  }

  public static ParseFromClient(clientRes: GetClientRes) : ClientVm {
    return {
      id: clientRes.id,
      originalName: clientRes.fullName,
      avatarFileName: clientRes.avatarFileName,
      originalInstagramProfile: clientRes.instagramProfile,
      phone: clientRes.phone,
    } as ClientVm;
  }


}
