<div class="side-menu">
  <div class="logo">
    <img src="assets/logo-02.svg" />
    <div class="slogan">Окружите себя лучшими мастерами</div>
  </div>

  <div class="item" routerLink="home" routerLinkActive="active">
    <ion-icon name="home"></ion-icon>
    <span>Главная</span>
  </div>
  <div class="item" routerLink="catalog" routerLinkActive="active">
    <ion-icon name="people"></ion-icon>
    <span>Мастера</span>
  </div>
  <div class="item" routerLink="bookings" routerLinkActive="active">
    <ion-icon name="book"></ion-icon>
    <span>Записи</span>
  </div>

  <div class="separator"></div>

  <div class="item" routerLink="profile" routerLinkActive="active">
    <ion-icon name="person-circle"></ion-icon>
    <span>Профиль</span>
  </div>
  <div class="item" routerLink="help" routerLinkActive="active">
    <ion-icon name="help"></ion-icon>
    <span>Помощь</span>
  </div>

<!--  <div class="separator"></div>-->

<!--  <div class="item" (click)="flip('https://master.bk.style')">-->
<!--    <ion-icon name="invert-mode"></ion-icon>-->
<!--    <span>Для мастеров</span>-->
<!--  </div>-->

<!--  <div class="item" (click)="flip('https://saloon.bk.style')">-->
<!--    <ion-icon name="invert-mode" style="transform: rotate(180deg)"></ion-icon>-->
<!--    <span>Для студий</span>-->
<!--  </div>-->

</div>


