<div>

  <div class="chips">
      <div class="chip ripple-parent ion-activatable" [ngClass]="{'empty': !filterModel?.city}"
           (click)="onSetCityClick()">
        <div class="label">
          Город
        </div>
        <div class="value" *ngIf="filterModel?.city as city">
          {{filterModel?.city?.name}}
        </div>
        <ion-ripple-effect></ion-ripple-effect>
      </div>

    <div class="chip ripple-parent ion-activatable" [ngClass]="{'empty': !filterModel?.region}"
         (click)="onSetRegionClick()">
      <div class="label">
        Район
      </div>
      <div class="value">
        {{filterModel?.region?.name}}
      </div>
      <ion-buttons>
        <ion-button
          (click)="$event.preventDefault(); $event.stopPropagation(); onUnsetRegionClick(filterModel?.region?.id)">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>

      <ion-ripple-effect></ion-ripple-effect>
    </div>
  </div>

  <div class="chips" *ngFor="let speciality of filterModel?.specialities">
    <div class="chip ripple-parent ion-activatable" [ngClass]="{'empty': filterModel?.specialities.length == 0}"
         (click)="onSetSpecialityClick(speciality.id)">
      <div class="label">
        Мастер
      </div>
      <div class="value">
        {{speciality.name}}
      </div>
      <ion-buttons>
        <ion-button (click)="$event.preventDefault(); $event.stopPropagation(); onUnsetSpecialityClick(speciality.id)">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-ripple-effect></ion-ripple-effect>
    </div>

    <div class="chip ripple-parent ion-activatable" *ngFor="let serviceType of speciality.serviceTypes"
         (click)="onSetServiceTypeClick(speciality.id, serviceType.id)">
      <div class="label">
        Услуга
      </div>
      <div class="value">
        {{serviceType.name}}
      </div>
      <ion-buttons>
        <ion-button (click)="$event.preventDefault(); $event.stopPropagation(); onUnsetServiceTypeClick(serviceType.id)"
                    name="close">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-ripple-effect></ion-ripple-effect>
    </div>

    <div class="chip empty ripple-parent ion-activatable" *ngIf="speciality.serviceTypes.length == 0"
         (click)="onSetServiceTypeClick(speciality.id)">
      <div class="label">
        Услуга
      </div>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
  </div>

  <div class="chips" *ngIf="filterModel?.specialities.length == 0">
    <div class="chip empty ripple-parent ion-activatable" (click)="onSetSpecialityClick()">
      <div class="label">Мастер</div>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
    <div class="chip empty ripple-parent ion-activatable" (click)="onSetServiceTypeClick(null)">
      <div class="label">Услуга</div>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
  </div>

  <div class="chips">
    <div class="chip ripple-parent ion-activatable" [ngClass]="{'empty': filterModel?.sorting == null}"
         (click)="onSetSortingClick()">
      <div class="label">Сортировка</div>
      <div class="value">
        {{filterModel?.sorting | sortingName}}
      </div>
      <ion-buttons>
        <ion-button (click)="$event.preventDefault(); $event.stopPropagation(); onUnsetSortingClick()" name="close">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
  </div>

</div>


