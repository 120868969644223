import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

import {filter} from "rxjs/operators";

import {NgEventBus} from "ng-event-bus";

import {ClientsClient} from "../../api-clients/clients/clients";
import {SaloonsClient} from "../../api-clients/saloon/clients";
import {MasterListClient} from "../../api-clients/master-list/clients";

import {AddBookingReq, BookingClient} from "../../api-clients/booking/clients";

import {BookingWizardController, BookingWizardSettings} from "../controller";

import {SubscriptionsBag} from "../../utils/subscriptions-bag";
import {ActivatedRouteFixService} from "../../utils/activated-route-fix.service";
import {AuthService} from "../../security/auth.service";
import {ClientVm} from "../../client/clients-vm";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {removeTimeZoneOffset} from "../../date-processing/functions";
import {AlertController, LoadingController} from "@ionic/angular";
import {firstValueFrom} from "rxjs";
import {BookingWaiter} from "../../utils/booking-waiter";

@Component({
  selector: 'lib-new-book',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
  host: {'class': 'ion-page'},
  providers: [
    BookingWizardController,
  ],
})
export class NewComponent implements OnInit, OnDestroy {

  private sb = new SubscriptionsBag();

  public constructor(
    private wizardSettings: BookingWizardSettings,
    private wizardController: BookingWizardController,
    private eventBus: NgEventBus,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private applicationIdService: ApplicationIdService,
    private authService: AuthService,
    private clientsClient: ClientsClient,
    private masterListClient: MasterListClient,
    private saloonClient: SaloonsClient,
    private bookingClient: BookingClient,
    private _loadingController: LoadingController,
    private bookingWaiter: BookingWaiter,
    private alertController: AlertController,
  ) {
  }

  private async goToTimeSlots(): Promise<void> {
    await this.router.navigate(
      ['time-slots'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  private async goToSignIn(): Promise<void> {
    await this.router.navigate(
      ['sign-in'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  private async goToClient(): Promise<void> {
    await this.router.navigate(
      ['client'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  private async goToCheck(): Promise<void> {
    await this.router.navigate(
      ['check'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  private async goToConfirmation(): Promise<void> {
    await this.router.navigate(
      ['confirmation'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
      }
    );
  }

  public ngOnInit(): void {

    this.wizardController.init(async () => {

      this.wizardSettings.fillByQueryParamMap(this.activatedRoute.snapshot.queryParamMap);

      this.sb.sub = this.wizardController.servicesData$
        .pipe(filter(sd => !!sd))
        .subscribe(async x => {
          await this.goToTimeSlots();
        });

      this.sb.sub = this.wizardController.timeSlotData$.subscribe(async timeSlotData => {
        if (!timeSlotData) {
          return;
        }

        const loading = await this.createAndShowLoading("Загрузка");

        if (!this.wizardSettings.clientId) {

          if (this.applicationIdService.applicationId === "client") {
            if (this.authService.isAuthenticated) {
              this.wizardSettings.clientId = this.authService.parsedToken.userId;
            } else {
              await this._loadingController.dismiss(loading);
              await this.goToSignIn();
              return;
            }
          }

          if (this.applicationIdService.applicationId === "master") {
            await this._loadingController.dismiss(loading);
            await this.goToClient();
            return;
          }

          if (this.applicationIdService.applicationId === "saloon") {
            await this._loadingController.dismiss(loading);
            await this.goToClient();
            return;
          }

        }

        if (this.applicationIdService.applicationId === "client") {
          const clientRes = await firstValueFrom(this.clientsClient.getClient(this.wizardSettings.clientId, null));
          this.wizardController.setClientVm(ClientVm.ParseFromClient(clientRes));
        }

        if (this.applicationIdService.applicationId === "master") {
          const res = await firstValueFrom(this.masterListClient.getClients(
            this.wizardController.timeSlotData.master.id,
            null,
            [this.wizardSettings.clientId],
            0,
            1,
            true));
          this.wizardController.setClientVm(ClientVm.ParseFromMaster(res.clients[0]));
        }

        if (this.applicationIdService.applicationId === "saloon") {
          const res = await firstValueFrom(this.saloonClient.getClients(
            this.wizardController.timeSlotData.address.addressSaloonId,
            null,
            [this.wizardSettings.clientId],
            0,
            1,
            true));
          this.wizardController.setClientVm(ClientVm.ParseFromSaloon(res.clients[0]));
        }

        await this._loadingController.dismiss(loading);
        await this.goToCheck();
      });

      this.sb.sub = this.wizardController.clientData$
        .pipe(filter(cd => !!cd))
        .subscribe(async cd => {
          await this.goToCheck();
        });

      this.sb.sub = this.wizardController.confirmed$
        .pipe(filter(x => !!x))
        .subscribe(async () => {
          const loading = await this.createAndShowLoading("Создание записи");

          let startTime = removeTimeZoneOffset(this.wizardController.timeSlotData.timeSlot.startTime);

          const req = new AddBookingReq();
          req.clientId = this.wizardController.clientVm.id;
          req.masterId = this.wizardController.timeSlotData.master.id;
          req.priceListItemIds = this.wizardController.timeSlotData.master.priceList?.priceListItems.filter(i => this.wizardController.servicesData.serviceTypeIds.find(stid => stid == i.serviceType.id)).map(i => i.id);
          req.startTime = startTime;
          req.durationInMinutes = this.wizardController.servicesData.durationInMinutes || null;
          req.restInMinutes = this.wizardController.servicesData.restInMinutes || null;
          req.frequency = this.wizardController.servicesData.frequency || null;
          req.notificationRequired = this.wizardController.notificationRequired || false;
          req.reminderBufferInMinutes = this.wizardController.reminderTimeInMinutes || null;
          req.masterNote = this.wizardController.masterNote || null;


          await firstValueFrom(this.bookingClient.addBooking(req))
            .catch(async () => {
              let alert = await this.alertController.create({
                message: 'Что-то пошло не так. Попробуйте снова',
                backdropDismiss: true,
                cssClass: 'center'
              });

              alert.buttons = [{
                text: 'Продолжить',
                handler: () => {
                  alert.dismiss().then();
                }
              }];

              return alert.present().then();
            });

          await this.bookingWaiter.wait(req.masterId, req.clientId, req.startTime);

          this.eventBus.cast('booking:created', {date: this.wizardController.timeSlotData.timeSlot.startTime});

          this.wizardController.dispose();
          await this.closeLoading(loading)
          await this.goToConfirmation();
        });

      if (this.applicationIdService.applicationId == "client" && this.authService.isAuthenticated){
        let masterIds: string[] = [];
        if (this.wizardSettings.saloonId){
          masterIds = (await firstValueFrom(this.saloonClient.getMasterConnections(this.wizardSettings.saloonId))).connections
            .filter(c => c.master != null)
            .map(c => c.master.id);
        }
        else if(this.wizardSettings.masterId){
          masterIds.push(this.wizardSettings.masterId);
        }

        if (masterIds.length) {
          const calcAll = await Promise.all([...masterIds.map(id => firstValueFrom(this.masterListClient.calcPromos(id, this.authService.parsedToken.userId, null)))]);
          this.wizardController.setCalcPromosSubj(calcAll.reduce((a, b) => a.concat(b)));
        }
      }
    }).then();
  }

  public ngOnDestroy(): void {
    this.wizardSettings.clear();
    this.sb.unsubscribeAll();
  }

  private async createAndShowLoading(text: string = null): Promise<HTMLIonLoadingElement> {
    const loading = await this._loadingController.create({
      message: text || 'Загрузка',
      backdropDismiss: false,
    });

    await loading.present();

    return loading;
  }

  private async closeLoading(loading: HTMLIonLoadingElement): Promise<void> {
    await this._loadingController.dismiss(loading);
  }
}
