import {
  CalcPromosRes,
  MasterRes,
  PriceListItemRes,
  SaloonWorkplaceTypeRes,
  SpecialityRes
} from "./../api-clients/master-list/clients";

export class PriceListVm {
  // Не забывать прописывать все филды в методе клон.
  public specialities: Speciality[] = [];
  public currency: number;

  public showHiddenFromClients: boolean = false;

  private wideMode: boolean;

  public get specsAllowedByFilter(): Speciality[] {
    return this.specialities.filter(s => s.allowedByFilter);
  }

  public static Parse(masterRes: MasterRes, specialities: SpecialityRes[], calcPromos: CalcPromosRes[], wideMode: boolean = false): PriceListVm {
    return PriceListVm.ParseFromMasters([masterRes], specialities, calcPromos, wideMode);
  }

  public static ParseFromMasters(masters: MasterRes[], specialities: SpecialityRes[], calcPromos: CalcPromosRes[], wideMode: boolean = false, showHiddenFromClients: boolean = false): PriceListVm {
    const allPriceListItemsForAllMasters: PriceListItemRes[] = masters.reduce((prev, curr) => [...prev, ...curr?.priceList?.priceListItems], []);

    if (masters.length > 0) {
      specialities = masters
        .map(m => m.specialities)
        .reduce((acc, curr) => [...acc, ...curr], [])
        .reduce((acc: Speciality[], curr) => acc.some(s => s.id == curr.id) ? acc : [...acc, curr], [])
        .map(s => specialities.find(s1 => s1.id === s.id)).filter(s => s);
    }

    let priceListVm = new PriceListVm();
    priceListVm.wideMode = wideMode;
    priceListVm.showHiddenFromClients = showHiddenFromClients;

    for (let speciality of specialities) {

      let specialityVm = new Speciality();
      specialityVm.id = speciality.id;
      specialityVm.name = speciality.name;

      let groups = [...speciality.groups];
      groups.reverse();// reverse чтобы сохранить порядок

      while (groups.length > 0) {
        let group = groups.pop();
        for (let g of group.groups) {
          (g as any).displayName = `${group.name} - ${g.name}`;
          groups.push(g);
        }

        let groupVm = new Group();
        groupVm.id = group.id;
        groupVm.name = (group as any).displayName || group.name;

        for (let serviceType of group.serviceTypes) {

          if (serviceType.ownerMasterId && !masters.some(m => m.id == serviceType.ownerMasterId) ||
            serviceType.ownerSaloonId && !masters.some(m => m.addresses.some(a => a.addressSaloonId == serviceType.ownerSaloonId))) {
            continue;
          }

          const relatedResItems = allPriceListItemsForAllMasters.filter(i => i.serviceType.id == serviceType.id);

          const item = new PriceListItem();

          item.id = relatedResItems.length === 1 ? relatedResItems[0].id : null;
          item.serviceTypeId = serviceType.id;
          item.serviceTypeName = serviceType.name;
          item.durationInMinutesMin = relatedResItems.length
            ? relatedResItems
              .map(i => i.durationInMinutesMax)
              .reduce((prev, curr) => curr < prev ? curr : prev, relatedResItems[0].durationInMinutesMax)
            : null;
          item.durationInMinutesMax = relatedResItems.length
            ? relatedResItems
              .map(i => i.durationInMinutesMax)
              .reduce((prev, curr) => curr > prev ? curr : prev, relatedResItems[0].durationInMinutesMax)
            : null;
          item.durationWithRestInMinutesMin = relatedResItems.length
            ? relatedResItems
              .map(i => i.durationInMinutesMax + i.requiredRestInMinutes)
              .reduce((prev, curr) => curr < prev ? curr : prev, relatedResItems[0].durationInMinutesMax + relatedResItems[0].requiredRestInMinutes)
            : null;
          item.durationWithRestInMinutesMax = relatedResItems.length
            ? relatedResItems
              .map(i => i.durationInMinutesMax + i.requiredRestInMinutes)
              .reduce((prev, curr) => curr > prev ? curr : prev, relatedResItems[0].durationInMinutesMax + relatedResItems[0].requiredRestInMinutes)
            : null;
          item.requiredRestInMinutes = relatedResItems.length
            ? relatedResItems
              .map(i => i.requiredRestInMinutes)
              .reduce((prev, curr) => curr > prev ? curr : prev, relatedResItems[0].requiredRestInMinutes)
            : null;
          item.connectedBookingsOnly = relatedResItems.length
            ? relatedResItems
              .map(i => i.connectedBookingsOnly)
              .reduce((acc, curr) => acc && curr, true)
            : false;
          item.isCustom = !!serviceType.ownerMasterId || !!serviceType.ownerSaloonId;
          item.ownerMasterId = serviceType.ownerMasterId;
          item.ownerSaloonId = serviceType.ownerSaloonId;
          item.owner = (!!serviceType.ownerMasterId ? "master" : null) || (!!serviceType.ownerSaloonId ? "saloon" : null);
          item.hiddenFromClients = relatedResItems.length
            ? relatedResItems[0].hiddenFromClients
            : false;
          item.infoText = relatedResItems.length
            ? relatedResItems[0].infoText
            : null;
          item.rank = relatedResItems.length
            ? relatedResItems[0].rank
            : serviceType.rank;
          item.canBeBoockedByMasterOnly = relatedResItems.length
            ? relatedResItems[0].canBeBoockedByMasterOnly
            : false;
          item.saloonWorkplaceTypes = relatedResItems.length
            ? relatedResItems[0].saloonWorkplaceType
            : [];

          item.priceMin = relatedResItems.length
            ? relatedResItems
              .map(i => i.priceMin)
              .reduce((prev, curr) => curr < prev ? curr : prev, relatedResItems[0].priceMin)
            : null;
          item.priceMax = relatedResItems.length
            ? relatedResItems
              .map(i => i.priceMax)
              .reduce((prev, curr) => curr > prev ? curr : prev, relatedResItems[0].priceMax)
            : null;
          item.priceMinWithDiscount = null;
          item.priceMaxWithDiscount = null;

          if (calcPromos?.length){
            for (let relItem of relatedResItems) {
              let promos = calcPromos.filter(p => p.priceListItemId == relItem.id)?.map(p => p.promo);

              for (let promo of promos){
                let priceMin;
                let priceMax;
                if (promo.isPercentValue) {
                  priceMin = relItem.priceMin - promo.value * relItem.priceMin / 100;
                  priceMax = relItem.priceMax - promo.value * relItem.priceMax / 100;
                } else {
                  priceMin = relItem.priceMin - promo.value;
                  if (priceMin < 0){
                    priceMin = 0;
                  }
                  priceMax = relItem.priceMax - promo.value;
                  if (priceMax < 0){
                    priceMax = 0;
                  }
                }
                item.priceMinWithDiscount = item.priceMinWithDiscount ?? item.priceMin > priceMin ?
                  priceMin :
                  item.priceMinWithDiscount ?? item.priceMin;
                item.priceMaxWithDiscount = item.priceMaxWithDiscount ?? item.priceMax > priceMax ?
                  priceMax :
                  item.priceMaxWithDiscount ?? item.priceMax;
              }
            }
          }

          if (relatedResItems.length > 0 || wideMode) {
            groupVm.items.push(item);
          }
        }

        if (groupVm.items.length > 0) {
          groupVm.items.sort((a, b) => a.rank - b.rank)
          specialityVm.groups.push(groupVm);
          specialityVm.serviceTypesCount += groupVm.items.length;
        }
      }

      if (specialityVm.groups.length > 0) {
        priceListVm.specialities.push(specialityVm);
      }
    }

    priceListVm.specialities.sort((a, b) => b.serviceTypesCount - a.serviceTypesCount);
    priceListVm.currency = masters[0]?.priceList?.currency;

    priceListVm.applySearch(null);

    return priceListVm;
  }

  public applySearch(search: string): void {
    for (let spec of this.specialities) {
      for (let group of spec.groups) {
        for (let item of group.items) {
          item.allowedByFilter =
            (
              !item.hiddenFromClients ||
              this.showHiddenFromClients || this.wideMode
            ) &&
            (
              !search ||
              item.serviceTypeName.toLowerCase().includes(search.toLowerCase()) ||
              group.name.toLowerCase().includes(search.toLowerCase()) ||
              spec.name.toLowerCase().includes(search.toLowerCase())
            )
          ;
        }
        group.allowedByFilter = group.items.some(i => i.allowedByFilter);
      }
      spec.allowedByFilter = spec.groups.some(g => g.allowedByFilter);
    }
  }

  public getAllItems(): PriceListItem[] {
    const res = [];

    for (let s of this.specialities) {
      for (let g of s.groups) {
        for (let i of g.items) {
          res.push(i);
        }
      }
    }

    return res;
  }

  public getAllowedByFilterItems(): PriceListItem[] {
    return this.getAllItems().filter(i => i.allowedByFilter);
  }

  public findItemByServiceType(serviceTypeId: string): PriceListItem {
    for (let s of this.specialities) {
      for (let g of s.groups) {
        for (let i of g.items) {
          if (i.serviceTypeId == serviceTypeId) {
            return i;
          }
        }
      }
    }

    return null;
  }

  public updateRanks() {
    for (let i = 0; i < this.specialities.length; i++) {
      this.specialities[i].updateRanks();
    }
  }

  public clone(): PriceListVm {
    const c = new PriceListVm();

    c.specialities = this.specialities.map(x => x.clone());
    c.currency = this.currency;
    c.wideMode = this.wideMode;
    c.showHiddenFromClients = this.showHiddenFromClients;

    return c;
  }

  public get isEmpty(): boolean {
    return this.getAllItems().some(i => i.id != null) == false;
  }
}

export class Speciality {
  public id: string;
  public name: string;

  public allowedByFilter: boolean = true;

  public groups: Group[] = [];

  public get groupsAllowedByFilter(): Group[] {
    return this.groups.filter(g => g.allowedByFilter);
  }

  public serviceTypesCount = 0;

  public updateRanks() {
    for (let i = 0; i < this.groups.length; i++) {
      this.groups[i].updateRanks();
    }
  }

  public clone(): Speciality {
    const c = new Speciality();

    c.id = this.id;
    c.name = this.name;
    c.serviceTypesCount = this.serviceTypesCount;
    c.groups = this.groups.map(x => x.clone());

    return c;
  }
}

export class Group {
  public id: string;
  public name: string;

  public allowedByFilter: boolean = true;

  public items: PriceListItem[] = [];

  public get itemsAllowedByFilter(): PriceListItem[] {
    return this.items.filter(i => i.allowedByFilter);
  }

  public updateRanks() {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].id == null) {
        continue;
      }

      let rank = 0;
      if (i > 0) {
        rank = this.items[i - 1]?.rank + 0.01;
      }

      this.items[i].rank = rank;
    }
  }

  public clone(): Group {
    const c = new Group();

    c.id = this.id;
    c.name = this.name;
    c.items = this.items.map(x => x.clone());

    return c;
  }
}

export class PriceListItem {

  // Когда добавляем сюда свойства - не заобыть обновить метод clone

  id: string;
  serviceTypeId: string;
  serviceTypeName: string;
  rank: number;

  priceMin: number;
  priceMax: number;

  priceMinWithDiscount: number;
  priceMaxWithDiscount: number;
  durationInMinutesMin: number;
  durationInMinutesMax: number;
  durationWithRestInMinutesMin: number;
  durationWithRestInMinutesMax: number;
  requiredRestInMinutes: number;
  infoText: string;

  connectedBookingsOnly: boolean;
  hiddenFromClients: boolean = false;
  canBeBoockedByMasterOnly: boolean = false;
  saloonWorkplaceTypes: SaloonWorkplaceTypeRes[];

  selected: boolean = false;
  isCustom: boolean = false;
  ownerMasterId: string = null;
  ownerSaloonId: string = null;
  owner: "saloon" | "master" | null = null;

  allowedByFilter: boolean = true;

  public get isEmpty(): boolean {
    return this.priceMin == null || this.priceMax == null || this.durationInMinutesMin == null || this.durationInMinutesMax == null;
  }

  public clone(): PriceListItem {
    const c = new PriceListItem();
    c.id = this.id;
    c.serviceTypeId = this.serviceTypeId;
    c.serviceTypeName = this.serviceTypeName;
    c.priceMin = this.priceMin;
    c.priceMax = this.priceMax;
    c.priceMinWithDiscount = this.priceMinWithDiscount;
    c.priceMaxWithDiscount = this.priceMaxWithDiscount;
    c.durationInMinutesMin = this.durationInMinutesMin;
    c.durationInMinutesMax = this.durationInMinutesMax;
    c.durationWithRestInMinutesMin = this.durationWithRestInMinutesMin;
    c.durationWithRestInMinutesMax = this.durationWithRestInMinutesMax;
    c.requiredRestInMinutes = this.requiredRestInMinutes;
    c.infoText = this.infoText;

    c.connectedBookingsOnly = this.connectedBookingsOnly;
    c.hiddenFromClients = this.hiddenFromClients;
    c.canBeBoockedByMasterOnly = this.canBeBoockedByMasterOnly;
    c.saloonWorkplaceTypes = this.saloonWorkplaceTypes;

    c.isCustom = this.isCustom;
    c.owner = this.owner;

    c.rank = this.rank;

    return c;
  }

  public parseFromPriseListItemRes(item: PriceListItemRes): PriceListItem {
    const c = new PriceListItem();
    c.id = item.id;
    c.serviceTypeId = item.serviceType.id;
    c.serviceTypeName = item.serviceType.name;
    c.priceMin = item.priceMin;
    c.priceMax = item.priceMax;
    c.durationInMinutesMax = item.durationInMinutesMax;
    c.requiredRestInMinutes = item.requiredRestInMinutes;
    c.infoText = item.infoText;

    c.connectedBookingsOnly = item.connectedBookingsOnly;
    c.hiddenFromClients = item.hiddenFromClients;
    c.canBeBoockedByMasterOnly = item.canBeBoockedByMasterOnly;
    c.saloonWorkplaceTypes = item.saloonWorkplaceType;

    c.rank = item.rank;

    return c;
  }
}

export class CalcPromosDict {
  masterId: string;
  calcPromos: CalcPromosRes[];
}
