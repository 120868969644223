import {Injectable} from '@angular/core';
import {startWith, switchMap} from "rxjs/operators";
import {catchError, Observable, shareReplay} from "rxjs";
import {CityRes, GeoClient} from "../api-clients/geo/clients";
import {RefreshService} from "../utils/refresh.service";

@Injectable({
  providedIn: 'root'
})
export class CitiesDirectoryService {
  private cache: Observable<CityRes[]> = null;

  public constructor(
    private client: GeoClient,
    private refreshService: RefreshService,
  ) {
  }

  public getCities(): Observable<CityRes[]> {
    if (!this.cache) {
      this.cache = this.refreshService.refreshAndResume$.pipe(
        startWith(null),
        switchMap(() => this.client.getCities()),
        catchError(err => {
          this.cache = null;
          throw err;
        }),
        shareReplay(1),
      )
    }

    return this.cache;
  }
}
