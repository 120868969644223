import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";

import {SaloonProfileComponent} from "./saloon-profile/saloon-profile.component";
import {
  EditorsModule,
  StarsModule,
  FiltersModule,
  ImagesModule,
  PriceListModule,
  UtilsModule,
  AvatarModule,
  BcCommonModule, DateProcessingModule, BookingWithFeedbackModule, ContactsModule
} from "bc-common";
import {SaloonMastersComponent} from "./saloon-masters/saloon-masters.component";
import {FiltersPageModule} from "../filters/filters.module";
import {SaloonInfoComponent} from "./saloon-info/saloon-info.component";
import {SaloonHistoryComponent} from "./saloon-history/saloon-history.component";
import {ComponentsModule} from "../components/components.module";

@NgModule({
  imports: [
    BcCommonModule,
    StarsModule,
    ImagesModule,
    PriceListModule,
    FiltersModule,
    EditorsModule,
    UtilsModule,
    AvatarModule,
    RouterModule,
    DateProcessingModule,
    FiltersPageModule,
    BookingWithFeedbackModule,
    ContactsModule,
    ComponentsModule,
  ],
  declarations: [
    SaloonProfileComponent,
    SaloonMastersComponent,
    SaloonInfoComponent,
    SaloonHistoryComponent
  ],
  exports: [
    SaloonProfileComponent,
    SaloonMastersComponent
  ],
})
export class SaloonsModule {
}
