import {Component, Input, OnInit} from '@angular/core';
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'lib-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss'],
})
export class AddressInfoComponent extends ModalComponentBase implements OnInit {

  @Input()
  public title: string;

  @Input()
  public addressLine1: string;

  @Input()
  public addressLine2: string;

  @Input()
  public addressSaloonId: string;

  @Input()
  public addressSaloonType: string;

  @Input()
  public addressSaloonName: string;

  @Input()
  public addressLongitude: number;

  @Input()
  public addressLatitude: number;

  @Input()
  public addressHowToGetText: string;

  @Input()
  public addressHowToGetImagesNames: string[];

  public constructor(
    modalCtrl: ModalController,
    router: Router,
    activeRoute: ActivatedRoute,
    location: Location,
  ) {
    super(modalCtrl, router, activeRoute, location);
  }

  ngOnInit() {

  }

}
