<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>Запись</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="booking && client" force-overscroll="true">

  <lib-refresher slot="fixed"></lib-refresher>

  <div class="content-section">

    <div class="cards-list">
      <div class="bwf-2 card padding">

        <div class="message" *ngIf="showMasterMessage">

          <ion-list class="transparent">
            <ion-item [button]="isMasterClickable" [detail]="isMasterClickable" (click)="onMasterClick()">
              <ion-avatar slot="start">
                <lib-avatar [name]="bwf.masterName"
                            [avatarFileName]="bwf.masterAvatarFileName">
                </lib-avatar>
              </ion-avatar>
              <ion-label>
                <h4>{{bwf.masterName}}</h4>
                <p>{{bookingSpecialises}}</p>
              </ion-label>
            </ion-item>
          </ion-list>

          <div class="bubble feedback" [ngClass]="{'editable': applicationId == 'master'}" *ngIf="isThereMasterFeedback" (click)="this.onMasterFeedbackClick($event)">
            <div class="stars" *ngIf="applicationId == 'master'">
              <ion-text color="primary" style="flex-grow: 1;">Изменить</ion-text>
              <ion-icon color="primary" class="edit" name="pencil-outline"></ion-icon>
            </div>
            <p *ngIf="bwf.masterText" [innerText]="bwf.masterText"></p>
            <app-images-set *ngIf="bwf.masterImagesFilesNames.length > 0"
                            [imagesInit]="bwf.masterImagesFilesNames"
                            title="Фото">
            </app-images-set>
          </div>

          <div class="bubble danger"
               *ngIf="isCancelled && !isMoved && bwf?.cancelationText && bwf?.canceledByUserWithId == bwf?.masterId ">
            <p>
              <span style="font-weight: 600">Отмена:</span>
              {{bwf?.cancelationText}}
            </p>
          </div>

          <ion-button *ngIf="(applicationId == 'master' && isInPast && !bwf.isCanceled) && !isThereMasterFeedback"
                      (click)="onMasterFeedbackClick($event)">
            <ion-icon name="flower-outline" slot="start"></ion-icon>
            Оформить работу
          </ion-button>

        </div>

        <div class="message" *ngIf="showClientMessage">

          <ion-list class="transparent">
            <ion-item [button]="isClientClickable" [detail]="isClientClickable" (click)="onClientClick()">
              <ion-avatar slot="start">
                <lib-avatar [name]="client.originalName | clientName : client.definedName"
                            [avatarFileName]="bwf.clientAvatarFileName">
                </lib-avatar>
              </ion-avatar>
              <ion-label>
                <h4>{{client.originalName | clientName : client.definedName}}</h4>
                <p *ngIf="client.isAd" class="bc-plus">
                  Клиент привлечён через Бьюти Круг
                  <ion-icon name="person-add-outline" slot="end"></ion-icon>
                </p>
                <p *ngIf="isReminderSent && this.bwf.visitConfirmedAt == null" class="bc-plus">
                  <span>Напоминание отправлено</span>
                  <ion-icon name="checkmark-outline"></ion-icon>
                </p>
                <p *ngIf="this.bwf.visitConfirmedAt != null" class="bc-plus">
                  <span>Визит подтвержден</span>
                  <ion-icon name="checkmark-done-outline"></ion-icon>
                </p>
                <p *ngIf="bwf.feedbackRequestedAt && !isThereClientFeedback && (applicationId == 'master' || applicationId == 'saloon')" class="bc-plus">
                  <span>Отзыв запрошен {{bwf.feedbackRequestedAt | moment:'LLL'}}</span>
                  <ion-icon name="checkmark-done-outline"></ion-icon>
                </p>
              </ion-label>
            </ion-item>
          </ion-list>

          <div class="bubble feedback" [ngClass]="{'editable': applicationId == 'client'}" *ngIf="isThereClientFeedback" (click)="this.onClientFeedbackClick($event)">
            <div class="stars">
              <lib-stars [stars]="bwf.clientStars"></lib-stars>
              <ion-icon color="primary" class="edit" name="pencil-outline"></ion-icon>
            </div>
            <p *ngIf="bwf.clientText" [innerText]="bwf.clientText"></p>
            <app-images-set *ngIf="bwf.clientImagesFilesNames?.length > 0"
                            [imagesInit]="bwf.clientImagesFilesNames"
                            title="Фото">
            </app-images-set>
          </div>

          <div class="bubble danger"
               *ngIf="isCancelled && !isMoved && bwf?.cancelationText && bwf?.canceledByUserWithId == bwf?.clientId ">
            <p>
              <span style="font-weight: 600">Отмена:</span>
              {{bwf?.cancelationText}}
            </p>
          </div>

          <ion-button
            (click)="onClientFeedbackClick($event)"
            *ngIf="(applicationId == 'client' && isInPast && isThisMineBooking && !booking.canceledAt) && !isThereClientFeedback">
            <ion-icon name="thumbs-up-outline" slot="start"></ion-icon>
            Оставить отзыв
          </ion-button>

          <ion-button
            (click)="onVisitConfirmClick($event)"
            *ngIf="(applicationId == 'client' && this.isInFuture && this.isReminderSent && this.bwf.visitConfirmedAt == null && !this.isCancelled)">
            Подтвердить визит
          </ion-button>

          <ion-button
            (click)="onRequestFeedbackClick($event)"
            *ngIf="(applicationId == 'master' && isInPast && isThisMineBooking && !booking.canceledAt) && !isThereClientFeedback && !bwf.feedbackRequestedAt">
            Запросить отзыв
          </ion-button>

          <ion-button
            (click)="onReplyClick($event)"
            *ngIf="(applicationId == 'master') && isThereClientFeedback && !isThereMasterReply">
            Ответить на отзыв
          </ion-button>

        </div>

        <div class="message" *ngIf="isThereMasterReply">

          <ion-list class="transparent">
            <ion-item [button]="isMasterClickable" [detail]="isMasterClickable" (click)="onMasterClick()">
              <ion-avatar slot="start">
                <lib-avatar [name]="bwf.masterName"
                            [avatarFileName]="bwf.masterAvatarFileName">
                </lib-avatar>
              </ion-avatar>
              <ion-label>
                <h4>{{bwf.masterName}}</h4>
                <p>ответ на отзыв:</p>
              </ion-label>
            </ion-item>
          </ion-list>

          <div class="bubble feedback" [ngClass]="{'editable': applicationId == 'master'}" *ngIf="isThereClientFeedback" (click)="onReplyClick($event)">
            <div class="stars" *ngIf="applicationId == 'master'">
              <ion-text color="primary" style="flex-grow: 1;">Изменить</ion-text>
              <ion-icon color="primary" class="edit" name="pencil-outline"></ion-icon>
            </div>
            <p *ngIf="bwf.masterReply" [innerText]="bwf.masterReply"></p>
          </div>

        </div>

        <div class="separator v2"></div>

        <div class="info">
          <div class="date-big">
          <span>
            {{bwf?.startTime | moment :'D MMM'}}, {{bwf?.startTime | moment :'LT'}} - {{bwf?.endTime | moment :'LT'}}
          </span>

            <span class="extra"
                  *ngIf="!isOnline && !isToday && !isTomorrow && !isCancelled && !isMoved && !isInPast">{{bwf?.startTime | moment :'dddd'}}</span>

            <span class="status today" *ngIf="isToday && isInFuture && !isCancelled">сегодня</span>
            <span class="status today" *ngIf="isTomorrow && !isCancelled">завтра</span>

            <span class="status online" *ngIf="isOnline && !isCancelled">в процессе</span>

            <span class="status done" *ngIf="isInPast && !isCancelled && !isMoved">завершено</span>
            <span class="status canceled" *ngIf="isCancelled && !isMoved">отменено</span>
            <span class="status moved" *ngIf="isMoved">перенесено</span>

          </div>

          <div class="address figure-1 ripple-parent ion-activatable clickable-square" (click)="this.onAddressClick()">

            <ng-container *ngIf="bwf?.addressSaloonId">
              <ion-avatar class="avatar">
                <div class="icon-container">
                  <lib-avatar [name]="bwf?.addressSaloonName"
                              [avatarFileName]="bwf?.addressSaloonAvatar">
                  </lib-avatar>
                </div>
              </ion-avatar>
              <div class="text">
                <h4>{{bwf?.addressSaloonType}} "{{bwf?.addressSaloonName}}"</h4>
                <p>{{bwf?.addressLine1}}, {{bwf?.addressLine2}}</p>
              </div>
              <div class="details">
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>
            </ng-container>

            <ng-container *ngIf="!bwf?.addressSaloonId">
              <ion-avatar class="avatar">
                <div class="icon-container">
                  <ion-icon name="location-outline"></ion-icon>
                </div>
              </ion-avatar>
              <div class="text">
                <h4>{{bwf?.addressLine1}}</h4>
                <p>{{bwf?.addressLine2}}</p>
              </div>
              <div class="details">
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>
            </ng-container>

            <ion-ripple-effect></ion-ripple-effect>

          </div>

        </div>

        <div class="services">
          <div class="line" *ngFor="let item of booking?.items">
            <div class="data">
              <span>{{item.serviceTypeName}}</span>
              <span>{{getPriceItem(item)}}</span>
            </div>
            <div class="data primary" *ngIf="item.promoId != null">
              <span>{{item.promoTitle}}</span>
              <span>-{{item.promoValue}}{{item.promoIsPercentValue == true ? '%' : ' BYN'}}</span>
            </div>
            <div class="subtotal" *ngIf="item.promoId != null">
              {{getPriceItemTotal(item)}}
            </div>
          </div>
          <div class="total" *ngIf="booking?.items.length > 1">
            <span>Итого</span>
            <span>{{getPriceTotal()}}</span>
          </div>
        </div>

        <div class="separator v2"></div>

        <ng-container *ngIf="bwf?.sequenceFrequency">
          <div>
            Автоповтор:
            <span *ngIf="bwf?.sequenceFrequency === 1">каждую неделю</span>
            <span *ngIf="bwf?.sequenceFrequency === 2">каждые 2 недели</span>
            <span *ngIf="bwf?.sequenceFrequency === 3">каждые 3 недели</span>
            <span *ngIf="bwf?.sequenceFrequency === 4">каждые 4 недели</span>
          </div>
          <div class="separator v2"></div>
        </ng-container>

        <div class="actions-1">
          <div class="figure-2">
            <ion-toggle class="toggle" [checked]="reminderActivated"
                        (ionChange)="reminderActivated = $event.detail.checked; toggleReminder($event)"></ion-toggle>
            <div class="label">Напомнить</div>
            <div class="options" *ngIf="reminderActivated && reminderItemSelected" (click)="onReminderClick()">
              <div class="current">{{reminderItemSelected.text.toLowerCase()}}</div>
              <div class="details">
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>
            </div>
          </div>

          <div class="actions-1" *ngIf="_applicationIdService.applicationId == 'master'">
            <div class="figure-2">
              <ion-toggle class="toggle" [checked]="masterNoteActivated"
                          (ionChange)="masterNoteActivated = $event.detail.checked; onMasterNoteEnabledClick($event)"></ion-toggle>
              <div class="label">Примечание</div>
              <div class="options" *ngIf="masterNoteActivated" (click)="onMasterNoteClick()">
                <div class="current">{{(booking.masterNote || booking.masterNote == '') ? booking.masterNote : 'пусто'}}</div>
                <div class="details">
                  <ion-icon name="chevron-forward-outline"></ion-icon>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="separator v2"></div>

        <ion-buttons class="action-buttons">
          <ion-button shape="round" (click)="onRepeatBookingClick()">
            <ion-label>
              <ion-icon class="repeat" name="repeat-outline"></ion-icon>
              <label>Повторить</label>
            </ion-label>
          </ion-button>
          <ion-button [disabled]="isCancelled || (!isOnline && !isInFuture)" shape="round"
                      (click)="onMoveBookingClick()">
            <ion-label>
              <ion-icon class="move" name="return-up-forward-outline"></ion-icon>
              <label>Перенести</label>
            </ion-label>
          </ion-button>
          <ion-button [disabled]="isCancelled || (!isOnline && !isInFuture)" shape="round"
                      (click)="onBookingCancelClick()">
            <ion-label>
              <ion-icon class="cancel" name="close-outline"></ion-icon>
              <label>Отменить</label>
            </ion-label>
          </ion-button>
        </ion-buttons>

      </div>
    </div>

  </div>
</ion-content>



