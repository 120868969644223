import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {AuthService, ClientsClient, RefreshService, SubscriptionsBag} from "bc-common";
import {NgEventBus} from "ng-event-bus";
import {combineLatest, of} from "rxjs";
import {finalize, map, startWith, switchMap, tap} from "rxjs/operators";

class MasterItem {
  id: string;
  link: string;
  avatarFileName: string;
  active: boolean;
  lastTouch: Date;
}

@Component({
  selector: 'app-your-masters',
  templateUrl: './your-masters.component.html',
  styleUrls: ['./your-masters.component.scss'],
})
export class YourMastersComponent implements OnInit {

  @Input()
  public hideIfEmpty: boolean = true;

  @HostBinding('hidden')
  public get hidden(): boolean {
    return this.hideIfEmpty && this.empty;
  }

  public get empty(): boolean {
    if (this.items === null) {
      return null;
    }

    return this.items.length === 0;
  }

  public items: MasterItem[] = null;
  public processing: boolean = null;

  private sb: SubscriptionsBag = new SubscriptionsBag();

  constructor(
    public authService: AuthService,
    private clientsClient: ClientsClient.ClientsClient,
    private _refreshService: RefreshService,
    private _eventBus: NgEventBus,
  ) {
  }

  public ngOnInit(): void {
    this.sb.sub = combineLatest([
      this.authService.parsedToken$,
      this._refreshService.refreshAndResume$.pipe(startWith(null)),
      this._eventBus.on('booking:*').pipe(startWith(null)),
      this._eventBus.on('favorite-masters:*').pipe(startWith(null))
    ]).pipe(
      tap(() => this.processing = true),
      map(([token, x]) => token?.userId),
      switchMap(userId => userId
        ? this.clientsClient.getClient(userId, null)
        : of(null)
      ),
      tap(clientRes => {
        if (clientRes) {
          this.items = [
            ...clientRes.favoriteMasters.map(m => ({
              id: m.masterId,
              active: m.active,
              link: '/masters/' + m?.masterId,
              avatarFileName: m.masterAvatarFileName,
              lastTouch: m.lastTouchAt
            }) as MasterItem),
            ...clientRes.favoriteSaloons.map(s => ({
              id: s.saloonId,
              active: s.active,
              link: '/saloons/' + s?.saloonId,
              avatarFileName: s.saloonAvatarFileName,
              lastTouch: s.lastTouchAt
            }) as MasterItem)
          ].sort((x, y) => y.lastTouch.valueOf() - x.lastTouch.valueOf());
        } else{
          this.items = [];
        }
      }),
      finalize(() => {
        this.processing = false;
      })
    ).subscribe(() => {
      this.processing = false;
    });
  }

  public ngOnDestroy(): void {
    this.sb.unsubscribeAll();
  }

  public trackById(index, item: any): any {
    return item.id;
  }
}
