import {Injectable} from "@angular/core";

@Injectable()
export class NavigatorService {
  MAX_ACCURACY_ERROR_METER = 500;

  constructor() {
  }

  async getUserCurrentLocation(): Promise<UserLocation> {

    const geolocation = navigator?.geolocation;

    if (!geolocation) {
      throw new Error('Geolocation is not supported!');
    }

    const promise: Promise<UserLocation> = new Promise((resolve, reject) => {
      geolocation.getCurrentPosition(
        position => {
          let userLocation = new UserLocation();
          userLocation.latitude = position.coords.latitude;
          userLocation.longitude = position.coords.longitude;
          userLocation.isAccurate = position.coords.accuracy <= this.MAX_ACCURACY_ERROR_METER;
          resolve(userLocation);
        },
        positionError => {
          reject(positionError);
        },
        {enableHighAccuracy: true});
    });

    return promise;
  }
}

export class UserLocation {
  public latitude: number;
  public longitude: number;
  public isAccurate: boolean;
}
