<ion-list *ngIf="feedbacks?.length > 0">
  <app-booking-with-feedback
    *ngFor="let item of feedbacks; trackBy: trackById"
    [bwf]="item"
    [mode]="mode"
    (detailsClicked)="onDetailsClicked(item)"
    (masterClicked)="masterClicked.emit(item)"
    (clientClicked)="clientClicked.emit(item)">
  </app-booking-with-feedback>
</ion-list>

<lib-card-spinner *ngIf="this.loading == true && this.feedbacks == null"></lib-card-spinner>

<div *ngIf="this.feedbacks?.length === 0 && this.loading == false" class="ion-text-center">
  Пусто
</div>

<ion-infinite-scroll (ionInfinite)="loadFeedbacksNextPage($event)" [threshold]="100">
  <!--    <ion-infinite-scroll-content loadingText="Загрузка..."></ion-infinite-scroll-content>-->
</ion-infinite-scroll>
