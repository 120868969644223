import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'clientName'
})
@Injectable({providedIn: 'root'})
export class ClientNamePipe implements PipeTransform {

  transform(originalName: string, masterDefinedName: string = null): string {

    if (masterDefinedName && originalName && (masterDefinedName != originalName) && !sameWords(originalName, masterDefinedName)) {
      return `${masterDefinedName} (${originalName})`;
    } else {
      return masterDefinedName || originalName || 'Аноним';
    }
  }

}

function sameWords(originalName: string, masterDefinedName: string): boolean {
  if (originalName.trim().length != masterDefinedName.trim().length){
    return false;
  }
  let wordsA = originalName.split(' ');
  let wordsB = originalName.split(' ');

  if (wordsA.length != wordsB.length){
    return false;
  }

  let sortedA = wordsA.sort((a, b) => a.localeCompare(b));
  let sortedB = wordsB.sort((a, b) => a.localeCompare(b));

  for (let i =0; i < sortedA.length; i++){
    if (sortedA[i] != sortedB[i]){
      return false;
    }
  }

  return true;
}


