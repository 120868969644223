<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{day | moment: 'D MMMM'}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div class="content-section">

    <form [formGroup]="form" *ngIf="form">
      <div class="cards-list form">
        <div class="card padding">
          <ion-list lines="none" class="classic">
            <ion-item [ngClass]="{'lib-invalid': form.errors?.startTimeExceedsEndTime}" [detail]="false">
              <ion-label position="stacked">Начало рабочего дня</ion-label>
              <ion-input formControlName="startTime"
                         (click)="dateTime.openTimePickerForControl(form.controls.startTime);"
                         class="clickable date-picker">{{form.controls.startTime.value | moment : 'LT'}}</ion-input>
            </ion-item>

            <ion-item [ngClass]="{'lib-invalid': form.errors?.startTimeExceedsEndTime}" [detail]="false">
              <ion-label position="stacked">Конец рабочего дня</ion-label>
              <ion-input formControlName="endTime" (click)="dateTime.openTimePickerForControl(form.controls.endTime);"
                         class="clickable date-picker">{{form.controls.endTime.value | moment : 'LT'}}</ion-input>
            </ion-item>
          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>

  </div>

</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block"
                (click)="onOkClick($event)">Сохранить
    </ion-button>
  </div>
</ng-template>
<lib-date-time #dateTime></lib-date-time>



