import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from '@ionic/angular';
import {TextInputPopupComponent} from "./text-input-popup/text-input-popup.component";
import {PhoneChangePopupComponent} from "./phone-change-popup/phone-change-popup.component";
import {TextAreaPopupComponent} from "./text-area-popup/text-area-popup.component";
import {EditScheduleTimeComponent} from "./edit-schedule-time/edit-schedule-time.component";
import {EmptyListPlaceholderComponent} from "./empty-list-placeholder/empty-list-placeholder.component";
import {InstagramChangePopupComponent} from "./instagram-change-popup/instagram-change-popup.component";
import {ReasonForCancellationPopupComponent} from "./reason-for-cancellation-popup/reason-for-cancellation-popup.component";
import {DateProcessingModule} from "../date-processing/date-processing.module";
import {DateTimeComponent} from "./date-time/date-time.component";
import {DateTimeModalComponent} from "./date-time/date-time-modal/date-time-modal.component";
import {UiComponentsModule} from "../ui-components/ui-components.module";
import {DeleteClientModalComponent} from "./delete-client-modal/delete-client-modal.component";
import {NameChangePopupComponent} from "./name-change-popup/name-change-popup.component";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        DateProcessingModule,
        UiComponentsModule,
    ],
  declarations: [
    TextInputPopupComponent,
    TextAreaPopupComponent,
    PhoneChangePopupComponent,
    InstagramChangePopupComponent,
    EditScheduleTimeComponent,
    EmptyListPlaceholderComponent,
    ReasonForCancellationPopupComponent,
    DateTimeComponent,
    DateTimeModalComponent,
    DeleteClientModalComponent,
    NameChangePopupComponent
  ],
  exports: [
    TextInputPopupComponent,
    TextAreaPopupComponent,
    PhoneChangePopupComponent,
    InstagramChangePopupComponent,
    EditScheduleTimeComponent,
    EmptyListPlaceholderComponent,
    ReasonForCancellationPopupComponent,
    DateTimeComponent,
    DateTimeModalComponent,
    DeleteClientModalComponent,
    NameChangePopupComponent
  ]
})
export class EditorsModule {
  static forRoot(): ModuleWithProviders<EditorsModule> {
    return {
      ngModule: EditorsModule,
      providers: []
    };
  }
}
