import {Component, Input, OnInit} from '@angular/core';
import {MasterContactsReq} from "../../api-clients/master-list/clients";
import {ContactsClickHandlerService} from "../contacts-click-handler.service";

@Component({
  selector: 'lib-contacts-info',
  templateUrl: './contacts-info.component.html',
  styleUrls: ['./contacts-info.component.scss'],
})
export class ContactsInfoComponent implements OnInit {

  @Input()
  public contacts: MasterContactsReq;

  constructor(public _contactsClickHandlerService: ContactsClickHandlerService) { }

  ngOnInit() {
  }

}
