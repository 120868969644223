import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SignInController} from "../signInController";
import {NgEventBus} from "ng-event-bus";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'lib-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  host: {'class': 'ion-page'},
})
export class InfoComponent implements OnInit {

  public formGroupInfo: FormGroup;

  public get processing(): boolean {
    return this._signInController.processing;
  }

  get firstNameControl() {
    return this.formGroupInfo.get('firstNameControl');
  }

  get lastNameControl() {
    return this.formGroupInfo.get('lastNameControl');
  }

  public constructor(
    private _signInController: SignInController,
    private _eventBus: NgEventBus,
    public _applicationIdService: ApplicationIdService,
  ) {
  }

  public ngOnInit(): void {

    this.formGroupInfo = new FormGroup({
      firstNameControl: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(80)
      ]),
      lastNameControl: new FormControl(null, [
        Validators.minLength(2),
        Validators.maxLength(80)
      ]),
    });

  }

  public async onSubmit(): Promise<void> {
    if (this.processing || this.formGroupInfo.invalid) {
      this.formGroupInfo.markAllAsTouched();
      return;
    }

    const firstName = this.firstNameControl.value?.trim();
    const lastName = this.lastNameControl.value?.trim();

    await this._signInController.setName(firstName, lastName);
  }

}
