import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonInput, ModalController} from "@ionic/angular";
import {FormControl, Validators} from "@angular/forms";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'text-input-popup',
  templateUrl: './text-input-popup.component.html',
  styleUrls: ['./text-input-popup.component.scss'],
})
export class TextInputPopupComponent extends ModalComponentBase implements OnInit {

  @Input()
  public val: string;

  @Input()
  public title: string = "Введите что-нибудь";

  @Input()
  public label: string;

  @ViewChild("inp", {static: true})
  public inp: IonInput;

  public control: FormControl;

  constructor(
    modalController: ModalController,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location,
    public _applicationIdService: ApplicationIdService,
  ) {
    super(modalController, router, activatedRoute, location);
  }

  public ngOnInit(): void {

    this.control = new FormControl(this.val, [
      Validators.required
    ]);

    setTimeout(async () => {
      await this.inp?.setFocus();
    });
  }

  public async onCloseClick(): Promise<void> {
    await super.dismiss();
  }

  public async onSaveClick(): Promise<void> {
    await super.dismiss(this.control.value?.toString()?.trim());
  }
}
