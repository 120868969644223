import {Component, OnInit, ViewChild} from '@angular/core';
import {IonSelect, ModalController} from "@ionic/angular";
import {CreatePromoReq, MasterListClient, PromoTypeRes} from "../../../api-clients/master-list/clients";
import {PriceListItem, PriceListVm} from "../../../price-list/price-list-vm";
import {ToastService} from "../../../toast/toast.service";
import {AuthService} from "../../../security/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SpecialitiesDirectoryService} from "../../../directories/specialities-directory.service";
import {ChoosePriceListItemPage} from "../../../price-list/choose-price-list-item/choose-price-list-item.page";
import {NavigationService} from "../../../utils/navigation.service";
import {finalize, take} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {PromoTypeEnum} from "../../../utils/promo-type.enum";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'lib-new-promo',
  templateUrl: './new-promo.component.html',
  styleUrls: ['./new-promo.component.scss'],
})
export class NewPromoComponent implements OnInit {
  @ViewChild('promoType')
  public promoTypeSelector: IonSelect;

  public masterId: string = null;
  public processing: boolean = false;
  public promoTypes: PromoTypeRes[] = [];

  public priceListVm: PriceListVm;
  public priceListVmSelected: PriceListVm;
  public priceListItemIdsSelected: string[] = [];

  public backUrl: string;

  public formGroup: FormGroup;

  constructor(
    private _masterListClient: MasterListClient,
    private _toastService: ToastService,
    private _authService: AuthService,
    private _router: Router,
    private _specialitiesDirectory: SpecialitiesDirectoryService,
    private _modalController: ModalController,
    private _activatedRoute: ActivatedRoute,
    private _navigationService: NavigationService,
    private _formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.backUrl = this._router.createUrlTree(['../'], {relativeTo: this._activatedRoute}).toString();
    this.masterId = this._authService.parsedToken?.userId;

    this.processing = true;

    combineLatest([
      this._masterListClient.getMasterPromosByMasterId(this.masterId),
      this._masterListClient.getMasterById(this.masterId),
      this._masterListClient.getMasterPromoTypes(),
      this._specialitiesDirectory.getSpecialities([this.masterId], true).pipe(take(1)),
    ]).subscribe(([promos, master, promoTypes, specialities]) => {
      this.promoTypes = promoTypes
        .filter(t =>
          promos
            .map(mp => mp.type.type)
            .indexOf(t.type) == -1);

      this.priceListVm = PriceListVm.Parse(master, specialities, null, false);

      this.priceListVmSelected = this.priceListVm.clone();
      this.priceListItemIdsSelected = this.priceListVm.getAllItems().map(i => i.id);

      this.processing = false;
    });
  }

  public initForm(promoType: PromoTypeEnum) {
    this.formGroup = this._formBuilder.group({
      startTimeOffset: new FormControl<Date>(null, [Validators.required]),
      endTimeOffset: new FormControl<Date>(null, [Validators.required]),
      value: [null, [Validators.required]],
      isPercentValue: [false, [Validators.required]],
      promoType: promoType
    });

    if (promoType == PromoTypeEnum.bogof) {
      this.formGroup.addControl('bookingsCount',
        new FormControl(2, [Validators.required, Validators.min(2), Validators.max(10)]));
    }

    if (promoType == PromoTypeEnum.hotWindow) {
      this.formGroup.addControl('bufferDays',
        new FormControl(1, [Validators.required, Validators.min(1)]));
    }

    this.formGroup.controls.isPercentValue.valueChanges.subscribe(value => {
      if (value == true) {
        this.formGroup.controls.value.setValidators([Validators.min(1), Validators.max(100)]);
        this.formGroup.controls.value.updateValueAndValidity();
        this.formGroup.controls.value.markAsTouched();
      } else {
        this.formGroup.controls.value.clearValidators();
        this.formGroup.controls.value.updateValueAndValidity();
      }
    });
  }

  onSelectChange(event: CustomEvent){
    this.initForm(event.detail.value);
  }

  async onSubmitClick() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.processing = false;
      return;
    }

    this.processing = true;

    let req: CreatePromoReq;
    req = this.formGroup.getRawValue() as CreatePromoReq;
    req.masterId = this.masterId;
    req.priceListItemIds = this.priceListItemIdsSelected;

    this._masterListClient.createPromo(req)
      .pipe(finalize(() => this.processing = false))
      .subscribe(() => {
        this._toastService.success("Акция добавлена");
        this._navigationService.goBack();
      }, () => {
        this._toastService.error("Произошла ошибка");
      });
  }

  public async onChooseItemClick() {
    const modal = await this._modalController.create({
      component: ChoosePriceListItemPage,
      componentProps: {
        priceList: this.priceListVm,
      },
    });

    modal.onDidDismiss().then(async (modal) => {
      if (this.priceListItemIdsSelected.some(i => i == modal.data.id)) {
        return;
      }

      this.priceListItemIdsSelected.push(modal.data.id);
      await this.updateSelectedVm();

      await this._toastService.success("Услуга добавлена");
    });

    await modal.present();
  }

  public async onUnchooseItemClick(item: PriceListItem) {
    this.priceListItemIdsSelected = this.priceListItemIdsSelected.filter(i => i != item.id);
    await this.updateSelectedVm();
  }

  public async updateSelectedVm() {
    let priceListVm = this.priceListVm.clone();

    for (let spec of priceListVm.specialities) {
      for (let group of spec.groups) {
        group.items = group.items.filter(i => this.priceListItemIdsSelected.some(p => p == i.id));
      }
      spec.groups = spec.groups.filter(g => g.items.length != 0);
    }

    this.priceListVmSelected = priceListVm;
  }
}
