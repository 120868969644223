import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {BcCommonModule} from "../bc-common.module";
import {BookingWithFeedbackComponent} from "./booking-with-feedback.component";
import {StarsModule} from "../stars/stars.module";
import {ImagesModule} from "../images/images.module";
import {DateProcessingModule} from "../date-processing/date-processing.module";
import {BookingsWithFeedbackListComponent} from "./bookings-with-feedback-list/bookings-with-feedback-list.component";
import {UtilsModule} from "../utils/utils.module";
import {EditorsModule} from "../editors/editors.module";
import {AvatarModule} from "../avatar/avatar.module";
import {FeedbackPageComponent} from "./feedback-page/feedback-page.component";
import {RouterModule} from "@angular/router";
import {StarRatingModule} from "../star-rating/star-rating.module";

@NgModule({
  imports: [
    BcCommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    StarsModule,
    ImagesModule,
    DateProcessingModule,
    UtilsModule,
    EditorsModule,
    AvatarModule,
    RouterModule,
    AvatarModule,
    StarRatingModule,
  ],
  exports: [
    BookingWithFeedbackComponent,
    BookingsWithFeedbackListComponent,
    FeedbackPageComponent
  ],
  declarations: [
    BookingWithFeedbackComponent,
    BookingsWithFeedbackListComponent,
    FeedbackPageComponent
  ]
})
export class BookingWithFeedbackModule {
}
