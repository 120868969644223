import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import {PriceListComponent} from "./price-list/price-list.component";
import {ChoosePriceListItemPage} from "./choose-price-list-item/choose-price-list-item.page";
import {EditPriceListItemComponent} from "./edit-price-list-item/edit-price-list-item.component";
import {AddPriceListItemComponent} from "./add-price-list-item/add-price-list-item.component";
import {DateProcessingModule} from "../date-processing/date-processing.module";
import {PricePipe} from "./price.pipe";
import {PriceRangePipe} from "./price-range.pipe";
import {CurrencyPipe} from "./currency.pipe";
import {ChoosePriceListCurrencyComponent} from "./choose-price-list-currency/choose-price-list-currency.component";
import {CurrencyArrayBuilder} from "./currency";
import {EditorsModule} from "../editors/editors.module";
import {EditPriceListItemNameComponent} from "./edit-price-list-item-name/edit-price-list-item-name.component";
import {ImagesModule} from "../images/images.module";
import {PriceListItemInfoComponent} from "./price-list-item-info/price-list-item-info.component";
import {StrapiModule} from "../strapi/strapi.module";
import {DurationRangePipe} from "./duration-range.pipe";
import {UiComponentsModule} from "../ui-components/ui-components.module";
import {
  ChooseSaloonWorkplaceTypeComponent
} from "./choose-saloon-workplace-type/choose-saloon-workplace-type.component";
import {PriceListEditComponent} from "./price-list-edit/price-list-edit.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonicModule,
    DateProcessingModule,
    EditorsModule,
    ImagesModule,
    StrapiModule,
    UiComponentsModule
  ],
  declarations: [
    PriceListComponent,
    PriceListEditComponent,
    ChoosePriceListItemPage,
    EditPriceListItemComponent,
    AddPriceListItemComponent,
    EditPriceListItemNameComponent,
    ChoosePriceListCurrencyComponent,
    PricePipe,
    PriceRangePipe,
    DurationRangePipe,
    CurrencyPipe,
    PriceListItemInfoComponent,
    ChooseSaloonWorkplaceTypeComponent
  ],
  exports: [
    PriceListComponent,
    PriceListEditComponent,
    ChoosePriceListItemPage,
    EditPriceListItemComponent,
    AddPriceListItemComponent,
    EditPriceListItemNameComponent,
    ChoosePriceListCurrencyComponent,
    PricePipe,
    CurrencyPipe,
    PriceRangePipe,
    PriceListItemInfoComponent,
    DurationRangePipe,
    ChooseSaloonWorkplaceTypeComponent
  ],
  providers: [
    CurrencyPipe,
    CurrencyArrayBuilder,
  ]
})
export class PriceListModule {
}
