<div class="banner b2">
  <img src="assets/banners/banner6.png"/>
  <h2>
    Внесите записи
  </h2>
  <p>
    Чтобы мы правильно определили "окошки"
  </p>
  <ion-button fill="clear" routerLink="./../bookings/new">Запись</ion-button>
</div>

