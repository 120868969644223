<div class="card padding" *ngIf="items !== null">
  <h2>
    <span>Ваши мастера</span>
    <a [routerLink]="['/catalog']">Найти</a>
  </h2>

  <lib-card-spinner *ngIf="this.processing == true && items?.length == 0"></lib-card-spinner>

  <div class="ion-text-center" *ngIf="items?.length === 0">
    Пусто
  </div>

  <div class="masters-row" style="min-height: 81px" *ngIf="items?.length > 0">

    <div class="master" [ngClass]="{'active': item.active}" [routerLink]="item.link"
         *ngFor="let item of items; trackBy: trackById">
      <lib-avatar [avatarFileName]="item.avatarFileName"></lib-avatar>
    </div>

    <button [routerLink]="'/catalog'">+</button>
  </div>

</div>
