import {Component, OnInit} from '@angular/core';
import {hasSameDate, MasterListClient, SaloonClient, TrackingService} from "bc-common";
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment";

@Component({
  selector: 'app-saloon-masters',
  templateUrl: './saloon-masters.component.html',
  styleUrls: ['./saloon-masters.component.scss'],
})
export class SaloonMastersComponent implements OnInit {

  public saloon: SaloonClient.Saloon = null;

  public masters: MasterListClient.MasterRes[] = [];
  public loading: boolean = false;
  public now: Date = new Date();


  constructor(
    private activatedRoute: ActivatedRoute,
    private saloonClient: SaloonClient.SaloonsClient,
    private masterListClient: MasterListClient.MasterListClient,
    private trackingService: TrackingService,
  ) {
  }

  ngOnInit() {
    this.loading = true;
    const saloonId = this.activatedRoute.snapshot.paramMap.get('id');
    const refCode = parseInt(this.activatedRoute.snapshot.paramMap.get('refCode'));

    if (refCode) {
      this.trackingService.setSaloonRefCode(refCode).then();
    }

    let dataPromise = null;

    if (saloonId) {
      dataPromise = this.saloonClient.getSaloonById(saloonId).toPromise();
    } else if (refCode) {
      dataPromise = this.saloonClient.getSaloonByReferalCode(refCode).toPromise();
    }

    dataPromise
      .then(async (saloonRes: SaloonClient.GetSaloonByIdRes) => {
        this.saloon = saloonRes.saloon;
        this.trackingService.track(null, this.saloon.id).then();

        return this.masterListClient.searchMasters(
          null,
          null,
          [this.saloon.id],
          null,
          null,
          null,
          null,
          0,
          100,
          2,
          null,
          null
        ).toPromise();
      })
      .then(masters => {
        this.masters = masters;
        this.loading = false;
      });
  }

  public dateToFromNowDaily(date): string {
    return moment(date).locale('ru').calendar(null, {
      sameDay: '[сегодня], D MMMM',
      nextDay: '[завтра], D MMMM',
      nextWeek: 'dddd, D MMMM',
      sameElse: 'dddd, D MMMM'
    });
  }

  public trackById(index, item: any): any {
    return item.id;
  }

  protected readonly hasSameDate = hasSameDate;
}
