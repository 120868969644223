import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {IonicModule, } from '@ionic/angular';

import {MastersRoutingModule} from './masters-routing.module';
import {MastersListComponent} from './masters-list/masters-list.component';
import {MasterProfileComponent} from './master-profile/master-profile.component';
import {ReactiveFormsModule} from "@angular/forms";
import {FiltersPageModule} from "../filters/filters.module";
import {
  BookingWithFeedbackModule,
  EditorsModule,
  StarsModule,
  FiltersModule,
  ImagesModule,
  PriceListModule,
  UtilsModule,
  AvatarModule,
  UiComponentsModule, DateProcessingModule, ContactsModule, BannerSliderModule
} from "bc-common";
import {MasterInfoComponent} from "./master-info/master-info.component";
import {MasterHistoryComponent} from "./master-history/master-history.component";
import {ComponentsModule} from "../components/components.module";

@NgModule({
  declarations: [MastersListComponent, MasterProfileComponent, MasterInfoComponent, MasterHistoryComponent],
  exports: [
    MastersListComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    MastersRoutingModule,
    ReactiveFormsModule,
    FiltersPageModule,
    BookingWithFeedbackModule,
    StarsModule,
    ImagesModule,
    PriceListModule,
    FiltersModule,
    EditorsModule,
    UtilsModule,
    AvatarModule,
    UiComponentsModule,
    DateProcessingModule,
    ComponentsModule,
    ContactsModule,
    BannerSliderModule,
  ],
  // providers: [
  //   {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  // ],
})
export class MastersModule {
}
