import {Component, OnInit} from '@angular/core';
import {AuthService, ClientsClient, SubscriptionsBag} from "bc-common";

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {
  private _sb: SubscriptionsBag = new SubscriptionsBag();

  public processing: boolean = true;

  public client: ClientsClient.GetClientRes = null;

  constructor(
    public authService: AuthService,
  ) {

  }

  public ngOnInit(): void {
  }

  public ngOnDestroy() {
    this._sb.unsubscribeAll();
  }

  public trackById(index, item: any): any {
    return item.id;
  }
}
