<ion-header>
  <ion-toolbar>
    <ion-title>Изменить имя</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <form [formGroup]="form">
      <div class="cards-list form">
        <div class="card padding">
          <ion-list lines="none" class="classic">
            <ion-item>
              <ion-label position="stacked">Имя</ion-label>
              <ion-input formControlName="firstName">
              </ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Фамилия</ion-label>
              <ion-input formControlName="lastName">
              </ion-input>
            </ion-item>
          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block"
                (click)="onSubmitClick($event)">Сохранить
    </ion-button>
  </div>
</ng-template>
