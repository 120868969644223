import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-become-master-promo',
  templateUrl: './become-master.component.html',
  styleUrls: ['./become-master.component.scss'],
})
export class BecomeMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}
