import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {TokenStoreService} from "../../security/token-store.service";
import {ToastService} from "../../toast/toast.service";
import {DeleteConnectionReq, GetActiveConnectionRes, NotificationClient} from "../../api-clients/notification/client";
import {BehaviorSubject, repeat, Subject, takeWhile} from "rxjs";
import {AuthService} from "../../security/auth.service";
import {AlertController, IonAccordionGroup, ModalController} from "@ionic/angular";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {NgEventBus} from "ng-event-bus";

@Component({
  selector: 'lib-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
  host: {'class': 'card padding'},
})
export class ConnectComponent implements OnInit {
  public code: string = null;
  public userId: string = null;

  public activeConnection: GetActiveConnectionRes = undefined;
  public activeConnection$ = new Subject<GetActiveConnectionRes>;
  public unsubscribeLoad$ = new BehaviorSubject<boolean>(false);

  public processing: boolean = false;

  constructor(
    public _applicationIdService: ApplicationIdService,
    private _tokenStore: TokenStoreService,
    private _toastService: ToastService,
    private _notificationClient: NotificationClient,
    private _authService: AuthService,
    private _alertController: AlertController,
    private _eventBus: NgEventBus,
    private _modalController: ModalController,
  ) {
  }

  public ngOnInit() {
    this.code = `/connect/${this._tokenStore.getToken()}`;
    this.userId = this._authService.parsedToken.userId;

    this.refreshConnection();
  }

  public ngOnDestroy(): void {
    this.unsubscribeLoad$.next(true);
  }

  public async onCopyClick($event) {
    try {
      navigator.clipboard.writeText(this.code).then();
      this._toastService.success("Cкопировано").then();
    } catch {
      this._toastService.error("Не получилось скопировать").then();
    }

    $event.preventDefault();
    $event.stopPropagation();
  }

  public refreshConnection() {
    this.activeConnection = undefined;
    this.activeConnection$.next(null);
    this.unsubscribeLoad$.next(false);
    this.processing = true;

    let connectionData$ = this._notificationClient.getActiveConnection(this.userId);

    connectionData$
      .pipe(
        repeat({delay: 1000}),
        takeWhile(() => !this.unsubscribeLoad$.value),
      )
      .subscribe(data => {
          this.processing = false;
          if (data.botConnectionType != 0) {
            this.activeConnection = data;
            this.activeConnection$.next(data);
            this.unsubscribeLoad$.next(true);
          }
        });
  }

  public async onDeleteConnectionClick() {
    const alert = await this._alertController.create({
      header: 'Отключение бота',
      message: 'Вы уверены, что хотите отключить бота?',
      cssClass: 'center',
      buttons: [
        {
          text: 'Отключить',
          handler: async () => {
            this._notificationClient.deleteConnection({userId: this.userId} as DeleteConnectionReq).toPromise()
              .then(() => {
                  this.refreshConnection();
                  this._eventBus.cast("profile:bot-removed");
                }
              )
              .catch((err) => this._toastService.error('Произошла ошибка.'));
          }
        },
        {
          text: 'Отмена',
          role: 'cancel',
        }
      ]
    });

    await alert.present();
  }
}
