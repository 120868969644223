import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {PromoTypeEnum} from "../../utils/promo-type.enum";

@Component({
  selector: 'lib-promo-form',
  templateUrl: './promo-form.component.html',
  styleUrls: ['./promo-form.component.scss'],
})
export class PromoFormComponent  implements OnInit {
  @Input()
  public formGroup: FormGroup;

  public now = (new Date()).toISOString();

  constructor() { }

  ngOnInit() {
  }

  get promoType(): PromoTypeEnum{
    return this.formGroup.get('promoType')?.value;
  }

  protected readonly PromoTypeEnum = PromoTypeEnum;
}
