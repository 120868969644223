<ion-header translucent="true" class="hide-mobile">
  <ion-toolbar>
    <ion-title>Мастера</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <lib-refresher slot="fixed"></lib-refresher>

  <div class="content-section">

    <div class="cards-list v2">

      <div class="head card padding-mobile transparent" style="overflow: visible">
        <app-filters [filterModel]="filtersModel"></app-filters>
      </div>

      <lib-banner-slider [banners]="['active-masters']"></lib-banner-slider>

    </div>

    <div class="masters-list">

      <div class="cards-list">
        <div class="card master" *ngFor="let master of vm?.Masters; trackBy: trackById"
             [routerLink]="['/masters', master.id]">
          <div class="head">
            <ion-avatar>
              <lib-avatar *ngIf="master"
                          class="ion-margin-end"
                          [name]="master.fullName"
                          [avatarFileName]="master.avatarFileName">
              </lib-avatar>
            </ion-avatar>
            <div>
              <h3>
                {{ master.fullName }}
              </h3>
              <p class="specs">
                {{ master | specialitiesPipe }}
              </p>
              <p
                *ngIf="master?.stars || master?.reviewsCount || master?.returnabilityPercent || master?.cancellationsPercent !== null || master?.movedPercent !== null"
                class="digits">
                  <span *ngIf="master?.stars" class="digit star">
                    <span title="Оценка">
                      <ion-icon name="star"></ion-icon>
                      <ion-text> {{ master?.stars | number: '1.1-1' }}
                        <span class="top"> {{ master?.reviewsCount }}</span>
                      </ion-text>
                    </span>
                  </span>

                <span *ngIf="master?.returnabilityPercent" class="digit returnability">
                    <span title="Возвращаемость">
                      <ion-icon name="repeat-outline"></ion-icon>
                      <ion-text> {{ master?.returnabilityPercent }}
                        <span class="bottom"> %</span>
                      </ion-text>
                    </span>
                  </span>

                <span *ngIf="master?.cancellationsPercent !== null || master?.movedPercent !== null"
                      class="digit reliability">
                    <span title="Надёжность">
    <!--                  <ion-icon name="shield-outline"></ion-icon>-->
                      <ion-icon name="shield-checkmark-outline"></ion-icon>
                      <ion-text> {{ 100 - (master?.cancellationsPercent + master?.movedPercent) }}<span
                        class="bottom"> %</span></ion-text>
                    </span>
                  </span>

                <ion-ripple-effect></ion-ripple-effect>
              </p>
            </div>
          </div>

          <!--          <lib-short-description-->
          <!--            [description]="master?.aboutMeText"-->
          <!--            mergeWithNewLine="false"-->
          <!--            linesCount="2">-->
          <!--          </lib-short-description>-->

          <div class="description"
               *ngIf="master?.aboutMeText != null && master?.aboutMeText?.trim().length">{{ master?.aboutMeText }}
          </div>

          <ion-list class="transparent" *ngIf="master?.addresses?.length > 0">
            <ion-item *ngIf="master.addresses[0].addressSaloonId == null">
              <ion-icon slot="start" name="location-outline"
                        style="margin: 0; margin-right: 5px; font-size: 16px"></ion-icon>
              <ion-label>
                <h4>{{ master.addresses[0].addressLine1 }}</h4>
                <p>{{ master.addresses[0].addressLine2 }}</p>
              </ion-label>
              <ion-button slot="end" *ngIf="master.distance != null">{{ master.distance }} км</ion-button>
            </ion-item>

            <ion-item *ngIf="master.addresses[0].addressSaloonId != null">
              <ion-icon slot="start" name="location-outline"
                        style="margin: 0; margin-right: 5px; font-size: 16px"></ion-icon>
              <ion-label>
                <h4>{{ master.addresses[0].addressSaloonType }} {{ master.addresses[0].addressSaloonName }}</h4>
                <p>{{ master.addresses[0].addressLine1 | addressPipe :master.addresses[0].addressLine2 }}</p>
              </ion-label>
              <ion-button slot="end" *ngIf="master.distance != null">{{ master.distance }} км</ion-button>
            </ion-item>
          </ion-list>

          <div *ngIf="master?.slogan" class="text">
            {{ master.slogan }}
          </div>

          <ion-list>
            <ng-container *ngFor="let promo of master.promos; trackBy: trackById">
              <ion-item *ngIf="(promo.startTimeOffset < now && promo.endTimeOffset > now) ||
              hasSameDate(now, promo.startTimeOffset) ||
              hasSameDate(now, promo.endTimeOffset)" class="promo colored-primary" detail="false" button="true">
                <ion-label>
                  <div>{{ promo.title }}</div>
                  <p class="info">
                    <span class="discount">
                      {{ promo.isPercentValue == true ? '- ' + promo.value + ' %' : '- ' + promo.value + ' BYN' }}
                    </span>
                    <span class="dates">
                      <span *ngIf="promo.endTimeOffset">
                        до {{ promo.endTimeOffset | moment:'D MMM' }}
                      </span>
                    </span>
                  </p>
                </ion-label>
              </ion-item>
            </ng-container>
            <ion-item *ngIf="master.nearestDays?.length > 0" class="master-details colored-secondary" detail="false" button="true">
              <ion-label>
                <div>Онлайн запись</div>
                <p class="days" *ngIf="master.nearestDays.length > 0">
                  <span *ngFor="let day of master.nearestDays" class="day">
                    <span>{{dateToFromNowDaily(day)}} </span>
                  </span>
                </p>
              </ion-label>
              <div slot="end" class="online" ></div>
            </ion-item>
            <ion-item *ngIf="master.nearestDays?.length === 0" class="master-details colored-secondary" detail="false" button="true">
              <ion-label>
                <div>Запись отключена</div>
                <p class="days" *ngIf="master.nearestDays.length === 0">
                  Оставьте номер для связи
                </p>
              </ion-label>
              <div slot="end" class="offline" *ngIf="master.nearestDays?.length === 0"></div>
            </ion-item>
          </ion-list>
        </div>

        <lib-card-empty *ngIf="this.loading == false && this.vm?.Masters?.length === 0">
          <div>
            Нет свободных мастеров
          </div>
          <div style="color: #888888; font-size: 14px">
            <a href="#" (click)="$event.preventDefault(); $event.stopPropagation(); onFiltersClearClick(); ">
              Сбросить фильтр
            </a>
          </div>
        </lib-card-empty>

        <lib-card-spinner *ngIf="this.loading == true"></lib-card-spinner>

      </div>

    </div>

    <ion-infinite-scroll (ionInfinite)="loadNextPageOfMasters($event)">
      <!--        <ion-infinite-scroll-content loadingText="Загрузка..."></ion-infinite-scroll-content>-->
    </ion-infinite-scroll>

  </div>

</ion-content>

