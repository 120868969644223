import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {MasterListClient} from "./master-list/clients";
import {FeedbackClient} from "./feedback/clients";
import {ClientsClient} from "./clients/clients";
import {BookingClient} from "./booking/clients";
import {AuthenticationClient} from "./authentication/clients";
import {NotificationClient} from "./notification/client";
import {BalanceClient} from "./balance/clients";
import {SaloonsClient} from "./saloon/clients";
import {GeoClient} from "./geo/clients";
import {TrackingClient} from "./tracking/clients";
import {FilesClient} from "./file/clients";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    MasterListClient,
    FeedbackClient,
    ClientsClient,
    BookingClient,
    AuthenticationClient,
    NotificationClient,
    BalanceClient,
    SaloonsClient,
    GeoClient,
    TrackingClient,
    FilesClient
  ],
})
export class ApiClientsModule {
  // Не надо добавлять этот сервис в зависимости.
  // Клиленты должны быть сконфигурированы только в корневом модуле приложений.
  // Возможно, надо будет сделать тут метод фор рут типа forRoot({masterBaseUrl: '123', feedbackBaseUrl: '123'})
}
