import {Component, OnDestroy, OnInit} from '@angular/core';
import {SignInController} from "../signInController";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivatedRouteFixService} from "../../utils/activated-route-fix.service";
import {SubscriptionsBag} from "../../utils/subscriptions-bag";
import {SignInSettings} from "../signInSettings";
import {ToastService} from "../../toast/toast.service";
import {delay, retryWhen, take} from "rxjs/operators";
import {AuthService} from "../../security/auth.service";
import {ClientsClient, UpdateClientReq} from "../../api-clients/clients/clients";
import {MasterListClient, UpdateMasterNameReq} from "../../api-clients/master-list/clients";
import {SaloonsClient, UpdateOwnerNameReq, UpdateSaloonAdminNameReq} from "../../api-clients/saloon/clients";
import {NgEventBus} from "ng-event-bus";

@Component({
  selector: 'lib-sign-in-root',
  templateUrl: './sign-in-root.component.html',
  styleUrls: ['./sign-in-root.component.scss'],
  host: {'class': 'ion-page'},
  providers: [
    SignInController
  ],
})
export class SignInRootComponent implements OnInit, OnDestroy {
  private _sb = new SubscriptionsBag();

  constructor(
    private signInSettings: SignInSettings,
    private signInController: SignInController,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private _authService: AuthService,
    private _clientsClient: ClientsClient,
    private _mastersListClient: MasterListClient,
    private _saloonClient: SaloonsClient,
    private toastService: ToastService,
    private eventBus: NgEventBus,
  ) {
  }

  private async goToBackUrl(): Promise<void> {
    if (this.signInSettings.backUrl) {
      await this._router.navigateByUrl(this.signInSettings.backUrl, {replaceUrl: true}).then();
    } else {
      await this._router.navigate(['/'], {replaceUrl: true}).then();
    }
  }

  private async goToInfo(): Promise<void> {
    await this._router.navigate(['info'],
      {
        relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute),
        replaceUrl: true
      }
    ).then();
  }

  public ngOnInit(): void {

    this._sb.sub = this.signInController.phoneSet$.subscribe(async () => {
      await this.signInController.handleProcessing(async () => {

        await this._authService.signInStep1(this.signInController.phone, this.signInSettings.role);
        await this._router.navigate(['code'], {
          relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)
        }).then();

      });
    });

    this._sb.sub = this.signInController.codeSet$.subscribe(async () => {
      await this.signInController.handleProcessing(async () => {

        try {
          await this._authService.signInStep2(
            this.signInController.phone,
            this.signInController.code,
            this.signInSettings.role
              ? [this.signInSettings.role]
              : undefined
          );
        } catch (e) {
          if (e.result.code == "invalid_authentication_code") {
            await this.toastService.error('Неверный код');
          } else {
            await this.toastService.error('Что-то пошло не так. Попробуйте снова');
          }
          return;
        }


        if (this.signInSettings.role == 'Client') {
          const clientId = await this._authService.parsedToken?.userId;
          const clientData = await this._clientsClient.getClient(clientId, null)
            .pipe(retryWhen(errors => errors.pipe(delay(1000), take(10))))
            .toPromise();

          if (!clientData || !clientData.fullName) {
            await this.goToInfo();
            return;
          }
        }

        this.eventBus.cast('sign-in:done');
        await this.goToBackUrl();

      });
    });

    this._sb.sub = this.signInController.nameSet$.subscribe(async () => {
      await this.signInController.handleProcessing(async () => {

        this.eventBus.cast("user:name-changed");

        if (this.signInSettings.role == "Client") {
          const clientId = this._authService.parsedToken?.userId;

          const req = new UpdateClientReq();
          req.id = clientId;
          req.firstName = this.signInController.firstName;
          req.lastName = this.signInController.lastName;
          req.instagramProfile = null;

          await this._clientsClient.updateClient(req).toPromise();
        }

        await this._authService.init();
        this.eventBus.cast('sign-in:done');
        await this.goToBackUrl();

      });
    });
  }

  public ngOnDestroy(): void {
    this._sb.unsubscribeAll();
  }
}
