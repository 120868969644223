import { Component, OnInit } from '@angular/core';
import {AddressController, AddressHowToGetData} from "../../addressController";

@Component({
  selector: 'lib-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class Step3Component implements OnInit {

  constructor(
    public _addressController: AddressController
  ) { }

  ngOnInit() {}

  onFormSubmit(data: AddressHowToGetData) {
    this._addressController.addressData.setAddressHowToGetData(data);
  }

}
