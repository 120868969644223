import { Component, OnInit } from '@angular/core';
import {AddressController, AddressLineData} from "../../addressController";

@Component({
  selector: 'lib-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {

  constructor(
    private _addressController: AddressController
  ) {
  }

  ngOnInit() {
  }

  onFormSubmit(data: AddressLineData) {
    this._addressController.addressData.setAddressLineData(data);
  }
}
