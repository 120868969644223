import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from "./currency.pipe";
import {DurationPipe} from "../date-processing/duration.pipe";

@Pipe({
  name: 'durationRange'
})
export class DurationRangePipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
    private durationPipe: DurationPipe,
  ) {
  }

  public transform(value: number, maxValue: number): string {
    if(value == maxValue){
      return `${this.durationPipe.transform(value, 'minutes')}`;
    } else{
      return `${this.durationPipe.transform(value, 'minutes')} - ${this.durationPipe.transform(maxValue, 'minutes')}`;
    }
  }

}

