import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-card-spinner',
  templateUrl: './card-spinner.component.html',
  styleUrls: ['./card-spinner.component.scss'],
  host: {'class': 'card transparent padding ion-text-center'}
})
export class CardSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
