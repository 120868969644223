import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  FeedbackClient,
  SaloonClient,
  RefreshService,
  SpecialitiesDirectoryService,
  TrackingService,
  PriceListVm, MasterListClient,
} from "bc-common";
import {ActionSheetController} from "@ionic/angular";
import {combineLatest, firstValueFrom, of} from "rxjs";
import {map, startWith, switchMap, tap} from "rxjs/operators";

class Vm {
  public saloon: SaloonClient.Saloon;
  public priceList: PriceListVm.PriceListVm;
}

@Component({
  selector: 'app-saloon-history',
  templateUrl: './saloon-history.component.html',
  styleUrls: ['./saloon-history.component.scss'],
})
export class SaloonHistoryComponent implements OnInit {

  public saloon;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private masterListClient: MasterListClient.MasterListClient,
    private saloonsClient: SaloonClient.SaloonsClient,
    private specialitiesDirectoryService: SpecialitiesDirectoryService,
    private actionSheetController: ActionSheetController,
    private refreshService: RefreshService,
    private trackingService: TrackingService,
  ) {
  }

  ngOnInit() {

    combineLatest([
      this.refreshService.refreshAndResume$.pipe(startWith(null)),
      this.activatedRoute.paramMap,
    ]).pipe(
      map(([x, paramMap]) => paramMap),
      map((paramMap): [string, number] => [paramMap.get('id'), parseInt(paramMap.get('refCode'))]),
      tap(([saloonId, refCode]) => refCode ? this.trackingService.setSaloonRefCode(refCode) : null),
      switchMap(([saloonId, refCode]) => saloonId ?
        firstValueFrom(this.saloonsClient.getSaloonById(saloonId).pipe(map(res => res))) :
        firstValueFrom(this.saloonsClient.getSaloonByReferalCode(refCode).pipe(map(res => res)))
      ),
      tap((saloonRes) => {
        this.saloon = saloonRes.saloon;
      })
    ).subscribe();
  }

  public async onBwfDetailsClicked(bwf: FeedbackClient.BookingWithFeedback): Promise<void> {
    const actionSheet = await this.actionSheetController.create({
      header: bwf.serviceTypes.map(t => t.name).join(", "),
      buttons: [
        {
          text: 'Записаться так же',
          handler: async () => {
            await this.router.navigate(['bookings', 'new'], {
              queryParams: {
                saloonId: bwf.addressSaloonId,
                serviceTypeIds: bwf.serviceTypes.map(st => st.id)
              }
            });
          }
        }
      ]
    });

    await actionSheet.present();
    return;
  }

}
