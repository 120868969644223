import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-submit-button',
  templateUrl: './form-submit-button.component.html',
  styleUrls: ['./form-submit-button.component.scss'],
})
export class FormSubmitButtonComponent implements OnInit {

  @HostBinding('className') componentClass: string = "card transparent padding-mobile";
  @Input()
  public disabled: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }
}
