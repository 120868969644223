import {Directive} from "@angular/core";
import {IonRouterOutlet, Platform} from "@ionic/angular";

@Directive({
  selector: 'ion-router-outlet'
})
export class OutletAnimationControllingDirective {
  constructor(
    private outlet: IonRouterOutlet,
    private platform: Platform,
  ) {
    if(this.platform.is('desktop'))
    {
      outlet.animated = false;
    }
  }
}
