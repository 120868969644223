import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClientVm} from "../../../client/clients-vm";
import {ModalController, ToastController} from "@ionic/angular";
import {FormControl} from "@angular/forms";
import {combineLatest, debounceTime, Subject} from "rxjs";
import {SubscriptionsBag} from "../../../utils/subscriptions-bag";
import {AuthService} from "../../../security/auth.service";
import {MasterListClient, AddClientReq as AddMasterClientReq} from "../../../api-clients/master-list/clients";
import {SaloonsClient, AddClientReq as AddSaloonClientReq} from "../../../api-clients/saloon/clients";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {BookingWizardController, BookingWizardSettings} from "../../controller";
import {ApplicationIdService} from "../../../application-id/application-id.service";
import {AddClientComponent} from "../../../master-clients/add-client/add-client.component";
import {ToastService} from "../../../toast/toast.service";
import {NavigationService} from "../../../utils/navigation.service";

class Vm {
  public clients: ClientVm[] = [];
}

@Component({
  selector: 'lib-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {

  public searchControl: FormControl = new FormControl(null);

  public masterId: string = null;
  public saloonId: string;

  public vm: Vm = new Vm();

  private currentPageIndex: number = 0;
  private pageSize: number = 20;
  private clientsEndMeet: boolean = false;

  private refreshRequiredSubj = new Subject<void>();

  private sb = new SubscriptionsBag();

  public constructor(
    private _wizardSettings: BookingWizardSettings,
    private _wizardController: BookingWizardController,
    private _navigationService: NavigationService,
    private authService: AuthService,
    private mastersListClient: MasterListClient,
    private saloonsClient: SaloonsClient,
    private applicationIdService: ApplicationIdService,
    private modalController: ModalController,
    private toastService: ToastService,
  ) {
  }

  public async ngOnInit(): Promise<void> {

    if (this._wizardController.disposed || !this._wizardController.timeSlotData) {
      await this._navigationService.goBack(true);
      return;
    }

    this.masterId = this._wizardSettings.masterId;
    this.saloonId = this._wizardSettings.saloonId;

    this.sb.sub = combineLatest([
      this.authService.parsedToken$.pipe(map(t => t?.userId)),
      this.searchControl.valueChanges.pipe(
        debounceTime(500),
        startWith(this.searchControl.value)
      ),
      this.refreshRequiredSubj.pipe(startWith(null))
    ]).pipe(
      tap(() => {
        this.currentPageIndex = 0;
        this.clientsEndMeet = false;
      }),
      switchMap(([userId, search]) => this.loadClientsData(userId, search, this.currentPageIndex))
    ).subscribe((res) => {
      this.vm.clients = res;
    });
  }

  public async ionViewWillEnter(): Promise<void> {
    if (this._wizardController.disposed || !this._wizardController.timeSlotData) {
      this._wizardController.dispose();
      await this._navigationService.goBack();
      return;
    }
  }

  public ngOnDestroy(): void {
    this.sb.unsubscribeAll();
  }

  public async loadClientsData(masterId: string, search: string, pageIndex: number = 0): Promise<ClientVm[]> {

    const applicationId = this.applicationIdService.applicationId;

    if (applicationId === "master") {
      return await this.mastersListClient.getClients(
        masterId,
        search,
        [],
        pageIndex * this.pageSize,
        this.pageSize,
        false
      ).toPromise().then(res => res.clients.map(c => ClientVm.ParseFromMaster(c)));
    }

    if (applicationId === "saloon") {
      return await this.saloonsClient.getClients(
        this.saloonId,
        search,
        [],
        pageIndex * this.pageSize,
        this.pageSize,
        false
      ).toPromise().then(res => res.clients.map(c => ClientVm.ParseFromSaloon(c)));
    }

    throw new Error("Unknown application id");
  }

  public async loadClientsNextPage($event: any): Promise<void> {
    if (this.clientsEndMeet) {
      $event?.target?.complete();
      return;
    }

    let clientsRes = await this.loadClientsData(this.masterId, this.getSearchValue(), ++this.currentPageIndex);

    this.clientsEndMeet = clientsRes.length !== this.pageSize;
    this.vm.clients.push(...clientsRes);
    $event?.target?.complete();
  }

  public doRefresh($event: any = null): void {
    this.refreshRequiredSubj.next();
    $event?.target?.complete();
  }

  public onClientClick(client: ClientVm): void {
    this._wizardController.setClientVm(client);
  }

  private getSearchValue(search = this.searchControl.value) {
    if (search === '') {
      search = null;
    }
    if (search !== null) {
      search = search.trim();
    }
    return search;
  }

  public async onAddClientClick(): Promise<void> {

    const applicationId = this.applicationIdService.applicationId;

    const modal = await this.modalController.create({
      component: AddClientComponent,
      componentProps: {
        title: 'Новый клиент',
      },
    });

    modal.onDidDismiss().then(async e => {
      if (e.data != null) {
        try {
          if (applicationId === "master") {
            await this.mastersListClient.addClient({
              masterId: this._wizardController.timeSlotData.master.id,
              masterDefinedName: e.data.name.trim(),
              phone: e.data.phone,
              masterDefinedInstagramProfile: e.data.masterDefinedInstagramProfile?.toString()?.trim()
            } as AddMasterClientReq).toPromise();

            this.ngOnDestroy();
            this.ngOnInit();

            await this.toastService.info("Клиент добавлен");
          }

          if (applicationId === "saloon") {

            await this.saloonsClient.addClientForSaloon({
              saloonId: this.saloonId,
              saloonDefinedName: e.data.name.trim(),
              phone: e.data.phone,
              saloonDefinedInstagramProfile: e.data.masterDefinedInstagramProfile?.toString()?.trim()
            } as AddSaloonClientReq).toPromise();

            this.ngOnDestroy();
            this.ngOnInit();

            await this.toastService.info("Клиент добавлен");
          }

        } catch (ex) {
          if (ex.result.code == 'MASTER_HAS_CLIENT_WITH_SUCH_PHONE') {
            await this.toastService.error('Клиент с таким номером уже есть');
          }
        }
      }
    });

    await modal.present();

  }
}
