<ion-header>
  <ion-toolbar>
    <ion-title>Начало работы</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <lib-address-form-step3 #formStep3Component (onFormSubmit)="this.onFormSubmit($event)"></lib-address-form-step3>
  </div>
</ion-content>
<ion-footer *ngIf="!formStep3Component.submitted" class="hide-desktop ion-no-border">
  <lib-submit-button (click)="formStep3Component.onNextClick()">Продолжить</lib-submit-button>
</ion-footer>
