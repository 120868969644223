import { Component, OnInit } from '@angular/core';
import {Platform} from "@ionic/angular";
import {ToastService} from "../../toast/toast.service";
import {ApplicationIdService} from "../../application-id/application-id.service";

@Component({
  selector: 'lib-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
  host: {'class': 'ion-page'},
})
export class Step1Component implements OnInit {

  public activeApp: 'client' | 'master' | 'saloon' = 'client';

  constructor(
    public platform: Platform,
    private applicationIdService: ApplicationIdService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.activeApp = this.applicationIdService.applicationId || 'client';
  }

  public async onLinkClick(url: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(url);
      await this.toastService.success("Cкопировано");
    } catch {
      await this.toastService.error("Не получилось скопировать");
    }
  }

}
