import {concatMap, expand, interval, mergeMap, Subject, takeWhile, tap, timer} from "rxjs";
import {finalize, startWith, takeUntil} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {dateToUtc} from "../date-processing/functions";
import {FeedbackClient} from "../api-clients/feedback/clients";

@Injectable({
  providedIn: 'root'
})
export class BookingWaiter {
  public constructor(
    private _feedbackClient: FeedbackClient
  ) {

  }

  public wait(masterId: string, clientId: string, startTime: Date) {
    let unsubscribeLoad$ = new Subject();

    return new Promise((resolve, reject) => {
      interval(1000).pipe(
        takeUntil(unsubscribeLoad$),
        takeWhile((x, index) => index < 15),
        concatMap(() => this._feedbackClient.getBookingsWithFeedbacks(masterId,
          clientId,
          null,
          null,
          false,
          false,
          true,
          null,
          null,
          null,
          null,
          0,
          0)),
        tap((data) => {
          if (data.bookingsWithFeedbacks.some(b => dateToUtc(b.startTimeOffset).getTime() == startTime.getTime())) {
            unsubscribeLoad$.next(true);
            resolve(null);
          }
        }),
        finalize(() => {
          unsubscribeLoad$.next(true);
          resolve(null);
        })
      ).subscribe();
    });

  }

  public waitCanceled(bookingId: string) {
    let unsubscribeLoad$ = new Subject();

    return new Promise((resolve, reject) => {
      interval(1000).pipe(
        takeUntil(unsubscribeLoad$),
        takeWhile((x, index) => index < 15),
        concatMap(() => this._feedbackClient.getBookingWithFeedback(bookingId)),
        tap((data) => {
          if (data.canceledAt != null) {
            unsubscribeLoad$.next(true);
            resolve(null);
          }
        }),
        finalize(() => {
          unsubscribeLoad$.next(true);
          resolve(null);
        })
      ).subscribe();
    });
  }
}
