import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActionSheetController, AlertController, LoadingController, ModalController} from "@ionic/angular";
import {AuthService} from "../security/auth.service";
import {BookingWithFeedback, FeedbackClient} from "../api-clients/feedback/clients";
import {ActivatedRoute, Router} from "@angular/router";
import {hasSameDate} from "../date-processing/functions";
import {ToastService} from "../toast/toast.service";
import {MasterListClient, SpecialityRes} from "../api-clients/master-list/clients";
import {ApplicationIdService} from "../application-id/application-id.service";
import {Location} from "@angular/common";
import {ActivatedRouteFixService} from "../utils/activated-route-fix.service";
import {SpecialitiesDirectoryService} from "../directories/specialities-directory.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-booking-with-feedback',
  templateUrl: './booking-with-feedback.component.html',
  styleUrls: ['./booking-with-feedback.component.scss'],
})
export class BookingWithFeedbackComponent implements OnInit {
  @Input()
  public bwf: BookingWithFeedback;

  @Input()
  public mode: "client-bookings" | "master-bookings" | "master-feedbacks" | "profile-bookings" | "home-client-bookings" = "client-bookings";

  @Input()
  public index: number = 0;



  @Output()
  public detailsClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public clientClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public masterClicked: EventEmitter<void> = new EventEmitter<void>();

  private _now = new Date();
  private _tomorrow = new Date(new Date(this._now).setDate(this._now.getDate() + 1));

  public showMasterMessage: boolean = false;
  public isMasterClickable: boolean = false;

  public showClientMessage: boolean = false;
  public isClientClickable: boolean = false;

  public showMasterReply: boolean = false;

  public bookingSpecialises: Observable<string> = null;

  public constructor(
    private actionSheetController: ActionSheetController,
    private authService: AuthService,
    private feedbackClient: FeedbackClient,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private modalController: ModalController,
    private _alertController: AlertController,
    private _loadingController: LoadingController,
    private _toastService: ToastService,
    private _masterListClient: MasterListClient,
    private _specialitiesDirectory: SpecialitiesDirectoryService,
    public applicationIdService: ApplicationIdService
  ) {
  }

  public get isInFuture(): boolean {
    return this.bwf.startTime > this._now;
  }

  public get isCancelled(): boolean {
    return this.bwf?.isCanceled;
  }

  public get isInPast(): boolean {
    return this._now > this.bwf.endTime;
  }

  public get isToday(): boolean {
    return hasSameDate(this._now, this.bwf.startTime);
  }

  public get isTomorrow(): boolean {
    return hasSameDate(this._tomorrow, this.bwf.startTime);
  }

  public get isOnline(): boolean {
    return this.bwf.startTime < this._now && this.bwf.endTime > this._now;
  }

  public get isThisMineBooking(): boolean {
    const saloonIdsUserOwnerOf = this.authService?.parsedToken?.saloonIdsUserOwnerOf || [];
    const saloonIdsUserAdminIn = this.authService?.parsedToken?.saloonIdsUserAdminIn || [];

    const userId = this.authService?.parsedToken?.userId;
    return this.bwf.clientId === userId || this.bwf.masterId === userId ||
      saloonIdsUserAdminIn.some(s => s == this.bwf.addressSaloonId) ||
      saloonIdsUserOwnerOf.some(s => s == this.bwf.addressSaloonId);
  }

  public get isThereClientFeedback(): boolean {
    return !!this.bwf.clientStars || !!this.bwf.clientText || this.bwf.clientImagesFilesNames.length > 0;
  }

  public get isThereMasterFeedback(): boolean {
    return !!this.bwf.masterText || this.bwf.masterImagesFilesNames.length > 0;
  }

  public get isMoved(): boolean {
    return this.bwf?.isMovedOff;
  }

  public ngOnInit(): void {
    this.showMasterMessage = this.isThereMasterFeedback || (this.bwf?.cancelationText && this.bwf?.canceledByUserWithId == this.bwf?.masterId);
    this.isMasterClickable = false;

    this.showClientMessage = (this.mode == 'master-bookings') || this.isThereClientFeedback || (this.bwf?.cancelationText && this.bwf?.canceledByUserWithId == this.bwf?.clientId);
    this.isClientClickable = false;

    this.showMasterReply = !!this.bwf.masterReply?.trim();

    this.bookingSpecialises = this._specialitiesDirectory.getSpecialities([this.bwf.masterId])
      .pipe(
        map((specs) => {
          return specs
            .filter(spec => spec.groups.filter(group => group.serviceTypes.filter(st => this.bwf.serviceTypes.findIndex(bst => bst.id === st.id) !== -1).length > 0).length > 0)
            .map(s => s.name.toLowerCase())
            .join(', ');
        })
      );
  }

  public async onItemClick($event: MouseEvent) {
    if (this.mode == 'profile-bookings' || this.mode == 'home-client-bookings') {
      $event?.preventDefault();
      $event?.stopPropagation();
      await this.router.navigate(['/bookings/' + this.bwf.id])
    }
    else{
      $event?.preventDefault();
      $event?.stopPropagation();
      this.detailsClicked.emit();
    }
  }

  public get getDetailsCursor(): any {
    return {'cursor': this.isThisMineBooking ? 'pointer' : 'default'};
  }
}
