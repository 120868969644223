import {Pipe, PipeTransform} from '@angular/core';
import {Duration, DurationInputArg2} from "moment";

import * as tmp from 'moment';

const moment = tmp['default'];

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  daysString(d: number): string {
    if (d >= 10 && d <= 20) {
      return "дней";
    }

    switch (d % 10) {
      case 0:
        return "дней";
      case 1:
        return "день";
      case 2:
        return "дня";
      case 3:
        return "дня";
      case 4:
        return "дня";
      case 5:
        return "дней";
      case 6:
        return "дней";
      case 7:
        return "дней";
      case 8:
        return "дней";
      case 9:
        return "дней";
      default:
        return "дней";
    }
  }

  hoursString(h: number): string {
    if (h >= 10 && h <= 20) {
      return "часов";
    }

    switch (h % 10) {
      case 0:
        return "часов";
      case 1:
        return "час";
      case 2:
        return "часа";
      case 3:
        return "часа";
      case 4:
        return "часа";
      case 5:
        return "часов";
      case 6:
        return "часов";
      case 7:
        return "часов";
      case 8:
        return "часов";
      case 9:
        return "часов";
      default:
        return "часов";
    }
  }

  minutesString(m: number): string {
    if (m >= 10 && m <= 20) {
      return "минут";
    }

    switch (m % 10) {
      case 0:
        return "минут";
      case 1:
        return "минута";
      case 2:
        return "минуты";
      case 3:
        return "минуты";
      case 4:
        return "минуты";
      case 5:
        return "минут";
      case 6:
        return "минут";
      case 7:
        return "минут";
      case 8:
        return "минут";
      case 9:
        return "минут";
      default:
        return "часов";
    }
  }

  ds(d: Duration): string {
    if (d.days()) {
      return d.days() + " " + this.daysString(d.days());
    } else {
      return "";
    }
  }

  hs(d: Duration): string {
    if (d.hours()) {
      return d.hours() + " " + this.hoursString(d.hours());
    } else {
      return "";
    }
  }

  ms(d: Duration): string {
    if (d.minutes()) {
      return d.minutes() + " " + this.minutesString(d.minutes());
    } else {
      return "";
    }
  }

  transform(value: number, unit: DurationInputArg2): string {
    //return moment.utc(moment.duration(value, unit).asMilliseconds()).format('HH:mm');
    const dur = moment.duration(value, unit);

    // Оно округляет только до чего-то одного, не может написать 3 час 5 минут, только 3 часа напишет.
    // const humanized = dur.locale('ru').humanize({  });
    // return humanized;

    const ds = this.ds(dur);
    const hs = this.hs(dur);
    const ms = this.ms(dur);

    const res = ds + (hs.length ? " " + hs : "") + (ms.length ? " " + ms : "");

    return res;
  }

}

