<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button></lib-back-button>
    </ion-buttons>
    <ion-title>Время</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">

    <div class="cards-list safe-area-space">

      <app-calendar [date]="date" [daysDataProvider]="daysDataProvider"
                    (dayActivated)="onDayActivated($event)"></app-calendar>

      <div class="card padding-mobile transparent" style="padding-bottom: 0; padding-top: 20px;">
        <h3 class="ion-no-margin" *ngIf="days?.length > 0">
          Окошки на {{activatedDate | moment :'D MMMM'}}:
        </h3>
        <h3 class="ion-no-margin" *ngIf="days?.length == 0">
          Окошек нет
        </h3>
      </div>

      <lib-card-spinner *ngIf="daysDataProvider?.loading"></lib-card-spinner>

      <ng-container *ngIf="daysDataProvider?.loading == false">
        <div class="master-slots card padding" *ngFor="let day of days">
          <div class="master figure-1 clickable-square ripple-parent ion-activatable">
            <ion-avatar class="avatar">
              <lib-avatar *ngIf="day.master"
                          [name]="day.master?.fullName"
                          [avatarFileName]="day.master?.avatarFileName">
              </lib-avatar>
            </ion-avatar>
            <div class="text">
              <h3>
                {{day.master.fullName}}
              </h3>
              <div>
<!--                <ng-container  *ngIf="(day.priceMinWithDiscount != null && day.priceMaxWithDiscount != null) &&-->
<!--                  (day.priceMin != day.priceMinWithDiscount || day.priceMax != day.priceMaxWithDiscount); else notDiscount">-->
<!--                  <span style="color: #DA4B90;">-->
<!--                    {{day.priceMinWithDiscount | priceRange :day.priceMaxWithDiscount :day.master.priceList.currency}}-->
<!--                  </span>-->
<!--                  <span style="text-decoration: line-through">-->
<!--                    {{day.priceMin | priceRange :day.priceMax :day.master.priceList.currency}}-->
<!--                  </span>-->
<!--                </ng-container>-->
<!--                <ng-template #notDiscount>-->
<!--                  <span>-->
<!--                    {{day.priceMin | priceRange :day.priceMax :day.master.priceList.currency}}-->
<!--                  </span>-->
<!--                </ng-template>-->
                <span>
                  {{day.priceMin | priceRange :day.priceMax :day.master.priceList.currency}}
                </span>
                , {{day.durationInMinutes | duration :'minutes'}}
              </div>
              <div>
              <span *ngIf="day.address.addressSaloonId">
                {{day.address.addressSaloonName}}, {{day.address.addressLine1 | addressPipe :day.address.addressLine2}}
              </span>
              <span *ngIf="!day.address.addressSaloonId">
                {{day.address.addressLine1 | addressPipe :day.address.addressLine2}}
              </span>
              </div>
            </div>
            <div class="details">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </div>
            <ion-ripple-effect></ion-ripple-effect>
          </div>

          <div class="slots" *ngIf="days">
            <ng-container *ngFor="let slot of day.timeSlots">
              <div class="slot ripple-parent ion-activatable"
                   [ngClass]="{'preferable': slot.preferable}"
                   (click)="onSlotClick(day?.master, slot,day?.address)">
                <span class="time">{{slot.startTime | moment:'HH:mm'}}</span>
                <ng-container *ngIf="isDiscountOnDay(day)">
                  <span class="price"
                        *ngIf="slot.priceMin == day.priceMin && slot.priceMax == day.priceMax;else withDiscount">
                  {{slot.priceMin | priceRange :slot.priceMax :day.master.priceList.currency}}
                </span>
                  <ng-template #withDiscount>
                    <span class="price discount" *ngIf="slot.priceMin || slot.priceMax">
                      {{slot.priceMin | priceRange :slot.priceMax :day.master.priceList.currency}}
                    </span>
                  </ng-template>
                </ng-container>
                <span *ngIf="slot.fatalProblems.length > 0 || slot.problems.length > 0">*</span>
                <ion-ripple-effect></ion-ripple-effect>
              </div>
            </ng-container>
          </div>

          <div class="legend">
            <div>
              <div class="color normal"></div>
              <div class="desc">Окошко</div>
            </div>

            <div>
              <div class="color preferable"></div>
              <div class="desc">Удобно мастеру</div>
            </div>

            <div>
              <div class="color discount"></div>
              <div class="desc">Скидка</div>
            </div>
          </div>

        </div>
      </ng-container>

    </div>

  </div>


</ion-content>
