<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button defaultHref="/"></lib-back-button>
    </ion-buttons>
    <ion-title>Инфо</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">
  <div class="content-section">
    <lib-card-spinner *ngIf="!formGroup || processing == true"></lib-card-spinner>
    <form [formGroup]="formGroup" *ngIf="formGroup && processing == false" class="form-meta">
      <div class="cards-list form">
        <div class="card padding">
          <ion-list lines="none" class="classic">
            <ion-item>
              <ion-label position="stacked">О себе</ion-label>
              <ion-textarea #inp
                            formControlName="aboutMe"
                            [autoGrow]="true"
                            placeholder="Введите текст">
              </ion-textarea>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Фото</ion-label>
              <app-images-set [imagesInit]="this.master.aboutMeImagesNames"
                              title="Отзывы"
                              [allowDeleting]="true"
                              [allowAdding]="true"
                              (deleteClicked)="onImageDeleteClicked($event)"
                              (addClicked)="onImageAddClicked($event)"
                              [wrap]="true"
                              style="margin-top: 8px; width: 100%">
              </app-images-set>
            </ion-item>
          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block"
                (click)="onSaveClick()">Сохранить
    </ion-button>
  </div>
</ng-template>
