import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-card-empty',
  templateUrl: './card-empty.component.html',
  styleUrls: ['./card-empty.component.scss'],
  host: {'class': 'card padding ion-text-center'}
})
export class CardEmptyComponent implements OnInit {

  @Input()
  public text: string = "Пусто";
  constructor() { }

  ngOnInit() {}

}
