<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title>
      <ion-searchbar mode="ios" animated="false" [formControl]="searchControl" placeholder="Клиенты"></ion-searchbar>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="openAddClientForm()">
        <ion-icon name="add" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <!--  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">-->
  <!--    <ion-refresher-content></ion-refresher-content>-->
  <!--  </ion-refresher>-->

  <div class="content-section">
    <div class="cards-list">

      <lib-card-empty *ngIf="clientsVm?.length == 0"></lib-card-empty>

      <div class="card padding" *ngIf="clientsVm?.length > 0">
        <ion-list lines="none">
          <ion-item *ngFor="let client of clientsVm" class="client" button="true" detail="false"
                    (click)="onItemClick(client)">
            <lib-avatar *ngIf="client"
                        class="ion-margin-end" slot="start"
                        style="width: 40px; height: 40px"
                        [name]="client?.definedName ||  client?.originalName || 'Аноним'"
                        [avatarFileName]="client?.avatarFileName">
            </lib-avatar>
            <ion-label>
              <div>
                {{client.originalName | clientName : client.definedName}}&nbsp;
              </div>
              <p>
                {{client.phone ? client.phone : "Клиент салона. Номер скрыт"}}
              </p>
            </ion-label>
            <ion-icon *ngIf="client.isAd == true" name="person-add-outline" slot="end"></ion-icon>
          </ion-item>
          <span *ngIf="clientsVm.length == 0" class="ion-text-center">Пусто</span>
        </ion-list>


      </div>

      <ion-infinite-scroll (ionInfinite)="loadClientsNextPage($event)"></ion-infinite-scroll>
      <lib-card-spinner *ngIf="this.loading == true;"></lib-card-spinner>

    </div>
  </div>
</ion-content>
