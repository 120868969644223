<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div class="content-section">

    <form id="textInputForm">
      <div class="cards-list form">
        <div class="card padding">
          <ion-list lines="none" class="classic">
            <ion-item>
              <ion-label position="stacked">{{label || title}}</ion-label>
              <ion-input #inp [formControl]="control"></ion-input>
            </ion-item>
          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>

  </div>

</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button [disabled]="!control.valid"
                expand="block"
                (click)="onSaveClick()">Сохранить
    </ion-button>
  </div>
</ng-template>

