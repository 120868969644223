<ng-container>

  <ion-header [translucent]="true">
    <ion-toolbar>
      <ion-buttons slot="start">
        <lib-back-button></lib-back-button>
      </ion-buttons>
      <ion-title>{{vm.saloon?.name}}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content forceOverscroll="true">

    <lib-refresher slot="fixed"></lib-refresher>

    <div class="content-section cards-list safe-area-space">

      <div class="card main no-vertical-padding">

        <div class="image" style="position: relative">
          <div style="padding-bottom: 100%"></div>
          <img #firstImage
               (load)="firstImage.style.visibility = 'visible'"
               [src]="vm.saloon?.avatarFileName | fileUrl | mediumUrl"
               style="width: 100%; height: 100%; position: absolute; top: 0; right: 0; bottom: 0; left: 0; object-fit: cover; visibility: hidden"/>

        </div>

        <div class="info" *ngIf="vm.saloon">
          <div class="name">
            <h1>
              {{vm.saloon?.name}}
            </h1>
            <div>
              {{vm.saloon?.type}}
            </div>
          </div>
          <div class="addresses">
            <ion-list class="transparent">
              <ng-container *ngFor="let address of vm.saloon?.addresses; trackBy: trackById">
                <ion-item button="true" detail="false"
                          (click)="$event.preventDefault(); $event.stopPropagation(); onAddressClick(address)">
                  <ion-avatar slot="start">
                    <ion-icon name="location-outline"></ion-icon>
                  </ion-avatar>
                  <ion-label>
                    <h4>{{address.addressLine1}}</h4>
                    <p>{{address.addressLine2}}</p>
                  </ion-label>
                  <ion-button slot="end"
                              (click)="$event.preventDefault(); $event.stopPropagation(); onAddressClick(address)">Карта
                  </ion-button>
                </ion-item>
              </ng-container>
            </ion-list>
          </div>

          <div class="text" *ngIf="vm.saloon?.slogan">
            <p [innerText]="">
              {{vm.saloon?.slogan}}
            </p>
          </div>

          <ion-buttons class="action-buttons">
            <ion-button shape="round" (click)="onInfoClick()">
              <ion-label>
                <ion-icon class="info " name="information-circle-outline"></ion-icon>
                <label>Информация<br/>и контакты</label>
              </ion-label>
            </ion-button>
            <ion-button shape="round" (click)="onHistoryClick()">
              <ion-label>
                <ion-icon class="history" name="chatbubbles-outline"></ion-icon>
                <label>Работы<br/>и отзывы</label>
              </ion-label>
            </ion-button>
            <ion-button shape="round" (click)="onAddToBkClick()" *ngIf="saloonIsFavorites == false">
              <ion-label>
                <ion-icon class="add-green" name="heart-outline"></ion-icon>
                <label>Добавить в <br/> Бьюти Круг</label>
              </ion-label>
            </ion-button>
            <ion-button shape="round" (click)="onDeleteBkClick()" *ngIf="saloonIsFavorites == true">
              <ion-label>
                <ion-icon class="add-green" name="heart"></ion-icon>
                <label>У вас в <br/> Бьюти Круге</label>
              </ion-label>
            </ion-button>
          </ion-buttons>
        </div>
      </div>

      <app-your-bookings *ngIf="authService.isAuthenticated && vm?.saloon"
                         [saloonId]="vm.saloon.id"></app-your-bookings>

      <div class="card padding" *ngIf="this.vm?.masters.length > 0">
        <h2>
          <span>Мастера</span>
          <a routerLink="masters">Подробнее</a>
        </h2>
        <div class="masters-row">
          <div class="master" [routerLink]="'/masters/' + master?.id"
               *ngFor="let master of this.vm.masters; let i = index; trackBy: trackById">
            <lib-avatar [avatarFileName]="master?.avatarFileName"></lib-avatar>
          </div>
        </div>
      </div>

      <div class="card padding">
        <h2 class="online">Онлайн запись</h2>
        <ion-searchbar

          mode="ios"
          placeholder="Поиск"
          (ionChange)="this.vm.priceList.applySearch($event?.detail?.value)"
          style="margin-bottom: 20px">
        </ion-searchbar>

        <app-price-list
          class="ion-margin-vertical"
          [showSpecialities]="true"
          [coloredSecondary]="true"
          [priceList]="vm.priceList"
          (itemClicked)="onBookClick($event)">
        </app-price-list>

      </div>
    </div>


  </ion-content>

</ng-container>
