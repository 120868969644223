export class NetworkError extends Error {

  private readonly _originalError: Error = null;

  public get originalError(): Error {
    return this._originalError;
  }

  public constructor(parentError: Error) {
    super();
    this._originalError = parentError;
  }
}
