import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {
  BookingClient,
  BookingRes,
  CancelBookingReq,
  Item, SuggestNotificationSettingsRes, UpdateMasterNoteReq,
  UpdateNotificationReq
} from "../../api-clients/booking/clients";
import {ActivatedRoute, Router} from "@angular/router";
import {PickerColumnOption} from "@ionic/core";
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  PickerController, Platform
} from "@ionic/angular";
import {combineLatest, firstValueFrom, forkJoin, of} from "rxjs";
import {map, startWith, switchMap, take, tap} from "rxjs/operators";

import {NgEventBus} from "ng-event-bus";
import {
  BookingWithFeedback,
  FeedbackClient,
  FeedbackMasterReplyReq,
  SentClientFeedbackRequestReq
} from "../../api-clients/feedback/clients";
import {ClientVm} from "../../client/clients-vm";
import {MasterAddressRes, MasterListClient, SpecialityRes} from "../../api-clients/master-list/clients";
import {ToastService} from "../../toast/toast.service";
import {AuthService} from "../../security/auth.service";
import {SaloonsClient} from "../../api-clients/saloon/clients";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {ClientsClient} from "../../api-clients/clients/clients";
import {SpecialitiesDirectoryService} from "../../directories/specialities-directory.service";
import {ClientNamePipe} from "../../utils/client-name.pipe";
import {dateToMinutes, hasSameDate, minutesToDate} from "../../date-processing/functions";
import {
  ReasonForCancellationPopupComponent
} from "../../editors/reason-for-cancellation-popup/reason-for-cancellation-popup.component";
import {TextAreaPopupComponent} from "../../editors/text-area-popup/text-area-popup.component";
import {ActivatedRouteFixService} from "../../utils/activated-route-fix.service";
import {FeedbackPageComponent} from "../../booking-with-feedback/feedback-page/feedback-page.component";
import {BookingDetailsSettings} from "../controller";
import {NavigationService} from "../../utils/navigation.service";
import {AddressInfoComponent} from "../../address/address-info/address-info.component";
import {BookingWaiter} from "../../utils/booking-waiter";
import {RefreshService} from "../../utils/refresh.service";
import {SubscriptionsBag} from "../../utils/subscriptions-bag";

@Component({
  selector: 'lib-booking',
  templateUrl: './booking.page.html',
  styleUrls: ['./booking.page.scss'],
  host: {'class': 'ion-page'},
})
export class BookingPage implements OnInit, OnDestroy {

  public backUrl: string;

  public bookingId: string;

  public applicationId: "client" | "master" | "saloon";
  public feedbackUpdated: boolean = false;

  private _sb: SubscriptionsBag = new SubscriptionsBag();

  private _now = new Date();
  private _tomorrow = new Date(new Date(this._now).setDate(this._now.getDate() + 1));

  public booking: BookingRes;
  public bwf: BookingWithFeedback;
  public client: ClientVm;
  public sequenceFrequency: string;
  public clientName: string;
  public speciality: SpecialityRes[] = null;

  public masterNoteActivated: boolean = false;
  public reminderActivated: boolean = false;
  public reminderItemSelected: PickerColumnOption = null;
  public reminderItemSelectedIndex: number = null;
  public notificationSelected: boolean;


  public showMasterMessage: boolean = false;
  public isMasterClickable: boolean = false;

  public showClientMessage: boolean = false;
  public isClientClickable: boolean = false;

  public isThereMasterReply: boolean = false;
  public suggestNotificationSettings: SuggestNotificationSettingsRes;


  public frequencyItems = [
    {value: 1, text: 'повторять каждую неделю'},
    {value: 2, text: 'повторять каждые 2 недели'},
    {value: 3, text: 'повторять каждые 3 недели'},
    {value: 4, text: 'повторять каждые 4 недели'},
  ];

  public reminderPickerOptions: PickerColumnOption[] = [
    {text: 'За 1 час', value: 60},
    {text: 'За 2 часа', value: 120},
    {text: 'За 3 часа', value: 180},
    {text: 'За 6 часов', value: 360},
    {text: 'За 12 часов', value: 720},
    {text: 'За 24 часа', value: 1440},
    {text: 'За 2 дня', value: 2880},
    {text: 'За 3 дня', value: 4320},
  ];

  constructor(
    private _bookingDetailsSettings: BookingDetailsSettings,
    private _eventBus: NgEventBus,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _activatedRouteFix: ActivatedRouteFixService,
    private _navigationService: NavigationService,
    private _modalCtrl: ModalController,
    private _bookingClient: BookingClient,
    private _actionSheetController: ActionSheetController,
    private _feedbackClient: FeedbackClient,
    private _loadingController: LoadingController,
    private _toastService: ToastService,
    private _authService: AuthService,
    private _alertController: AlertController,
    private _pickerController: PickerController,
    private _masterListClient: MasterListClient,
    private _saloonClient: SaloonsClient,
    private _location: Location,
    private _applicationIdService: ApplicationIdService,
    private _platform: Platform,
    private _clientsClient: ClientsClient,
    private _specialitiesDirectory: SpecialitiesDirectoryService,
    private _bookingWaiter: BookingWaiter,
    private _refreshService: RefreshService,
  ) {
  }

  public ngOnInit(): void {
    this.applicationId = this._applicationIdService.applicationId;

    this._sb.sub = combineLatest([
      this._activatedRoute.paramMap.pipe(map(pm => pm.get('id')), tap(id => this.bookingId = id)),
      this._refreshService.refreshAndResume$.pipe(startWith(null))
    ]).pipe(
      switchMap(([bookingId]) => combineLatest([
        this._bookingClient.getBooking(bookingId),
        this._feedbackClient.getBookingWithFeedback(bookingId),
      ])),
      switchMap(([bookingRes, bwf]) => {
        const specs$ = this._specialitiesDirectory.getSpecialities([bookingRes.masterId]).pipe(take(1));
        const client$ = this.loadClientVm(bookingRes);
        const suggestNotificationSettings$ = firstValueFrom(this._bookingClient.suggestNotificationSettings(
          bwf.masterId,
          bwf.clientId,
          bwf.serviceTypes.map(st => st.id)
        ));
        return combineLatest([of(bookingRes), of(bwf), specs$, client$, suggestNotificationSettings$]);
      })
    ).subscribe(([bookingRes, bwf, spec, clientVm, suggestNotificationSettings]) => {
      this.booking = bookingRes;
      this.bwf = bwf;
      this.speciality = spec;
      this.suggestNotificationSettings = suggestNotificationSettings;

      this.sequenceFrequency = this.frequencyItems.find(item => this.booking.sequenceFrequency == item.value)?.text;

      this.reminderActivated = !!this.booking.reminderBufferInMinutes;
      this.masterNoteActivated = this.booking.masterNote != null;
      this.reminderItemSelected = this.reminderPickerOptions.find(item => item.value == this.booking.reminderBufferInMinutes);
      this.reminderItemSelectedIndex = this.reminderItemSelected
        ? this.reminderPickerOptions.map(item => item.value).indexOf(this.reminderItemSelected?.value) || null
        : null;

      this.notificationSelected = this.booking.notificationRequired;

      this.client = clientVm;
      this.clientName = new ClientNamePipe().transform(clientVm.originalName, clientVm.definedName);

      this.showMasterMessage = true;
      this.isMasterClickable = this.applicationId != 'master';

      this.showClientMessage = true;
      this.isClientClickable = this.applicationId != 'client';

      this.isThereMasterReply = !!this.bwf.masterReply?.trim();
    });
  }

  public ngOnDestroy() {
    this._sb.unsubscribeAll();
  }

  public async loadClientVm(bookingRes: BookingRes): Promise<ClientVm> {
    if (this.applicationId === "client") {
      return this._clientsClient.getClient(
        bookingRes.clientId,
        null).toPromise().then(value => ClientVm.ParseFromClient(value));
    }

    if (this.applicationId === "master") {
      return this._masterListClient.getClients(
        bookingRes.masterId,
        null,
        [bookingRes.clientId],
        0,
        1,
        true
      )
        .toPromise()
        .then(res => ClientVm.ParseFromMaster(res.clients[0]));
    }

    if (this.applicationId === "saloon") {
      return this._saloonClient.getClients(
        bookingRes.addressSaloonId,
        null,
        [bookingRes.clientId],
        0,
        1,
        true
      )
        .toPromise()
        .then(res => ClientVm.ParseFromSaloon(res.clients[0]));
    }

    throw new Error("Unknown mode");
  }

  public get isInFuture(): boolean {
    return this.booking.startTime > this._now;
  }

  public get isInPast(): boolean {
    return this._now > this.booking.endTime;
  }

  public get isOnline(): boolean {
    return this.booking.startTime < this._now && this.booking.endTime > this._now;
  }

  public get isCancelled(): boolean {
    return this.booking?.canceledAt != null;
  }

  public get isMoved(): boolean {
    return this.booking?.movedOffAt != null;
  }

  public get isToday(): boolean {
    return hasSameDate(this._now, this.booking.startTime);
  }

  public get isTomorrow(): boolean {
    return hasSameDate(this._tomorrow, this.booking.startTime);
  }

  public get isThisMineBooking(): boolean {
    const userId = this._authService?.parsedToken?.userId;
    return this.booking.clientId === userId || this.booking.masterId === userId;
  }

  public get isThereClientFeedback(): boolean {
    return !!this.bwf.clientStars || !!this.bwf.clientText || this.bwf.clientImagesFilesNames?.length > 0;
  }

  public get isThereMasterFeedback(): boolean {
    return !!this.bwf.masterText || this.bwf.masterImagesFilesNames?.length > 0;
  }

  public get isBookingSequence(): boolean {
    return this.booking?.sequenceId != null && !this.booking?.isSequenceCanceled
  }

  public get endTime(): Date {
    if (this.applicationId == 'client') {
      return minutesToDate(dateToMinutes(this.booking.startTime) + this.booking.serviceDurationInMinutes);
    } else {
      return this.booking.endTime;
    }

  }

  public getMinutesTimeDifference(date: Date): number {
    let timeDifference = date.getTime() - new Date().getTime();
    let minutesTimeDifference = Math.floor((timeDifference) / (1000 * 60));
    return minutesTimeDifference;
  }

  public getPriceItem(item: Item): string {
    if (item.priceMin != item.priceMax) {
      return `${item.priceMin} - ${item.priceMax} BYN`;
    } else {
      return `${item.priceMin.toString()} BYN`;
    }
  }

  public getPriceItemTotal(item: Item): string {
    if (item.priceMin != item.priceMax) {
      return `${this.CalcPrice(item.priceMin, item)} - ${this.CalcPrice(item.priceMax, item)} BYN`;
    } else {
      return `${this.CalcPrice(item.priceMin, item).toString()} BYN`;
    }
  }

  public CalcPrice(price, item): number {
    if (item.promoId == null) {
      return price;
    }

    let res: number;
    if (item.promoIsPercentValue == true) {
      res = price - item.promoValue * price / 100;
    } else {
      res = price - item.promoValue;
    }

    return res > 0 ? parseFloat(res.toFixed(2)) : 0;
  }

  public getPriceTotal(): string {
    let totalPriceMin: number = 0;
    let totalPriceMax: number = 0;
    this.booking.items.map(item => {
      totalPriceMin += this.CalcPrice(item.priceMin, item);
      totalPriceMax += this.CalcPrice(item.priceMax, item);
    })
    if (totalPriceMin != totalPriceMax) {
      return `${totalPriceMin.toFixed(2)} - ${totalPriceMax.toFixed(2)} BYN`;
    } else {
      return `${totalPriceMax.toFixed(2)} BYN`;
    }
  }

  public async onMasterFeedbackClick($event: MouseEvent) {
    $event.stopPropagation();

    if (this.applicationId != "master") {
      return;
    }

    const modal = await this._modalCtrl.create({
      component: FeedbackPageComponent,
      componentProps: {
        bwf: this.bwf,
        title: "Оформить работу",
      }
    });

    await modal.present();

    const data = (await modal.onDidDismiss())?.data;

    if (data?.refresh) {
      this.feedbackUpdated = true;
      this._refreshService.refresh();
    }
  }

  public async onClientFeedbackClick($event: MouseEvent) {
    $event.stopPropagation();

    if (this.applicationId != "client") {
      return;
    }

    const modal = await this._modalCtrl.create({
      component: FeedbackPageComponent,
      componentProps: {
        bwf: this.bwf,
        title: "Оставить отзыв",
      }
    });

    await modal.present();

    const data = (await modal.onDidDismiss())?.data;

    if (data?.refresh) {
      this.feedbackUpdated = true;
      this._refreshService.refresh();
    }
  }

  public async onVisitConfirmClick($event: MouseEvent) {
    $event.stopPropagation();

    this._feedbackClient.confirmVisit(this.bookingId).toPromise()
      .then(() => {
        this._toastService.info("Вы подтвердили, что придёте. Спасибо!");
        this.bwf.visitConfirmedAt = new Date();
      })
      .catch(() => this._toastService.info("Произошла ошибка"))
  }

  public async onNotificationClick() {
    if (this.isCancelled) {
      await this.toastThisIsCancelled();
      return;
    }
    if (this.isBookingSequence) {
      this.notificationSelected = true;
      await this.updateNotification();
      await this.toastNotificationUpdateCompleted();
    } else {
      if (this.isInFuture) {
        this.notificationSelected = true;
        await this.updateNotification();
        await this.toastNotificationUpdateCompleted();
      } else {
        await this.toastThisIsCompleted();
      }
    }
  }

  public async onUnchooseNotificationClick() {
    if (this.isCancelled) {
      await this.toastThisIsCancelled();
      return;
    }
    if (!this.isInFuture) {
      await this.toastThisIsCompleted();
      return
    }
    if (this.isBookingSequence) {
      this.notificationSelected = false;
      await this.updateNotification();
      await this.toastNotificationUpdateCompleted();
    } else {
      if (this.isInFuture) {
        await this.toastThisNotificationIsSent();
        return;
      }
    }
  }

  public setDefaultReminder(): boolean {
    const initialValue = !!this.booking.reminderBufferInMinutes;

    if (this.reminderActivated == initialValue) {
      return false;
    }

    setTimeout(() => {
      this.reminderActivated = initialValue;
    }, 0);

    return true;
  }

  public async activateReminder() {
    if (this.reminderItemSelected == null) {
      const reminderPickerItemsSelected = this.reminderPickerOptions
        .sort((a, b) => a.value - b.value)
        .find(x => x.value >= this.suggestNotificationSettings.reminderBufferInMinutes);

      if (reminderPickerItemsSelected) {
        this.reminderItemSelected = reminderPickerItemsSelected;
      } else {
        this.reminderItemSelected = this.reminderPickerOptions[5]; // 24 часа
      }

      this.reminderItemSelectedIndex = this.reminderPickerOptions.indexOf(this.reminderItemSelected);
    }
  }

  public async deactivateReminder() {
    this.reminderItemSelected = null;
    await this.updateNotification();
    await this.toastReminderUpdateCompleted();
  }

  public async toggleReminder($event): Promise<void> {

    $event.cancelBubble = true;
    $event.stopPropagation();
    $event.preventDefault();
    $event.stopImmediatePropagation();


    if (this.isCancelled) {
      if (this.setDefaultReminder()) {
        await this.toastThisIsCancelled();
      }
      return;
    }

    if (!this.isInFuture) {
      if (this.setDefaultReminder()) {
        await this.toastThisIsCompleted();
      }
      return;
    }

    const timeLeft = this.getMinutesTimeDifference(this.booking.startTimeOffset);
    const isReminderSent = this.reminderItemSelected?.value > timeLeft;

    if (isReminderSent) {
      if (this.setDefaultReminder()) {
        await this.toastThisReminderIsSent();
      }
      return;
    }

    if (this.reminderActivated == true) {
      this.reminderActivated = true;
      this.reminderItemSelected = {text: 'Пусто', value: null} as PickerColumnOption;
      await this.onReminderClick();
    } else {
      await this.deactivateReminder();
    }

  }

  public get isReminderSent() {
    const timeLeft = this.getMinutesTimeDifference(this.booking.startTimeOffset);
    return this.reminderItemSelected?.value > timeLeft;
  }

  public async onReminderClick() {
    if (this.isCancelled) {
      await this.toastThisIsCancelled();
      return;
    }

    if (!this.isInFuture) {
      await this.toastThisIsCompleted();
      return;
    }

    if (this.isReminderSent) {
      await this.toastThisReminderIsSent();
      return;
    }

    const options = this.reminderPickerOptions.map(x => ({...x})); // Копируем, потому-что, если юзать так, то айтемы в пикере скукожит https://stackoverflow.com/questions/56555593/ion-picker-options-overlaps
    this.reminderItemSelectedIndex = this.suggestNotificationSettings?.reminderBufferInMinutes
      ? options.map(o => o.value).indexOf(this.suggestNotificationSettings?.reminderBufferInMinutes)
      : 5;

    const reminderPicker = await this._pickerController.create({
      buttons: [
        {
          text: "ОТМЕНА",
          role: 'cancel'
        },
        {
          text: 'ОК',
          role: 'ok'
        }
      ],
      columns: [{
        name: 'reminderItems',
        options: options,
        selectedIndex: this.reminderItemSelectedIndex,
      }],
    });

    reminderPicker.onDidDismiss().then(data => {
      if (data.data == null) {
        this.reminderItemSelected = null;
        this.reminderActivated = false;
        return;
      }

      this.reminderItemSelected = data.data.reminderItems;
      this.updateNotification()
        .then(() => this.toastReminderUpdateCompleted());
    });

    await reminderPicker.present();
  }


  public async toastThisIsCompleted() {
    await this._toastService.warning("Нельзя изменить, запись уже прошла");
  }

  public async toastThisIsCancelled() {
    await this._toastService.warning("Нельзя изменить, запись отменена");
  }

  public async toastThisReminderIsSent() {
    await this._toastService.warning("Нельзя изменить, напоминание уже отправлено");
  }

  public async toastThisNotificationIsSent() {
    await this._toastService.warning("Нельзя изменить, уведомление уже отправлено");
  }

  public async toastReminderUpdateCompleted() {
    await this._toastService.success("Настройки напоминания сохранены");
  }

  public async toastNotificationUpdateCompleted() {
    await this._toastService.success("Настройки уведомления сохранены");
  }

  public async onBookingCancelClick() {
    const hasSequence = this.booking.sequenceId && !this.booking.isSequenceCanceled;

    let header = '';
    let info = '';

    let req: CancelBookingReq = new CancelBookingReq();
    req.bookingId = this.booking.id;

    if (hasSequence) {
      const actionSheet = await this._actionSheetController.create({
        header: "Отменить",
        buttons: [{
          text: 'Только эту запись',
          role: 'cancelBooking'
        }, {
          text: 'Эту запись и последующие повторы',
          role: 'cancelBookingSequence'
        },]
      });

      await actionSheet.present();

      const role = await actionSheet.onDidDismiss().then(x => x.role);

      if (role == "backdrop") {
        return;
      }
      if (role == "cancelBooking") {
        header = 'Отмена записи';
        info = 'Будет отменена только эта запись';
      }
      if (role == "cancelBookingSequence") {
        header = 'Отмена цепочки записей';
        info = 'Будет отменена эта запись и все последующие её повторы.';
        req.cancelSequence = true;
      }
    } else {
      header = 'Отмена записи';
      info = undefined;
    }

    const modal = await this._modalCtrl.create({
      component: ReasonForCancellationPopupComponent,
      componentProps: {
        title: header,
        info: info,
        val: ''
      },
    });

    modal.onDidDismiss().then(async e => {
      if (e.data?.cancelBooking == false) {
        return;
      }

      const loading = await this.createAndShowLoading("Отмена записи");

      req.cancelationText = e.data?.toString()?.trim();

      await this._bookingClient.cancelBooking(req).toPromise();

      // Чтобы эвенты на беке успели отработать
      await this._bookingWaiter.waitCanceled(this.booking.id);

      this._eventBus.cast('booking:canceled');
      await this.closeLoading(loading);
      await this._navigationService.goBack();
      await this._toastService.info(req.cancelSequence ? "Цепочка записей отменена" : "Запись отменена");
    });

    await modal.present();
  }

  public async onRepeatBookingClick() {
    await this._router.navigate(['../new'], {
      relativeTo: this._activatedRouteFix.getActivatedRoute(this._activatedRoute),
      queryParams: {
        masterId: this.booking.masterId,
        saloonId: this.booking.addressSaloonId,
        clientId: this.client.id,
        serviceTypeIds: this.booking.items.map(st => st.serviceTypeId),
        rnd: Math.random()
      }
    });
  }

  public async onMoveBookingClick() {
    const isBookingSequence = this.booking.sequenceId && !this.booking.isSequenceCanceled;
    let withSequence: boolean;


    if (!isBookingSequence) {
      withSequence = false;
    } else {
      const actionSheet = await this._actionSheetController.create({
        header: "Перенести",
        buttons: [{
          text: 'Только эту запись',
          role: 'moveBooking'
        }, {
          text: 'Эту запись и последующие повторы',
          role: 'moveBookingSequence'
        },]
      });

      await actionSheet.present();

      const role = await actionSheet.onDidDismiss().then(x => x.role);

      if (role == "backdrop") {
        return;
      }

      withSequence = role == 'moveBookingSequence';
    }

    await this._router.navigate(['move'], {
      relativeTo: this._activatedRouteFix.getActivatedRoute(this._activatedRoute),
      queryParams: {
        withSequence: withSequence,
      }
    }).then();

  }

  public async updateNotification() {
    const req = new UpdateNotificationReq();

    req.bookingId = this.booking.id;
    req.notificationRequired = this.notificationSelected;
    req.reminderBufferInMinutes = this.reminderItemSelected
      ? this.reminderItemSelected.value
      : null;

    await this._bookingClient.updateNotification(req).toPromise();
  }

  public get getClientCursorStyle() {
    return {'cursor': !!this._bookingDetailsSettings.navigateToClient ? 'pointer' : 'default'};
  }

  public get getMasterCursorStyle() {
    return {'cursor': !!this._bookingDetailsSettings.navigateToMaster ? 'pointer' : 'default'};
  }

  public async onClientClick() {
    if (await this._bookingDetailsSettings?.navigateToClient) {
      await this._bookingDetailsSettings.navigateToClient(this.booking.clientId);
    }
  }

  public async onMasterClick() {
    if (this._bookingDetailsSettings?.navigateToMaster) {
      await this._bookingDetailsSettings.navigateToMaster(this.booking.masterId);
    }
  }

  public async onRequestFeedbackClick($event: MouseEvent) {
    $event.stopPropagation();
    const req = new SentClientFeedbackRequestReq();
    req.bookingId = this.booking.id;

    await this._feedbackClient.sentClientFeedbackRequest(req).toPromise();

    this.bwf.feedbackRequestedAt = new Date();
  }

  public async onReplyClick($event: MouseEvent) {
    $event.stopPropagation();

    if (this.applicationId != "master") {
      return;
    }

    const modal = await this._modalCtrl.create({
      component: TextAreaPopupComponent,
      componentProps: {
        val: this.bwf.masterReply,
        title: "Ответ на отзыв"
      }
    });

    await modal.present();

    const masterReply = (await modal.onDidDismiss())?.data;

    if (masterReply) {
      const req = new FeedbackMasterReplyReq();
      req.bookingId = this.bwf.id;
      req.masterReply = masterReply?.toString()?.trim();

      await this._feedbackClient.updateFeedbackMasterReply(req).toPromise();

      this.bwf.masterReply = masterReply;
      this.feedbackUpdated = true;

      this._refreshService.refresh();
    }
  }

  public async onMasterNoteClick() {
    const modal = await this._modalCtrl.create({
      component: TextAreaPopupComponent,
      componentProps: {
        title: 'Примечание',
        val: this.booking.masterNote,
      },
    });

    modal.onDidDismiss().then(async e => {
      if (e.data == null) {
        if (this.booking.masterNote == null) {
          this.masterNoteActivated = false;
        }
        return;
      }

      if (e.data == '') {
        this.masterNoteActivated = false;
      }

      await this.updateMasterNote(e.data);
    });

    await modal.present();
  }

  public async onMasterNoteEnabledClick($event) {
    $event.cancelBubble = true;
    $event.stopPropagation();
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.masterNoteActivated) {
      await this.onMasterNoteClick();
    } else {
      await this.updateMasterNote(null);
    }
  }

  public async updateMasterNote(masterNote: string) {
    this.bwf.masterNote = masterNote;
    await this._bookingClient
      .updateMasterNote({bookingId: this.booking.id, masterNote: masterNote} as UpdateMasterNoteReq)
      .subscribe(() => {
        this._refreshService.refresh();
      });
  }

  public get bookingSpecialises(): string {
    return this.speciality
      .filter(spec => spec.groups.filter(group => group.serviceTypes.filter(st => this.bwf.serviceTypes.findIndex(bst => bst.id === st.id) !== -1).length > 0).length > 0)
      .map(s => s.name.toLowerCase())
      .join(', ');
  }

  public async onAddressClick(): Promise<void> {
    let address = {
      addressLine1: this.booking.addressLine1,
      addressLine2: this.booking.addressLine2,
      addressLatitude: this.booking.addressLatitude,
      addressLongitude: this.booking.addressLongitude,
      addressSaloonId: this.booking.addressSaloonId,
      addressSaloonName: this.booking.addressSaloonName,
      addressSaloonType: this.booking.addressSaloonType,
      howToGetText: this.booking.addressHowToGetText,
      howToGetImagesNames: this.booking.addressHowToGetImagesNames,
    } as MasterAddressRes;

    let options = {
      component: AddressInfoComponent,
      initialBreakpoint: undefined,
      breakpoints: undefined,
      componentProps: {
        title: this.bwf.masterName,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        addressSaloonId: address.addressSaloonId,
        addressSaloonType: address.addressSaloonType,
        addressSaloonName: address.addressSaloonName,
        addressLongitude: address.addressLongitude,
        addressLatitude: address.addressLatitude,
        addressHowToGetText: address.howToGetText,
        addressHowToGetImagesNames: address.howToGetImagesNames,
      }
    };

    const addressInfoModal = await this._modalCtrl.create(options);

    await addressInfoModal.present();
  }

  private async createAndShowLoading(text: string = null): Promise<HTMLIonLoadingElement> {
    const loading = await this._loadingController.create({
      message: text || 'Загрузка',
      backdropDismiss: true,
    });

    await loading.present();

    return loading;
  }

  private async closeLoading(loading: HTMLIonLoadingElement): Promise<void> {
    await this._loadingController.dismiss(loading);
  }
}
