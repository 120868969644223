import {firstValueFrom, Observable, ReplaySubject} from "rxjs";
import {ParamMap} from "@angular/router";
import {CitiesDirectoryService} from "./../directories/cities-directory.service";
import {SpecialitiesDirectoryService} from "./../directories/specialities-directory.service";
import {RegionsDirectoryService} from "./../directories/regions-directory.service";
import {NavigatorService} from "../navigator/navigator.service";
import {CityStore} from "../utils/city-store";

export class FiltersModel {
  public city: City = null;
  public region: Region = null;
  public specialities: Speciality[] = [];
  public latitude: number = null;
  public longitude: number = null;
  public sorting: number = null;
  private readonly changedSubj: ReplaySubject<FiltersModel>;

  constructor(
    private citiesDirectoryService: CitiesDirectoryService,
    private regionsDirectoryService: RegionsDirectoryService,
    private specialitiesDirectoryService: SpecialitiesDirectoryService,
    private navigatorService: NavigatorService,
  ) {
    this.changedSubj = new ReplaySubject<FiltersModel>(1);
  }

  public get changed$(): Observable<FiltersModel> {
    return this.changedSubj.asObservable();
  }

  public get isEmpty(): boolean {
    return this.city == null && this.specialities.length < 1 && this.sorting === null;
  }

  public static async fromParamMap(
    paramMap: ParamMap,
    citiesDirectoryService: CitiesDirectoryService,
    regionsService: RegionsDirectoryService,
    specialitiesDirectoryService: SpecialitiesDirectoryService,
    navigatorService: NavigatorService,
  ): Promise<FiltersModel> {
    const model = new FiltersModel(
      citiesDirectoryService,
      regionsService,
      specialitiesDirectoryService,
      navigatorService);

    const cityId = paramMap.get("cityId");
    const regionId = paramMap.get("regionId");
    const sorting = parseInt(paramMap.get("sorting"));
    const specialityIds = paramMap.getAll("specialityIds");
    const serviceTypeIds = paramMap.getAll("serviceTypeIds");

    await model.setCity(cityId);
    await model.setRegion(cityId, regionId);
    await model.setSorting(sorting);

    for (let specialityId of specialityIds) {
      await model.setSpeciality(specialityId);
    }

    for (let serviceTypeId of serviceTypeIds) {
      await model.setServiceType(serviceTypeId);
    }

    return model;
  }

  public toQueryParams(): any {
    return {
      cityId: this.city?.id,
      regionId: this.region?.id,
      sorting: this.sorting,
      specialityIds: this.specialities?.map(s => s.id),
      serviceTypeIds: this.specialities
        .map(s => s.serviceTypes)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .map(s => s.id)
    };
  }

  public async setCity(cityId: string): Promise<void> {
    const cities = await firstValueFrom(this.citiesDirectoryService.getCities());
    const city = cities.find(c => c.id === cityId) || null;

    if (city) {
      this.city = {
        id: city.id,
        name: city.name
      };
      this.region = null;
    } else {
      this.city = null;
      this.region = null;
    }

    this.changedSubj.next(this);
  }

  public async unsetCity(cityId: string): Promise<void> {
    if (this.city && this.city.id === cityId) {
      this.city = null;
      this.region = null;
    }

    this.changedSubj.next(this);

    for (const speciality of this.specialities) {
      await this.unsetSpeciality(speciality.id);
    }
  }

  public async setRegion(cityId: string, regionId: string) {
    const regions = await firstValueFrom(this.regionsDirectoryService.getRegions(cityId));
    const region = regions.filter(r => r.id === regionId)[0] || null;

    if (region) {
      this.region = {
        id: region.id,
        name: region.name
      };
    } else {
      this.region = null;
    }

    this.changedSubj.next(this);
  }

  public unsetRegion(regionId) {
    if (this.region && this.region.id === regionId) {
      this.region = null;
    }

    this.changedSubj.next(this);
  }

  public async setSorting(sorting: number): Promise<void> {
    if (!sorting) {
      this.unsetSorting();
      return;
    }

    if (sorting == 1) {
      try {
        const userLocation = await this.navigatorService.getUserCurrentLocation();
        this.latitude = userLocation.latitude;
        this.longitude = userLocation.longitude;
      } catch (e) {
        throw new Exception('CANT_GET_LOCATION', 'Не удалось определить вашу локацию!');
        this.unsetSorting();
      }
    }

    if (sorting == 4) {
      const res = this.unsetSortingIfNeeded();
      if (res) {
        throw new Exception('SERVICE_TYPE_NOT_SELECTED', 'Сначала выберите услугу!');
      }
    }

    this.sorting = sorting;

    this.changedSubj.next(this);
  }

  public unsetSorting(): void {
    this.sorting = null;
    this.changedSubj.next(this);
  }

  public unsetSortingIfNeeded(): boolean {
    if (!this.specialities.some(speciality => speciality.serviceTypes.length > 0) && this.sorting == 4) {
      this.unsetSorting();
      return true;
    } else {
      return false;
    }
  }

  public setLocation(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;

    this.changedSubj.next(this);
  }

  public unsetLocation() {
    this.latitude = null;
    this.longitude = null;

    this.changedSubj.next(this);
  }

  public async setSpeciality(specialityId: string, specialityIdToReplace: string = null): Promise<void> {
    const specialities = await firstValueFrom(this.specialitiesDirectoryService.getSpecialities());
    const speciality = specialities.filter(s => s.id === specialityId)[0] || null;

    let newSpec: Speciality;

    if (speciality) {
      newSpec = new Speciality();
      newSpec.id = speciality.id;
      newSpec.name = speciality.name;
    } else {
      newSpec = null;
    }

    if (this.specialities.find(s => s.id === newSpec.id)) {
      return;
    }

    const specialityIndex = this.specialities.findIndex(s => s.id === specialityIdToReplace);
    if (specialityIndex >= 0) {
      this.specialities[specialityIndex] = newSpec;
    } else {
      this.specialities.push(newSpec);
    }

    this.changedSubj.next(this);
  }

  public async unsetSpeciality(specialityId: string): Promise<void> {
    this.specialities = this.specialities.filter(s => s.id !== specialityId);
    this.unsetSortingIfNeeded();
    this.changedSubj.next(this);
  }

  public async setServiceType(serviceTypeId: string, serviceTypeIdToReplace: string = null): Promise<void> {
    const specialities = await firstValueFrom(this.specialitiesDirectoryService.getSpecialities());

    const serviceTypes: ServiceType[] = [];

    for (let spec of specialities) {
      let groups = [...spec.groups];

      while (groups.length > 0) {
        let group = groups.pop();
        for (let g of group.groups) {
          groups.push(g);
        }
        for (let st of group.serviceTypes) {
          const newServiceType = new ServiceType();

          newServiceType.id = st.id;
          newServiceType.name = st.name;
          newServiceType.specialityId = spec.id;

          serviceTypes.push(newServiceType);
        }
      }
    }

    const newServiceType = serviceTypes.find(st => st.id === serviceTypeId);
    let speciality = this.specialities.find(s => s.id === newServiceType.specialityId);

    if (!speciality) {
      await this.setSpeciality(newServiceType.specialityId);
      speciality = this.specialities.find(s => s.id === newServiceType.specialityId);
    }

    if (!speciality || speciality.serviceTypes.find(st => st.id === newServiceType.id)) {
      return;
    }

    const serviceTypeIndex = speciality.serviceTypes.findIndex(st => st.id === serviceTypeIdToReplace);
    if (serviceTypeIndex >= 0) {
      speciality.serviceTypes[serviceTypeIndex] = newServiceType;
    } else {
      speciality.serviceTypes.push(newServiceType);
    }

    this.changedSubj.next(this);
  }

  public async unsetServiceType(serviceTypeId: string): Promise<void> {
    for (let spec of this.specialities) {
      spec.serviceTypes = spec.serviceTypes.filter(s => s.id !== serviceTypeId);
    }

    this.unsetSortingIfNeeded();

    this.changedSubj.next(this);
  }

  public async clear(): Promise<void> {
    this.city = null;
    this.region = null;
    this.specialities = [];
    this.sorting = null;
    this.changedSubj.next(this);
  }

  public async clone(): Promise<FiltersModel> {
    const newModel = new FiltersModel(
      this.citiesDirectoryService,
      this.regionsDirectoryService,
      this.specialitiesDirectoryService,
      this.navigatorService);

    newModel.city = this.city;
    newModel.region = this.region;
    newModel.specialities = this.specialities.map(s => s.clone());
    newModel.sorting = this.sorting;
    newModel.longitude = this.longitude;
    newModel.latitude = this.latitude;
    return newModel;
  }

  public toDisplayString(): string {
    const strs = [];

    strs.push(this.city?.name);
    strs.push(...this.specialities.map(s => s.name));
    strs.push(...this.specialities.map(s => s.serviceTypes).reduce((prev, curr) => [...prev, ...curr], []).map(x => x.name));

    return strs.filter(s => !!s).join(', ');
  }
}

export class Exception {
  public code: string;
  public message: string;

  constructor(code, message) {
    this.code = code;
    this.message = message;
  }
}

export class City {
  public id: string;
  public name: string;
}

export class Region {
  public id: string;
  public name: string;
}

export class Speciality {
  public id: string;
  public name: string;

  public serviceTypes: ServiceType[] = [];

  public clone(): Speciality {
    const res = new Speciality();

    res.id = this.id;
    res.name = this.name;
    res.serviceTypes = this.serviceTypes.map(st => st.clone());

    return res;
  }
}

export class ServiceType {
  public id: string;
  public name: string;
  public specialityId: string;

  public clone(): ServiceType {
    const res = new ServiceType();
    res.id = this.id;
    res.name = this.name;
    res.specialityId = this.specialityId;

    return res;
  }
}

export class FiltersData {
  public city: City;
  public specialities: Speciality[] = [];
}
