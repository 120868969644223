import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from "./currency.pipe";

@Pipe({
  name: 'priceRange'
})
export class PriceRangePipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe
  ) {
  }

  public transform(value: number, maxValue: number, currency: number): string {
    const currencyCode = this.currencyPipe.transform(currency);
    if(value == maxValue){
      return `${value} ${currencyCode}`;
    } else{
      return `${value} - ${maxValue} ${currencyCode}`;
    }
  }

}

