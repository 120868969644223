import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'addressPipe'
})
export class AddressPipe implements PipeTransform {

  transform(addressLine1: string, addressLine2: string = null): string {
    if(!this.isNullOrWhiteSpace(addressLine2))
      return `${addressLine1}, ${addressLine2}`
    else
      return addressLine1;
  }

  isNullOrWhiteSpace(str){
    return str === null || str.match(/^ *$/) !== null;
  }
}

