import { Injectable } from '@angular/core';
import {AlertController} from "@ionic/angular";
import {ToastService} from "../toast/toast.service";

@Injectable({
  providedIn: 'root'
})
export class ContactsClickHandlerService {

  constructor(
    private _alertController: AlertController,
    private _toastService: ToastService,
  ) { }

  public async onPhoneClick(phone: string) {

    const alert = await this._alertController.create({
      message: phone,
      buttons: [
        {
          text: 'Скопировать',
          handler: () => {
            try {
              navigator.clipboard.writeText(phone).then();
              this._toastService.info("Cкопировано").then();
            } catch {
              this._toastService.error("Не получилось скопировать").then();
            }
          }
        },
        {
          text: 'Позвонить',
          handler: () => {
            var fallbackLink = 'tel:' + phone;
            window.open(fallbackLink);
          }
        }
      ]
    });
    await alert.present();
  }

  public async onInstagramClick(instagram: string) {
    console.log(instagram);
    const fallbackLink = instagram;
    window.open(fallbackLink, "_blank");
  }

  public async onVkClick(vk: string) {
    const fallbackLink = vk;
    window.open(fallbackLink, "_blank");
  }

  public async onViberClick(viber: string) {
    const fallbackLink = 'viber://chat?number=' + viber.replace('+', '%2B');
    window.open(fallbackLink);
  }

  public async onTelegramClick(telegram: string) {
    const fallbackLink = telegram.startsWith('http') ? telegram : 'https://' + telegram;//'https://t.me/preston';
    window.open(fallbackLink);
  }

  public async onSkypeClick(skype: string) {
    const fallbackLink = 'skype:' + skype + '?chat';
    window.open(fallbackLink);
  }
}
