import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {FiltersPage} from './filters.page';

import {PriceListModule, FiltersModule} from "bc-common";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PriceListModule,
        FiltersModule,
    ],
    exports: [
        FiltersPage
    ],
    declarations: [FiltersPage]
})
export class FiltersPageModule {
}
