import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Loader} from "@googlemaps/js-api-loader";
import {AddressLocationData} from "../../addressController";

@Component({
  selector: 'lib-address-form-step2',
  templateUrl: './address-form-step2.component.html',
  styleUrls: ['./address-form-step2.component.scss'],
})
export class AddressFormStep2Component implements OnInit {
  @Input()
  public addressLocation: google.maps.LatLng;

  @Output()
  public onFormSubmit: EventEmitter<AddressLocationData> = new EventEmitter<AddressLocationData>();

  public mapMarker: google.maps.Marker;

  private loader = new Loader({
    apiKey: "AIzaSyA1rGT_3ycjqg0RWD764N1gFJjrA9KOhXo",
    version: "weekly",
    language: "ru",
  });

  constructor() { }

  ngOnInit() {
    this.loader.load().then(async () => {
      let map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: this.addressLocation,
        zoom: 17,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
      });

      this.mapMarker = new google.maps.Marker({
        position: this.addressLocation,
        map: map,
        draggable: true,
      });
      map.setCenter(this.addressLocation);

      google.maps.event.addListener(map, 'center_changed', () => {
        this.mapMarker.setPosition(map.getCenter());
      });
    });
  }

  public async onNextClick() {
    this.onFormSubmit.emit({addressLocation: this.mapMarker.getPosition()});
  }
}
