<form [formGroup]="addressForm" *ngIf="addressForm">
  <div class="cards-lists">
    <div class="card padding">
      <ion-list lines="none" class="classic">
        <ion-item>
          <ion-label position="stacked">Город</ion-label>
          <ion-input formControlName="addressCity"></ion-input>
        </ion-item>
        <ion-row>
          <ion-col size="8" class="ion-no-padding" style="padding-right: 5px">
            <ion-item>
              <ion-label position="stacked">Улица</ion-label>
              <ion-input formControlName="addressStreet"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="4" class="ion-no-padding" style="padding-left: 5px">
            <ion-item>
              <ion-label position="stacked">Дом</ion-label>
              <ion-input formControlName="addressHouse"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-item>
          <ion-label position="stacked">Уточнение (кабинет, этаж)</ion-label>
          <ion-textarea formControlName="addressLine2"></ion-textarea>
        </ion-item>
      </ion-list>
    </div>
  </div>
  <div class="hide-mobile">
    <lib-submit-button [disabled]="addressForm.invalid" (click)="this.onNextClick()">
      {{buttonTitle}}
    </lib-submit-button>
  </div>
</form>
