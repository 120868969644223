import {Component, OnInit} from '@angular/core';
import {filter} from "rxjs/operators";
import {NavigationEnd, Router} from "@angular/router";
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public isMobile: boolean = null;
  public isPWA: boolean = null;

  public activatedUrl: string = null;

  constructor(
    private router: Router,
    private platform: Platform) {
    // this.platform.keyboardDidShow.subscribe(ev => {
    //   document.activeElement.scrollIntoView({behavior: "smooth", block: "center"});
    // });
  }

  public ngOnInit() {

    this.isMobile = this.platform.is('mobile');
    this.isPWA = this.platform.is('pwa');

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {

        const shortUrls = [
          '/home',
          '/bookings',
          '/profile',
          '/help',
          '/catalog',
          '/saloons',
        ];

        const longUrls = [
          '/masters',
          '/saloons',
          '/m',
          '/s'
        ];

        this.activatedUrl = null;

        this.activatedUrl = shortUrls.find(url => e.urlAfterRedirects.includes(url) && !e.urlAfterRedirects.includes(url + '/'));

        if (!this.activatedUrl) {
          this.activatedUrl = longUrls.find(url => e.urlAfterRedirects.includes(url) && e.urlAfterRedirects.includes(url + '/'));
        }

      });
  }
}
