<ion-header>
  <ion-toolbar>
    <ion-title>
      <ion-searchbar [formControl]="searchControl" mode="ios" placeholder="Специальность"
                     style="margin: 0"></ion-searchbar>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content forceOverscroll="true">
  <div class="content-section">
    <div class="cards-list">
      <div class="card padding">
        <ion-list>
          <ion-item *ngFor="let speciality of specialities$ | async" (click)="onSpecialityClick(speciality)"
                    button="true" detail="false">
            {{speciality.name}}
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
</ion-content>
