<ng-container *ngIf="step=='feedback'">
  <ion-header>
    <ion-toolbar>
      <ion-title>{{title}}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="onCloseClick()">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content force-overscroll="true">
    <div class="content-section">
      <form [formGroup]="formGroupFeedback">
        <div class="cards-list form">
          <div class="card padding">
            <ion-list lines="none" class="classic">
              <ion-item *ngIf="isThisClient()" class="item-interactive"
                        [ngClass]="{'ion-invalid': formGroupFeedback.controls.feedbackStars.invalid}">
                <lib-star-rating style="margin: auto" formControlName="feedbackStars"
                                 [applicationId]="this._applicationIdService.applicationId"></lib-star-rating>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">Отзыв</ion-label>
                <ion-textarea autoGrow="true" placeholder="Введите текст" formControlName="feedbackText"></ion-textarea>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">Фото</ion-label>
                <app-images-set title="Отзывы"
                                [imagesInit]="this.feedbackImages"
                                [allowDeleting]="true"
                                [allowAdding]="true"
                                (deleteClicked)="onImageDeleteClicked($event)"
                                (addClicked)="onImageAddClicked($event)"
                                [wrap]="true"
                                style="margin-top: 8px; width: 100%">
                </app-images-set>
              </ion-item>
            </ion-list>
          </div>
          <div class="hide-mobile">
            <ng-container *ngTemplateOutlet="save_button"></ng-container>
          </div>
        </div>
      </form>
    </div>
  </ion-content>
</ng-container>

<ng-container *ngIf="step == 'confirmation'">
  <ion-header>
    <ion-toolbar>
      <ion-title>Успех!</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="onCloseClick()">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content force-overscroll="true">
    <div class="content-section">
      <div class="confirmation">
        <h1 class="ion-margin-bottom">Спасибо за отзыв!</h1>

        <ion-row>
          <ion-col class="ion-no-padding" style="padding-right: 5px">
            <ion-button expand="block"
                        [routerLink]="['masters']">
              <ion-icon slot="start" name="people-outline"></ion-icon>
              Мастера
            </ion-button>
          </ion-col>
          <ion-col class="ion-no-padding" style="padding-left: 5px">
            <ion-button expand="block"
                        [routerLink]="['bookings']">
              <ion-icon slot="start" name="book-outline"></ion-icon>
              Мои записи
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </ion-content>
</ng-container>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="save_button"></ng-container>
</ion-footer>
<ng-template #save_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block"
                (click)="onSaveClick()">{{title}}
    </ion-button>
  </div>
</ng-template>


