<ion-header>
  <ion-toolbar>
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content force-overscroll="true">

  <div class="content-section">
    <form [formGroup]="formAddClient" *ngIf="formAddClient">
      <div class="cards-list form">
        <div class="card padding">
          <ion-list lines="none" class="classic">
            <ion-item>
              <ion-label position="stacked">Имя</ion-label>
              <ion-input #inp formControlName="nameControl" autofocus="true"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Телефон</ion-label>
              <ion-input formControlName="phoneControl"
                         [maxlength]="15"
                         autocomplete="tel"
                         inputmode="tel"
                         placeholder="Через плюс"
                         type="tel"
                         (keypress)="inputOnlyDigits($event)"
                         (paste)="onPaste($event)"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Instagram(не обязательно)</ion-label>
              <ion-input [formControl]="masterDefinedInstagramProfileControl"
                         placeholder="https://www.instagram.com/...">
              </ion-input>
            </ion-item>
          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>
  </div>

</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block"
                (click)="onSaveClick()">Добавить
    </ion-button>
  </div>
</ng-template>
