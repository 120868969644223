<div class="card no-vertical-padding transparent">

  <div class="grid">
    <div class="two ion-activatable ripple-parent" routerLink="/catalog"
         [queryParams]="{'specialityIds': 'b5981ebf-9d54-430b-a037-7756abcb5816'}">
      <img src="https://krasiviepeople.ru/wp-content/uploads/manikyur-modnye-tendencii.jpg"/>
      <p><span>Маникюр</span></p>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
    <div class="two ion-activatable ripple-parent" routerLink="/catalog"
         [queryParams]="{'specialityIds': '0467aa70-9ab4-46b0-b02b-c6cfac2ffe61'}">
      <img src="https://www.stylenews.ru/wp-content/uploads/2016/03/lashextensions.jpg"/>
      <p><span>Ресницы</span></p>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
    <div class="two ion-activatable ripple-parent" routerLink="/catalog"
         [queryParams]="{'specialityIds': '5348d8cf-f750-4d8f-8067-00e47e468701'}">
      <img src="assets/services/massague.jpg"/>
      <p><span>Массаж</span></p>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
    <div class="two ion-activatable ripple-parent" routerLink="/catalog">
      <div class="all"></div>
      <p><span>Посмотреть всё</span></p>
      <ion-ripple-effect></ion-ripple-effect>
    </div>
  </div>

</div>
