import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Platform} from "@ionic/angular";
import {NotificationClient} from "../../api-clients/notification/client";
import {AuthService} from "../../security/auth.service";
import {BehaviorSubject, combineLatest, of} from "rxjs";
import {map, startWith, switchMap} from "rxjs/operators";
import {RefreshService} from "../../utils/refresh.service";
import {SubscriptionsBag} from "../../utils/subscriptions-bag";

const HIDDEN_BANNERS_STORAGE_KEY = "hidden_banners";

@Component({
  selector: 'lib-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.scss'],
})
export class BannerSliderComponent implements OnInit, OnChanges {
  @Input()
  public banners: Array<"install" | "active-masters" | "become-master" | "promos" | "empty-days" | "no-bookings"> = [];

  @Input()
  public maxLength: number;

  public bannersView: Array<"install" | "active-masters" | "become-master" | "promos" | "empty-days" | "no-bookings">;

  private sb = new SubscriptionsBag();
  private close_event = new EventEmitter<void>(null);

  private banners$: BehaviorSubject<Array<"install" | "active-masters" | "become-master" | "promos" | "empty-days" | "no-bookings">> =
    new BehaviorSubject<Array<"install" | "active-masters" | "become-master" | "promos" | "empty-days" | "no-bookings">>(this.banners);

  constructor(
    private platform: Platform,
    private notificationClient: NotificationClient,
    private authService: AuthService,
    private refreshService: RefreshService,
  ) {
  }

  ngOnInit() {
    this.sb.sub = combineLatest([
      this.authService.parsedToken$,
      this.banners$,
      this.refreshService.refreshAndResume$.pipe(startWith(null)),
      this.close_event.asObservable().pipe(startWith(null)),
    ]).pipe(
      switchMap(([token, banners]) => {
        return combineLatest([
          of(token?.userId),
          of(banners),
          of(JSON.parse(localStorage.getItem(HIDDEN_BANNERS_STORAGE_KEY)) ?? []),
          this.banners.some(b => b == "promos") && token?.userId ?
            this.notificationClient.getActiveConnection(token?.userId) : of(null)
        ])
      })
    ).subscribe(([userId, banners, notShowBanners, connection]) => {
      let bannersView = [];
      bannersView.push(...banners);
      for (let banner of bannersView) {
        if (notShowBanners.some(b => b == banner)) {
          bannersView = bannersView.filter(b => b != banner);
        }

        if (!userId) {
          bannersView = bannersView.filter(b => b == "become-master" || b == "active-masters");
        }

        if (banner == "promos" && connection != null && connection.botConnectionType != 0) {
          bannersView = bannersView.filter(b => b != "promos");
        }

        if (banner == "install" && (this.platform.is("pwa") || this.platform.is("desktop"))) {
          bannersView = bannersView.filter(b => b != "install");
        }
      }

      if (this.maxLength != null && this.banners.length > this.maxLength) {
        bannersView = bannersView.splice(0, this.maxLength);
      }

      this.bannersView = bannersView;
    });
  }

  public ngOnDestroy() {
    this.sb.unsubscribeAll();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['banners']) {
      this.banners$.next(changes['banners'].currentValue);
    }
  }

  public async onCloseBannerClick(banner: string) {
    let banners: Array<string> = JSON.parse(localStorage.getItem(HIDDEN_BANNERS_STORAGE_KEY)) ?? [];

    if (banners.some(b => b == banner) == false) {
      banners.push(banner);
    }

    localStorage.setItem(HIDDEN_BANNERS_STORAGE_KEY, JSON.stringify(banners));
    this.close_event.next();
  }
}
