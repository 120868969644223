import {Component, Input, OnInit} from '@angular/core';
import {ActionSheetController, LoadingController, ModalController} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {
  BookingWithFeedback,
  FeedbackClient,
  ClientFeedbackReq, MasterFeedbackReq, DeleteImageReq, UploadImageReq
} from "../../api-clients/feedback/clients";
import {ApplicationIdService} from "../../application-id/application-id.service";
import {MasterListClient} from "../../api-clients/master-list/clients";
import {AuthService} from "../../security/auth.service";
import {ToastService} from "../../toast/toast.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalComponentBase} from "../../modal-window/modal-window";
import {Location} from "@angular/common";
import {NgEventBus} from "ng-event-bus";

@Component({
  selector: 'lib-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.scss'],
})
export class FeedbackPageComponent extends ModalComponentBase implements OnInit {

  @Input()
  public bwf: BookingWithFeedback;

  @Input()
  public title: string = "Отзыв";

  public formGroupFeedback: FormGroup;
  public feedbackImages: string[] = [];

  public imagesAdded: string[] = [];
  public imagesDeleted: string[] = [];

  public feedbackText: string;

  public step: "feedback" | "confirmation" = "feedback";


  constructor(
    modalController: ModalController,
    router: Router,
    activatedRoute: ActivatedRoute,
    location: Location,
    private _actionSheetController: ActionSheetController,
    private _feedbackClient: FeedbackClient,
    public _applicationIdService: ApplicationIdService,
    private _authService: AuthService,
    private _mastersListClient: MasterListClient,
    private _toastService: ToastService,
    private _loadingController: LoadingController,
    private _router: Router,
    private _eventBus: NgEventBus,
  ) {
    super(modalController, router, activatedRoute, location);
  }

  ngOnInit() {
    super.init().then();

    const feedbackImages: string[] = [];

    if (this.isThisMaster()) {
      feedbackImages.push(...this.bwf.masterImagesFilesNames);
      this.feedbackText = this.bwf.masterText;
    }

    if (this.isThisClient()) {
      feedbackImages.push(...this.bwf.clientImagesFilesNames);
      this.feedbackText = this.bwf.clientText;
    }

    this.feedbackImages = feedbackImages;

    this.formGroupFeedback = new FormGroup({
      feedbackText: new FormControl(this.feedbackText, []),
    });

    if (this.isThisClient()) {
      this.formGroupFeedback.setControl("feedbackStars", new FormControl(this.bwf.clientStars, [Validators.required]))
    }
  }

  public isThisMaster(): boolean {
    return this._applicationIdService.applicationId == "master";
  }

  public isThisClient(): boolean {
    return this._applicationIdService.applicationId == "client";
  }

  public onImageAddClicked(images: string[]) {
    this.imagesAdded.push(...images);
  }

  public onImageDeleteClicked(image: string) {
    if (this.imagesAdded.some(i => i == image)){
      this.imagesAdded = this.imagesAdded.filter(i => i != image);
    }
    else {
      this.imagesDeleted.push(image);
    }
  }

  public async onSaveClick(): Promise<void> {
    if (this.formGroupFeedback.invalid) {
      return;
    }

    const loading = await this._loadingController.create({
      message: 'Загрузка...',
    });

    await loading.present();

    await this.postFeedback();
    await this.uploadImages();

    await this._loadingController.dismiss();

    await super.dismiss({refresh: true});

    if (this._applicationIdService.applicationId == "client") {
      // this.step = "confirmation";
      // Временно выключен степ конфирмейшен, да и конфирмейшен плохое название для этого, т.к. юзер тут уже ничего не подтверждает
      await this._toastService.success('Спасибо за отзыв!');
      if (!this.bwf?.clientStars && !this.bwf?.clientText) {
        this._eventBus.cast("booking:feedbackChanged");
      } else {
        this._eventBus.cast("booking:feedbackUpdated");
      }
    } else {
      await this._toastService.success('Готово!');
    }
  }

  public async onCloseClick() {
    await super.dismiss({refresh: true});
    this.step = "feedback";
  }

  public async postFeedback() {

    if (this.isThisClient()) {
      const req = new ClientFeedbackReq();

      req.bookingId = this.bwf.id;
      req.stars = this.formGroupFeedback.controls.feedbackStars.value;
      req.text = this.formGroupFeedback.controls.feedbackText.value?.toString()?.trim();

      await this._feedbackClient.postClientFeedback(req).toPromise();
    }

    if (this.isThisMaster()) {
      const req = new MasterFeedbackReq();

      req.bookingId = this.bwf.id;
      req.text = this.formGroupFeedback.controls.feedbackText.value?.toString()?.trim();

      await this._feedbackClient.postMasterFeedback(req).toPromise();
    }
  }

  public async uploadImages() {
    for (let image of this.imagesAdded) {
      if (this.isThisMaster()) {
        await this._feedbackClient.uploadMasterImage({
          bookingId: this.bwf.id,
          temporaryFilePatch: image
        } as UploadImageReq).toPromise();
      }

      if (this.isThisClient()) {
        await this._feedbackClient.uploadClientImage({
          bookingId: this.bwf.id,
          temporaryFilePatch: image
        } as UploadImageReq).toPromise();
      }
    }

    for (let image of this.imagesDeleted) {
      const req = new DeleteImageReq();
      req.bookingId = this.bwf.id;
      req.imageName = image;

      if (this.isThisMaster()) {
        await this._feedbackClient.deleteMasterImage(req).toPromise();
      }
      if (this.isThisClient()) {
        await this._feedbackClient.deleteClientImage(req).toPromise();
      }
    }
  }
}
