import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NavigatorService} from "./navigator.service";

@NgModule({
  imports: [
    CommonModule,
  ]
})
export class NavigatorModule{
  static forRoot(): ModuleWithProviders<NavigatorModule> {
    return {
      ngModule: NavigatorModule,
      providers: [
        NavigatorService,
      ]
    };
  }
}
