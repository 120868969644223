<div class="card padding">

  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="onPrevMonthClick()">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title style="width: 100%" class="ion-text-center">
      {{activatedMonth?.date | moment:'MMMM YYYY'}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onNextMonthClick()">
        <ion-icon slot="icon-only" name="chevron-forward"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div class="calendar">

    <div class="dow header">пн</div>
    <div class="dow header">вт</div>
    <div class="dow header">ср</div>
    <div class="dow header">чт</div>
    <div class="dow header">пт</div>
    <div class="dow header">сб</div>
    <div class="dow header">вс</div>

    <ng-container *ngFor="let w of activatedMonth?.weeks">
      <ng-container *ngFor="let d of w.days">
        <div class="dow ripple-parent ion-activatable"
             [class]="d.dayData?.style || 'empty'"
             [ngClass]="{'active': d === activatedDay}"
             (click)="onDayClick(d)">
          {{d.date | moment:'D'}}
          <ion-ripple-effect></ion-ripple-effect>
        </div>
      </ng-container>
    </ng-container>

  </div>

</div>
