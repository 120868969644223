<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <lib-back-button #bb></lib-back-button>
    </ion-buttons>
    <ion-title>
      <span>
        Перерыв
      </span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="pause" forceOverscroll="true">
  <div class="content-section">
    <form [formGroup]="form">
      <div class="cards-list form">
        <div class="card padding">
          <ion-list lines="none" class="classic">
        <ion-item [ngClass]="{'lib-invalid': form.errors?.startTimeIsPast}">
          <ion-label position="stacked">День</ion-label>
          <ion-input formControlName="day" (click)="dateTime.openDatePickerForControl(form.controls.day)"
                     class="clickable date-picker">{{form.controls.day.value | moment : 'dddd, LL'}}</ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Начало</ion-label>
          <ion-input formControlName="start" (click)="dateTime.openTimePickerForControl(form.controls.start)"
                     class="clickable date-picker">{{form.controls.start.value | moment : 'LT'}}</ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="stacked">Конец</ion-label>
          <ion-input formControlName="end" (click)="dateTime.openTimePickerForControl(form.controls.end)"
                     class="clickable date-picker">{{form.controls.end.value | moment : 'LT'}}</ion-input>
        </ion-item>

        <ion-item>
          <ion-textarea formControlName="note" auto-grow="true" rows="3" placeholder="Введите текст"></ion-textarea>
        </ion-item>

          </ion-list>
        </div>
        <div class="hide-mobile">
          <ng-container *ngTemplateOutlet="submit_button"></ng-container>
        </div>
      </div>
    </form>
  </div>
</ion-content>
<ion-footer class="hide-desktop ion-no-border">
  <ng-container *ngTemplateOutlet="submit_button"></ng-container>
</ion-footer>
<ng-template #submit_button>
  <div class="card transparent padding-mobile">
    <ion-button expand="block"
                (click)="onOkClick($event)">Перенести
    </ion-button>
  </div>
</ng-template>

<lib-date-time #dateTime></lib-date-time>
