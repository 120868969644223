import {Component, OnInit} from '@angular/core';
import {MasterFirstSetupWizardController} from "../../../master-first-setup-wizard.controller";
import {AddressLineData} from "../../../../../address/addressController";

@Component({
  selector: 'lib-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  public submitted: boolean = false;

  constructor(
    private _wizardController: MasterFirstSetupWizardController
  ) {
  }

  ngOnInit() {
  }

  onFormSubmit(data: AddressLineData) {
    this.submitted = true;
    this._wizardController.addressData.setAddressLineData(data);
  }

}
