import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {CalendarComponent} from "./calendar/calendar.component";
import {MomentPipe} from "./moment.pipe";
import {DurationPipe} from "./duration.pipe";
import {CalendarPipe} from "./calendar.pipe";

@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [
    CalendarComponent,
    MomentPipe,
    DurationPipe,
    CalendarPipe,
  ],
  exports: [
    CalendarComponent,
    MomentPipe,
    DurationPipe,
    CalendarPipe,
  ],
  providers: [
    MomentPipe,
    DurationPipe,
    CalendarPipe,
  ]
})
export class DateProcessingModule {
}
