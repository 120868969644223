import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {IonInput, ModalController} from "@ionic/angular";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ActivatedRouteFixService} from "../../../utils/activated-route-fix.service";
import {AuthService} from "../../../security/auth.service";
import {AddClientReq, MasterListClient} from "../../../api-clients/master-list/clients";
import {ToastService} from "../../../toast/toast.service";
import {validatorInstagramLink} from "../../../editors/validation";
import {ClientsControllerProvider, MasterClientsController} from "../masterClientsController";

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
  providers: [
    {
      provide: MasterClientsController,
      useFactory: (provider: ClientsControllerProvider) => provider.provide(),
      deps: [ClientsControllerProvider]
    }
  ]
})
export class NewComponent implements OnInit {
  @ViewChild("inp", {static: true})
  public inp: IonInput;

  public formAddClient: FormGroup;
  public masterDefinedInstagramProfileControl: FormControl;

  private masterId: string;
  public processing: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activatedRouteFix: ActivatedRouteFixService,
    private authService: AuthService,
    private mastersListClient: MasterListClient,
    private toastService: ToastService,
    private masterClientController: MasterClientsController,
  ) {
  }

  ngOnInit() {
    this.masterId = this.authService.parsedToken?.userId;

    this.formAddClient = new FormGroup({
      nameControl: new FormControl(null, [
        Validators.required
      ]),
      phoneControl: new FormControl(null, [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(15),
        Validators.pattern("^([+][0-9]{12,15})$")
      ])
    });

    this.masterDefinedInstagramProfileControl = new FormControl(null, [
      validatorInstagramLink()
    ]);

    setTimeout(async () =>
      await this.inp?.setFocus());
  }

  public async onCloseClick() {
  }

  public async onSaveClick() {
    if (!this.formAddClient.valid) {
      return;
    }
    this.processing = true;

    const data = {
      name: this.formAddClient.controls.nameControl.value?.trim(),
      phone: this.formAddClient.controls.phoneControl.value?.trim(),
      instagram: this.masterDefinedInstagramProfileControl.value?.trim()
    }

    await this.mastersListClient.addClient({
      masterId: this.masterId,
      masterDefinedName: data.name,
      phone: data.phone,
      masterDefinedInstagramProfile: data.instagram
    } as AddClientReq).toPromise()
      .then(() => {
        this.masterClientController.notifyClientsUpdated();
        this.router.navigate(['/clients'], {relativeTo: this.activatedRouteFix.getActivatedRoute(this.activatedRoute)}).then();
      })
      .catch((ex) => {
        if (ex.result.code == 'MASTER_HAS_CLIENT_WITH_SUCH_PHONE') {
          this.toastService.error('Клиент с таким телефоном уже есть');
        }
      })
      .finally(() => this.processing = false);
  }


  inputOnlyDigits($event: any) {
    const pattern = /[0-9+]/;
    let inputChar = String.fromCharCode($event.charCode);
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
    }
  }

  onPaste($event: ClipboardEvent) {
    let pastedText = $event.clipboardData.getData("text");
    const pattern = new RegExp("^([+][0-9]{12,15})$");
    let test = pattern.test(pastedText);
    if (!test) {
      let trimmedText = pastedText.replace(/[^0-9+]/g, '');
      this.formAddClient.controls.phoneControl.setValue(trimmedText);
      $event.preventDefault();
    }
  }

}
